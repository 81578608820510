//src\store\slices\dishesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



export const uploadDishImageThunk = createAsyncThunk(
  'dishes/uploadDishImage',
  async ({ file, userId, category_id, dishId }, { rejectWithValue }) => {





    const formData = new FormData();
    formData.append('userId', userId); // Добавляем userId перед файлом
    formData.append('categoryId', category_id); // Добавляем categoryId перед файлом
    formData.append('dishId', dishId); // Добавляем categoryId перед файлом
    formData.append('image', file); // Добавляем файл
    // console.log('~ dishId = ', dishId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadDishImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // console.log('~ response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




// Async Thunk для удаления фото блюда
export const deleteDishImage = createAsyncThunk(
  'dishes/deleteDishImage',
  async ({ id, userId, categoryId }, { rejectWithValue }) => {
    try {
      // console.log('~ Вызвана deleteDishImage AsyncThunk');
      // console.log('~ userId deleteDishImage = ', userId);
      // console.log('~ categoryId deleteDishImage = ', categoryId);
      
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteDishImage/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { userId, categoryId } // передача дополнительных данных в теле DELETE запроса
      });

      // console.log('~ response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);



export const updateMenuOptionOnServer = createAsyncThunk(
  'dishes/updateMenuOption',
  async ({ id, newMenuOption }, thunkAPI) => {
    // console.log('~ Вызван updateMenuOptionOnServer');
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/updateMenuoption/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ menuoption: newMenuOption }),
      });

      if (response.ok) {
        const data = await response.json();
        return { id, newMenuOption };
      } else {
        return thunkAPI.rejectWithValue('Ошибка сервера');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка сети');
    }
  }
);



export const dishesSlice = createSlice({
  name: 'dishes',
  initialState: [],
  reducers: {
    setDishes: (state, action) => {
      return action.payload;
    },
    addDish: (state, action) => {
      state.push(action.payload);
    },
    updateDish: (state, action) => {
      findAndUpdateDish(state, action.payload);
      updateCategoryFlags(state, action.payload.id);
    },
    deleteDish: (state, action) => {
      const dishIndex = state.findIndex((dish) => dish.id === action.payload.id);
      if (dishIndex !== -1) {
        state.splice(dishIndex, 1);
      }
    },
    setDishes: (state, action) => {
      return enrichDishesWithFirstAndLastFlags(action.payload);
    },

  },

  extraReducers: (builder) => {
    builder.addCase(deleteDishAsync.fulfilled, (state, action) => {
      const { id } = action.payload; // Извлечение id из payload
      const dishIndex = state.findIndex((dish) => dish.id === id);
      if (dishIndex !== -1) {
        state.splice(dishIndex, 1);
      }
    }).addCase(updateMenuOptionOnServer.fulfilled, (state, action) => {
      const { id, newMenuOption } = action.payload;
      const dishIndex = state.findIndex((dish) => dish.id === id);
      if (dishIndex !== -1) {
        state[dishIndex].menuoption = newMenuOption;
      }
    }).addCase(deleteDishImage.fulfilled, (state, action) => {
      // Найти и обновить блюдо в состоянии
      const dishIndex = state.findIndex(dish => dish.id === Number(action.payload.id));
      // console.log('~ dishIndex = ', dishIndex);
      if (dishIndex !== -1) {
        state[dishIndex].img_url = null; // или какое-либо другое значение, указывающее на отсутствие изображения
      } 
    }).addCase(uploadDishImageThunk.fulfilled, (state, action) => {
      const dish = state.find((dish) => String(dish.id) === String(action.payload.id));

      // console.log('~ addCase uploadDishImageThunk сработал');
      // console.log('~ dish = ', dish);

      if (dish) {
        dish.img_url = action.payload.img_url;
      }
    })
  },

});

export const moveDish = createAsyncThunk(
  'dishes/moveDish',
  async (payload, thunkAPI) => {
    try {
      // console.log('~ Вызван moveDish в Слайсе');
      const { currentDishId, moveDirection } = payload;
      const state = thunkAPI.getState();
      // console.log('~ Полный стейт:', state);
      const dishesSlice = state.dishesSlice;
      if (!dishesSlice) {
        throw new Error("dishesSlice не найден в стейте");
      }
      // ... оставшийся код
    } catch (error) {
      console.error("Ошибка в moveDish:", error.message);
    }

    // console.log('~ Не вызывается ----');

  }
);


export const deleteDishAsync = createAsyncThunk(
  'dishes/deleteDish',
  async ({ id, categoryId, img_url, user_id }, { rejectWithValue }) => {
    // console.log('~ асинк deleteDishAsync');
    try {
      // Теперь у вас есть доступ к id, categoryId и img_url
      await axios.delete(`${process.env.REACT_APP_URL}/api/deleteDish/${id}`, {
        data: { categoryId, img_url, user_id } // передача categoryId и img_url на сервер, если это необходимо
      });
      return { id, categoryId, img_url }; // возвращение всех трех значений, если это необходимо
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




function determineFirstAndLastPositions(dishes) {
  let positions = {};

  for (let dish of dishes) {
    if (!positions[dish.category_id]) {
      positions[dish.category_id] = {
        min: dish.position,
        max: dish.position,
      };
    } else {
      if (dish.position < positions[dish.category_id].min) {
        positions[dish.category_id].min = dish.position;
      }
      if (dish.position > positions[dish.category_id].max) {
        positions[dish.category_id].max = dish.position;
      }
    }
  }

  return positions;
}

function enrichDishesWithFirstAndLastFlags(dishes) {
  let positions = determineFirstAndLastPositions(dishes);

  for (let dish of dishes) {
    dish.isFirstInCategory = dish.position === positions[dish.category_id].min;
    dish.isLastInCategory = dish.position === positions[dish.category_id].max;
  }

  return dishes;
}

function updateCategoryFlags(state, dishId) {
  const dishToUpdate = state.find(dish => dish.id === dishId);
  const dishesInCategory = state.filter(dish => dish.category_id === dishToUpdate.category_id).sort((a, b) => a.position - b.position);

  dishesInCategory.forEach((dish, index) => {
    dish.isFirstInCategory = index === 0;
    dish.isLastInCategory = index === dishesInCategory.length - 1;
  });
}

function findAndUpdateDish(state, updatedDish) {
  const dishIndex = state.findIndex(dish => dish.id === updatedDish.id);
  if (dishIndex !== -1) {
    state[dishIndex] = updatedDish;
  }
}



export const { addDish, updateDish, deleteDish, setDishes } = dishesSlice.actions;
export const selectDishes = (state) => state.dishes;
/* export const selectDishesTags  */
export default dishesSlice.reducer;
