import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { useSelector } from 'react-redux'; // <-- Импорт useSelector
import { useDispatch } from 'react-redux';
import { addNewBranchOnServer, setActiveBranch, setNewBranchId } from '../store/slices/branchSlice';
import { setStage, selectStage } from '../store/slices/branchSlice';
import { addTable } from '../store/slices/tableSlice';
import { selectAllTables } from '../store/slices/tableSlice';
import { Link } from 'react-router-dom';
//import { addBranchAsync } from '../store/slices/newBranchSlice';

import { useNavigate } from 'react-router-dom';
import { selectNewBranchId } from '../store/slices/newBranchSlice';

import {
  updateName,
  updateCity,
  updateAddress,
  updateLink,
  updateDescription,
  updateStatus,
  setFileData,
  addNewBranch,
} from '../store/slices/newBranchSlice';
import { selectUserId } from '../store/slices/userSlice';

const NewBranch = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');

  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(true);

  const userId = useSelector(selectUserId);

  const dispatch = useDispatch();

  // check link dublicate
  const handleLinkBlur = async (event) => {
    // console.log('~ Сработал handleLinkBlur');
    const link = event.target.value;
    const regex = /^[a-zA-Z0-9-_]+$/; // Разрешены только буквы, цифры, дефисы и подчеркивания

    if (!regex.test(link)) {
      setUrlError('Ссылка содержит недопустимые символы');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/check_link?link=${link}`);

      if (response.data.exists) {
        setUrlError('Такая ссылка на филиал уже существует. Нужно придумать другую');
      } else {
        setUrlError(null); // Очистите ошибку, если ссылка уникальна
        //dispatch(updateLink(link));
      }
    } catch (error) {
      console.error(error);
      // Обработка ошибок сети или сервера здесь
    }
  };

  async function handleAddBranch() {
    if (urlError === null) {
      const action = await dispatch(
        addNewBranchOnServer({ name, address, city, description, url, userId }),
      );

      let newBranch = null;

      if (action.type.endsWith('/fulfilled')) {
        newBranch = action.payload;

        navigate(`/editbranch/${newBranch.id}`, { state: { newBranch } });
        // console.log('~ newBranch = ', newBranch);
      }
      if (newBranch) {
        // console.log('~ newBranch = ', newBranch);
        // console.log('~ newBranch id = ', newBranch.id);
      }
    }
  }

  return (
    <div>
      <div className="py-10 max-w-xl">
        <div className="text-field text-field_floating-2 ">
          <input
            type="text"
            className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            id="basic-url"
            ariaDescribedby="basic-addon3"
            placeholder='Ресторан "Сказка"'
            onChange={(e) => setName(e.target.value)}
            name="name-branch"
          />
          <label class="text-field__label" for="name-branch">
            Название филиала
          </label>
        </div>

        <div className="text-field text-field_floating-2 mt-5">
          <input
            type="text"
            className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            ariaDescribedby="basic-addon3"
            placeholder="Краснодар"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            name="city"
          />
          <label class="text-field__label" for="city">
            Город{' '}
          </label>
        </div>

        <div className="text-field text-field_floating-2 mt-5">
          <input
            type="text"
            className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            id="basic-url"
            ariaDescribedby="basic-addon3"
            placeholder="ул. Ломоносова д.1"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            name="address"
          />
          <label class="text-field__label" for="address">
            Адрес{' '}
          </label>
        </div>

        <label for="basic-url" className="mb-2 inline-block text-slate-700">
          URL ссылка на филиал *
        </label>
        <div className="relative mb-4 flex flex-wrap items-stretch">
          <span className="border-2 pt-2 pl-3 bg-slate-300 cursor-default" id="basic-addon3">
            https://app.restolink.ru/
          </span>
          <input
            type="text"
            className="border-2 p-2 "
            id="basic-url"
            ariaDescribedby="basic-addon3"
            placeholder="skazka"
            value={url}
            onBlur={handleLinkBlur}
            onChange={(e) => setUrl(e.target.value)}
          />
          {urlError && <div className="text-red-700">{urlError}</div>}
        </div>

        <div className="relative flex flex-wrap flex-col items-stretch">
          <label for="basic-url w-full" className="mb-2 inline-block text-slate-700">
            Информация для клиентов{' '}
          </label>
          <textarea
            id="myTextArea"
            className="textarea-1"
            ariaLabel="With textarea"
            placeholder="Время работы, условия обслуживания, пароль от Wi-Fi, формы оплаты и т.д."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
          />
        </div>
        {/*  <div>
          <div>
            <h2>Логотип филиала (необязательно)</h2>
            {previewImage && <img className="my-2" src={previewImage} alt="Preview" />}
            <input type="file" name="logo" onChange={onFileChange} />
          </div>
        </div> */}
        <div className="flex justify-end mt-3">
          <Link to="/branches">
            <div>
              <button className="button-cancel w-24">
                Отмена
              </button>
            </div>
          </Link>
          <div className="ml-3 ">
            <button
              className={`button-s w-24 ${
                urlError || name === ''
                  ? 'bg-slate-300 text-slate-100 cursor-not-allowed'
                  : 'bg-blue-300 hover:bg-blue-400 text-slate-800'
              }`}
              disabled={urlError || name === ''}
              onClick={handleAddBranch}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBranch;
