import React from 'react';

const ToggleSwitch = ({ isChecked, onToggle, label }) => {
  return (
    <div className="flex items-center">
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input type="checkbox" className="hidden" checked={isChecked} onChange={onToggle} />
          <div className={`block w-10 h-6 rounded-full ${isChecked ? 'bg-blue-600' : 'bg-gray-700'}`}></div>
          <div className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${isChecked ? 'transform translate-x-full' : ''}`}></div>
        </div>
      </label>
      <span className="ml-3 text-xl">{label}</span>
    </div>
  );
};

export default ToggleSwitch;
