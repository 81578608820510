import axios from 'axios';
import React, { useEffect, useState } from 'react';

const FoodPhotoBank = ({ closePhotoBank, selectImage }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/foodphotobank`);
        setImages(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (search.trim()) {
        setIsLoading(true);
        axios
          .get(`${process.env.REACT_APP_URL}/api/foodphotobank`, {
            params: { search: search },
          })
          .then((response) => {
            setImages(response.data);
          })
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [search]);

  return (
    <div className="bg-teal-100 mt-2">
      <input
        type="text"
        placeholder="Поиск блюда в фотобанке..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className=" border h-8 border-slate-400 w-full sm:w-2/3 md:w-2/3 pl-2 mt-3 ml-3"
      />
      {isLoading ? (
        <p>Загрузка...</p>
      ) : error ? (
        <p>Ошибка: {error}</p>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              gap: '10px',
            
            }}>
            {images.map((image) => (
              <div
                key={image.id}
                style={{textAlign: 'center', marginBottom: '20px',   width: '200px',
                height: 'auto' }}
                className="ml-3 mt-3">
                <img
                  src={ `${process.env.REACT_APP_URL}/${image.url}`}
                  alt={image.description}
                  style={{ width: 'auto', height: '200px', objectFit: 'contain' }}
                />
                <p style={{ wordWrap: 'break-word' }} className="text-xs font-normal">
                  {image.description}
                </p>
                <button
                  onClick={() => selectImage(`${process.env.REACT_APP_URL}/${image.url}`)}
                  className="pt-1 text-xs font-normal cursor-pointer text-blue-600">
                  Выбрать
                </button>
              </div>
            ))}
          </div>
          <button
            onClick={closePhotoBank}
            className="ml-3 bg-slate-300 hover:bg-slate-300 text-slate-800 font-sm py-2 px-4 my-3 rounded shadow-md">
            Отмена
          </button>
        </div>
      )}
    </div>
  );
};

export default FoodPhotoBank;
