//src\App.js
import './App.css';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import Sidebar from './components/Sidebar';
import BranchesPage from './pages/BranchesPage.jsx'
import MenuPage from './pages/MenuPage';
import NewBranchPage from './pages/NewBranchPage';
import TipsPage from './pages/TipsPage';
import PaymentPage from './pages/PaymentPage';
import FeedbackPage from './pages/FeedbackPage';
import OptionsPage from './pages/OptionsPage';
import EditBranchPage from './pages/EditBranchPage';
import DeliveryPage from './pages/DeliveryPage';
import LinksPage from './pages/LinksPage';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchBranches } from './store/slices/branchSlice';
import { useAuth } from './hooks/use-auth.js';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import SubscriptionPage from './pages/SubscriptionPage.jsx';
import ExternalPaymentPage from './pages/ExternalPaymentPage.jsx';
import AddPhotoFoodBank from './pages/AddPhotoFoodBank.jsx';
import ApiTestPage from './pages/ApiTestPage.jsx';
import EditBranchPageTips from './pages/EditBranchPageTips.jsx';
import ApiTestPage2 from './pages/ApiTestPage2.jsx';

import Gorygirodelivery from './components/Gorygirodelivery.jsx'



// TODO пока убрать систему тегов

const PageWrapper = ({ children }) => {




  const location = useLocation();
  const excludeSidebar = location.pathname === "/login" || location.pathname === "/register";
  return (
    <div className="lg:flex">
      {!excludeSidebar && <Sidebar />}
      <div className="w-full">
        {children}
      </div>
    </div>
  );
}


//TODO всегда нужно отслеживать состояние activeBranch, и синхронизировать его с localStorage, если в редаксе оно null, то брать его с localStorage или делать запрос к БД и устанавливать первый попавшийся


function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/extpayment/:uuid" element={<ExternalPaymentPage />} />
      <Route path="/gorygirodelivery" element={<Gorygirodelivery />} />


      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/branches" element={<BranchesPage />} />
        <Route path="/newbranch" element={<NewBranchPage />} />
        <Route path="/tips" element={<TipsPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/feedbacks" element={<FeedbackPage />} />
        <Route path="/options" element={<OptionsPage />} />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/editbranch/:branchId" element={<EditBranchPage />} />
        <Route path="/editbranchtips/:branchId" element={<EditBranchPageTips />} />
        <Route path="/links" element={<LinksPage />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/addfoodphotobank" element={<AddPhotoFoodBank />} />
        <Route path="/apitestpage" element={<ApiTestPage />} />
        <Route path="/apitestpage2" element={<ApiTestPage2 />} />
        

      </Route>

    </Routes>
  );
}

export default App;
