import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { selectBranch, updateBranchSubscriptions } from '../store/slices/branchSlice';
import {
  fetchDailyWithdrawalLimit,
  fetchUserBalance,
  selectUserId,
  updateChatId,
} from '../store/slices/userSlice';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

const SubscriptionPage = () => {
  const notify = () =>
    toast.success('Изменения сохранены', {
      duration: 4000,
      position: 'top-center',
    });
  const notifyAmount = () =>
    toast.error('Сумма платежа должна быть не меньше 50 руб. ', {
      duration: 4000,
      position: 'top-center',
    });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [amount, setAmount] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const branches = useSelector(selectBranch);
  const userId = useSelector(selectUserId);
  const [localBranches, setLocalBranches] = useState([]);

  const [monthFullPayment, SetMonthFullPayment] = useState([]);
  const [monthReviewPayment, SetMonthReviewPayment] = useState([]);
  const [chatId, SetChatId] = useState(useSelector((state) => state.user.chat_id_service_notify));

  const [monthAllPayment, SetMonthAllPayment] = useState(
    useSelector((state) => state.user.monthAllPayment),
  );
  const [discountRate, SetDiscountRate] = useState(
    useSelector((state) => state.user.discount_rate),
  );
  const [dailyAllPayment, SetDailyAllPayment] = useState([]);
  const [daysLeft, SetDaysLeft] = useState([]);

  const globalSettings = useSelector((state) => state.globalSettings.data);
  const dailyWithdrawalLimit = useSelector((state) => state.user.dailyWithdrawalLimit);
  // const chatId = useSelector((state) => state.user.chat_id_service_notify);

  // когда меняется branches, то они записываются в копию, для использования в calculateDailyDeduction
  useEffect(() => {
    setLocalBranches(branches || []);
  }, [branches]);

  useEffect(() => {
    calculateDailyDeduction(localBranches);
    SetMonthAllPayment(monthFullPayment + monthReviewPayment);
  }, [localBranches]);

  const { balance, balanceLoading } = useSelector((state) => state.user);
  const [subscriptions, setSubscriptions] = React.useState({});

  const subscriptionOptions = {
    off: 0,
    review: 1,
    full: 2,
  };

  const handleSaveChanges = async () => {
    // //console.log('~ 🛸 formattedDate = ', formattedDate);
    const updates = branches
      .filter((branch) => subscriptions[branch.id] !== undefined)
      .map((branch) => ({
        branchId: branch.id,
        subscriptionOption: subscriptionOptions[subscriptions[branch.id]],
      }));
    const payload = {
      updates,
      dailyAllPayment: dailyAllPayment,
      userId: userId,
      monthAllPayment,
      subscriptionEndDate,
      subscription_expiry: formattedDate,
    };

    try {
      await dispatch(updateBranchSubscriptions(payload));
      notify(); // Вызывается только если dispatch успешно выполнен
    } catch (error) {
      console.error('Ошибка при обновлении подписок филиала:', error);
      // Обработка ошибки, если необходимо
    }
  };

  const payment = () => {
    // // // console.log('~ 🛩amount === ', amount);
    if (parseFloat(amount) < 50) {
      notifyAmount();
      return;
    }
    setIsSidebarOpen(false);
    // // // console.log('~ Пошел процесс оплаты');
    // Здесь добавляем данные для оплаты, например, сумму и идентификатор пользователя
    const paymentData = {
      amount: amount, // Пример суммы
      userId: userId, // Пример идентификатора пользователя
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/pay`, paymentData)
      .then((response) => {
        // // //console.log('~ 🛴 Ответ сервера:', response.data);
        // // //console.log('~ 🛹 url = ', response.data.confirmation.confirmation_url);
        //navigate(response.data.confirmation.confirmation_url); //переадресация на оплату
        // Проверка, что URL начинается с http:// или https://
        if (/^https?:\/\//.test(response.data.confirmation.confirmation_url)) {
          window.location.href = response.data.confirmation.confirmation_url;
        } else {
          // Обработка случая, когда URL не начинается с http:// или https://
          console.error(
            'Получен неправильный URL для переадресации:',
            response.data.confirmation.confirmation_url,
          );
        }
        // Обработка успешной оплаты
      })
      .catch((error) => {
        console.error('Ошибка при оплате:', error);
        // Обработка ошибки оплаты
      });
  };

  // Функция для обработки изменения состояния переключателя
  const handleSwitchChange = (branchId, newValue) => {
    // Сначала создаем новую копию массива localBranches
    const newLocalBranches = [...localBranches];
    // Находим индекс филиала, который нужно обновить
    const branchIndex = newLocalBranches.findIndex((branch) => branch.id === branchId);
    // Если филиал найден, обновляем его subscription_options
    if (branchIndex !== -1) {
      newLocalBranches[branchIndex] = {
        ...newLocalBranches[branchIndex],
        subscription_options: subscriptionOptions[newValue],
      };
      // Обновляем состояние localBranches новым массивом
      setLocalBranches(newLocalBranches);
    }
    // Также обновляем состояние subscriptions
    setSubscriptions((prevSubscriptions) => ({
      ...prevSubscriptions,
      [branchId]: newValue,
    }));
  };

  /*   useEffect(() => {
    SetMonthAllPayment(monthFullPayment + monthReviewPayment);
  }, [localBranches]); */

  useEffect(() => {
    // Рассчитываем общую ежемесячную оплату без учета скидки
    const totalPaymentWithoutDiscount = monthFullPayment + monthReviewPayment;
    // Применяем скидку
    const discountMultiplier = 1 - discountRate / 100;
    const totalPaymentWithDiscount = totalPaymentWithoutDiscount * discountMultiplier;
    // Устанавливаем финальную ежемесячную оплату с учетом скидки
    SetMonthAllPayment(totalPaymentWithDiscount);
    // // // console.log('~ 📜📜📜 totalPaymentWithDiscount = ', totalPaymentWithDiscount);
  }, [localBranches, discountRate, monthFullPayment, monthReviewPayment]);

  useEffect(() => {
    dispatch(fetchUserBalance(userId)); // userId должен быть получен откуда-то
    dispatch(fetchDailyWithdrawalLimit(userId));
    // // // console.log('~ 🧶 balance = ', balance);
    // // // console.log('~ 🧶 dailyWithdrawalLimit = ', dailyWithdrawalLimit);
    SetDaysLeft(Math.floor(balance / dailyWithdrawalLimit));
  }, []);

  useEffect(() => {
    // Инициализация состояния подписок из Redux store
    const initialSubscriptions = branches.reduce((acc, branch) => {
      // Преобразование числового значения подписки в текстовый эквивалент
      const subscriptionKey = Object.keys(subscriptionOptions).find(
        (key) => subscriptionOptions[key] === branch.subscription_options,
      );
      acc[branch.id] = subscriptionKey || 'off'; // Если подписка не найдена, устанавливаем 'off'
      return acc;
    }, {});
    setSubscriptions(initialSubscriptions);
    if (globalSettings) {
      calculateDailyDeduction(branches);
    }
  }, [branches]);

  // Подготовка информации о подписках
  const noSubscriptionBranches = localBranches
    .filter((branch) => branch.subscription_options === 0)
    .map((branch) => branch.name);
  const reviewSubscriptionBranches = localBranches
    .filter((branch) => branch.subscription_options === 1)
    .map((branch) => branch.name);
  const fullSubscriptionBranches = localBranches
    .filter((branch) => branch.subscription_options === 2)
    .map((branch) => branch.name);
  // дата истечения подписки
  const subsExpDate = () => {
    const currentDate = new Date();
    // // console.log('~ currentDate = ', currentDate);
    // Количество дней, на которое хватит подписки
    // // console.log('~ 🌹 monthAllPayment перед расчетом daysLeft = ', monthAllPayment);
    SetDaysLeft(Math.floor(balance / (Math.round((dailyAllPayment / 31) * 100) / 100))); // Например, на 30 дней
    // // console.log('~ 💲💲💲 monthAllPayment = ', monthAllPayment);

    // Рассчитываем дату истечения подписки
    //const subscriptionEndDate = new Date(currentDate.getTime() + daysLeft * 24 * 60 * 60 * 1000);
    // //console.log('~ 🎶🎶🎶 subscriptionEndDate = ', subscriptionEndDate); //Wed Jan 03 2024 18:26:34 GMT+0300 (Москва, стандартное время)
  };

  // форматируем дату в yy.dd.yyyy
  function formatDate(subscriptionEndDate) {
    const parts = subscriptionEndDate.split('-'); // Разделение исходной строки
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    return `${day}.${month}.${year}`; // Формирование новой строки в формате DD.MM.YYYY
  }

  function saveChatId() {
    dispatch(updateChatId({ chatId, userId }));
  }

  // Расчет суммы ежедневного списания
  const calculateDailyDeduction = (branches) => {
    //////////////////////
    let baseFullPrice = Number(globalSettings.BaseFullPrice);
    let baseReviewPrice = Number(globalSettings.BaseReviewPrice);
    const freeFullCount = Number(globalSettings.FreeFullCount); // количество филиалов входяший в тариф Макс
    const freeReviewCount = Number(globalSettings.FreeReviewCount); // количество филиалов входяший в тариф Базовый
    const priceFullExtra = Number(globalSettings.PriceFullExtra); // цена превышения тариф Макс
    const priceReviewExtra = Number(globalSettings.PriceReviewExtra); // цена превышения тариф Макс

    let reviewSubscriptionCount = 0; // кол-во филиалов подписанных на Отзывы
    let fullSubscriptionCount = 0; // кол-во филиалов подписанных на Макс
    // Подсчет количества филиалов для каждой подписки
    branches.forEach((branch) => {
      if (branch.subscription_options === 1) {
        reviewSubscriptionCount++;
      } else if (branch.subscription_options === 2) {
        fullSubscriptionCount++;
      }
    });
    if (fullSubscriptionCount === 0) {
      SetMonthFullPayment(0);
    }
    if (fullSubscriptionCount <= freeFullCount && fullSubscriptionCount > 0) {
      SetMonthFullPayment(baseFullPrice);
    }
    if (fullSubscriptionCount > freeFullCount) {
      const countFullExtra = fullSubscriptionCount - freeFullCount;
      SetMonthFullPayment(countFullExtra * priceFullExtra + baseFullPrice);
    }

    if (reviewSubscriptionCount === 0) {
      SetMonthReviewPayment(0);
    }
    if (reviewSubscriptionCount <= freeReviewCount && reviewSubscriptionCount > 0) {
      SetMonthReviewPayment(baseReviewPrice);
    }
    if (reviewSubscriptionCount > freeReviewCount) {
      const countReviewExtra = reviewSubscriptionCount - freeReviewCount;
      SetMonthReviewPayment(countReviewExtra * priceReviewExtra + baseReviewPrice);
    }
    // Установить значения в состояние

    SetMonthAllPayment(monthFullPayment + monthReviewPayment);
    //dispatch(fetchDailyWithdrawalLimit(userId));
    subsExpDate();
  };

  useEffect(() => {
    // Рассчитываем общую ежемесячную оплату без учета скидки
    const totalPaymentWithoutDiscount = monthFullPayment + monthReviewPayment;
    // Применяем скидку
    const discountMultiplier = 1 - discountRate / 100;
    const totalPaymentWithDiscount = totalPaymentWithoutDiscount * discountMultiplier;
    const roundedMonthAllPayment = parseFloat(totalPaymentWithDiscount.toFixed(2));

    // Устанавливаем финальную ежемесячную оплату с учетом скидки
    SetMonthAllPayment(roundedMonthAllPayment);
  }, [monthFullPayment, monthReviewPayment]);

  useEffect(() => {
    // старый код, который применяет скидку ошибочно и на ежедневное списание
    // Рассчитываем ежедневный платеж без учета скидки
    //const dailyPaymentWithoutDiscount = monthAllPayment / 31;
    // Применяем скидку
    //const discountMultiplier = 1 - discountRate / 100;
    //const dailyPaymentWithDiscount = dailyPaymentWithoutDiscount * discountMultiplier;
    // Округляем и устанавливаем финальный ежедневный платеж
    //SetDailyAllPayment(Math.round(dailyPaymentWithDiscount * 100) / 100);

    // Прямой расчет ежедневного расхода на основе месячного расхода с учетом скидки
    const dailyPaymentWithDiscount = monthAllPayment / 31;
    // Округляем и устанавливаем финальный ежедневный платеж
    SetDailyAllPayment(Math.round(dailyPaymentWithDiscount * 100) / 100);
  }, [monthAllPayment]);

  /* const daysRemaining = Math.floor(balance / (Math.round((monthAllPayment / 31) * 100) / 100)); */
  const daysRemaining = Math.floor(balance / dailyAllPayment);
  const currentDate = new Date();
  const subscriptionEndDate = new Date(currentDate);

  subscriptionEndDate.setDate(subscriptionEndDate.getDate() + daysRemaining);

  // Форматирование даты для вывода (можно изменить формат по желанию)
  const formattedDate = `${subscriptionEndDate.getDate()}.${
    subscriptionEndDate.getMonth() + 1
  }.${subscriptionEndDate.getFullYear()}`;

  // //console.log('~ ✔✔✔ subscriptionEndDate = ', subscriptionEndDate);

  return (
    <div className="flex mb-10">
      <ToastContainer />
      <Sidebar />
      <div className="flex flex-col w-full">
        <div className="py-2 px-4 font-medium shadow-md flex flex-row justify-between">
          <div>
            {/* Кнопка для открытия/закрытия панели */}

            {/* Сама боковая панель */}
            <div
              className={`pl-6 fixed top-0 right-0 w-64 h-full bg-white shadow-2xl transform transition-transform ${
                isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
              }`}>
              {/* Контент панели */}
              <div className=" text-xl font-bold pt-5">Пополнение счета</div>
              <div className="pt-5 font-normal">Сумма пополнения</div>
              <div>
                <input
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                  name=""
                  id=""
                  className="p-2 w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2  block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="text-xs font-normal">Минимальный платеж 50 руб.</div>
              <button
                onClick={payment}
                className="mt-4 bg-blue-600 hover:bg-blue-500 font-sm py-2 px-4 rounded shadow-md text-white">
                Выбрать способ оплаты
              </button>
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="mt-4 hover:bg-slate-200 text-slate-800 font-sm py-2 px-4 rounded shadow-sm border">
                Отменить
              </button>
            </div>
          </div>

          <div>Управление подпиской на сервиc</div>
          <div>Баланс: {balance} ₽</div>
          <button
            onClick={() => setIsSidebarOpen(true)}
            className=" bg-blue-600 hover:bg-blue-500 text-slate-800 font-sm py-2 px-4 rounded shadow-md text-white">
            Пополнить
          </button>
          <div className="font-extralight">Скидка: {discountRate} %</div>
          {/*  <div className="font-thin">{dailyWithdrawalLimit} ₽ в день. </div> */}
          {/* <div className="font-thin">До. {formatDate(subsExpDate())}</div>*/}
          {/*  <div className="font-thin">До. subsExpDate()</div> */}
        </div>
        <div className="ml-4">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-4 py-2">Название</th>
                <th className="px-4 py-2">Адрес</th>
                <th className="px-4 py-2">Тариф</th>
              </tr>
            </thead>
            <tbody>
              {branches.map((branch, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{branch.name}</td>
                  <td className="border px-4 py-2">{branch.address}</td>
                  <td className="border px-4 py-2">
                    <select
                      onChange={(e) => handleSwitchChange(branch.id, e.target.value)}
                      value={subscriptions[branch.id] || 'off'}>
                      <option value="off">Выкл</option>
                      <option value="review">Тариф Отзыв</option>
                      <option value="full">Тариф Макс</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br></br>
          <div>
            Расход <span className="text-xl font-semibold"> {monthAllPayment}</span> руб. в месяц
          </div>
          <div>Ежедневно {dailyAllPayment} руб. в день</div>
          Текущего баланса хватит на{' '}
          {/* {Math.floor(balance / (Math.round((monthAllPayment / 31) * 100) / 100))} дн. */}
          {discountRate === '100.00' ? (
            <>
              <span className="font-bold text-lg">∞ </span> дней
            </>
          ) : (
            `${daysRemaining} дней`
          )}
          {discountRate === '100.00' ? <></> : <div>Подписка активна до: {formattedDate} </div>}
          <br />
          <div>
            <p className="mt-6">Всего филиалов: {branches.length}</p>
            <p className="mt-2">
              Тариф <span className="font-semibold"> Отзывы:</span>{' '}
              {reviewSubscriptionBranches.length} фил.
              <span> - {monthReviewPayment} руб. в месяц</span>
            </p>
            <p className="mt-2">
              Тариф <span className="font-semibold">Макс: </span> {fullSubscriptionBranches.length}{' '}
              фил.
              <span> - {monthFullPayment} руб. в месяц</span>
            </p>
            <p className="mt-2">Отключены: {noSubscriptionBranches.length} фил.</p>
          </div>
          <div className='mt-3'>
            <button
              onClick={() => {
                // Определение асинхронной функции внутри обработчика событий
                const handleButtonClick = async () => {
                  // Ожидание завершения асинхронной функции handleSaveChanges, если она возвращает Promise
                  await handleSaveChanges();
                  // После завершения асинхронной операции производим расчет
                  /* const deduction = calculateDailyDeduction(branches); */
                };

                // Вызов асинхронной функции
                handleButtonClick();
              }}
              className="button-s">
              Сохранить изменения подписки
            </button>
          </div>
          <div className="mt-5">chat_id Телеграм канала для отправки служебных уведомлений</div>
          <input
            placeholder="chat_id телеграм канала"
            className=" w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            type="text"
            name="name"
            value={chatId}
            onChange={(e) => SetChatId(e.target.value)}
            /* onBlur={() => saveTableChanges( table.name, table.id )}   */
          />
          <div className='mt-3'>
          <button onClick={saveChatId} className="button-s">
            Сохранить chat_id
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
