import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async action для получения всех тегов блюд
export const fetchDishTags = createAsyncThunk('dishTags/fetchDishTags', async () => {
  console.log('~ 🥓🥓🥓🥓🥓');
  // //console.log('~ Слайс /api/dish_tags отработал');
  const response = await axios.get(`${process.env.REACT_APP_URL}/api/dish_tags`);
  return response.data;
});

const dishTagsSlice = createSlice({
  name: 'dishTags',
  initialState: {
    tags: [],
    selectedTags: [], // Добавляем новое поле для выбранных тегов
    status: 'idle',
    error: null,
  },
  reducers: {
    // Добавляем редьюсер для добавления тега
    addSelectedTag: (state, action) => {
      state.selectedTags.push(action.payload);
    },
    // Добавляем редьюсер для удаления тега
    removeSelectedTag: (state, action) => {
      const index = state.selectedTags.indexOf(action.payload);
      if (index > -1) {
        state.selectedTags.splice(index, 1);
      }
    },
    // Добавляем редьюсер для очистки всех выбранных тегов
    clearSelectedTags: (state) => {
      state.selectedTags = [];
    },
    toggleTag: (state, action) => {
        // Найти тег с соответствующим ID и измените его статус "checked"
        const tagIndex = state.tags.findIndex((tag) => tag.id === action.payload);
        state.tags[tagIndex].checked = !state.tags[tagIndex].checked;
        // Добавьте или удалите тег из массива selectedTags в зависимости от статуса
        if (state.tags[tagIndex].checked) {
          state.selectedTags.push(state.tags[tagIndex]);
        } else {
          state.selectedTags = state.selectedTags.filter((tag) => tag.id !== action.payload);
        }
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDishTags.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDishTags.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tags = action.payload;
      })
      .addCase(fetchDishTags.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { addSelectedTag, removeSelectedTag, clearSelectedTags, toggleTag  } = dishTagsSlice.actions;

export default dishTagsSlice.reducer;
