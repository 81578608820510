import React, { useState } from 'react';
import { TiClipboard } from 'react-icons/ti';
import { PiCaretRightBold, PiCaretDownBold } from 'react-icons/pi';

const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      // console.log('~ Текст скопирован в буфер обмена');
    })
    .catch((err) => {
      console.error('Ошибка при копировании текста: ', err);
    });
};

const Links = ({ id, link, name, branchLink }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className=" pt-2 mb-6 mt-4 bg-slate-200 pl-2">
        <div className="">
          <div>Название столика:</div>
          <div className="font-semibold"> {name}</div>
        </div>
        <div className="pt-2">
          <div>Главная ссылка:</div>
          <div className="flex flex-row items-center">
            <div>
              <a href={`https://app.restolink.ru/${branchLink}?t=${link}`}>
              https://app.restolink.ru/{branchLink}?t={link}
              </a>
            </div>
            <div className="text-gray-600">
              <TiClipboard
                className="font-bold ml-2 "
                onClick={() => copyToClipboard(`https://app.restolink.ru/${branchLink}?t=${link}`)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>

        <div className="w-full max-w-md mt-4 pb-3">
          <div
            className="bg-slate-300 p-2 cursor-pointer flex flex-row items-center"
            onClick={() => setIsOpen(!isOpen)}>
            <div>
              {isOpen ? (
                <PiCaretDownBold className="mr-2" />
              ) : (
                <PiCaretRightBold className="mr-2" />
              )}
            </div>

            <div> Остальные ссылки</div>
          </div>
          {isOpen && (
            <div className="bg-slate-300 pl-4 pb-4 border-t border-gray-300">
              <div className="text-sm pt-4">
                <div>Прямая ссылка на отзыв:</div>
                <div>
                  <a href={`https://app.restolink.ru/${branchLink}/feedback?t=${link}`}>
                  https://app.restolink.ru/{branchLink}/feedback?t={link}
                  </a>
                </div>
              </div>
              <div className="text-sm pt-4">
                <div>Прямая ссылка на чаевые:</div>
                <div>
                  <a href={`https://app.restolink.ru/${branchLink}/tips?t=${link}`}>
                  https://app.restolink.ru/{branchLink}/tips?t={link}
                  </a>
                </div>
              </div>
              <div className="text-sm pt-4">
                <div>Прямая ссылка на оплату:</div>
                <div>
                  <a href={`https://app.restolink.ru/${branchLink}/payment?t=${link}`}>
                  https://app.restolink.ru/{branchLink}/payment?t={link}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};

export default Links;
