//src\hooks\use-auth.js - старая версия 
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setUser, removeUser } from '../store/slices/userSlice';

export function useAuth() {
    const dispatch = useDispatch();
    const { email, token, id, isLoading } = useSelector((state) => state.user);
  
    useEffect(() => {
       const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((token) => {
            dispatch(
              setUser({
                email: user.email,
                id: user.uid,
                token: token,
              })
            );
          });
        } else {
          dispatch(removeUser());
        }
      });
  
      return () => unsubscribe(); 
    }, [dispatch]);
  
    return {
      isAuth: !!email,
      isLoading,
      email,
      token,
      id,    
    };
  }
