// src\store\slices\newDishSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { selectUserId } from './userSlice';
import { selectActiveBranch } from './branchSlice';
import { setDishes } from './dishesSlice';
import { useDispatch, useSelector } from 'react-redux';




export const addNewDish = createAsyncThunk(
  'dishes/addNewDish',
  async (dishData, thunkAPI) => { // Измените второй аргумент на thunkAPI

    const { getState, dispatch, rejectWithValue } = thunkAPI;
    // console.log('~ dishes/addNewDish отработал');
    const userId = selectUserId(getState());
    const activeBranch = selectActiveBranch(getState());
    const activeBranchId = activeBranch.id;
    const { categoryId, ...newDish } = dishData;
    // console.log('~ categoryId = ', categoryId);

    try {
      const formData = new FormData();
      formData.append('category_id', categoryId);
      formData.append('userId', userId);
      formData.append('name', newDish.name);
      formData.append('description', newDish.description);
      formData.append('price', newDish.price);
      formData.append('ingredients', newDish.ingredients);
      formData.append('output', newDish.output);
      formData.append('cooking_time', newDish.cooking_time);
      formData.append('calories', newDish.calories);
      formData.append('file', newDish.fileData);
      formData.append('selectedTags', JSON.stringify(newDish.selectedTags));
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewDish`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const dishesResponse = await axios.get(`${process.env.REACT_APP_URL}/api/dishes/${activeBranchId}`);
      dispatch(setDishes(dishesResponse.data)); // Используйте dispatch, чтобы вызвать setDishes

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const newDishSlice = createSlice({
  name: 'newDish',
  initialState: {
    name: '',
    description: '',
    price: '',
    ingredients: '',
    output: '',
    cooking_time: '',
    calories: '',
    fileData: null, // Здесь можно хранить строку с данными файла, если нужно
    fileName: '',
    fileType: '',

    // другие поля
  },
  reducers: {
    setFileData: (state, action) => {
      state.fileData = action.payload.data;
      state.fileName = action.payload.name;
      state.fileType = action.payload.type;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    updatePrice: (state, action) => {
      state.price = action.payload;
    },
    updateIngredients: (state, action) => {
      state.ingredients = action.payload;
    },
    updateOutput: (state, action) => {
      state.output = action.payload;
    },
    updateCookingTime: (state, action) => {
      state.cooking_time = action.payload;
    },
    updateCalories: (state, action) => {
      state.calories = action.payload;
    },
    // другие редьюсеры
    clearNewDish: (state) => {
      state.name = '';
      state.description = '';
      state.price = 0;
      // очистить другие поля
    },
  },
});

export const {
  updateName,
  updateDescription,
  updatePrice,
  updateIngredients,
  updateOutput,
  updateCookingTime,
  updateCalories,
  clearNewDish,
  setFileData,

} = newDishSlice.actions;

export default newDishSlice.reducer;
