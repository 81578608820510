import React, { useState } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';

import Resizer from 'react-image-file-resizer';

const AddPhotoFoodBank = () => {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (event) => {
    const originalFile = event.target.files[0];
    if (!originalFile) {
      setError('No file selected.');
      return;
    }

    Resizer.imageFileResizer(
      originalFile,
      300, // width
      300, // height
      'PNG', // file type
      100, // quality
      0, // rotation
      (uri) => {
        fetch(uri)
          .then((res) => res.blob())
          .then((blob) => {
            const resizedFile = new File([blob], originalFile.name, {
              type: 'image/png',
              lastModified: new Date().getTime(),
            });
            setFile(resizedFile);
            setPreviewImage(URL.createObjectURL(resizedFile));
          })
          .catch((err) => setError('Error processing image: ' + err.message));
      },
      'base64', // output type
    );
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || !description) {
      setError('Both an image and description are required.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);

    try {
      setUploading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/foodphotobank/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log(response.data);
      setFile(null);
      setDescription('');
      setPreviewImage(null);
      setError(null);
    } catch (err) {
      setError('Failed to upload the image: ' + err.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className='mt-10 ml-3'>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="fileInput">Image:</label>
            <input type="file" id="fileInput" onChange={handleFileChange} />
            {previewImage && (
              <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px' }} />
            )}
          </div>
          <div className='flex flex-col mt-5'>
            <label htmlFor="descriptionInput">Description:</label>
            <textarea
              id="descriptionInput"
              value={description}
              onChange={handleDescriptionChange}
              className='border border-slate-800'
            />
          </div>
          <button className='border-2 border-slate-600 p-2 mt-2 ' type="submit" disabled={uploading}>
            Upload
          </button>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default AddPhotoFoodBank;
