import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addExistRecipientToBranch,
  deleteRecipientPhoto,
  detachRecipientFromBranch,
  uploadRecipientPhoto,
} from '../../store/slices/tipsSlice';
import { PiTrashThin } from 'react-icons/pi';
import Resizer from 'react-image-file-resizer';

const TipsRecipientCard = ({
  id,
  fullname,
  status,
  photo_url,
  cloudtips_user_id,
  phone_number,
  branchIdCT,
}) => {
  const dispatch = useDispatch();
  const [photoFile, setPhotoFile] = useState(null);
  

  const handlePhotoUpload = () => {
    console.log('~ cloudtips_user_id: ', cloudtips_user_id);
    console.log('~ handlePhotoUpload');
    if (photoFile) {
      dispatch(uploadRecipientPhoto({ id: cloudtips_user_id, photoFile }));
    }
  };

  const handlePhotoChange = async (event) => {
    const file = await CompressFile(event.target.files[0]);
    if (file) {
      setPhotoFile(file);
    }
  };

  // добавление существующего сотрудника в существующий филиал
  const handleAddRecipient = () => {
    console.log('~ branchIdCT: ', branchIdCT);
    dispatch(addExistRecipientToBranch({ cloudtips_user_id, id, branchIdCT }));
  };
  // отвязать сотрудника от филиала 
  const handleDetachRecipient = () => {
    console.log('~ branchIdCT: ', branchIdCT);
    dispatch(detachRecipientFromBranch({ cloudtips_user_id, id, branchIdCT }));
  };

  const CompressFile = async (originalFile) => {
    try {
      const image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          originalFile,
          150,
          150,
          'JPG',
          100,
          0,
          (uri) => {
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], originalFile.name, {
                  type: 'image/jpg',
                  lastModified: new Date().getTime(),
                });
                resolve(file);
              })
              .catch((err) => console.error(err));
          },
          'base64',
        );
      });
      return image;
    } catch (error) {
      console.error('Не удалось сжать файл: ', error);
    }
  };

  const handlePhotoDelete = () => {
    console.log('~ handlePhotoDelete');
    dispatch(deleteRecipientPhoto({ cloudtips_user_id, id }));
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 border border-slate-200 ">
      <div className="flex flex-col">
        <div>
          {' '}
          <p className="text-lg font-semibold"> {fullname}</p>{' '}
        </div>
        <div className="">
          <p>{phone_number}</p>
        </div>
      </div>
      {/* <div>{cloudtips_user_id}</div> */}

      {!photo_url && (
        <div className="border border-slate-900 p-2 w-72 mt-4 flex flex-col">
          <div>Фото получателя</div>
          <div className="mt-2">
            <input type="file" accept="image/*" onChange={handlePhotoChange} />
          </div>
          {photoFile && (
            <div className="mt-4">
              <button onClick={handlePhotoUpload} className="button-s">
                Добавить фото
              </button>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-row mt-3">
        <img className="w-20" src={photo_url} alt="" srcset="" />
        {photo_url && (
          <div>
            <PiTrashThin className="ml-2 cursor-pointer" onClick={handlePhotoDelete} />
          </div>
        )}
      </div>
      <div>
        {status === 'added' && (
          /*  <div className="text-blue-700 cursor-pointer text-sm font-light">Привязать к филиалу</div> */
          <div className="text-blue-700 cursor-pointer text-sm font-light mt-2">
            <button onClick={handleDetachRecipient}>Отвязать сотрудника от филиала</button>
          </div>
        )}
        {status === 'notadded' && (
          <div className="text-blue-700 cursor-pointer text-sm font-light mt-2">
            <button onClick={handleAddRecipient}>Добавить сотрудника в этот филиал</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TipsRecipientCard;
