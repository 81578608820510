import React from 'react';
import Sidebar from '../components/Sidebar';
import Branch from '../components/Branch';
import { CiCircleChevUp } from 'react-icons/ci';

const BranchesPage = () => {
  return (
    <div className="flex ">
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-10 right-2  text-white rounded ">
        <CiCircleChevUp className="text-3xl text-slate-500" />
      </button>
      <Sidebar />
      <div>
        <div className="flex px-10	">
          <Branch />
        </div>
      </div>
    </div>
  );
};

export default BranchesPage;
