import React, { useEffect, useState } from 'react';
import Story from './Story';
import { useDispatch } from 'react-redux';
import {
  addNewStory,
  deleteGroupImage,
  deleteGroupStory,
  moveDownGroup,
  moveUpDown,
  moveUpGroup,
  updateGroupStoriesOption,
  updateGroupTitle,
  uploadGroupImage,
} from '../store/slices/storiesSlice';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { CiCircleQuestion } from 'react-icons/ci';
import Resizer from 'react-image-file-resizer';

import { PiNotePencilDuotone } from 'react-icons/pi';

// StoriesGroip.jsx Компонент для отображения предварительного просмотра истории
const StoriesGroup = ({
  title: initialTitle,
  stories,
  id,
  profileImage,
  display_location,
  display_order,
  branchId,
  isFirst,
  isLast,
}) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(initialTitle);
  const [labelToggle, setLabelToggle] = useState(''); // left, center, right

  const [newStoryTitle, SetNewStoryTitle] = useState('');
  const [isFormVisible, setFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  async function CompressFile(originalFile) {
    try {
      const image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          originalFile,
          250, // Установите размеры, которые вы хотите здесь.
          250,
          'PNG',
          100,
          0,
          (uri) => {
            // Convert blobUrl to File object
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], originalFile.name, {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                });
                resolve(file);
              })
              .catch((err) => console.error(err));
          },
          'base64',
        );
      });
      return image;
    } catch (error) {
      console.error('Не удалось сжать файл: ', error);
    }
  }

  useEffect(() => {
    switch (display_location) {
      case 1:
        setLabelToggle('Основное меню и доставка');
        break;
      case 2:
        setLabelToggle('Только основное меню');
        break;
      case 3:
        setLabelToggle('Только доставка');
        break;
      case 4:
        setLabelToggle('Нигде не отображать');
        break;
      default:
        break;
    }
  }, [display_location]);

  const handleCancelButtonClick = () => {
    setFormVisible(false);
    SetNewStoryTitle('');
  };

  const handleSaveTitle = () => {
    if (initialTitle === title) {
      return;
    }
    dispatch(updateGroupTitle({ id, title }));
  };

  const handleDeleteGroupImage = () => {
    dispatch(deleteGroupImage({ groupId: id, profileImage }));
  };

  // Функция для обработки добавления фото
  async function handleAddPhotoClick(event) {
    const file = await CompressFile(event.target.files[0]); // Получаем выбранный файл
    if (file) {
      // Вызываем async thunk для загрузки изображения, передавая ID группы и выбранный файл
      dispatch(uploadGroupImage({ groupId: id, imageFile: file }));
    }
  }

  const handleDeleteGroupStory = () => {
    if (stories && stories.length > 0) {
      // Если в группе есть истории, выводим предупреждение
      alert('Группа содержит истории. Сначала удалите все истории в этой группе.');
    } else {
      // Если историй нет, выполняем удаление группы
      dispatch(deleteGroupStory({ groupId: id, profileImage }));
    }
    //dispatch(deleteGroupStory({ groupId: id, profileImage }));
  };

  const handleSaveButtonClick = () => {
    setFormVisible(false);
    dispatch(addNewStory({ groupId: id, newStoryHeading: newStoryTitle }));
  };

  const handleAddButtonClick = () => {
    setFormVisible(true);
  };

  async function handleMoveUp() {
    dispatch(moveUpGroup({ groupId: id, display_order, branchId }));
  }
  async function handleMoveDown() {
    dispatch(moveDownGroup({ groupId: id, display_order, branchId }));
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const sortedStories = stories.slice().sort((a, b) => a.display_order - b.display_order);

  return (
    <div className="story-preview bg-slate-200 pb-1 mt-6 ">
      {/* 
      <Tippy content="Это подсказка!">
      <button>
        <CiCircleQuestion/>
      </button>
    </Tippy> */}

      <div className="story-info ">
        <div className="flex flex-row justify-between ">
          <div className="ml-2 pt-2 text-xl flex flex-row items-center mt-4">
            <p className="text-base">Группа историй -&nbsp;</p>
            {!isEditing && (
              <>
                <p> {title}</p>
                <p onClick={handleEditClick}>
                  <PiNotePencilDuotone className="ml-4 cursor-pointer" />
                </p>
              </>
            )}
            {isEditing && (
              <>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="pl-2 ml-2 border flex-grow py-1 "
                  onBlur={handleSaveTitle}
                />
                <button className="button-s" onClick={() => setIsEditing(false)}>
                  ок
                </button>
              </>
            )}
          </div>
          <div>
            <button className="text-gray-400 hover:text-gray-800" onClick={() => handleMoveUp()}>
              {!isFirst && <FaArrowUp />}
            </button>
            <button className="text-gray-400 hover:text-gray-800" onClick={() => handleMoveDown()}>
              {!isLast && <FaArrowDown />}
            </button>
          </div>
          <button className="ml-2 pt-2 text-xs mr-3 text-red-800" onClick={handleDeleteGroupStory}>
            Удалить группу
          </button>
        </div>

        <div className="mt-4 flex flex-col pl-2 ">
          <div className="text-xs">Где отображать</div>

          <div className="">
            <select
              className="bg-slate-100 border border-1 text-slate-800"
              value={display_location}
              onChange={(e) => {
                const displayLocation = parseInt(e.target.value, 10);
                dispatch(updateGroupStoriesOption({ groupId: id, displayLocation }));
              }}>
              <option value={1}>Основное меню и доставка</option>
              <option value={2}>Только основное меню</option>
              <option value={3}>Только доставка</option>
              <option value={4}>Нигде не отображать</option>
            </select>
          </div>
        </div>

        {/*         <div className="flex justify-between items-center mb-4 ">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="pl-2 mt-3 ml-2 border flex-grow py-1"
            onBlur={handleSaveTitle}
          />

        </div> */}
        <div className="flex flex-row">
          {profileImage ? (
            <div className="flex flex-col ml-2 mt-3">
              <p className="text-xs pb-1">Картинка группы</p>
              <div className="flex flex-row">
                <img
                  key={profileImage || 'default-key'}
                  src={`${process.env.REACT_APP_URL}${profileImage}`}
                  alt="Profile"
                  className="story-group-profile-image w-28  mb-4"
                />
                <div className="">
                  <RiDeleteBinLine
                    className="cursor-pointer mx-2"
                    onClick={handleDeleteGroupImage}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <input
                type="file"
                onChange={handleAddPhotoClick}
                style={{ display: 'none' }}
                id={`upload-photo-${id}`}
              />
              <label htmlFor={`upload-photo-${id}`}>
                <div className="border-2 bg-slate-200 add-photo-button ml-3 p-1 text-sm">
                  Добавить картинку группы
                </div>
              </label>
            </div>
          )}
        </div>

        {sortedStories.map((story, index) => (
          <Story
            key={story.content_id}
            id={id}
            duration={story.duration}
            heading={story.title}
            subheading={story.text}
            url={story.url_image_story}
            sort={story.sort}
            content_id={story.content_id}
            display_order={story.display_order}
            isFirst={index === 0} // True, если элемент первый в массиве
            isLast={index === stories.length - 1} // True, если элемент последний в массиве
          />
        ))}

        <button
          onClick={handleAddButtonClick}
          type="submit"
          className="flex bg-teal-50 ml-2 hover:bg-teal-100 text-slate-800 font-sm py-2 px-4 my-5 rounded shadow-md">
          Добавить историю
        </button>
        {isFormVisible && (
          <div className="border-4 my-5 flex flex-col px-2 bg-teal-50 ">
            <div class="text-field text-field_floating-2 mt-5">
              <input
                onChange={(e) => SetNewStoryTitle(e.target.value)}
                name="cat-desc"
                placeholder="Название группы истории"
                className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
              />
              <label class="text-field__label" for="cat-desc">
                Краткое описание категории
              </label>
            </div>
            <div className='flex flex-row gap-4 my-3'>
              <button
                className="button-cancel"
                onClick={handleCancelButtonClick}>
                Отмена
              </button>
              <button
                className="button-s"
                onClick={handleSaveButtonClick}>
                Сохранить
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoriesGroup;
