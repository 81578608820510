//src\pages\HomePage.jsx
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../store/slices/userSlice';
import { setActiveBranch, updateBranches } from '../store/slices/branchSlice'; // импортируем новый action
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import Sidebar from '../components/Sidebar';
import { selectBranch, selectActiveBranch } from '../store/slices/branchSlice'; // путь может отличаться в зависимости от расположения вашего файла
import { setIdDb } from '../store/slices/userSlice'; // импортируем новый action
import { fetchGlobalSettings, setGlobalSettings } from '../store/slices/globalSettingsSlice';

const HomePage = () => {
  const dispatch = useDispatch();
  const { isAuth, email, isLoading } = useAuth();
  const token = useSelector((state) => state.user.token);

  const branches = useSelector(selectBranch);
  const activeBranch = useSelector(selectActiveBranch);

  const userEmail = useSelector((state) => state.user.email);
  const firebase_uid = useSelector((state) => state.user.id);

  // Проверяет авторизацию на серверной стороне. Добавляет пользователя в БД, если его там нет.
  // На сервере извлекает email из токена
  const checkAuth = async () => {
    // // // // // // console.log('~ Отправил запрос');
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/test-token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
    } catch (error) {
      console.error(error);
    }
  };

  const checkBranches = () => {
    // добавьте здесь код для отправки запроса на сервер и проверки наличия филиалов
    // в ответе обновите состояние Redux с помощью updateBranches
    // пример:
    axios
      .get(`${process.env.REACT_APP_URL}/api/branches`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // // // // // console.log('~ ', response.data);
        dispatch(updateBranches(response.data));
        dispatch(setActiveBranch(response.data[0]));
      })
      .catch((error) => {
        console.error(error);
      });
  };

/*    const fetchGlobalSettings = async () => {
    try {
      // Замените URL на адрес вашего API
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/global-settings/7`);
      // // console.log('~ response.data = ', response.data);
    } catch (error) {
      console.error(error);
    }
  }; 
 */
  useEffect(() => {
    if (isAuth) {
      const checkAuth = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/test-token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // // // // console.log('~ ', response.data);
        } catch (error) {
          console.error(error);
        }
      };

      const checkBranches = () => {
        axios
          .get(`${process.env.REACT_APP_URL}/api/branches`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // // console.log('~ ', response.data);
            dispatch(updateBranches(response.data));
            dispatch(setActiveBranch(response.data[0]));
          })
          .catch((error) => {
            console.error(error);
          });
      };

      const getUserID = async () => {
        // // // // // // // console.log('~ Вызван getUserID');
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/api/get_user_id`, {
            params: {
              userEmail,
              firebase_uid,
            },
          });
          // // // // // // // // console.log('~ 🎢 response.data = ', response.data);
          dispatch(setIdDb(response.data.userId));
          // // // console.log('~ ', response.data);
        } catch (error) {
          console.error(error);
        }
      };
      // Запускаем все проверки
      getUserID();
      checkAuth();
      checkBranches();
      dispatch(fetchGlobalSettings(7));
    }
  }, [isAuth]);

  /* 
  useEffect(() => {
    if(isAuth) {
      //checkAuth();
      checkBranches();

      const getUserID = async () => {
        try {
          const response = await axios.get(`/api/get_user_id`, {
            params: {
              userEmail
            }
          });
  
          // console.log('~ ', response.data);
        } catch (error) {
          console.error(error);
        }
      };
  
      getUserID();
      

    }
  }, []); */

  if (isLoading) {
    return <div>Загрузка...</div>; // или любой другой загрузочный индикатор
  }

  const handleLogOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    dispatch(removeUser());
  };

  return isAuth ? (
    <div className="flex ">
      <Sidebar />
      <div className="flex  w-full flex-col">
        <div className="w-full flex bg-teal-50 "></div>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default HomePage;

/* <div>
<h1 className="text-3xl">Welcome to HomePage</h1>
<button onClick={handleLogOut} className='border-2'>Log out from {email}</button>
</div> */
