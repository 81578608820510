import { useState, useEffect } from 'react';
import QRCode from 'qrcode';

const useQRCode = (qrLink, qrColor, qrBgColor) => {
  const [src, setSrc] = useState('');
  
  useEffect(() => {
    QRCode.toDataURL(qrLink, {
      margin: 1,
      width: 450,
      color: {
        dark: qrColor,  // Тёмно-синий цвет элементов
        light: qrBgColor  // Светло-голубой цвет фона
      }
    }, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }
      setSrc(url);
    });
  }, [qrLink, qrColor, qrBgColor]);

  return src;
};

export default useQRCode;
