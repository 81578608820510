import React, { useState } from 'react';
import axios from 'axios';

const ApiTestPage = () => {
  const [token, setToken] = useState();
  const [places, setPlaces] = useState([]);
  const [name, setName] = useState(''); // Состояние для хранения имени заведения
  const [companyName, setCompanyName] = useState('');
  const [companyInn, setCompanyInn] = useState('');

  const [placeId, setPlaceId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [needConfirmLayouts, setNeedConfirmLayouts] = useState(false); // По умолчанию true
  const [file, setFile] = useState(null);
  const [userId, setUserId] = useState('');
  const [receivers, setReceivers] = useState([]);

  const uploadPhoto = () => {
    const formData = new FormData();
    formData.append('FormFile', file);
  
    axios.post(`${process.env.REACT_APP_URL}/upload-receiver-photo/${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Убедись, что token определен
      }
    })
    .then(response => {
      console.log('Фото загружено:', response.data);
      alert('Фото успешно загружено!');
    })
    .catch(error => {
      console.error('Ошибка при загрузке фото:', error);
      alert('Ошибка при загрузке фото!');
    });
  };

  const fetchReceivers = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/get-receivers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Получены получатели:', response.data);
        setReceivers(response.data); // Сохранение списка получателей в состояние
      })
      .catch((error) => {
        console.error('Ошибка при получении списка получателей:', error);
      });
  };

  const createReceivers = () => {
    const receiversData = {
      placeId: placeId,
      needConfirmLayouts: needConfirmLayouts,
      receivers: [
        {
          phoneNumber: phoneNumber,
          name: receiverName,
          externalId: '', // Предполагаем, что это поле может быть пустым, если не используется
          sendPassword: true, // По умолчанию отправляем пароль
        },
      ],
    };

    axios
      .post(`${process.env.REACT_APP_URL}/create-receivers`, receiversData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Созданные получатели:', response.data);
        alert('Получатели успешно созданы!');
      })
      .catch((error) => {
        console.error('Ошибка при создании получателей:', error);
        alert('Ошибка при создании получателей!');
      });
  };

  const createCompany = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}/create-company`,
        { name: companyName, inn: companyInn },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Отправка токена серверу
          },
        },
      )
      .then((response) => {
        console.log('Созданная компания:', response.data);
        alert('Компания успешно создана!');
      })
      .catch((error) => {
        console.error('Ошибка при создании компании:', error);
        alert('Ошибка при создании компании!');
      });
  };

  function getMe() {
    // Обращение к Node.js серверу
    axios
      .post(`${process.env.REACT_APP_URL}/get-token`)
      .then((response) => {
        console.log('Token:', response.data);
        setToken(response.data.access_token);
        // Теперь токен доступен для использования в дальнейших запросах
      })
      .catch((error) => {
        console.error('Ошибка при получении токена:', error);
      });
  }

  const fetchPlaces = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/get-places`, {
        headers: {
          Authorization: `Bearer ${token}`, // Отправка токена серверу
        },
      })
      .then((response) => {
        console.log('Places:', response.data);
        setPlaces(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении списка заведений:', error);
      });
  };

  const createPlace = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}/create-place`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Отправка токена серверу
          },
        },
      )
      .then((response) => {
        console.log('Созданное заведение:', response.data);
        alert('Заведение успешно создано!');
      })
      .catch((error) => {
        console.error('Ошибка при создании заведения:', error);
        alert('Ошибка при создании заведения!');
      });
  };

  return (
    <div>
      ApiTestPage
      <div className="mt-10 ml-10">
        <button className="button-s" onClick={getMe}>
          Получить токен
        </button>
        <div>
          <p>{token}</p>
        </div>
        <button className="button-s" onClick={fetchPlaces}>
          Получить список заведений
        </button>
        <div>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Введите название заведения"
          />
          <button onClick={createPlace} className="button-s">
            Создать заведение
          </button>
        </div>
        <div>
          <input
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Введите имя компании"
          />
          <input
            value={companyInn}
            onChange={(e) => setCompanyInn(e.target.value)}
            placeholder="Введите ИНН компании"
          />
          <button className="button-s" onClick={createCompany}>
            Создать компанию
          </button>
        </div>
        <div>
          <input
            value={placeId}
            onChange={(e) => setPlaceId(e.target.value)}
            placeholder="ID заведения"
          />
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Номер телефона"
          />
          <input
            value={receiverName}
            onChange={(e) => setReceiverName(e.target.value)}
            placeholder="Имя получателя"
          />
          <button className="button-s" onClick={createReceivers}>
            Создать получателей чаевых
          </button>
        </div>
        <div>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <input
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="ID пользователя"
          />
          <button className='button-s' onClick={uploadPhoto}>Загрузить фото</button>
        </div>
        <div>
          <button className='button-s' onClick={fetchReceivers}>Получить список получателей</button>
          
        </div>
      </div>
    </div>
  );
};

export default ApiTestPage;
