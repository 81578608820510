import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateUseOrderLocalOnServer,
  selectBranchById,
  updateBranch,
  updateBranchOnServer,
  selectActiveBranch,
  textabovemenu,
  updateTextAboveMenu,
  updateUseOrderCommentOnServer,
  updateDesignMenu,
  selectDesignSettings,
} from '../store/slices/branchSlice';
import TelegramOptions from './TelegramOptions';
import { SketchPicker } from 'react-color';
import PopupColorPicker from './PopupColorPicker';

const OptionsMenu = () => {
  const dispatch = useDispatch();
  //const branch = useSelector((state) => selectBranchById(state, parseInt(branchId)));
  const designSettingsFromSlice = useSelector(selectDesignSettings);

  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;
 

  const chatIdOrder = activeBranch.chat_id_order;
  const [textAboveMenu, setTextAboveMenu] = useState(activeBranch.textabovemenu);
  const [wasEditTextAbove, setWasEditTextAbove] = useState(false);
  
  
  
  useEffect(() => {
    // // console.log('~ сработал useEffect OptionsPage');
    //  setTables(activeBranch.tables);
  }, [activeBranch]);

 



  // для телеграм компонента
  const actionPage = 'order';

  // изменения использования заказа в основном меню
  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    dispatch(updateUseOrderLocalOnServer({ useOrderLocal: newValue, branchId: activeBranchId }));
  };

  // изменения использования обязательного комментария в основном меню
  const handleToggleChangeOrderComment = (event) => {
    const newValue = event.target.checked;
    dispatch(
      updateUseOrderCommentOnServer({ useordercomment: newValue, branchId: activeBranchId }),
    );
  };

  const handleTextAboveMenuChange = (event) => {
    const newText = event.target.value;
    setTextAboveMenu(newText);
    setWasEditTextAbove(true);
  };
  const handleTextAboveMenuChangeBlur = (event) => {
    const newText = event.target.value;
    // проверяем, было ли редактирование текста или нет
    if (wasEditTextAbove) {
      // было редактирование
      setWasEditTextAbove(false);
      setTextAboveMenu(newText);
      dispatch(updateTextAboveMenu({ newText, branchId: activeBranchId }));
    } else {
      // не было редактирования
    }
  };


  function saveDesign() {
    // Отправляем полный объект настроек на сервер
  }




  const [fontUpdated, setFontUpdated] = useState(false);

  useEffect(() => {
    // console.log('~ 🍘 useEffect сработал');
    if (fontUpdated) {
      saveDesign();
      // Сброс состояния fontUpdated после сохранения
      setFontUpdated(false);
    }
  }, [fontUpdated]);







  return (
    <div>
      <div>


        <h1 className="mt-4 text-centertext text-2xl text-center">Настройки меню</h1>
        <br />

        <div className="font-semibold text-xl">Текст над меню</div>
        <div className="grid md:grid-cols-2 mt-4">
          <textarea
            value={textAboveMenu}
            onChange={handleTextAboveMenuChange}
            className=" textarea-1 w-full py-2 px-2"
            rows="4"
            placeholder=""
            onBlur={handleTextAboveMenuChangeBlur}></textarea>
        </div>

        <div>
          <div className="font-semibold text-xl mt-10">Заказ</div>
          <div className="">
            Включить возможность заказа из меню. Заказ будет приходить в телеграм официанта. В
            противном случае, меню будет доступно только для просмотра.
          </div>
          <br />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={activeBranch?.useOrderLocal}
              onChange={handleToggleChange}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-900">Использовать заказ</span>
          </label>
        </div>

        <div>
          <div className="font-semibold text-xl mt-10">Комментарий</div>
          <div className="">Сделать обязательным комментарий к заказу основного меню</div>
          <br />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={activeBranch?.useordercomment}
              onChange={handleToggleChangeOrderComment}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-900">Комментарий обязателен</span>
          </label>
        </div>

        <hr className="mt-3" />
      </div>
      <div>
        {/* Условный рендеринг компонента TelegramOptions */}
        {activeBranch?.useOrderLocal ? (
          <TelegramOptions
            key={Math.random()}
            chatId={chatIdOrder}
            actionPage={actionPage}
            actibeBranchId={activeBranchId}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OptionsMenu;
