import axios from 'axios';
import React from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { RiEyeCloseLine } from "react-icons/ri";
import { format, parseISO } from 'date-fns';

const formatDate = (dateString) => {
  const date = parseISO(dateString); // Преобразуем строку ISO в объект даты
  return format(date, 'dd.MM.yyyy HH:mm'); // Форматируем дату
};



const ReviewsTable = ({ reviews, onDelete, onHide }) => {
  // Собираем все уникальные ключи из custom_review всех отзывов
  const allKeys = [
    ...new Set(reviews.flatMap((review) => Object.keys(review.custom_review || {}))),
  ];

  /*   if (!reviews || reviews.length === 0) {
    return <div>Нет отзывов для отображения</div>;
  } */

  return (
    <div className=" mt-2 w-full mb-20">
      <table className="w-full divide-y divide-gray-200 ">
        <thead className="bg-slate-200">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Дата
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Оценка
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Комментарий
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Контакт
            </th>
            {/* Создаем заголовки столбцов для каждого ключа из custom_review */}
            {allKeys.map((key) => (
              <th className="text-xs font-normal" key={key}>
                {key}
              </th>
            ))}
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Удалить
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">
              Скрыть
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 ">
          {reviews.map((review, index) => (
            <tr key={index}>
              <td className="px-4 py-4 w-1 whitespace-nowrap text-sm text-gray-500 border-r">
                {' '}
                {formatDate(review.created_at)}
              </td>
              <td className="text-center px-4 py-4 w-1 whitespace-normal text-sm text-gray-500 break-words max-w-xs border-r">
                {review.rating}
              </td>
              <td className="text-xs px-4 py-4 whitespace-normal  text-gray-500 break-words max-w-xs border-r">
                {review.comment}
              </td>
              <td className="px-4 py-4 whitespace-normal text-sm text-gray-500 break-words max-w-xs border-r">
                {review.contact_info}
              </td>

              {/* Заполняем данные для каждого ключа из custom_review, используя оператор ?. для предотвращения ошибки */}
              {allKeys.map((key) => (
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-r"
                  key={key}>
                  {review.custom_review?.[key] || '—'}
                </td>
              ))}
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium border-r">
                <RiDeleteBinLine
                  className="text-slate-500 hover:text-red-600 cursor-pointer"
                  onClick={() => onDelete(review.id)}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium border-r">
                <RiEyeCloseLine
                  className="text-slate-500 hover:text-slate-200 cursor-pointer"
                  onClick={() => onHide(review.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReviewsTable;
