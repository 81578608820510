//src\components\FilialsDropdown.jsx
import React, { Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectBranch,
  selectActiveBranch,
  setActiveBranch,
  updateBranches,
  clearActiveBranch,
} from '../store/slices/branchSlice';
import { useAuth } from '../hooks/use-auth';
import axios from 'axios';

const FilialsDropdown = () => {


  const { isAuth, email, isLoading } = useAuth();
  const token = useSelector((state) => state.user.token);

  const branches = useSelector(selectBranch);
  const activeBranch = useSelector(selectActiveBranch);
  const dispatch = useDispatch();

  // если data пустая, нет филиалов, то activeBranch надо сбрасывать
  useEffect(() => {
    if (branches.length === 0) {
      // console.log('~  Дата пустая');
      dispatch(clearActiveBranch());
    }
  }, []);



  return (
    <Listbox value={activeBranch} onChange={(branch) => dispatch(setActiveBranch(branch))}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-1 pl-3 pr-10 text-left bg-white rounded-lg cursor-default focus:outline-none sm:text-sm">
          <span className="block truncate">{activeBranch?.name || '<Нет филиалов>'}</span>
          <span className='text-xs font-light'>{' '}{activeBranch?.city}</span>
          <span className='text-xs font-light'>{' '}{activeBranch?.address}</span>

          
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {branches.map((branch, branchIdx) => (
              <Listbox.Option
                key={branchIdx}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-10 pr-4 ${
                    active ? 'text-white bg-indigo-600' : 'text-gray-900'
                  }`
                }
                value={branch}>
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                      {branch.name}
                      <span className='text-xs font-light'>{' '}{branch.address}</span>
                      <span className='text-xs font-light'>{' '}{branch.city}</span>
                    </span>
                    {selected ? (
                      <span className={`absolute inset-y-0 left-0 pl-3 flex items-center`}>
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default FilialsDropdown;
