import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCompany, fetchToken } from '../../store/slices/tipsSlice';
import { selectUserId } from '../../store/slices/userSlice';

const AddNewCompany = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyInn, setCompanyInn] = useState('');
  const dispatch = useDispatch();
  const token = useSelector((state) => state.tips.token);
  const userId = useSelector(selectUserId);

  const validationErrors = useSelector((state) => state.tips.validationErrors);
  const error = useSelector((state) => state.tips.error); // Предполагая, что ошибки сохраняются в tips.error

  const handleCreateCompany = () => {
    dispatch(createCompany({ name: companyName, inn: companyInn, userId: userId }));
  };

  return (
    <div>
      <div>Добавление организации</div>

      <div class="text-field text-field_floating-2 mt-3">
        <input
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Название организации"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          name="name"
        />
        <label class="text-field__label" for="name">
          Название компании (обязательно)
        </label>
      </div>

      <div class="text-field text-field_floating-2 mt-3">
        <input
          type="number"
          value={companyInn}
          onChange={(e) => setCompanyInn(e.target.value)}
          placeholder="ИНН"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          name="inn"
        />
        <label class="text-field__label" for="inn">
          ИНН (обязательно)
        </label>
      </div>
      <div className='mt-5'>
        <button className="button-s" onClick={handleCreateCompany}>
          Создать организацию
        </button>
      </div>

      {error && <div style={{ color: 'red' }}>Ошибка: {error.message || 'Неизвестная ошибка'}</div>}
    </div>
  );
};

export default AddNewCompany;
