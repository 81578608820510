import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice';
import branchReducer from "./slices/branchSlice";
import tableReducer from './slices/tableSlice';
import categoriesReducer from './slices/categoriesSlice';
import dishesReducer from './slices/dishesSlice';
import dishTagsReducer from './slices/dishTagsSlice';
import newDishReducer from './slices/newDishSlice';
import newBranchReducer from './slices/newBranchSlice';
import reviewPlatformsReducer from './slices/reviewPlatformsSlice';
import branchOptionsReducer from './slices/branchOptions';
import menuReducer from './slices/menuSlice';
import menuImportedReducer from "./slices/menuImportedSlice";
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import globalSettingsReducer from './slices/globalSettingsSlice';
import storiesReducer from './slices/storiesSlice'
import tipsReducer from './slices/tipsSlice'


const persistConfig = {
    key: 'root',
    storage,
    // whitelist: ['someReducer'] // опционально, если вы хотите сохранять только определенные редьюсеры
};

const persistedUserReducer = persistReducer({ ...persistConfig, key: 'user' }, userReducer);
const persistedBranchReducer = persistReducer({ ...persistConfig, key: 'branch' }, branchReducer);
const persistedTableReducer = persistReducer({ ...persistConfig, key: 'table' }, tableReducer);
const persistedCategoriesReducer = persistReducer({ ...persistConfig, key: 'categories' }, categoriesReducer);
//const persistedDishesReducer = persistReducer({ ...persistConfig, key: 'dishes' }, dishesReducer);
const persistedDishTagsReducer = persistReducer({ ...persistConfig, key: 'dishTags' }, dishTagsReducer);
const persistedNewDishReducer = persistReducer({ ...persistConfig, key: 'newDish' }, newDishReducer);
const persistedNewBranchReducer = persistReducer({ ...persistConfig, key: 'newBranch' }, newBranchReducer);
const persistedReviewPlatformsReducer = persistReducer({ ...persistConfig, key: 'reviewPlatforms' }, reviewPlatformsReducer);
const persistedBranchOptionsReducer = persistReducer({ ...persistConfig, key: 'branchOptions' }, branchOptionsReducer);
const persistedMenuReducer = persistReducer({ ...persistConfig, key: 'menu' }, menuReducer);
const persistedMenuImported = persistReducer({ ...persistConfig, key: 'menuImported' }, menuImportedReducer);
const persistGlobalSettingsReducer = persistReducer({ ...persistConfig, key: 'globalSettings' }, globalSettingsReducer);
const persistStroiesReducer = persistReducer({ ...persistConfig, key: 'stories' }, storiesReducer);
const persistTipsReducer = persistReducer({ ...persistConfig, key: 'tips' }, tipsReducer);


// ... и так далее для каждого редьюсера

// ... остальные редьюсеры

export const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        //tips: persistTipsReducer,
        tips: tipsReducer,
        //user: userReducer,
        branch: persistedBranchReducer,
        table: persistedTableReducer,
        //categories: persistedCategoriesReducer,
        //dishes: persistedDishesReducer,
        //dishTags: persistedDishTagsReducer,
        dishTags: dishTagsReducer,
        menu: persistedMenuReducer,
        newDish: persistedNewDishReducer,
        newBranch: persistedNewBranchReducer,
        reviewPlatforms: persistedReviewPlatformsReducer,
        branchOptions: persistedBranchOptionsReducer, 

        categories: categoriesReducer,
        dishes: dishesReducer,
        menuImported: persistedMenuImported,
        globalSettings : persistGlobalSettingsReducer, 
        //stories : persistStroiesReducer, 
        stories: storiesReducer

/*         user: userReducer,
        branch: branchReducer,
        table: tableReducer,
        categories: categoriesReducer,
        dishes: dishesReducer,
        dishTags: dishTagsReducer,
        newDish: newDishReducer,
        newBranch: newBranchReducer,
        reviewPlatforms: reviewPlatformsReducer,
        branchOptions: branchOptionsReducer,  */
    },
    middleware: [thunk],
})

export const persistor = persistStore(store);
