import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveBranch,
  updateCustomFormJson,
  updateUseCustomForm,
} from '../store/slices/branchSlice';
import ToggleSwitch from './ToggleSwitch';

const CustomFormsFeedback = () => {
  //usecustomformfeedback
  const dispatch = useDispatch();
  const activeBranch = useSelector(selectActiveBranch);

  const activeBranchId = activeBranch?.id;

  const [customFormJson, setCustomFormJson] = useState(
    activeBranch && activeBranch.customformfeedback
      ? JSON.stringify(activeBranch.customformfeedback, null, 2) // Форматирование с отступом в 2 пробела
      : '',
  );
  const [isUseCustomForm, setIsUseCustomForm] = useState(false);

  const handleToggleCustomForm = () => {
    const newUseCustomForm = !isUseCustomForm;

    setIsUseCustomForm(newUseCustomForm);

    dispatch(updateUseCustomForm({ newUseCustomForm, branchId: activeBranch.id }));
  };

  useEffect(() => {
    if (!activeBranch) return;
    if (activeBranch) {
      setIsUseCustomForm(activeBranch.usecustomformfeedback);
    }
  }, [activeBranch]);

  const handleSave = () => {
    try {
      // Попытка парсинга JSON для проверки валидности
      //     const parsed = JSON.parse(customFormJson);
      dispatch(updateCustomFormJson({ customFormJson, branchId: activeBranchId }));
    } catch (e) {
      // Обработка ошибки, если строка не является валидным JSON
      alert('Неверный формат JSON!');
      console.error('Неверный формат JSON:', e);
    }
  };

  useEffect(() => {
    console.log('useEffect сработал');

    if (activeBranch?.customcss !== undefined) {
      setCustomFormJson(JSON.stringify(activeBranch.customformfeedback, null, 2) || '');
    }
  }, [activeBranch]);

  return (
    <div>
      <div className="mt-6 pb-10">
        <span>Использовать опросник</span>
        <div className="mt-4">
          <ToggleSwitch
            isChecked={isUseCustomForm}
            onToggle={handleToggleCustomForm}
            label={isUseCustomForm ? 'Да' : 'Нет'}
          />
        </div>
        <div className="pt-6 pb-6">
          В данный момент визуальное редактирование опросников находится в стадии разработки,
          поэтому если вам нужно создать опросник, обратитесь в службу поддержки
        </div>
        <div>
          <textarea
            value={customFormJson}
            placeholder="Спасибо за заказ!"
            className="w-full mt-1 md:w-full lg:w-full xl:w-2/3  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            rows="30"
            onChange={(e) => setCustomFormJson(e.target.value)}
          />
        </div>
        <div className='mt-3'>
          <button onClick={handleSave} className="button-s">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomFormsFeedback;
