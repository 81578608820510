import React, { useEffect, useRef, useState } from 'react';
import FilialsDropdown from './FilialsDropdown';
import { useAuth } from '../hooks/use-auth';
import {
  MdOutlineDashboard,
  MdAccountCircle,
  MdAnalytics,
  MdOutlineSettings,
  MdLogout,
} from 'react-icons/md';
import { BiFoodMenu } from 'react-icons/bi';
import { BsChevronDown, BsChatLeftText, BsCalendarCheck, BsFiles, BsServer } from 'react-icons/bs';
import { RiExpandLeftRightLine } from 'react-icons/ri';
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { TbBuildingStore } from 'react-icons/tb';
import { GoCreditCard } from 'react-icons/go';
import { PiCoinsLight } from 'react-icons/pi';
import { getAuth, signOut } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser } from '../store/slices/userSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CiDeliveryTruck } from 'react-icons/ci';
import { BsLink } from 'react-icons/bs';
import { selectActiveBranch } from '../store/slices/branchSlice';
import { setActiveMenu } from '../store/slices/menuSlice';
import { TbLayoutGridAdd } from "react-icons/tb";


//{ title: 'Accounts', src: 'User', gap: true, icon: <MdAccountCircle /> },
// gap: true = отступ от верхнего пункта
// src: использовать для роутинга
//isOpen: true - sub menu раскрыты по умолчанию

const App = () => {
  /* const activeBranch = useSelector(selectActiveBranch);
  const activeBranchUseMenu = useSelector(selectActiveBranch).usemenu;

  let colorMenu = 'text-slate-500';
  useEffect(() => {
    colorMenu = activeBranchUseMenu ? 'text-blue-600' : 'text-slate-500';
  }, []); */



  
  const [open, setOpen] = useState(true);
  
  const [dropdownOpen, setDropdownOpen] = useState(true); // состояние для управления FilialsDropdown

  const dispatch = useDispatch();
  const { isAuth, email, isLoading } = useAuth();
  const token = useSelector((state) => state.user.token);



  const toggleSidebar = () => {
    setOpen(!open); // скрыть/показать Sidebar
    setDropdownOpen(!dropdownOpen); // скрыть FilialsDropdown при закрытии боковой панели
  };

  const handleLogOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    dispatch(removeUser());
    navigate(`/login`);
  };

  const bgcolor = 'bg-blue-900';
  const navigate = useNavigate();

  const activeMenu = useSelector((state) => state.menu.activeMenu);

  const handleItemClick = (menu) => {
    // console.log('~ menu = ', menu);
    if (menu === 'menu') {
      navigate(`/menu`);
    }
    if (menu === 'feedbacks') {
      navigate(`/feedbacks`);
    }
    if (menu === 'payment') {
      navigate(`/payment`);
    }
    if (menu === 'tips') {
      navigate(`/tips`);
    }
    if (menu === 'delivery') {
      navigate(`/delivery`);
    }
    if (menu === 'branches') {
      navigate(`/branches`);
    }
    if (menu === 'links') {
      navigate(`/links`);
    }
    if (menu === 'options') {
      navigate(`/options`);
    }
    if (menu === 'subscription') {
      navigate(`/subscription`);
    }
    dispatch(setActiveMenu(menu));
  };

  return (
    <aside className="min-h-screen flex ">
      <div
        className={` ${
          open ? 'w-48 px-2 ' : 'w-0 '
        } lg:w-72 bg-slate-800 h-screen   relative duration-500`}>
        <button
          className="mx-1 my-1 lg:hidden  bg-slate-400 w-10 h-10 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-slate-600   duration-300"
          onClick={toggleSidebar}>
          <RiExpandLeftRightLine className="text-white text-2xl" />
        </button>
        <div className=" justify-center mt-1">
          <div
            className={`text-violet-600  font-medium text-base text-center duration-200  ${
              !open && 'invisible'
            }`}>
            RestoLink.ru
          </div>
          {/* <div className="py-3 text-slate-400">{dropdownOpen && email}</div> */}
        </div>
        <div className={`text-white mt-2 ${!open && 'invisible'}`}>Мои филиалы:</div>
        {dropdownOpen && <FilialsDropdown />}{' '}
        {/* Отображение FilialsDropdown в зависимости от состояния dropdownOpen */}
        <div className={` ${!open && 'invisible'}`}>
          <div
            className={`${
              activeMenu === 'menu' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('menu')}>
            <BiFoodMenu />
            Меню
          </div>
          <div
            className={`${
              activeMenu === 'feedbacks' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('feedbacks')}>
            <HiOutlineChatBubbleLeftEllipsis />
            Отзывы
          </div>
          <div
            className={`${
              activeMenu === 'payment' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('payment')}>
            <GoCreditCard />
            Оплата
          </div>

          <div
            className={`${
              activeMenu === 'tips' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('tips')}>
            <PiCoinsLight />
            Чаевые
          </div>
          <div
            className={`${
              activeMenu === 'delivery' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('delivery')}>
            <CiDeliveryTruck />
            Доставка
          </div>
          <div
            className={`${
              activeMenu === 'branches' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('branches')}>
            <TbBuildingStore />
            Филиалы
          </div>
          <div
            className={`${
              activeMenu === 'links' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('links')}>
            <BsLink />
            Ссылки
          </div>
          <div
            className={`${
              activeMenu === 'options' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('options')}>
            <MdOutlineSettings />
            Настройки
          </div>
          <div
            className={`${
              activeMenu === 'subscription' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2`}
            onClick={() => handleItemClick('subscription')}>
            <TbLayoutGridAdd />
            Подписка
          </div>
          <div>
          <hr />
        </div>
          <div
            className={`${
              activeMenu === 'logout' ? bgcolor : ''
            } flex rounded-md p-2 cursor-pointer hover:bg-slate-600 text-white text-base items-center gap-x-2 mt-4`}
            onClick={handleLogOut}>
            <MdLogout />
            Выход
          </div>
        </div>
        
      
        <div className="py-3 text-slate-400">{dropdownOpen && email}</div>
      </div>
    </aside>
  );
};

export default App;
