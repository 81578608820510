//src\store\slices\userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

const initialState = {
  isLoading: true,
  email: null,
  token: null,
  id: null, // firebase id
  id_db: null, // user id from database - Users table
  balance: null,
  balanceLoading: false,
  dailyWithdrawalLimit: null,
  discount_rate: null,
  chat_id_service_notify: null,
};


export const fetchUserBalance = createAsyncThunk(
  'user/fetchBalance',
  async (userId, thunkAPI) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/users/${userId}/balance`);
      return response.data.balance;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  })

export const fetchDailyWithdrawalLimit = createAsyncThunk(
  'user/fetchDailyWithdrawalLimit',
  async (userId, thunkAPI) => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/users/${userId}/fetchDailyWithdrawalLimit`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  })

export const updateChatId = createAsyncThunk(
  'user/updateChatId',
  async ({ chatId, userId, }, { rejectWithValue }) => {
    // console.log('~ 👛 chatId, userId =  ', chatId, userId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/users/updateChatId`, {
        userId,
        chatId,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIdDb: (state, action) => {
      state.id_db = action.payload;
    },
    setUser: (state, action) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.isLoading = false;
    },
    removeUser: (state) => {
      state.email = null;
      state.token = null;
      state.id = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserBalance.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(fetchUserBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
        state.balanceLoading = false;
      })
      .addCase(fetchUserBalance.rejected, (state) => {
        state.balanceLoading = false;
      }).addCase(fetchDailyWithdrawalLimit.fulfilled, (state, action) => {
        state.dailyWithdrawalLimit = action.payload.dailyWithdrawalLimit
        state.monthAllPayment = action.payload.monthAllPayment
        state.discount_rate = action.payload.discount_rate
        state.chat_id_service_notify = action.payload.chat_id_service_notify


      }).addCase(updateChatId.fulfilled, (state, action) => {
        state.chat_id_service_notify = action.payload.chat_id_service_notify;
      });
  },
});

export const selectUserId = (state) => state.user.id_db;
//export const selectUserUUID = (state) => state.user.id;



export const { setUser, removeUser, setLoading, setIdDb } = userSlice.actions;
export default userSlice.reducer;