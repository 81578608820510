import React from 'react'
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';

const TopCardBranch = () => {
    const activeBranch = useSelector(selectActiveBranch);

  return (
    <div className=" py-2 px-4 font-medium shadow-md ">
    <div>
      {activeBranch.name}
      {', '}
      {activeBranch.city}
      {', '}
      {activeBranch.address}
    </div>
  </div>
  )
}

export default TopCardBranch