import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateLink } from '../store/slices/branchSlice';

const EditLink = ({ link, branchId }) => {
  const dispatch = useDispatch();

  const localLink = link;

  const [url, setUrl] = useState(link);
  const [urlError, setUrlError] = useState(null);

  const handleLinkBlur = async (event) => {
    if (url === localLink) {
      // // // console.log('~ ссылка не изменилась');
      return;
    }

    // console.log('~ Сработал handleLinkBlur');
    const link = event.target.value;
    const regex = /^[a-zA-Z0-9-_]+$/; // Разрешены только буквы, цифры, дефисы и подчеркивания

    if (!regex.test(link)) {
      setUrlError('Ссылка содержит недопустимые символы');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/check_link?link=${link}`);

      if (response.data.exists) {
        setUrlError('Такая ссылка на филиал уже существует. Нужно придумать другую');
      } else {
        setUrlError(null); // Очистите ошибку, если ссылка уникальна
        dispatch(updateLink({ url, branchId }));
      }
    } catch (error) {
      console.error(error);
      // Обработка ошибок сети или сервера здесь
    }
  };

  return (
    <div>
      <label for="basic-url" className="mb-2 inline-block text-slate-700 mt-4">
        URL ссылка на филиал
      </label>
      <div className="relative flex flex-row items-stretch">
        <span className="text-sm items-center pt-1 bg-slate-300 pl-1">https://app.restolink.ru/</span>

        <input
          type="text"
          className="border border-slate-400 w-full md:w-2/5 lg:w-2/5 xl:w-2/5 mr-4 py-1 px-2 pr-11 bloc shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          id="basic-url"
          ariaDescribedby="basic-addon3"
          placeholder="skazka"
          value={url}
          onBlur={handleLinkBlur}
          onChange={(e) => setUrl(e.target.value)}
        />
        {urlError && <div className="text-red-700">{urlError}</div>}
      </div>
    </div>
  );
};

export default EditLink;
