import React, { useEffect, useState } from 'react';
import { useSelect } from '@material-tailwind/react';
import { selectActiveBranch, updateFeedbackOption } from '../store/slices/branchSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  fetchBranchOptions,
  selectBranchOptions,
  clearBranchOptions,
  updatePositiveFeedback,
  updateNegativeFeedback,
} from '../store/slices/branchOptions';
import { IoIosArrowForward } from 'react-icons/io';

/*
feedbackOptions 
4 - оценка 4 и 5. сразу направлять на карты
5 - оценка 5. сразу направлять на карты
6 - оценки 4 и 5. Дать написать отзыв, потом направлять на карты
7 - оценка 5. Дать написать отзыв, потом направлять на карты
8 - не направлять на карты
*/

const OptionsFeedback = () => {
  const dispatch = useDispatch();
  const branchOptions = useSelector(selectBranchOptions);
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const toggleOpen1 = () => setIsOpen1(!isOpen1);
  const toggleOpen2 = () => setIsOpen2(!isOpen2);
  const toggleOpen3 = () => setIsOpen3(!isOpen3);

  const [fbOption, setFbOption] = useState(activeBranch.feedback_options);

  const [negativeFeedback, setNegativeFeedback] = useState(branchOptions.negative_feedback_text);

  const [initialNegativeFeedback, setInitialNegativeFeedback] = useState(
    branchOptions.negative_feedback_text,
  );

  const [positiveFeedback, setPositiveFeedback] = useState(branchOptions.positive_feedback_text);

  const [initialPositiveFeedback, setInitialPositiveFeedback] = useState(
    branchOptions.positive_feedback_text,
  );

  const handleOptionChange = (value) => {
    // console.log('~ вызван handleOptionChange');
    setFbOption(value);
    dispatch(updateFeedbackOption({ branchId: activeBranch.id, feedbackOption: value }));
  };

  useEffect(() => {
    setNegativeFeedback(branchOptions.negative_feedback_text || ''); 
    setPositiveFeedback(branchOptions.positive_feedback_text || '');
    setInitialNegativeFeedback(branchOptions.negative_feedback_text || ''); 
    setInitialPositiveFeedback(branchOptions.positive_feedback_text || ''); 
  }, [branchOptions.negative_feedback_text, branchOptions.positive_feedback_text]);

  // меняем на сервере позитивный текст
  const handlePositiveTextChange = (event) => {
    const newText = event.target.value;
    setPositiveFeedback(newText);
  };
  const handleNegativeTextChange = (event) => {
    const newText = event.target.value;
    setNegativeFeedback(newText);
  };

  function handlePositiveFeedbackBlur() {
    if (positiveFeedback !== initialPositiveFeedback) {
      // Текст изменился, отправляем запрос на сервер
      dispatch(
        updatePositiveFeedback({
          branchId: activeBranchId,
          positiveText: positiveFeedback,
        }),
      );
      // Обновляем начальное значение, так как текст был сохранен
      setInitialPositiveFeedback(positiveFeedback);
    }
  }

  function handleNegativeFeedbackBlur() {
    if (negativeFeedback !== initialNegativeFeedback) {
      // Текст изменился, отправляем запрос на сервер
      dispatch(
        updateNegativeFeedback({
          branchId: activeBranchId,
          negativeText: negativeFeedback,
        }),
      );
      // Обновляем начальное значение, так как текст был сохранен
      setInitialNegativeFeedback(negativeFeedback);
    }
  }
  useEffect(() => {
    // console.log('~ OptionsFeedback useEffect[] сработал');
    dispatch(clearBranchOptions());
    dispatch(fetchBranchOptions(activeBranchId));
    setFbOption(activeBranch.feedback_options);
  }, [activeBranch]);

  return (
    <>
      <div className="font-extralight">Перенаправление на Яндекс карты, Google, 2gis и т.д.</div>
      <div className="font-extralight">
        <p className="font-medium pt-4">
          Вариант 1. Приоритет карты <span className="font-extralight">(рекомендуется)</span>{' '}
        </p>
        <span> </span>
        <span></span>
        <p></p>
        <div>
          <div className="flex flex-row items-center">
            <IoIosArrowForward />

            <button onClick={toggleOpen1}>
              {' '}
              <span className="text-blue-700 font-normal underline"> Подробнее </span>
            </button>
          </div>
          {isOpen1 && (
            <div>
              Положительные отзывы сразу отправляются на карты. В Телеграм канал приходят только негативные отзывы. Этот вариант увеличивает количество положительных отзывов на картах.
            </div>
          )}
        </div>
        <div className="mt-2 bg-slate-200 p-2">
          <label className="flex items-center">
            <input
              type="radio"
              name="options"
              value="option1"
              className="form-radio"
              checked={fbOption === 4}
              onChange={() => handleOptionChange(4)}
            />
            <span className="ml-2 font-extralight">Предлагать карты при оценке 4 и 5 звезд</span>
          </label>

          <label className="flex items-center">
            <input
              type="radio"
              name="options"
              value="option2"
              className="form-radio"
              checked={fbOption === 5}
              onChange={() => handleOptionChange(5)}
            />
            <span className=" ml-2 font-extralight">Предлагать карты только при оценке 5</span>
          </label>
        </div>
      </div>
      {/* конец вариант 1 */}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* вариант 2 */}
      <div className="font-extralight">
        <p className="pt-4 font-medium">Вариант 2. Приоритет отзыва</p>
        <div className="flex flex-row items-center">
          <IoIosArrowForward />
          <button onClick={toggleOpen2}>
            <span className="text-blue-700 font-normal underline"> Подробнее </span>
          </button>
        </div>
        {isOpen2 && (
          <div>
           Пользователь сначала пишет положительный отзыв у нас, затем дублирует его на картах. Все отзывы приходят в Телеграм канал. Этот вариант снижает отклик на картах, но вы видите все отзывы.
          
          </div>
        )}
      </div>

      <div className="bg-slate-200 p-2 mt-2">
        <label className="flex items-center ">
          <input
            type="radio"
            name="options"
            value="option3"
            className="form-radio"
            checked={fbOption === 6}
            onChange={() => handleOptionChange(6)}
          />
          <span className="ml-2 font-extralight">Предлагать карты при оценке 4 и 5 звезд</span>
        </label>

        <label className="flex items-center">
          <input
            type="radio"
            name="options"
            value="option4"
            className="form-radio"
            checked={fbOption === 7}
            onChange={() => handleOptionChange(7)}
          />
          <span className="ml-2 font-extralight">Предлагать карты только при оценке 5</span>
        </label>
      </div>

      {/* конец вариант 2 */}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      {/* вариант 3 */}

      <div className="font-extralight">
        <p className="pt-4 font-medium">Вариант 3. Без карт</p>
        <div className="flex flex-row items-center">
          <IoIosArrowForward />
          <button onClick={toggleOpen3}>
            <span className="text-blue-700 font-normal underline"> Подробнее </span>
          </button>
        </div>
        {isOpen3 && (
          <div>
            Отзывы на карты не отправляются. Все отзывы приходят в Телеграм канал. Выбирайте этот вариант, если не нужны отзывы на картах.
          </div>
        )}
      </div>

      <div className="bg-slate-200 p-2 mt-2">
        <label className="flex items-center">
          <input
            type="radio"
            name="options"
            value="option8"
            className="form-radio"
            checked={fbOption === 8}
            onChange={() => handleOptionChange(8)}
          />
          <span className="ml-2 font-extralight">Не использовать карты</span>
        </label>
      </div>
      {/* //////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////// */}

      <div className="text-base text-center font-medium mt-8">Текст в отзывах</div>

      <p className="mt-2 font-extralight">
        Текст ниже отображается, если оценка{' '}
        <span className="font-normal text-red-600"> не должна </span> попадать на сервисы карт.
        Негативная оценка
      </p>

      <textarea
        value={negativeFeedback}
        className=" font-extralight w-full py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-lg shadow focus:outline-none focus:shadow-outline"
        rows="4"
        placeholder=""
        onChange={handleNegativeTextChange} /* // Этот обработчик обновляет локальное состояние */
        onBlur={handleNegativeFeedbackBlur}></textarea>
      <div>
        <p className="font-extralight">
          Текст ниже отображается, если оценка{' '}
          <span className="font-normal text-green-600"> должна </span> попадать на сервисы карт.
          Позитивная оценка
        </p>
        <textarea
          value={positiveFeedback}
          className=" font-extralight w-full py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-lg shadow focus:outline-none focus:shadow-outline"
          rows="4"
          placeholder=""
          onChange={handlePositiveTextChange} /* // Этот обработчик обновляет локальное состояние */
          onBlur={handlePositiveFeedbackBlur}>
          {/* // Этот обработчик отправляет запрос при потере фокуса */}
        </textarea>
      </div>
    </>
  );
};

export default OptionsFeedback;
