import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateUseOrderLocalOnServer,
  selectBranchById,
  updateBranch,
  updateBranchOnServer,
  selectActiveBranch,
  textabovemenu,
  updateTextAboveMenu,
  updateUseOrderCommentOnServer,
  updateDesignMenu,
  selectDesignSettings,
} from '../store/slices/branchSlice';
import TelegramOptions from './TelegramOptions';
import { SketchPicker } from 'react-color';
import PopupColorPicker from './PopupColorPicker';

const DesignMenu = () => {
  const dispatch = useDispatch();
  //const branch = useSelector((state) => selectBranchById(state, parseInt(branchId)));
  const designSettingsFromSlice = useSelector(selectDesignSettings);

  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;
  const useOrderLocal = useSelector((state) => state.branch.activeBranch.useOrderLocal);
  const useOrderComment = useSelector((state) => state.branch.activeBranch.useordercomment);
  const [color, setColor] = useState('#ffffff'); // Устанавливаем начальный цвет

  const chatIdOrder = activeBranch.chat_id_order;
  const [textAboveMenu, setTextAboveMenu] = useState(activeBranch.textabovemenu);
  const [wasEditTextAbove, setWasEditTextAbove] = useState(false);
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const [isMainBackgroundPickerVisible, setIsMainBackgroundPickerVisible] = useState(false);
  const [isDishBackgroundPickerVisible, setIsDishBackgroundPickerVisible] = useState(false);
  const [isDishCardPickerVisible, setIsDishCardPickerVisible] = useState(false);
  const dishCardButtonRef = useRef(null);
  const [isCategoryMenuPickerVisible, setIsCategoryMenuPickerVisible] = useState(false);
  const categoryMenuButtonRef = useRef(null);
  const [
    isCategoryDescriptionMenuPickerVisible,
    setIsCategoryDescriptionMenuPickerVisible,
  ] = useState(false);
  const categoryDescriptionMenuButtonRef = useRef(null);
  const [isCategoryHorizontalBackPickerVisible, setIsCategoryHorizontalBackPickerVisible] = useState(false);
  const [isCategoryHorizontalPickerVisible, setIsCategoryHorizontalPickerVisible] = useState(false);
  const [isCategoryHorizontalActiveButtonPickerVisible, setIsCategoryHorizontalActiveButtonPickerVisible] = useState(false);
  const [isCategoryHorizontalActiveTextButtonPickerVisible, setIsCategoryHorizontalActiveTextButtonPickerVisible] = useState(false);
  const colorBackgroundHorizontalMenuRef = useRef(null);
  const categoryHorizontalButtonRef = useRef(null);

  const categoryHorizontalActiveButtonRef = useRef(null);
  const categoryHorizontalActiveTextButtonRef = useRef(null);


  const [isOrderButtonPlusPickerVisible, setIsOrderButtonPlusPickerVisible] = useState(false);
  const orderButtonPlusButtonRef = useRef(null);
  const [isTopMainMenuPickerVisible, setIsTopMainMenuPickerVisible] = useState(false);
  const topMainMenuButtonRef = useRef(null);
  const [isSearchPickerVisible, setIsSearchPickerVisible] = useState(false);
  const searchButtonRef = useRef(null);
  const [isFontDishTitlePickerVisible, setIsFontDishTitlePickerVisible] = useState(false);
  const fontDishTitleButtonRef = useRef(null);
  const [isFontDishDescriptionPickerVisible, setIsFontDishDescriptionPickerVisible] = useState(
    false,
  );
  const fontDishDescriptionButtonRef = useRef(null);
  const [isFontDishPricePickerVisible, setIsFontDishPricePickerVisible] = useState(false);
  const fontDishPriceButtonRef = useRef(null);
  const [isFontDishOutputPickerVisible, setIsFontDishOutputPickerVisible] = useState(false);
  const fontDishOutputButtonRef = useRef(null);
  const [isDishCardBorderPickerVisible, setIsDishCardBorderPickerVisible] = useState(false);
  const dishCardBorderButtonRef = useRef(null);
  const [isFontDishOrderPickerVisible, setIsFontDishOrderPickerVisible] = useState(false);
  const fontDishOrderButtonRef = useRef(null);
  const [isFontDishOrderCountPickerVisible, setIsFontDishOrderCountPickerVisible] = useState(false);
  const fontDishOrderCountButtonRef = useRef(null);
  const [isFontCategoryPickerVisible, setIsFontCategoryPickerVisible] = useState(false);
  const fontCategoryButtonRef = useRef(null);
  const [
    isFontCategoryDescriptionPickerVisible,
    setIsFontCategoryDescriptionPickerVisible,
  ] = useState(false);
  const fontCategoryDescriptionButtonRef = useRef(null);
  const [isFontMainMenuPickerVisible, setIsFontMainMenuPickerVisible] = useState(false);
  const fontMainMenuButtonRef = useRef(null);
  const [isFontIconsMainMenuPickerVisible, setIsFontIconsMainMenuPickerVisible] = useState(false);
  const fontIconsMainMenuButtonRef = useRef(null);
  const [
    isFontIconsMainMenuActivePickerVisible,
    setIsFontIconsMainMenuActivePickerVisible,
  ] = useState(false);
  const fontIconsMainMenuActiveButtonRef = useRef(null);
  const [isFontMainMenuActivePickerVisible, setIsFontMainMenuActivePickerVisible] = useState(false);
  const fontMainMenuActiveButtonRef = useRef(null);
  const [isBorderMainMenuPickerVisible, setIsBorderMainMenuPickerVisible] = useState(false);
  const borderMainMenuButtonRef = useRef(null);

  const [
    isFontCategoryHorizontalPickerVisible,
    setIsFontCategoryHorizontalPickerVisible,
  ] = useState(false);
  const fontCategoryHorizontalButtonRef = useRef(null);
  const [
    isBorderCategoryHorizontalPickerVisible,
    setIsBorderCategoryHorizontalPickerVisible,
  ] = useState(false);
  const borderCategoryHorizontalButtonRef = useRef(null);
  const [isFontBranchNamePickerVisible, setIsFontBranchNamePickerVisible] = useState(false);
  const fontBranchNameButtonRef = useRef(null);
  const [isFontBranchAddressPickerVisible, setIsFontBranchAddressPickerVisible] = useState(false);
  const fontBranchAddressButtonRef = useRef(null);
  const [isWaiterCallIconButtonPickerVisible, setIsWaiterCallIconButtonPickerVisible] = useState(
    false,
  );
  const waiterCallIconButtonRef = useRef(null);
  const [isFontSearchPlaceholderPickerVisible, setIsFontSearchPlaceholderPickerVisible] = useState(
    false,
  );
  const fontSearchPlaceholderButtonRef = useRef(null);
  const [isFontSearchInputPickerVisible, setIsFontSearchInputPickerVisible] = useState(false);
  const fontSearchInputButtonRef = useRef(null);
  const [isOrderDivPickerVisible, setIsOrderDivPickerVisible] = useState(false);
  const orderDivRef = useRef(null);
  const [isOrderDivButtonPickerVisible, setIsOrderDivButtonPickerVisible] = useState(false);
  const orderDivButtonButtonRef = useRef(null);
  const [isOrderDivButtonBorderPickerVisible, setIsOrderDivButtonBorderPickerVisible] = useState(
    false,
  );
  const orderDivButtonBorderButtonRef = useRef(null);
  const [isOrderDivFontColorPickerVisible, setIsOrderDivFontColorPickerVisible] = useState(false);
  const orderDivFontColorButtonRef = useRef(null);
  const [
    isOrderDivFontColorButtonPickerVisible,
    setIsOrderDivFontColorButtonPickerVisible,
  ] = useState(false);
  const orderDivFontColorButtonButtonRef = useRef(null);
  const [isFontFeedbackPickerVisible, setIsFontFeedbackPickerVisible] = useState(false);
  const fontFeedbackButtonRef = useRef(null);
  const [isDivFeedbackPickerVisible, setIsDivFeedbackPickerVisible] = useState(false);
  const divFeedbackButtonRef = useRef(null);
  const [isDivBorderFeedbackPickerVisible, setIsDivBorderFeedbackPickerVisible] = useState(false);
  const divBorderFeedbackButtonRef = useRef(null);
  const [isStarsFeedbackPickerVisible, setIsStarsFeedbackPickerVisible] = useState(false);
  const starsFeedbackButtonRef = useRef(null);
  const [isEditsFeedbackPickerVisible, setIsEditsFeedbackPickerVisible] = useState(false);
  const editsFeedbackButtonRef = useRef(null);
  const [isEditsBorderFeedbackPickerVisible, setIsEditsBorderFeedbackPickerVisible] = useState(
    false,
  );
  const editsBorderFeedbackButtonRef = useRef(null);
  const [isButtonFeedbackPickerVisible, setIsButtonFeedbackPickerVisible] = useState(false);
  const buttonFeedbackButtonRef = useRef(null);
  const [isButtonTextFeedbackPickerVisible, setIsButtonTextFeedbackPickerVisible] = useState(false);
  const buttonTextFeedbackButtonRef = useRef(null);
  const [isButtonBorderFeedbackPickerVisible, setIsButtonBorderFeedbackPickerVisible] = useState(
    false,
  );
  const buttonBorderFeedbackButtonRef = useRef(null);
  const [isStoriesGroupBorderPickerVisible, setIsStoriesGroupBorderPickerVisible] = useState(false);
  const storiesGroupBorderButtonRef = useRef(null);
  const [isSearchBorderPickerVisible, setIsSearchBorderPickerVisible] = useState(false);
  const searchBorderButtonRef = useRef(null);
  const [isFontGroupStoriesPickerVisible, setIsFontGroupStoriesPickerVisible] = useState(false);
  const fontGroupStoriesButtonRef = useRef(null);
  const [isFontTitleStoryPickerVisible, setIsFontTitleStoryPickerVisible] = useState(false);
  const fontTitleStoryButtonRef = useRef(null);
  const [isFontTextStoryPickerVisible, setIsFontTextStoryPickerVisible] = useState(false);
  const fontTextStoryButtonRef = useRef(null);

  const [isDivTitleAndTextStoryPickerVisible, setIsDivTitleAndTextStoryPickerVisible] = useState(
    false,
  );

  const divTitleAndTextStoryButtonRef = useRef(null);
  const [isFontTextAboveMenuPickerVisible, setIsFontTextAboveMenuPickerVisible] = useState(false);
  const fontTextAboveMenuButtonRef = useRef(null);

  const [isDishCardButtonBorderPickerVisible, setIsDishCardButtonBorderPickerVisible] = useState(
    false,
  );
  const dishCardButtonBorderButtonRef = useRef(null);

  const [isFontDeliveryMenuPickerVisible, setIsFontDeliveryMenuPickerVisible] = useState(false);
  const fontDeliveryMenuTextRef = useRef(null);

  useEffect(() => {
    // // console.log('~ сработал useEffect OptionsPage');
    setDesignSettings(activeBranch?.settings);
    //  setTables(activeBranch.tables);
  }, [activeBranch]);

  const [isFontInputFeedbackPickerVisible, setIsFontInputFeedbackPickerVisible] = useState(false);
  const fontInputFeedbackButtonRef = useRef(null);

  const [designSettings, setDesignSettings] = useState({
    colorMainBackground: activeBranch.settings?.colorMainBackground || '#FFFFFF', // Начальное значение цвета
    colorDishBackground: activeBranch.settings?.colorDishBackground || '#FFFFFF',
    colorFontTextAboveMenu: activeBranch.settings?.colorFontTextAboveMenu || '#FFFFFF',
    colorDishCard: activeBranch.settings?.colorDishCard || '#FFFFFF',
    colorCategoryMenu: activeBranch.settings?.colorCategoryMenu || '#FFFFFF',
    colorCategoryDescriptionMenu: activeBranch.settings?.colorCategoryDescriptionMenu || '#FFFFFF',
    colorCategoryHorizontal: activeBranch.settings?.colorCategoryHorizontal || '#FFFFFF',
    colorOrderButtonPlus: activeBranch.settings?.colorOrderButtonPlus || '#FFFFFF',
    colorTopMainMenu: activeBranch.settings?.colorTopMainMenu || '#FFFFFF',
    colorSearch: activeBranch.settings?.colorSearch || '#FFFFFF',
    colorFontDishTitle: activeBranch.settings?.colorFontDishTitle || '#FFFFFF',
    colorFontDishDescription: activeBranch.settings?.colorFontDishDescription || '#FFFFFF',
    colorFontDishPrice: activeBranch.settings?.colorFontDishPrice || '#FFFFFF',
    colorFontDishOutput: activeBranch.settings?.colorFontDishOutput || '#FFFFFF',
    colorDishCardBorder: activeBranch.settings?.colorDishCardBorder || '#FFFFFF',
    colorFontDishOrder: activeBranch.settings?.colorFontDishOrder || '#FFFFFF',
    colorFontDishOrderCount: activeBranch.settings?.colorFontDishOrderCount || '#FFFFFF',
    colorFontCategory: activeBranch.settings?.colorFontCategory || '#FFFFFF',
    colorFontCategoryDescription: activeBranch.settings?.colorFontCategoryDescription || '#FFFFFF',
    colorFontMainMenu: activeBranch.settings?.colorFontMainMenu || '#FFFFFF',
    colorFontIconsMainMenu: activeBranch.settings?.colorFontIconsMainMenu || '#FFFFFF',
    colorFontIconsMainMenuActive: activeBranch.settings?.colorFontIconsMainMenuActive || '#FFFFFF',
    colorFontMainMenuActive: activeBranch.settings?.colorFontMainMenuActive || '#FFFFFF',
    colorBorderMainMenu: activeBranch.settings?.colorBorderMainMenu || '#FFFFFF',
    colorFontCategoryHorizontal: activeBranch.settings?.colorFontCategoryHorizontal || '#FFFFFF',
    colorBorderCategoryHorizontal:
      activeBranch.settings?.colorBorderCategoryHorizontal || '#FFFFFF',
    colorFontBranchName: activeBranch.settings?.colorFontBranchName || '#FFFFFF',
    colorFontBranchAddress: activeBranch.settings?.colorFontBranchAddress || '#FFFFFF',
    colorWaiterCallIconButton: activeBranch.settings?.colorWaiterCallIconButton || '#FFFFFF',
    colorFontSearchPlaceholder: activeBranch.settings?.colorFontSearchPlaceholder || '#FFFFFF',
    colorFontSearchInput: activeBranch.settings?.colorFontSearchInput || '#FFFFFF',
    colorOrderDiv: activeBranch.settings?.colorOrderDiv || '#FFFFFF',
    colorOrderDivButton: activeBranch.settings?.colorOrderDivButton || '#FFFFFF',
    colorOrderDivButtonBorder: activeBranch.settings?.colorOrderDivButtonBorder || '#FFFFFF',
    colorOrderDivFontColor: activeBranch.settings?.colorOrderDivFontColor || '#FFFFFF',
    colorOrderDivFontColorButton: activeBranch.settings?.colorOrderDivFontColorButton || '#FFFFFF',
    colorFontFeedback: activeBranch.settings?.colorFontFeedback || '#FFFFFF',
    colorDivFeedback: activeBranch.settings?.colorDivFeedback || '#FFFFFF',
    colorDivBorderFeedback: activeBranch.settings?.colorDivBorderFeedback || '#FFFFFF',
    colorStarsFeedback: activeBranch.settings?.colorStarsFeedback || '#FFFFFF',
    colorEditsFeedback: activeBranch.settings?.colorEditsFeedback || '#FFFFFF',
    colorEditsBorderFeedback: activeBranch.settings?.colorEditsBorderFeedback || '#FFFFFF',
    colorButtonFeedback: activeBranch.settings?.colorButtonFeedback || '#FFFFFF',
    colorButtonTextFeedback: activeBranch.settings?.colorButtonTextFeedback || '#FFFFFF',
    colorButtonBorderFeedback: activeBranch.settings?.colorButtonBorderFeedback || '#FFFFFF',
    colorStoriesGroupBorder: activeBranch.settings?.colorStoriesGroupBorder || '#FFFFFF',
    colorSearchBorder: activeBranch.settings?.colorSearchBorder || '#FFFFFF',
    colorFontGroupStories: activeBranch.settings?.colorFontGroupStories || '#FFFFFF',
    colorFontTitleStory: activeBranch.settings?.colorFontTitleStory || '#FFFFFF',
    colorFontTextStory: activeBranch.settings?.colorFontTextStory || '#FFFFFF',
    colorDivTitleAndTextStory: activeBranch.settings?.colorDivTitleAndTextStory || '#FFFFFF',

    primaryFont: activeBranch.settings?.primaryFont || 'Arial',
    secondaryFont: activeBranch.settings?.secondaryFont || 'Arial',
    fontForTitle: activeBranch.settings?.fontForTitle || 'Arial',
    fontForTitleSize: activeBranch.settings?.fontForTitleSize || '18',
    fontForTitleWeight: activeBranch.settings?.fontForTitleWeight || '500',
    fontForTitleStyle: activeBranch.settings?.fontForTitleStyle || 'normal',
    // текст над меню
    fontForTextAboveMenu: activeBranch.settings?.fontForTextAboveMenu || 'Arial',
    fontForTextAboveMenuSize: activeBranch.settings?.fontForTextAboveMenuSize || '16',
    fontForTextAboveMenuWeight: activeBranch.settings?.fontForTextAboveMenuWeight || '500',
    fontForTextAboveMenuStyle: activeBranch.settings?.fontForTextAboveMenuStyle || 'normal',

    // Шрифт адреса
    fontForAdress: activeBranch.settings?.fontForAdress || 'Arial',
    fontForAdressSize: activeBranch.settings?.fontForAdressSize || '16',
    fontForAdressWeight: activeBranch.settings?.fontForAdressWeight || '500',
    fontForAdressStyle: activeBranch.settings?.fontForAdressStyle || 'normal',

    // шрифт горизонтальных категории
    fontForHorizontalCategory: activeBranch.settings?.fontForHorizontalCategory || 'Arial',
    fontForHorizontalCategorySize: activeBranch.settings?.fontForHorizontalCategorySize || '16',
    fontForHorizontalCategoryWeight:
      activeBranch.settings?.fontForHorizontalCategoryWeight || '500',
    fontForHorizontalCategoryStyle:
      activeBranch.settings?.fontForHorizontalCategoryStyle || 'normal',

    // шрифт главного меню
    fontForMainMenu: activeBranch.settings?.fontForMainMenu || 'Arial',
    fontForMainMenuSize: activeBranch.settings?.fontForMainMenuSize || '16',
    fontForMainMenuWeight: activeBranch.settings?.fontForMainMenuWeight || '500',
    fontForMainMenuStyle: activeBranch.settings?.fontForMainMenuStyle || 'normal',

    // шрифт Категории меню
    fontForCategory: activeBranch.settings?.fontForCategory || 'Arial',
    fontForCategorySize: activeBranch.settings?.fontForCategorySize || '16',
    fontForCategoryWeight: activeBranch.settings?.fontForCategoryWeight || '500',
    fontForCategoryStyle: activeBranch.settings?.fontForCategoryStyle || 'normal',

    // шрифт описания категории меню
    fontForCategoryDescription: activeBranch.settings?.fontForCategoryDescription || 'Arial',
    fontForCategoryDescriptionSize: activeBranch.settings?.fontForCategoryDescriptionSize || '16',
    fontForCategoryDescriptionWeight:
      activeBranch.settings?.fontForCategoryDescriptionWeight || '500',
    fontForCategoryDescriptionStyle:
      activeBranch.settings?.fontForCategoryDescriptionStyle || 'normal',

    //Шрифт названия блюда в карточке
    fontForDishCardTitle: activeBranch.settings?.fontForDishCardTitle || 'Arial',
    fontForDishCardTitleSize: activeBranch.settings?.fontForDishCardTitleSize || '16',
    fontForDishCardTitleWeight: activeBranch.settings?.fontForDishCardTitleWeight || '500',
    fontForDishCardTitleStyle: activeBranch.settings?.fontForDishCardTitleStyle || 'normal',

    //Шрифт описания блюда в карточке
    fontForDishCardDescription: activeBranch.settings?.fontForDishCardDescription || 'Arial',
    fontForDishCardDescriptionSize: activeBranch.settings?.fontForDishCardDescriptionSize || '16',
    fontForDishCardDescriptionWeight:
      activeBranch.settings?.fontForDishCardDescriptionWeight || '500',
    fontForDishCardDescriptionStyle:
      activeBranch.settings?.fontForDishCardDescriptionStyle || 'normal',

    //Шрифт выхода блюда в карточке
    fontForDishCardOutput: activeBranch.settings?.fontForDishCardOutput || 'Arial',
    fontForDishCardOutputSize: activeBranch.settings?.fontForDishCardOutputSize || '16',
    fontForDishCardOutputWeight: activeBranch.settings?.fontForDishCardOutputWeight || '500',
    fontForDishCardOutputStyle: activeBranch.settings?.fontForDishCardOutputStyle || 'normal',

    //Шрифт цены блюда в карточке
    fontForDishCardPrice: activeBranch.settings?.fontForDishCardPrice || 'Arial',
    fontForDishCardPriceSize: activeBranch.settings?.fontForDishCardPriceSize || '16',
    fontForDishCardPriceWeight: activeBranch.settings?.fontForDishCardPriceWeight || '500',
    fontForDishCardPriceStyle: activeBranch.settings?.fontForDishCardPriceStyle || 'normal',

    //Шрифт заказа
    fontForOrderDiv: activeBranch.settings?.fontForOrderDiv || 'Arial',
    fontForOrderDivSize: activeBranch.settings?.fontForOrderDivSize || '16',
    fontForOrderDivWeight: activeBranch.settings?.fontForOrderDivWeight || '500',
    fontForOrderDivStyle: activeBranch.settings?.fontForOrderDivStyle || 'normal',

    // Шрифт в отзывах
    fontForFeedback: activeBranch.settings?.fontForFeedback || 'Arial',
    fontForFeedbackSize: activeBranch.settings?.fontForFeedbackSize || '16',
    fontForFeedbackWeight: activeBranch.settings?.fontForFeedbackWeight || '500',
    fontForFeedbackStyle: activeBranch.settings?.fontForFeedbackStyle || 'normal',

    // прозрачность div внутри истории
    opacityDivTitleAndTextStory: activeBranch.settings?.opacityDivTitleAndTextStory || '0',
    // истории

    fontForTitleStorySize: activeBranch.settings?.fontForTitleStorySize || '16',
    fontForTextSize: activeBranch.settings?.fontForTextSize || '16',
    fontForTitleGroupStoies: activeBranch.settings?.fontForTitleGroupStoies || 'Arial',
    fontForTitleGroupStoriesSize: activeBranch.settings?.fontForTitleGroupStoriesSize || '16',
    fontForTitleGroupStoriesWeight: activeBranch.settings?.fontForTitleGroupStoriesWeight || '500',
    fontForTitleGroupStoriesStyle: activeBranch.settings?.fontForTitleGroupStoriesStyle || 'normal',
    fontForTitleStory: activeBranch.settings?.fontForTitleStory || 'Arial',
    fontForTitleStoryWeight: activeBranch.settings?.fontForTitleStoryWeight || '500',
    fontForTitleStoryStyle: activeBranch.settings?.fontForTitleStoryStyle || 'normal',
    fontForTextStory: activeBranch.settings?.fontForTextStory || 'Arial',
    fontForTextWeight: activeBranch.settings?.fontForTextWeight || '500',
    fontForTextStyle: activeBranch.settings?.fontForTextStyle || 'normal',
    groupStoriesWidth: activeBranch.settings?.groupStoriesWidth || '100',
    groupStoriesHeight: activeBranch.settings?.groupStoriesHeight || '100',
    groupStoriesBorderRadius: activeBranch.settings?.groupStoriesBorderRadius || '10',
    groupStoriesBorderRadiusSize: activeBranch.settings?.groupStoriesBorderRadiusSize || '1',
    mainMenuBorderRadius: activeBranch.settings?.mainMenuBorderRadius || '1',
    mainMenuBorderSize: activeBranch.settings?.mainMenuBorderSize || '1',
    // searchBorderRadius: activeBranch.settings?.searchBorderRadius || '5',
    searchBorderSize: activeBranch.settings?.searchBorderSize || '1',
    horizCategoryBorderRadius: activeBranch.settings?.horizCategoryBorderRadius || '5',
    horizCategoryBorderSize: activeBranch.settings?.horizCategoryBorderSize || '1',
    dishCardBorderRadius: activeBranch.settings?.dishCardBorderRadius || '5',
    dishCardBorderSize: activeBranch.settings?.dishCardBorderSize || '1',
    colorDishCardButtonBorder: activeBranch.settings?.colorDishCardButtonBorder || 'FFFFFF',
    dishCardButtonBorderRadius: activeBranch.settings?.dishCardButtonBorderRadius || '5',
    dishCardButtonBorderSize: activeBranch.settings?.dishCardButtonBorderSize || '1',
    allButtonsBorderRadius: activeBranch.settings?.allButtonsBorderRadius || '5',
    allInputsBorderRadius: activeBranch.settings?.allInputsBorderRadius || '5',

    // текст доставки
    colorfontDeliveryMenu: activeBranch.settings?.colorfontDeliveryMenu || 'FFFFFFF',
    fontDeliveryMenu: activeBranch.settings?.fontDeliveryMenu || 'Arial',
    fontDeliveryMenuStyle: activeBranch.settings?.fontDeliveryMenuStyle || 'normal',
    fontDeliveryMenuSize: activeBranch.settings?.fontDeliveryMenuSize || '16',
    fontDeliveryMenuWeight: activeBranch.settings?.fontDeliveryMenuWeight || '500',

    colorFontInputFeedback: activeBranch.settings?.colorFontInputFeedback || 'FFFFFF',
    colorBackgroundHorizontalMenu: activeBranch.settings?.colorBackgroundHorizontalMenu || 'FFFFFF',

    colorActiveButtonHorizontalMenu: activeBranch.settings?.colorActiveButtonHorizontalMenu || 'FFFFFF',
    colorActiveButtonTextHorizontalMenu: activeBranch.settings?.colorActiveButtonTextHorizontalMenu || 'FFFFFF',

    // инструкция, как добавить новое свойство obsidian > Как добавить стиль в restolink
  });

  // для телеграм компонента
  const actionPage = 'order';

  // изменения использования заказа в основном меню
  const handleToggleChange = (event) => {
    const newValue = event.target.checked;
    dispatch(updateUseOrderLocalOnServer({ useOrderLocal: newValue, branchId: activeBranchId }));
  };

  // изменения использования обязательного комментария в основном меню
  const handleToggleChangeOrderComment = (event) => {
    const newValue = event.target.checked;
    dispatch(
      updateUseOrderCommentOnServer({ useordercomment: newValue, branchId: activeBranchId }),
    );
  };

  const handleTextAboveMenuChange = (event) => {
    const newText = event.target.value;
    setTextAboveMenu(newText);
    setWasEditTextAbove(true);
  };
  const handleTextAboveMenuChangeBlur = (event) => {
    const newText = event.target.value;
    // проверяем, было ли редактирование текста или нет
    if (wasEditTextAbove) {
      // было редактирование
      setWasEditTextAbove(false);
      setTextAboveMenu(newText);
      dispatch(updateTextAboveMenu({ newText, branchId: activeBranchId }));
    } else {
      // не было редактирования
    }
  };

  function handleChange(newColor, name) {
    setDesignSettings((prevSettings) => ({
      ...prevSettings,
      [name]: newColor,
    }));
  }

  const handleChangeText = (event) => {
    const { name, value, type, checked, dataset } = event.target;

    let fieldValue;

    if (type === 'checkbox') {
      // Определяем действие на основе data-type
      const actionType = dataset.type;
      switch (actionType) {
        case 'fontWeight':
          fieldValue = checked ? '600' : '500';
          break;
        case 'fontStyle':
          fieldValue = checked ? 'italic' : 'normal';
          break;
        default:
          return; // Ничего не делаем, если тип действия неизвестен
      }
    } else {
      fieldValue = value;
    }

    setDesignSettings((prevSettings) => ({ ...prevSettings, [name]: fieldValue }));
  };

  function saveDesign() {
    // Отправляем полный объект настроек на сервер
    dispatch(updateDesignMenu({ designSettings, branchId: activeBranchId }));
  }

  function saveNewFontPrimary() {
    const oldPrimaryFont = designSettingsFromSlice.primaryFont;
    const newPrimaryFont = designSettings.primaryFont;

    // Итерируем по объекту designSettings, проверяя каждое его значение
    const updatedSettings = Object.entries(designSettings).reduce((acc, [key, value]) => {
      // Пропускаем обновление для secondaryFont
      if (key === 'secondaryFont') {
        acc[key] = value; // Просто копируем текущее значение без изменений
      }
      // Если значение поля совпадает со старым primary шрифтом и ключ не secondaryFont, обновляем его на новый primary шрифт
      else if (value === oldPrimaryFont) {
        acc[key] = newPrimaryFont;
      } else {
        // Иначе оставляем значение поля без изменений
        acc[key] = value;
      }
      return acc;
    }, {});

    // Обновляем состояние designSettings новыми значениями
    setDesignSettings(updatedSettings);

    // Отмечаем, что primary шрифт был обновлен
    setFontUpdated(true);
  }

  function saveNewFontSecondary() {
    const oldSecondaryFont = designSettingsFromSlice.secondaryFont;
    const newSecondaryFont = designSettings.secondaryFont;

    // Итерируем по объекту designSettings, проверяя каждое его значение
    const updatedSettings = Object.entries(designSettings).reduce((acc, [key, value]) => {
      // Пропускаем обновление для primaryFont
      if (key === 'primaryFont') {
        acc[key] = value; // Просто копируем текущее значение без изменений
      }
      // Если значение поля совпадает со старым secondary шрифтом и ключ не primaryFont, обновляем его на новый secondary шрифт
      else if (value === oldSecondaryFont) {
        acc[key] = newSecondaryFont;
      } else {
        // Иначе оставляем значение поля без изменений
        acc[key] = value;
      }
      return acc;
    }, {});

    // Обновляем состояние designSettings новыми значениями
    setDesignSettings(updatedSettings);

    // Отмечаем, что secondary шрифт был обновлен
    setFontUpdated(true);
  }

  const [fontUpdated, setFontUpdated] = useState(false);

  useEffect(() => {
    // console.log('~ 🍘 useEffect сработал');
    if (fontUpdated) {
      saveDesign();
      // Сброс состояния fontUpdated после сохранения
      setFontUpdated(false);
    }
  }, [fontUpdated]);

  const [inputValuePrimaryFont, setInputValuePrimaryFont] = useState('');

  const handleInputChange = (event) => {
    setInputValuePrimaryFont(event.target.value);
  };

  const handleCheckboxChangeWeigth = (event) => {
    const newWeight = event.target.checked ? '600' : '500'; // Если чекбокс отмечен, вес шрифта 600, иначе 500
    setDesignSettings((prevSettings) => ({
      ...prevSettings,
      fontForTitleWeight: newWeight,
      fontForAdressWeight: newWeight,
    }));
  };
  const handleCheckboxChangeStyle = (event) => {
    const newStyle = event.target.checked ? 'italic' : 'normal'; // Если чекбокс отмечен, вес шрифта 600, иначе 500
    setDesignSettings((prevSettings) => ({
      ...prevSettings,
      fontForTitleStyle: newStyle,
      fontForAdressStyle: newStyle,
    }));
  };
  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  const mainBackgroundButtonRef = useRef(null);
  const dishBackgroundButtonRef = useRef(null);
  return (
    <div className="pb-10">
      <p className="text-xl font-semibold mt-6">Настройка дизайна меню</p>

      <p className="text-lg text-center font-semibold pt-6 pb-2">Используемые шрифты</p>
      <p>Можно добавить до 2-х шрифтов</p>
      <div>
        <p className="font-semibold mt-4">Первый шрифт</p>
        <div className="flex flex-row items-center">
          <div>
            <input
              className="border border-slate-400 w-full   py-1 px-2 pr-11 block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              type="text"
              name="primaryFont"
              value={designSettings.primaryFont}
              onChange={handleChangeText} // сборка нового designSettings
            />
          </div>

          <div>
            <button
              className="button-s"
              onClick={saveNewFontPrimary} // Использование функции saveDesign для обработки клика
            >
              Изменить шрифт
            </button>
          </div>
        </div>
        <p>используемый шрифт: {designSettingsFromSlice?.primaryFont}</p>
      </div>
      <div>
        <p className="font-semibold mt-4">Второй шрифт</p>
        <div className="flex flex-row items-center">
          <div>
            <input
              className="border border-slate-400 w-full   py-1 px-2 pr-11 block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              type="text"
              name="secondaryFont"
              value={designSettings.secondaryFont}
              onChange={handleChangeText}
            />
          </div>

          <div>
            <button
              className="button-s"
              onClick={saveNewFontSecondary} // Использование функции saveDesign для обработки клика
            >
              Изменить шрифт
            </button>
          </div>
        </div>
        <p>используемый шрифт: {designSettingsFromSlice?.secondaryFont}</p>
        {/* Другие элементы UI */}
      </div>

      <div className="flex flex-row mt-6">
        <p className="pr-4">Цвет основного фона</p>
        <button
          ref={mainBackgroundButtonRef}
          onClick={() => setIsMainBackgroundPickerVisible(true)}
          className="border-2 w-12 h-5"
          style={{ backgroundColor: designSettings.colorMainBackground }}></button>
        <PopupColorPicker
          color={designSettings.colorMainBackground}
          name="colorMainBackground"
          onChangeComplete={handleChange}
          isVisible={isMainBackgroundPickerVisible}
          onClose={() => setIsMainBackgroundPickerVisible(false)}
          triggerRef={mainBackgroundButtonRef}
        />
        <p className="pl-4"> {designSettings.colorMainBackground}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет фона под блюдами</p>
        <button
          ref={dishBackgroundButtonRef}
          onClick={() => setIsDishBackgroundPickerVisible(true)}
          className="border-2 w-12 h-5"
          style={{ backgroundColor: designSettings.colorDishBackground }}></button>
        <PopupColorPicker
          color={designSettings.colorDishBackground}
          name="colorDishBackground"
          onChangeComplete={handleChange}
          isVisible={isDishBackgroundPickerVisible}
          onClose={() => setIsDishBackgroundPickerVisible(false)}
          triggerRef={dishBackgroundButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDishBackground}</p>
      </div>

      <div className="flex flex-row pt-3">
        <p className="pr-4">Цвет информационного текста над меню</p>
        <button
          ref={fontTextAboveMenuButtonRef}
          onClick={() => setIsFontTextAboveMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontTextAboveMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorFontTextAboveMenu}
          name="colorFontTextAboveMenu"
          onChangeComplete={handleChange}
          isVisible={isFontTextAboveMenuPickerVisible}
          onClose={() => setIsFontTextAboveMenuPickerVisible(false)}
          triggerRef={fontTextAboveMenuButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontTextAboveMenu}</p>
      </div>
      <div>
        <label htmlFor="fontForTextAboveMenuSelect">Шрифт информационного текста над меню:</label>
        <select
          className="border-2"
          id="fontForTextAboveMenuSelect"
          name="fontForTextAboveMenu"
          value={designSettings.fontForTextAboveMenu}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForTextAboveMenuSize"
          value={designSettings.fontForTextAboveMenuSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTextAboveMenuWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForTextAboveMenuWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTextAboveMenuWeight"
          data-type="fontWeight"
          checked={designSettings.fontForTextAboveMenuWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTextAboveMenuStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForTextAboveMenuStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTextAboveMenuStyle"
          data-type="fontStyle"
          checked={designSettings.fontForTextAboveMenuStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <div className="flex flex-row pt-3">
        <p className="pr-4">Цвет информационного текста в меню доставки</p>
        <button
          ref={fontDeliveryMenuTextRef}
          onClick={() => setIsFontDeliveryMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorfontDeliveryMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorfontDeliveryMenu}
          name="colorfontDeliveryMenu"
          onChangeComplete={handleChange}
          isVisible={isFontDeliveryMenuPickerVisible}
          onClose={() => setIsFontDeliveryMenuPickerVisible(false)}
          triggerRef={fontDeliveryMenuTextRef}
        />
        <p className="pl-4">{designSettings.colorfontDeliveryMenu}</p>
      </div>
      <div>
        <label htmlFor="fontDeliveryMenuSelect">
          Шрифт информационного текста в меню доставки:
        </label>
        <select
          className="border-2"
          id="fontDeliveryMenuSelect"
          name="fontDeliveryMenu"
          value={designSettings.fontDeliveryMenu}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontDeliveryMenuSize"
          value={designSettings.fontDeliveryMenuSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontDeliveryMenuWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontDeliveryMenuWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontDeliveryMenuWeight"
          data-type="fontWeight"
          checked={designSettings.fontDeliveryMenuWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontDeliveryMenuStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontDeliveryMenuStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontDeliveryMenuStyle"
          data-type="fontStyle"
          checked={designSettings.fontDeliveryMenuStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <div className="flex flex-row pt-3">
        <p className="pr-4">Цвет кнопки вызова официанта</p>
        <button
          ref={waiterCallIconButtonRef}
          onClick={() => setIsWaiterCallIconButtonPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorWaiterCallIconButton }}></button>
        <PopupColorPicker
          color={designSettings.colorWaiterCallIconButton}
          name="colorWaiterCallIconButton"
          onChangeComplete={handleChange}
          isVisible={isWaiterCallIconButtonPickerVisible}
          onClose={() => setIsWaiterCallIconButtonPickerVisible(false)}
          triggerRef={waiterCallIconButtonRef}
        />
        <p className="pl-4"> {designSettings.colorWaiterCallIconButton}</p>
      </div>

      <div className="flex flex-row mt-6">
        <p className="pr-4">Радиус закругления всех кнопок</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="allButtonsBorderRadius"
          value={designSettings.allButtonsBorderRadius}
          onChange={handleChangeText}
          min="1"
          max="1000"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <div className="flex flex-row mt-6">
        <p className="pr-4">Радиус закругления полей ввода</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="allInputsBorderRadius"
          value={designSettings.allInputsBorderRadius}
          onChange={handleChangeText}
          min="1"
          max="1000"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>

      {/* НАЗВАНИЕ И АДРЕС */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Название и адрес</p>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста названия заведения</p>
        <button
          ref={fontBranchNameButtonRef}
          onClick={() => setIsFontBranchNamePickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontBranchName }}></button>
        <PopupColorPicker
          color={designSettings.colorFontBranchName}
          name="colorFontBranchName"
          onChangeComplete={handleChange}
          isVisible={isFontBranchNamePickerVisible}
          onClose={() => setIsFontBranchNamePickerVisible(false)}
          triggerRef={fontBranchNameButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontBranchName}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста адреса</p>
        <button
          ref={fontBranchAddressButtonRef}
          onClick={() => setIsFontBranchAddressPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontBranchAddress }}></button>
        <PopupColorPicker
          color={designSettings.colorFontBranchAddress}
          name="colorFontBranchAddress"
          onChangeComplete={handleChange}
          isVisible={isFontBranchAddressPickerVisible}
          onClose={() => setIsFontBranchAddressPickerVisible(false)}
          triggerRef={fontBranchAddressButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontBranchAddress}</p>
      </div>

      <div>
        <label htmlFor="fontForTitleSelect">Шрифт названия заведения:</label>
        <select
          className="border-2"
          id="fontForTitleSelect"
          name="fontForTitle"
          value={designSettings.fontForTitle}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForTitleSize"
          value={designSettings.fontForTitleSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForTitleWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTitleWeight"
          data-type="fontWeight"
          checked={designSettings.fontForTitleWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForTitleStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTitleStyle"
          data-type="fontStyle"
          checked={designSettings.fontForTitleStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <div>
        <label htmlFor="fontForTitleSelect">Шрифт адреса:</label>
        <select
          className="border-2"
          id="fontForAdressSelect"
          name="fontForAdress"
          value={designSettings.fontForAdress}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>

        {/* При необходимости, другие элементы интерфейса */}
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForAdressSize"
          value={designSettings.fontForAdressSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForAdressWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForAdressWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          data-type="fontWeight"
          name="fontForAdressWeight"
          checked={designSettings.fontForAdressWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForAdressStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForAdressStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          data-type="fontStyle"
          name="fontForAdressStyle"
          checked={designSettings.fontForAdressStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* СТРОКА  ПОИСКА */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Строка поиска</p>
      <div className="flex flex-row">
        <p className="pr-4 ">Цвет фона поиска</p>
        <button
          ref={searchButtonRef}
          onClick={() => setIsSearchPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorSearch }}></button>
        <PopupColorPicker
          color={designSettings.colorSearch}
          name="colorSearch"
          onChangeComplete={handleChange}
          isVisible={isSearchPickerVisible}
          onClose={() => setIsSearchPickerVisible(false)}
          triggerRef={searchButtonRef}
        />
        <p className="pl-4"> {designSettings.colorSearch}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4 ">Цвет обводки строки поиска</p>
        <button
          ref={searchBorderButtonRef}
          onClick={() => setIsSearchBorderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorSearchBorder }}></button>
        <PopupColorPicker
          color={designSettings.colorSearchBorder}
          name="colorSearchBorder"
          onChangeComplete={handleChange}
          isVisible={isSearchBorderPickerVisible}
          onClose={() => setIsSearchBorderPickerVisible(false)}
          triggerRef={searchBorderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorSearchBorder}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки поиска</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="searchBorderSize"
          value={designSettings.searchBorderSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>
      {/*   <div className="flex flex-row">
      <p className="pr-4">Радиус закругления обводки поиска</p>
      <input
        className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
        type="number"
        name="searchBorderRadius"
        value={designSettings.searchBorderRadius}
        onChange={handleChangeText}
        min="0"
        max="30"
      />
      <p className="pl-3">пикселей</p>
    </div>
*/}
      <div className="flex flex-row">
        <p className="pr-4 ">Цвет иконки поиска</p>
        <button
          ref={fontSearchPlaceholderButtonRef}
          onClick={() => setIsFontSearchPlaceholderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontSearchPlaceholder }}></button>
        <PopupColorPicker
          color={designSettings.colorFontSearchPlaceholder}
          name="colorFontSearchPlaceholder"
          onChangeComplete={handleChange}
          isVisible={isFontSearchPlaceholderPickerVisible}
          onClose={() => setIsFontSearchPlaceholderPickerVisible(false)}
          triggerRef={fontSearchPlaceholderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontSearchPlaceholder}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4 ">Цвет текста поиска</p>
        <button
          ref={fontSearchInputButtonRef}
          onClick={() => setIsFontSearchInputPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontSearchInput }}></button>
        <PopupColorPicker
          color={designSettings.colorFontSearchInput}
          name="colorFontSearchInput"
          onChangeComplete={handleChange}
          isVisible={isFontSearchInputPickerVisible}
          onClose={() => setIsFontSearchInputPickerVisible(false)}
          triggerRef={fontSearchInputButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontSearchInput}</p>
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>


      {/* ГОРИЗОНТАЛЬНЫЕ КАТЕГОРИИ МЕНЮ*/}

      <p className="text-lg text-center font-semibold pt-6 pb-2">Горизонтальные категории</p>



      <div className="flex flex-row">
        <p className="pr-4">Цвет фона горизонтальных категорий меню</p>
        <div className="flex flex-row">
          <button
            ref={colorBackgroundHorizontalMenuRef}
            onClick={() => setIsCategoryHorizontalBackPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorBackgroundHorizontalMenu }}></button>
          <PopupColorPicker
            color={designSettings.colorBackgroundHorizontalMenu}
            name="colorBackgroundHorizontalMenu"
            onChangeComplete={handleChange}
            isVisible={isCategoryHorizontalBackPickerVisible}
            onClose={() => setIsCategoryHorizontalBackPickerVisible(false)}
            triggerRef={colorBackgroundHorizontalMenuRef}
          />
        </div>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет кнопок горизонтальных категорий меню</p>
        <div className="flex flex-row">
          <button
            ref={categoryHorizontalButtonRef}
            onClick={() => setIsCategoryHorizontalPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorCategoryHorizontal }}></button>
          <PopupColorPicker
            color={designSettings.colorCategoryHorizontal}
            name="colorCategoryHorizontal"
            onChangeComplete={handleChange}
            isVisible={isCategoryHorizontalPickerVisible}
            onClose={() => setIsCategoryHorizontalPickerVisible(false)}
            triggerRef={categoryHorizontalButtonRef}
          />
        </div>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста горизонтальных категорий меню</p>
        <button
          ref={fontCategoryHorizontalButtonRef}
          onClick={() => setIsFontCategoryHorizontalPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontCategoryHorizontal }}></button>
        <PopupColorPicker
          color={designSettings.colorFontCategoryHorizontal}
          name="colorFontCategoryHorizontal"
          onChangeComplete={handleChange}
          isVisible={isFontCategoryHorizontalPickerVisible}
          onClose={() => setIsFontCategoryHorizontalPickerVisible(false)}
          triggerRef={fontCategoryHorizontalButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontCategoryHorizontal}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста Нажатой кнопки горизонтальных категорий меню</p>
        <div className="flex flex-row">
          <button
            ref={categoryHorizontalActiveTextButtonRef}
            onClick={() => setIsCategoryHorizontalActiveTextButtonPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorActiveButtonTextHorizontalMenu }}></button>
          <PopupColorPicker
            color={designSettings.colorActiveButtonTextHorizontalMenu}
            name="colorActiveButtonTextHorizontalMenu"
            onChangeComplete={handleChange}
            isVisible={isCategoryHorizontalActiveTextButtonPickerVisible}
            onClose={() => setIsCategoryHorizontalActiveTextButtonPickerVisible(false)}
            triggerRef={categoryHorizontalActiveTextButtonRef}
          />
        </div>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет Нажатой кнопки горизонтальных категорий меню</p>
        <div className="flex flex-row">
          <button
            ref={categoryHorizontalActiveButtonRef}
            onClick={() => setIsCategoryHorizontalActiveButtonPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorActiveButtonHorizontalMenu }}></button>
          <PopupColorPicker
            color={designSettings.colorActiveButtonHorizontalMenu}
            name="colorActiveButtonHorizontalMenu"
            onChangeComplete={handleChange}
            isVisible={isCategoryHorizontalActiveButtonPickerVisible}
            onClose={() => setIsCategoryHorizontalActiveButtonPickerVisible(false)}
            triggerRef={categoryHorizontalActiveButtonRef}
          />
        </div>
      </div>


      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки горизонтальных категорий меню</p>
        <button
          ref={borderCategoryHorizontalButtonRef}
          onClick={() => setIsBorderCategoryHorizontalPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorBorderCategoryHorizontal }}></button>
        <PopupColorPicker
          color={designSettings.colorBorderCategoryHorizontal}
          name="colorBorderCategoryHorizontal"
          onChangeComplete={handleChange}
          isVisible={isBorderCategoryHorizontalPickerVisible}
          onClose={() => setIsBorderCategoryHorizontalPickerVisible(false)}
          triggerRef={borderCategoryHorizontalButtonRef}
        />
        <p className="pl-4"> {designSettings.colorBorderCategoryHorizontal}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="horizCategoryBorderSize"
          value={designSettings.horizCategoryBorderSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Радиус закругления обводки</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="horizCategoryBorderRadius"
          value={designSettings.horizCategoryBorderRadius}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <div>
        <label htmlFor="fontForHorizontalCategorySelect">Шрифт горизонтальных категорий:</label>
        <select
          className="border-2"
          id="fontForHorizontalCategory"
          name="fontForHorizontalCategory"
          value={designSettings.fontForHorizontalCategory}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForHorizontalCategorySize"
          value={designSettings.fontForHorizontalCategorySize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForHorizontalCategoryWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForHorizontalCategoryWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForHorizontalCategoryWeight"
          data-type="fontWeight"
          checked={designSettings.fontForHorizontalCategoryWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForHorizontalCategoryStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForHorizontalCategoryStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForHorizontalCategoryStyle"
          data-type="fontStyle"
          checked={designSettings.fontForHorizontalCategoryStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* ГЛАВНОЕ ВЕРХНЕЕ МЕНЮ */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Главное верхнее меню</p>

      <div className="flex flex-row">
        <p className="pr-4">Цвет фона главного меню</p>
        <button
          ref={topMainMenuButtonRef}
          onClick={() => setIsTopMainMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorTopMainMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorTopMainMenu}
          name="colorTopMainMenu"
          onChangeComplete={handleChange}
          isVisible={isTopMainMenuPickerVisible}
          onClose={() => setIsTopMainMenuPickerVisible(false)}
          triggerRef={topMainMenuButtonRef}
        />
        <p className="pl-4"> {designSettings.colorTopMainMenu}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки главного меню</p>
        <button
          ref={borderMainMenuButtonRef}
          onClick={() => setIsBorderMainMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorBorderMainMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorBorderMainMenu}
          name="colorBorderMainMenu"
          onChangeComplete={handleChange}
          isVisible={isBorderMainMenuPickerVisible}
          onClose={() => setIsBorderMainMenuPickerVisible(false)}
          triggerRef={borderMainMenuButtonRef}
        />
        <p className="pl-4"> {designSettings.colorBorderMainMenu}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Радиус закругления главного меню</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="mainMenuBorderRadius"
          value={designSettings.mainMenuBorderRadius}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки главного меню</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="mainMenuBorderSize"
          value={designSettings.mainMenuBorderSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста главного меню</p>
        <button
          ref={fontMainMenuButtonRef}
          onClick={() => setIsFontMainMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontMainMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorFontMainMenu}
          name="colorFontMainMenu"
          onChangeComplete={handleChange}
          isVisible={isFontMainMenuPickerVisible}
          onClose={() => setIsFontMainMenuPickerVisible(false)}
          triggerRef={fontMainMenuButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontMainMenu}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет иконок главного меню</p>
        <button
          ref={fontIconsMainMenuButtonRef}
          onClick={() => setIsFontIconsMainMenuPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontIconsMainMenu }}></button>
        <PopupColorPicker
          color={designSettings.colorFontIconsMainMenu}
          name="colorFontIconsMainMenu"
          onChangeComplete={handleChange}
          isVisible={isFontIconsMainMenuPickerVisible}
          onClose={() => setIsFontIconsMainMenuPickerVisible(false)}
          triggerRef={fontIconsMainMenuButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontIconsMainMenu}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет активной иконки главного меню</p>
        <button
          ref={fontIconsMainMenuActiveButtonRef}
          onClick={() => setIsFontIconsMainMenuActivePickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontIconsMainMenuActive }}></button>
        <PopupColorPicker
          color={designSettings.colorFontIconsMainMenuActive}
          name="colorFontIconsMainMenuActive"
          onChangeComplete={handleChange}
          isVisible={isFontIconsMainMenuActivePickerVisible}
          onClose={() => setIsFontIconsMainMenuActivePickerVisible(false)}
          triggerRef={fontIconsMainMenuActiveButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontIconsMainMenuActive}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста активного пункта главного меню</p>
        <button
          ref={fontMainMenuActiveButtonRef}
          onClick={() => setIsFontMainMenuActivePickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontMainMenuActive }}></button>
        <PopupColorPicker
          color={designSettings.colorFontMainMenuActive}
          name="colorFontMainMenuActive"
          onChangeComplete={handleChange}
          isVisible={isFontMainMenuActivePickerVisible}
          onClose={() => setIsFontMainMenuActivePickerVisible(false)}
          triggerRef={fontMainMenuActiveButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontMainMenuActive}</p>
      </div>

      <div>
        <label htmlFor="fontForMainMenuSelect">Шрифт главного меню:</label>
        <select
          className="border-2"
          id="fontForMainMenuSelect"
          name="fontForMainMenu"
          value={designSettings.fontForMainMenu}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForMainMenuSize"
          value={designSettings.fontForMainMenuSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForMainMenuWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForMainMenuWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForMainMenuWeight"
          data-type="fontWeight"
          checked={designSettings.fontForMainMenuWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForMainMenuStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForMainMenuStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForMainMenuStyle"
          data-type="fontStyle"
          checked={designSettings.fontForMainMenuStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* КАТЕГОРИИ МЕНЮ */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Категории меню</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет фона категории блюда</p>
        <div className="flex flex-row">
          <button
            ref={categoryMenuButtonRef}
            onClick={() => setIsCategoryMenuPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorCategoryMenu }}></button>
          <PopupColorPicker
            color={designSettings.colorCategoryMenu}
            name="colorCategoryMenu"
            onChangeComplete={handleChange}
            isVisible={isCategoryMenuPickerVisible}
            onClose={() => setIsCategoryMenuPickerVisible(false)}
            triggerRef={categoryMenuButtonRef}
          />
          <p className="pl-4"> {designSettings.colorCategoryMenu}</p>
        </div>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста категории</p>
        <button
          ref={fontCategoryButtonRef}
          onClick={() => setIsFontCategoryPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontCategory }}></button>
        <PopupColorPicker
          color={designSettings.colorFontCategory}
          name="colorFontCategory"
          onChangeComplete={handleChange}
          isVisible={isFontCategoryPickerVisible}
          onClose={() => setIsFontCategoryPickerVisible(false)}
          triggerRef={fontCategoryButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontCategory}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста описания категории</p>
        <button
          ref={fontCategoryDescriptionButtonRef}
          onClick={() => setIsFontCategoryDescriptionPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontCategoryDescription }}></button>
        <PopupColorPicker
          color={designSettings.colorFontCategoryDescription}
          name="colorFontCategoryDescription"
          onChangeComplete={handleChange}
          isVisible={isFontCategoryDescriptionPickerVisible}
          onClose={() => setIsFontCategoryDescriptionPickerVisible(false)}
          triggerRef={fontCategoryDescriptionButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontCategoryDescription}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет фона описания категории блюда</p>
        <div className="flex flex-row">
          <button
            ref={categoryDescriptionMenuButtonRef}
            onClick={() => setIsCategoryDescriptionMenuPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorCategoryDescriptionMenu }}></button>
          <PopupColorPicker
            color={designSettings.colorCategoryDescriptionMenu}
            name="colorCategoryDescriptionMenu"
            onChangeComplete={handleChange}
            isVisible={isCategoryDescriptionMenuPickerVisible}
            onClose={() => setIsCategoryDescriptionMenuPickerVisible(false)}
            triggerRef={categoryDescriptionMenuButtonRef}
          />
        </div>
      </div>

      <div>
        <label htmlFor="fontForCategorySelect">Шрифт категории:</label>
        <select
          className="border-2"
          id="fontForCategorySelect"
          name="fontForCategory"
          value={designSettings.fontForCategory}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForCategorySize"
          value={designSettings.fontForCategorySize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForCategoryWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForCategoryWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForCategoryWeight"
          data-type="fontWeight"
          checked={designSettings.fontForCategoryWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForCategoryStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForCategoryStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForCategoryStyle"
          data-type="fontStyle"
          checked={designSettings.fontForCategoryStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <div>
        <label htmlFor="fontForCategoryDescriptionSelect">Шрифт описания категории:</label>
        <select
          className="border-2"
          id="fontForCategoryDescriptionSelect"
          name="fontForCategoryDescription"
          value={designSettings.fontForCategoryDescription}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForCategoryDescriptionSize"
          value={designSettings.fontForCategoryDescriptionSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForCategoryDescriptionWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForCategoryDescriptionWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForCategoryDescriptionWeight"
          data-type="fontWeight"
          checked={designSettings.fontForCategoryDescriptionWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForCategoryDescriptionStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForCategoryDescriptionStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForCategoryDescriptionStyle"
          data-type="fontStyle"
          checked={designSettings.fontForCategoryDescriptionStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* КАРТОЧКА БЛЮДА */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Карточка блюда</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет карточки блюда</p>
        <button
          ref={dishCardButtonRef}
          onClick={() => setIsDishCardPickerVisible(true)}
          className="border-2 w-12 h-5"
          style={{ backgroundColor: designSettings.colorDishCard }}></button>
        <PopupColorPicker
          color={designSettings.colorDishCard}
          name="colorDishCard"
          onChangeComplete={handleChange}
          isVisible={isDishCardPickerVisible}
          onClose={() => setIsDishCardPickerVisible(false)}
          triggerRef={dishCardButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDishCard}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки карточки блюда</p>
        <button
          ref={dishCardBorderButtonRef}
          onClick={() => setIsDishCardBorderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorDishCardBorder }}></button>
        <PopupColorPicker
          color={designSettings.colorDishCardBorder}
          name="colorDishCardBorder"
          onChangeComplete={handleChange}
          isVisible={isDishCardBorderPickerVisible}
          onClose={() => setIsDishCardBorderPickerVisible(false)}
          triggerRef={dishCardBorderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDishCardBorder}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки карточки блюда</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="dishCardBorderSize"
          value={designSettings.dishCardBorderSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Радиус обводки карточки блюда</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="dishCardBorderRadius"
          value={designSettings.dishCardBorderRadius}
          onChange={handleChangeText}
          min="0"
          max="1000"
        />
        <p className="pl-3">пикселей</p>
      </div>

      <p className="mt-4 font-semibold">Название блюда</p>
      <div className="flex flex-row">
        <p className="pr-4 ">Цвет текста названия блюда</p>
        <button
          ref={fontDishTitleButtonRef}
          onClick={() => setIsFontDishTitlePickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontDishTitle }}></button>
        <PopupColorPicker
          color={designSettings.colorFontDishTitle}
          name="colorFontDishTitle"
          onChangeComplete={handleChange}
          isVisible={isFontDishTitlePickerVisible}
          onClose={() => setIsFontDishTitlePickerVisible(false)}
          triggerRef={fontDishTitleButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontDishTitle}</p>
      </div>
      <div>
        <label htmlFor="fontForDishCardTitleSelect">Шрифт названия блюда в карточке:</label>
        <select
          className="border-2"
          id="fontForDishCardTitleSelect"
          name="fontForDishCardTitle"
          value={designSettings.fontForDishCardTitle}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForDishCardTitleSize"
          value={designSettings.fontForDishCardTitleSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardTitleWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForDishCardTitleWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardTitleWeight"
          data-type="fontWeight"
          checked={designSettings.fontForDishCardTitleWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardTitleStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForDishCardTitleStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardTitleStyle"
          data-type="fontStyle"
          checked={designSettings.fontForDishCardTitleStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <p className="mt-4 font-semibold">Описание блюда</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста описания блюда</p>
        <div className="flex flex-row">
          <button
            ref={fontDishDescriptionButtonRef}
            onClick={() => setIsFontDishDescriptionPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorFontDishDescription }}></button>
          <PopupColorPicker
            color={designSettings.colorFontDishDescription}
            name="colorFontDishDescription"
            onChangeComplete={handleChange}
            isVisible={isFontDishDescriptionPickerVisible}
            onClose={() => setIsFontDishDescriptionPickerVisible(false)}
            triggerRef={fontDishDescriptionButtonRef}
          />
        </div>

        <p className="pl-4"> {designSettings.colorFontDishDescription}</p>
      </div>
      <div>
        <label htmlFor="fontForDishCardDescriptionSelect">Шрифт описания блюда в карточке:</label>
        <select
          className="border-2"
          id="fontForDishCardDescriptionSelect"
          name="fontForDishCardDescription"
          value={designSettings.fontForDishCardDescription}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForDishCardDescriptionSize"
          value={designSettings.fontForDishCardDescriptionSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardDescriptionWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForDishCardDescriptionWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardDescriptionWeight"
          data-type="fontWeight"
          checked={designSettings.fontForDishCardDescriptionWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardDescriptionStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForDishCardDescriptionStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardDescriptionStyle"
          data-type="fontStyle"
          checked={designSettings.fontForDishCardDescriptionStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <p className="mt-4 font-semibold">Цена блюда</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста цены блюда</p>
        <div className="flex flex-row">
          <button
            ref={fontDishPriceButtonRef}
            onClick={() => setIsFontDishPricePickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorFontDishPrice }}></button>
          <PopupColorPicker
            color={designSettings.colorFontDishPrice}
            name="colorFontDishPrice"
            onChangeComplete={handleChange}
            isVisible={isFontDishPricePickerVisible}
            onClose={() => setIsFontDishPricePickerVisible(false)}
            triggerRef={fontDishPriceButtonRef}
          />
        </div>

        <p className="pl-4"> {designSettings.colorFontDishPrice}</p>
      </div>
      <div>
        <label htmlFor="fontForDishCardPriceSelect">Шрифт цены блюда в карточке:</label>
        <select
          className="border-2"
          id="fontForDishCardPriceSelect"
          name="fontForDishCardPrice"
          value={designSettings.fontForDishCardPrice}
          onChange={handleChangeText}>
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForDishCardPriceSize"
          value={designSettings.fontForDishCardPriceSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardPriceWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForDishCardPriceWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardPriceWeight"
          data-type="fontWeight"
          checked={designSettings.fontForDishCardPriceWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardPriceStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForDishCardPriceStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardPriceStyle"
          data-type="fontStyle"
          checked={designSettings.fontForDishCardPriceStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <p className="mt-4 font-semibold">Выход блюда</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста выхода блюда</p>
        <button
          ref={fontDishOutputButtonRef}
          onClick={() => setIsFontDishOutputPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontDishOutput }}></button>
        <PopupColorPicker
          color={designSettings.colorFontDishOutput}
          name="colorFontDishOutput"
          onChangeComplete={handleChange}
          isVisible={isFontDishOutputPickerVisible}
          onClose={() => setIsFontDishOutputPickerVisible(false)}
          triggerRef={fontDishOutputButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontDishOutput}</p>
      </div>
      <div>
        <label htmlFor="fontForDishCardOutputSelect">Шрифт выхода блюда в карточке:</label>
        <select
          className="border-2"
          id="fontForDishCardOutputSelect"
          name="fontForDishCardOutput"
          value={designSettings.fontForDishCardOutput}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForDishCardOutputSize"
          value={designSettings.fontForDishCardOutputSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardOutputWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForDishCardOutputWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardOutputWeight"
          data-type="fontWeight"
          checked={designSettings.fontForDishCardOutputWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForDishCardOutputStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForDishCardOutputStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForDishCardOutputStyle"
          data-type="fontStyle"
          checked={designSettings.fontForDishCardOutputStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <p className="mt-4 font-semibold">Кнопки заказа + -</p>

      <div className="flex flex-row">
        <p className="pr-4">Цвет кнопки заказа +</p>
        <div className="flex flex-row">
          <button
            ref={orderButtonPlusButtonRef}
            onClick={() => setIsOrderButtonPlusPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorOrderButtonPlus }}></button>
          <PopupColorPicker
            color={designSettings.colorOrderButtonPlus}
            name="colorOrderButtonPlus"
            onChangeComplete={handleChange}
            isVisible={isOrderButtonPlusPickerVisible}
            onClose={() => setIsOrderButtonPlusPickerVisible(false)}
            triggerRef={orderButtonPlusButtonRef}
          />
        </div>
        <p className="pl-4"> {designSettings.colorOrderButtonPlus}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста в кнопках заказа блюда + -</p>
        <button
          ref={fontDishOrderButtonRef}
          onClick={() => setIsFontDishOrderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontDishOrder }}></button>
        <PopupColorPicker
          color={designSettings.colorFontDishOrder}
          name="colorFontDishOrder"
          onChangeComplete={handleChange}
          isVisible={isFontDishOrderPickerVisible}
          onClose={() => setIsFontDishOrderPickerVisible(false)}
          triggerRef={fontDishOrderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontDishOrder}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки кнопки заказа в карточке</p>
        <button
          ref={dishCardButtonBorderButtonRef}
          onClick={() => setIsDishCardButtonBorderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorDishCardButtonBorder }}></button>
        <PopupColorPicker
          color={`#${designSettings.colorDishCardButtonBorder}`}
          name="colorDishCardButtonBorder"
          onChangeComplete={handleChange}
          isVisible={isDishCardButtonBorderPickerVisible}
          onClose={() => setIsDishCardButtonBorderPickerVisible(false)}
          triggerRef={dishCardButtonBorderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDishCardButtonBorder}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Радиус обводки</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="dishCardButtonBorderRadius"
          value={designSettings.dishCardButtonBorderRadius}
          onChange={handleChangeText}
          min="0"
          max="1000"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="dishCardButtonBorderSize"
          value={designSettings.dishCardButtonBorderSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>

      {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

      <br />
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста количества заказа в карточке блюда</p>
        <button
          ref={fontDishOrderCountButtonRef}
          onClick={() => setIsFontDishOrderCountPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontDishOrderCount }}></button>
        <PopupColorPicker
          color={designSettings.colorFontDishOrderCount}
          name="colorFontDishOrderCount"
          onChangeComplete={handleChange}
          isVisible={isFontDishOrderCountPickerVisible}
          onClose={() => setIsFontDishOrderCountPickerVisible(false)}
          triggerRef={fontDishOrderCountButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontDishOrderCount}</p>
      </div>

      <div>
        <div className="mt-5">
          <button onClick={saveDesign} className="button-s">
            Сохранить
          </button>
        </div>
      </div>

      {/* ЗАКАЗ */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Нижнее меню заказа</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет фона заказа +</p>
        <div className="flex flex-row">
          <button
            ref={orderDivRef}
            onClick={() => setIsOrderDivPickerVisible(true)}
            className="border-2 w-12 h-5 ml-4"
            style={{ backgroundColor: designSettings.colorOrderDiv }}></button>
          <PopupColorPicker
            color={designSettings.colorOrderDiv}
            name="colorOrderDiv"
            onChangeComplete={handleChange}
            isVisible={isOrderDivPickerVisible}
            onClose={() => setIsOrderDivPickerVisible(false)}
            triggerRef={orderDivRef}
          />
        </div>

        <p className="pl-4"> {designSettings.colorOrderDiv}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет кнопки заказа</p>
        <button
          ref={orderDivButtonButtonRef}
          onClick={() => setIsOrderDivButtonPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorOrderDivButton }}></button>
        <PopupColorPicker
          color={designSettings.colorOrderDivButton}
          name="colorOrderDivButton"
          onChangeComplete={handleChange}
          isVisible={isOrderDivButtonPickerVisible}
          onClose={() => setIsOrderDivButtonPickerVisible(false)}
          triggerRef={orderDivButtonButtonRef}
        />
        <p className="pl-4"> {designSettings.colorOrderDivButton}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки кнопки заказа +</p>
        <button
          ref={orderDivButtonBorderButtonRef}
          onClick={() => setIsOrderDivButtonBorderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorOrderDivButtonBorder }}></button>
        <PopupColorPicker
          color={designSettings.colorOrderDivButtonBorder}
          name="colorOrderDivButtonBorder"
          onChangeComplete={handleChange}
          isVisible={isOrderDivButtonBorderPickerVisible}
          onClose={() => setIsOrderDivButtonBorderPickerVisible(false)}
          triggerRef={orderDivButtonBorderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorOrderDivButtonBorder}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста заказа +</p>
        <button
          ref={orderDivFontColorButtonRef}
          onClick={() => setIsOrderDivFontColorPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorOrderDivFontColor }}></button>
        <PopupColorPicker
          color={designSettings.colorOrderDivFontColor}
          name="colorOrderDivFontColor"
          onChangeComplete={handleChange}
          isVisible={isOrderDivFontColorPickerVisible}
          onClose={() => setIsOrderDivFontColorPickerVisible(false)}
          triggerRef={orderDivFontColorButtonRef}
        />
        <p className="pl-4"> {designSettings.colorOrderDivFontColor}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста кнопки заказа +</p>
        <button
          ref={orderDivFontColorButtonButtonRef}
          onClick={() => setIsOrderDivFontColorButtonPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorOrderDivFontColorButton }}></button>
        <PopupColorPicker
          color={designSettings.colorOrderDivFontColorButton}
          name="colorOrderDivFontColorButton"
          onChangeComplete={handleChange}
          isVisible={isOrderDivFontColorButtonPickerVisible}
          onClose={() => setIsOrderDivFontColorButtonPickerVisible(false)}
          triggerRef={orderDivFontColorButtonButtonRef}
        />
        <p className="pl-4"> {designSettings.colorOrderDivFontColorButton}</p>
      </div>

      <div>
        <label htmlFor="fontForOrderDivSelect">Шрифт заказа:</label>
        <select
          className="border-2"
          id="fontForOrderDivSelect"
          name="fontForOrderDiv"
          value={designSettings.fontForOrderDiv}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForOrderDivSize"
          value={designSettings.fontForOrderDivSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForOrderDivWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForOrderDivWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForOrderDivWeight"
          data-type="fontWeight"
          checked={designSettings.fontForOrderDivWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForOrderDivStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForOrderDivStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForOrderDivStyle"
          data-type="fontStyle"
          checked={designSettings.fontForOrderDivStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* ОТЗЫВЫ */}
      <p className="text-lg text-center font-semibold pt-6 pb-2">Отзывы</p>
      <div className="flex flex-row">
        <p className="pr-4">Цвет фона под звездочками отзыва</p>
        <button
          ref={divFeedbackButtonRef}
          onClick={() => setIsDivFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorDivFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorDivFeedback}
          name="colorDivFeedback"
          onChangeComplete={handleChange}
          isVisible={isDivFeedbackPickerVisible}
          onClose={() => setIsDivFeedbackPickerVisible(false)}
          triggerRef={divFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDivFeedback}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки плашки со звездочками </p>
        <button
          ref={divBorderFeedbackButtonRef}
          onClick={() => setIsDivBorderFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorDivBorderFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorDivBorderFeedback}
          name="colorDivBorderFeedback"
          onChangeComplete={handleChange}
          isVisible={isDivBorderFeedbackPickerVisible}
          onClose={() => setIsDivBorderFeedbackPickerVisible(false)}
          triggerRef={divBorderFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDivBorderFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет звездочек</p>
        <button
          ref={starsFeedbackButtonRef}
          onClick={() => setIsStarsFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorStarsFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorStarsFeedback}
          name="colorStarsFeedback"
          onChangeComplete={handleChange}
          isVisible={isStarsFeedbackPickerVisible}
          onClose={() => setIsStarsFeedbackPickerVisible(false)}
          triggerRef={starsFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorStarsFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет поля ввода отзыва и телефона</p>
        <button
          ref={editsFeedbackButtonRef}
          onClick={() => setIsEditsFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorEditsFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorEditsFeedback}
          name="colorEditsFeedback"
          onChangeComplete={handleChange}
          isVisible={isEditsFeedbackPickerVisible}
          onClose={() => setIsEditsFeedbackPickerVisible(false)}
          triggerRef={editsFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorEditsFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки поля ввода отзыва и телефона</p>
        <button
          ref={editsBorderFeedbackButtonRef}
          onClick={() => setIsEditsBorderFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorEditsBorderFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorEditsBorderFeedback}
          name="colorEditsBorderFeedback"
          onChangeComplete={handleChange}
          isVisible={isEditsBorderFeedbackPickerVisible}
          onClose={() => setIsEditsBorderFeedbackPickerVisible(false)}
          triggerRef={editsBorderFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorEditsBorderFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста в отзывах </p>
        <button
          ref={fontFeedbackButtonRef}
          onClick={() => setIsFontFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorFontFeedback}
          name="colorFontFeedback"
          onChangeComplete={handleChange}
          isVisible={isFontFeedbackPickerVisible}
          onClose={() => setIsFontFeedbackPickerVisible(false)}
          triggerRef={fontFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontFeedback}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста в полях ввода отзывов </p>
        <button
          ref={fontInputFeedbackButtonRef}
          onClick={() => setIsFontInputFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontInputFeedback }}></button>
        <PopupColorPicker
          color={`#${designSettings.colorFontInputFeedback}`}
          name="colorFontInputFeedback"
          onChangeComplete={handleChange}
          isVisible={isFontInputFeedbackPickerVisible}
          onClose={() => setIsFontInputFeedbackPickerVisible(false)}
          triggerRef={fontInputFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontInputFeedback}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Цвет кнопок в отзывах</p>
        <button
          ref={buttonFeedbackButtonRef}
          onClick={() => setIsButtonFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorButtonFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorButtonFeedback}
          name="colorButtonFeedback"
          onChangeComplete={handleChange}
          isVisible={isButtonFeedbackPickerVisible}
          onClose={() => setIsButtonFeedbackPickerVisible(false)}
          triggerRef={buttonFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorButtonFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки кнопок в отзывах </p>
        <button
          ref={buttonBorderFeedbackButtonRef}
          onClick={() => setIsButtonBorderFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorButtonBorderFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorButtonBorderFeedback}
          name="colorButtonBorderFeedback"
          onChangeComplete={handleChange}
          isVisible={isButtonBorderFeedbackPickerVisible}
          onClose={() => setIsButtonBorderFeedbackPickerVisible(false)}
          triggerRef={buttonBorderFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorButtonBorderFeedback}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет текста на кнопках в отзывах</p>
        <button
          ref={buttonTextFeedbackButtonRef}
          onClick={() => setIsButtonTextFeedbackPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorButtonTextFeedback }}></button>
        <PopupColorPicker
          color={designSettings.colorButtonTextFeedback}
          name="colorButtonTextFeedback"
          onChangeComplete={handleChange}
          isVisible={isButtonTextFeedbackPickerVisible}
          onClose={() => setIsButtonTextFeedbackPickerVisible(false)}
          triggerRef={buttonTextFeedbackButtonRef}
        />
        <p className="pl-4"> {designSettings.colorButtonTextFeedback}</p>
      </div>

      <div>
        <label htmlFor="fontForFeedbackSelect">Шрифт в отзывах:</label>
        <select
          className="border-2"
          id="fontForFeedbackSelect"
          name="fontForFeedback"
          value={designSettings.fontForFeedback}
          onChange={handleChangeText}>
          {/* Создание опции для primaryFont и secondaryFont */}
          {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
          <option value={designSettingsFromSlice?.primaryFont}>
            {designSettingsFromSlice?.primaryFont}
          </option>
          <option value={designSettingsFromSlice?.secondaryFont}>
            {designSettingsFromSlice?.secondaryFont}
          </option>
        </select>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForFeedbackSize"
          value={designSettings.fontForFeedbackSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForFeedbackWeightCheckbox" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForFeedbackWeightCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForFeedbackWeight"
          data-type="fontWeight"
          checked={designSettings.fontForFeedbackWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForFeedbackStyleCheckbox" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForFeedbackStyleCheckbox"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForFeedbackStyle"
          data-type="fontStyle"
          checked={designSettings.fontForFeedbackStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
      {/* ИСТОРИИ */}

      <p className="text-lg text-center font-semibold pt-6 pb-2">Истории</p>
      <div className="flex flex-row">
        <p className="pr-4">Ширина иконки группы историй</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="groupStoriesWidth"
          value={designSettings.groupStoriesWidth}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Высота иконки группы историй</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="groupStoriesHeight"
          value={designSettings.groupStoriesHeight}
          onChange={handleChangeText}
          min="1"
          max="500"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Радиус закругления иконки истории</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="groupStoriesBorderRadius"
          value={designSettings.groupStoriesBorderRadius}
          onChange={handleChangeText}
          min="1"
          max="1000"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Толщина обводки иконки истории</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="groupStoriesBorderRadiusSize"
          value={designSettings.groupStoriesBorderRadiusSize}
          onChange={handleChangeText}
          min="0"
          max="30"
        />
        <p className="pl-3">пикселей</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Цвет обводки истории</p>
        <button
          ref={storiesGroupBorderButtonRef}
          onClick={() => setIsStoriesGroupBorderPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorStoriesGroupBorder }}></button>
        <PopupColorPicker
          color={designSettings.colorStoriesGroupBorder}
          name="colorStoriesGroupBorder"
          onChangeComplete={handleChange}
          isVisible={isStoriesGroupBorderPickerVisible}
          onClose={() => setIsStoriesGroupBorderPickerVisible(false)}
          triggerRef={storiesGroupBorderButtonRef}
        />
        <p className="pl-4"> {designSettings.colorStoriesGroupBorder}</p>
      </div>

      <p className="mt-4 font-semibold">Группы историй</p>
      <label htmlFor="fontForFeedbackSelect">Шрифт под группой истории:</label>
      <select
        className="border-2"
        id="fontForTitleGroupStoies"
        name="fontForTitleGroupStoies"
        value={designSettings.fontForTitleGroupStoies}
        onChange={handleChangeText}>
        {/* Создание опции для primaryFont и secondaryFont */}
        {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
        <option value={designSettingsFromSlice?.primaryFont}>
          {designSettingsFromSlice?.primaryFont}
        </option>
        <option value={designSettingsFromSlice?.secondaryFont}>
          {designSettingsFromSlice?.secondaryFont}
        </option>
      </select>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста под группой истории</p>
        <button
          ref={fontGroupStoriesButtonRef}
          onClick={() => setIsFontGroupStoriesPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontGroupStories }}></button>
        <PopupColorPicker
          color={designSettings.colorFontGroupStories}
          name="colorFontGroupStories"
          onChangeComplete={handleChange}
          isVisible={isFontGroupStoriesPickerVisible}
          onClose={() => setIsFontGroupStoriesPickerVisible(false)}
          triggerRef={fontGroupStoriesButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontGroupStories}</p>
      </div>

      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForTitleGroupStoriesSize"
          value={designSettings.fontForTitleGroupStoriesSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleGroupStoriesWeight" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForTitleGroupStoriesWeight"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTitleGroupStoriesWeight"
          data-type="fontWeight"
          checked={designSettings.fontForTitleGroupStoriesWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleGroupStoriesStyle" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForTitleGroupStoriesStyle"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTitleGroupStoriesStyle"
          data-type="fontStyle"
          checked={designSettings.fontForTitleGroupStoriesStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <p className="mt-4 font-semibold mb-1">Истории</p>
      <label htmlFor="fontForTitleStorykSelect">Шрифт заголовка истории:</label>
      <select
        className="border-2"
        id="fontForTitleStory"
        name="fontForTitleStory"
        value={designSettings.fontForTitleStory}
        onChange={handleChangeText}>
        {/* Создание опции для primaryFont и secondaryFont */}
        {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
        <option value={designSettingsFromSlice?.primaryFont}>
          {designSettingsFromSlice?.primaryFont}
        </option>
        <option value={designSettingsFromSlice?.secondaryFont}>
          {designSettingsFromSlice?.secondaryFont}
        </option>
      </select>
      <div className="flex flex-row">
        <p className="pr-4">Цвет заголовка внутри истории</p>
        <button
          ref={fontTitleStoryButtonRef}
          onClick={() => setIsFontTitleStoryPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontTitleStory }}></button>
        <PopupColorPicker
          color={designSettings.colorFontTitleStory}
          name="colorFontTitleStory"
          onChangeComplete={handleChange}
          isVisible={isFontTitleStoryPickerVisible}
          onClose={() => setIsFontTitleStoryPickerVisible(false)}
          triggerRef={fontTitleStoryButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontTitleStory}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForTitleStorySize"
          value={designSettings.fontForTitleStorySize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleStoryWeight" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForTitleStoryWeight"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTitleStoryWeight"
          data-type="fontWeight"
          checked={designSettings.fontForTitleStoryWeight === '600'}
          onChange={handleChangeText}
        />
      </div>

      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitleStoryStyle" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForTitleStoryStyle"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500 "
          type="checkbox"
          name="fontForTitleStoryStyle"
          data-type="fontStyle"
          checked={designSettings.fontForTitleStoryStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <label htmlFor="fontForFeedbackSelect">Шрифт текста внутри истории</label>
      <select
        className="border-2 mt-3"
        id="fontForTextStory"
        name="fontForTextStory"
        value={designSettings.fontForTextStory}
        onChange={handleChangeText}>
        {/* Создание опции для primaryFont и secondaryFont */}
        {/* Предполагается, что значения для primaryFont и secondaryFont получены из другого состояния или пропсов */}
        <option value={designSettingsFromSlice?.primaryFont}>
          {designSettingsFromSlice?.primaryFont}
        </option>
        <option value={designSettingsFromSlice?.secondaryFont}>
          {designSettingsFromSlice?.secondaryFont}
        </option>
      </select>

      <div className="flex flex-row">
        <p className="pr-4">Цвет текста внутри истории</p>
        <button
          ref={fontTextStoryButtonRef}
          onClick={() => setIsFontTextStoryPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorFontTextStory }}></button>
        <PopupColorPicker
          color={designSettings.colorFontTextStory}
          name="colorFontTextStory"
          onChangeComplete={handleChange}
          isVisible={isFontTextStoryPickerVisible}
          onClose={() => setIsFontTextStoryPickerVisible(false)}
          triggerRef={fontTextStoryButtonRef}
        />
        <p className="pl-4"> {designSettings.colorFontTextStory}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Размер шрифта</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="fontForTextSize"
          value={designSettings.fontForTextSize}
          onChange={handleChangeText}
          min="6"
          max="72"
        />
      </div>

      <div className="flex flex-row items-center">
        <label htmlFor="fontForTitlefontForTextWeightStoryWeight" className="pr-4">
          Полужирный:
        </label>
        <input
          id="fontForTextWeight"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTextWeight"
          data-type="fontWeight"
          checked={designSettings.fontForTextWeight === '600'}
          onChange={handleChangeText}
        />
      </div>
      <div className="flex flex-row items-center">
        <label htmlFor="fontForTextStyle" className="pr-4">
          Наклонный:
        </label>
        <input
          id="fontForTextStyle"
          className="border border-slate-400 shadow-sm rounded-md focus:ring-blue-500"
          type="checkbox"
          name="fontForTextStyle"
          data-type="fontStyle"
          checked={designSettings.fontForTextStyle === 'italic'}
          onChange={handleChangeText}
        />
      </div>

      <div className="flex flex-row mt-6">
        <p className="pr-4">Цвет фона под заголовком и текстом внутри истории</p>
        <button
          ref={divTitleAndTextStoryButtonRef}
          onClick={() => setIsDivTitleAndTextStoryPickerVisible(true)}
          className="border-2 w-12 h-5 ml-4"
          style={{ backgroundColor: designSettings.colorDivTitleAndTextStory }}></button>
        <PopupColorPicker
          color={designSettings.colorDivTitleAndTextStory}
          name="colorDivTitleAndTextStory"
          onChangeComplete={handleChange}
          isVisible={isDivTitleAndTextStoryPickerVisible}
          onClose={() => setIsDivTitleAndTextStoryPickerVisible(false)}
          triggerRef={divTitleAndTextStoryButtonRef}
        />
        <p className="pl-4"> {designSettings.colorDivTitleAndTextStory}</p>
      </div>
      <div className="flex flex-row">
        <p className="pr-4">Прозрачность фона под заголовком и текстом в истории</p>
        <input
          className="border border-slate-400 w-20  px-2  block shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="opacityDivTitleAndTextStory"
          value={designSettings.opacityDivTitleAndTextStory}
          onChange={handleChangeText}
          min="0"
          max="100"
        />
        <p className="pl-2">%</p>
      </div>
      <div className="mt-5">
        <button onClick={saveDesign} className="button-s">
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default DesignMenu;
