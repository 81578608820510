import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useQRCode from '../utils/useQRCode';
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import PopupColorPicker from './PopupColorPicker';
import Cookies from 'js-cookie';
import WebFont from 'webfontloader';

const ExportToPDF = () => {
  const fonts = [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Merriweather',
    'Roboto Condensed',
    'Oswald',
    'Raleway',
    'Ubuntu',
    'Roboto Slab',
    'Bitter',
    'Exo 2',
    'Pacifico',
    'Lobster',
    'Play',
    'Caveat',
    'Yanone Kaffeesatz',
    'Amatic SC',
    'Russo One',
    'Philosopher',
    'Alice',
    'Old Standard TT',
    'Unbounded',
    'Poiret One',
    'Jura',
    'Bad Script',
    'Neucha',
    'Pangolin',
    'Marck Script',
    'Alumni Sans',
    'Balsamiq Sans',
    'Pattaya',
    'Kurale',
    'Alegreya SC',
    'Viaoda Libre',
    'Bona Nova',
  ];

  const [fontFamily, setFontFamily] = useState(Cookies.get('fontFamily') || 'Ubuntu');
  const [aspectRatio, setAspectRatio] = useState(Cookies.get('aspectRatio') || 'ar4-5');
  const [sideW, setSideW] = useState('500');
  const [sideH, setSideH] = useState('625');
  const [orientation, setOrientation] = useState('portrait');
  const [qrValue, setQrValue] = useState('');
  const [showQrBgColor, setShowQrBgColor] = useState(false);
  const [qrColor, setQrColor] = useState(Cookies.get('qrColor') || '#321651');
  const [qrSize, setQrSize] = useState(Cookies.get('qrSize') || '420');
  const [qrLink, setQrLink] = useState('');
  const [linkOption, setLinkOption] = useState('direct');
  const [title, setTitle] = useState(Cookies.get('title') || 'Меню. Оплата. Чаевые. Отзыв');
  const [titleSize, setTitleSize] = useState(Cookies.get('titleSize') || '30');
  const [titlePaddingTop, setTitlePaddingTop] = useState(Cookies.get('titlePaddingTop') || '30');

  const [titleFontWeight, setTitleFontWeight] = useState(Cookies.get('titleFontWeight') || '500');
  // Обработчик для чекбокса
  const handleTitleFontWeight = (event) => {
    // Если чекбокс активен, устанавливаем жирность 700, иначе 500
    const newWeight = event.target.checked ? '700' : '500';
    setTitleFontWeight(newWeight);
    // Записываем новое значение в Cookies для сохранения состояния
    Cookies.set('titleFontWeight', newWeight);
  };
  const [textUnderTitleWeight, setTextUnderTitleWeight] = useState(
    Cookies.get('textUnderTitleWeight') || '500',
  );

  const handleTextUnderTitleWeight = (event) => {
    // Если чекбокс активен, устанавливаем жирность 700, иначе 500
    const newWeight = event.target.checked ? '700' : '500';
    setTextUnderTitleWeight(newWeight);
    // Записываем новое значение в Cookies для сохранения состояния
    Cookies.set('textUnderTitleWeight', newWeight);
  };

  const [footerText1Weight, setFooterText1Weight] = useState(
    Cookies.get('footerText1Weight') || '500',
  );

  const handleFooterText1Weight = (event) => {
    // Если чекбокс активен, устанавливаем жирность 700, иначе 500
    const newWeight = event.target.checked ? '700' : '500';
    setFooterText1Weight(newWeight);
    // Записываем новое значение в Cookies для сохранения состояния
    Cookies.set('footerText1Weight', newWeight);
  };

  const [footerText2Weight, setFooterText2Weight] = useState(
    Cookies.get('footerText2Weight') || '500',
  );

  const handleFooterText2Weight = (event) => {
    // Если чекбокс активен, устанавливаем жирность 700, иначе 500
    const newWeight = event.target.checked ? '700' : '500';
    setFooterText2Weight(newWeight);
    // Записываем новое значение в Cookies для сохранения состояния
    Cookies.set('footerText2Weight', newWeight);
  };

  const branchLink = useSelector(selectActiveBranch).link;

  const [titleColor, setTitleColor] = useState(Cookies.get('titleColor') || '#321651');
  const [showTitleColorPicker, setShowTitleColorPicker] = useState(false);

  const [qrBgColor, setQrBgColor] = useState(Cookies.get('qrBgColor') || 'FFFFFF');
  const [showQrColor, setShowQrColor] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
  const [footerText1Size, setFooterText1Size] = useState(Cookies.get('footerText1Size') || '20');
  const [footerText2PaddingTop, setFooterText2PaddingTop] = useState(
    Cookies.get('footerText2PaddingTop') || '20',
  );
  const [footerText2PaddingBottom, setFooterText2PaddingBottom] = useState(
    Cookies.get('footerText2PaddingBottom') || '20',
  );
  const [footerText2, setFooterText2] = useState(Cookies.get('footerText2') || '');
  const [footerText2Size, setFooterText2Size] = useState(Cookies.get('footerText2Size') || '16');
  const [backgroundColor, setBackgroundColor] = useState(
    Cookies.get('backgroundColor') || '#E2E2E2',
  );

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }, []); // Загружаем шрифты при монтировании компонента
  const handleChangeFont = (event) => {
    setFontFamily(event.target.value);
  };

  const handleBackgroundColorChange = (color, name) => {
    if (name === 'backgroundColor') {
      setBackgroundColor(color);
    }
  };
  const toggleBackgroundColorPicker = () => {
    setShowBackgroundColorPicker(!showBackgroundColorPicker);
  };
  const toggleTitleColorPicker = () => {
    setShowTitleColorPicker(!showTitleColorPicker);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTitleSizeChange = (event) => {
    setTitleSize(event.target.value);
  };
  const handleTitlePaddingTop = (event) => {
    setTitlePaddingTop(event.target.value); // Обновление состояния при выборе другого размера
  };
  const handleFooterText1Size = (event) => {
    setFooterText1Size(event.target.value);
  };
  const handleFooterText2Size = (event) => {
    setFooterText2Size(event.target.value);
  };
  const [textUnderTitlePaddingTop, setTextUnderTitlePaddingTop] = useState(
    Cookies.get('textUnderTitlePaddingTop') || '20',
  );
  const [textUnderTitleSize, setTextUnderTitleSize] = useState(
    Cookies.get('textUnderTitleSize') || '20',
  );
  const [textUnderTitle, setTextUnderTitle] = useState(Cookies.get('textUnderTitle') || '');
  const [textUnderTitlePaddingBottom, setTextUnderTitlePaddingBottom] = useState(
    Cookies.get('textUnderTitlePaddingBottom') || '20',
  );
  const [footerText1, setFooterText1] = useState(
    Cookies.get('footerText1') || 'Наведите камеру на qr код',
  );
  const [footerText1PaddingTop, setFooterText1PaddingTop] = useState(
    Cookies.get('footerText1PaddingTop') || '15',
  );
  const handleFooterText1PaddingTop = (event) => {
    setFooterText1PaddingTop(event.target.value); // Обновление состояния при выборе другого размера
  };
  const [footerText1PaddingBottom, setFooterText1PaddingBottom] = useState(
    Cookies.get('footerText1PaddingBottom') || '7',
  );
  const handleFooterText1PaddingBottom = (event) => {
    setFooterText1PaddingBottom(event.target.value); // Обновление состояния при выборе другого размера
  };

  const handleDownloadPdf = async () => {
    const element = document.getElementById('content-to-capture');
    //const canvas = await html2canvas(element);
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      //format: [canvas.width, canvas.height]
      format: [sideW * 2, sideH * 2],
    });

    pdf.addImage(data, 'PNG', 0, 0, canvas.width, canvas.height);
    pdf.save('download.pdf');
  };

  //const qrCodeImage = useQRCode(qrLink, qrColor, qrBgColor, qrSize);
  const qrCodeImage = useQRCode(qrLink, qrColor, qrBgColor, qrSize);

  const styleQrCode = {
    width: `${qrSize}`, // ширина в пунктах
    height: `${qrSize}`, // высота в пунктах
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    alignItems: 'center',
  };

  const handleAspectRationChange = (event) => {
    setAspectRatio(event.target.value);
  };
  const tables = useSelector(selectActiveBranch).tables;

  useEffect(() => {
    if (aspectRatio === 'ar4-5') {
      setSideW('500');
      setSideH('625');
      setOrientation('portrait');
    }
    if (aspectRatio === 'ar2-3') {
      setSideW('500');
      setSideH('750');
      setOrientation('portrait');
    }
    if (aspectRatio === 'ar3-5') {
      setSideW('500');
      setSideH('833');
      setOrientation('portrait');
    }
    if (aspectRatio === 'ar9-16') {
      setSideW('500');
      setSideH('885');
      setOrientation('portrait');
    }
    if (aspectRatio === 'ar1-2') {
      setSideW('500');
      setSideH('1000');
      setOrientation('portrait');
    }
    if (aspectRatio === 'square') {
      setSideW('500');
      setSideH('500');
      setOrientation('portrait');
    }
    if (aspectRatio === 'ar5-4') {
      setSideW('625');
      setSideH('500');
      setOrientation('landscape');
    }
    if (aspectRatio === 'ar3-2') {
      setSideW('750');
      setSideH('500');
      setOrientation('landscape');
    }
    if (aspectRatio === 'ar5-3') {
      setSideW('830');
      setSideH('500');
      setOrientation('landscape');
    }
    if (aspectRatio === 'ar16-9') {
      setSideW('885');
      setSideH('500');
      setOrientation('landscape');
    }
    if (aspectRatio === 'ar2-1') {
      setSideW('1000');
      setSideH('500');
      setOrientation('landscape');
    }
  }, [aspectRatio]);

  const handleChange = (event) => {
    console.log('Выбран столик с ID:', event.target.value);
    if (event.target.value === 'notable') {
      setQrValue(null);
      return;
    }
    setQrValue(event.target.value);
  };

  const toggleQrBgColorPicker = () => {
    setShowQrBgColor(!showQrBgColor);
  };

  const handleQrBgColorChange = (color, name) => {
    if (name === 'qrBgColor') {
      setQrBgColor(color);
    }
  };

  const toggleQrColorPicker = () => {
    setShowQrColor(!showQrColor);
  };
  const handleQrColorChange = (color, name) => {
    if (name === 'qrColor') {
      setQrColor(color);
    }
  };
  const handleQrSize = (event) => {
    console.log('qrSize: ', event.target.value);
    const newSize = event.target.value;
    setQrSize(newSize);
  };
  const handleTitleColorChange = (color, name) => {
    if (name === 'titleColor') {
      setTitleColor(color);
    }
  };
  const [titlePaddingBottom, setTitlePaddingBottom] = useState(
    Cookies.get('titlePaddingBottom') || '0',
  );
  const handleTitlePaddingBottom = (event) => {
    setTitlePaddingBottom(event.target.value); // Обновление состояния при выборе другого размера
  };
  const handleTextUnderTitleChange = (event) => {
    setTextUnderTitle(event.target.value);
  };
  const handleUnderTitleSizeChange = (event) => {
    setTextUnderTitleSize(event.target.value);
  };
  const handleTextUnderTitlePaddingTop = (event) => {
    setTextUnderTitlePaddingTop(event.target.value); // Обновление состояния при выборе другого размера
  };
  const handleTextUnderTitlePaddingBottom = (event) => {
    setTextUnderTitlePaddingBottom(event.target.value); // Обновление состояния при выборе другого размера
  };
  const handleFooterText2PaddingTop = (event) => {
    setFooterText2PaddingTop(event.target.value); // Обновление состояния при выборе другого размера
  };
  const handleFooterText2PaddingBottom = (event) => {
    setFooterText2PaddingBottom(event.target.value); // Обновление состояния при выборе другого размера
  };

  useEffect(() => {
    console.log('Сработал');
    if (linkOption === 'delivery') {
      setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/delivery`);
      return;
    }
    if (linkOption === 'direct') {
      if (qrValue) {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}?t=${qrValue}`);
      } else {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}`);
      }
    }
    if (linkOption === 'feedback') {
      if (qrValue) {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/feedback?t=${qrValue}`);
      } else {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/feedback`);
      }
    }
    if (linkOption === 'tips') {
      if (qrValue) {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/tips?t=${qrValue}`);
      } else {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/tips`);
      }
    }
    if (linkOption === 'payment') {
      if (qrValue) {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/payment?t=${qrValue}`);
      } else {
        setQrLink(`${process.env.REACT_APP_LINK}/${branchLink}/payment`);
      }
    }
  }, [linkOption, qrValue, branchLink]);

  const handleLinkOptionChange = (event) => {
    setLinkOption(event.target.value);
  };

  useEffect(() => {
    const saveCookies = () => {
      Cookies.set('title', title);
      Cookies.set('textUnderTitle', textUnderTitle);
      //Cookies.set('qrValue', qrValue);
      Cookies.set('footerText1', footerText1);
      Cookies.set('footerText2', footerText2);
      Cookies.set('titleSize', titleSize);
      Cookies.set('textUnderTitleSize', textUnderTitleSize);
      Cookies.set('footerText1Size', footerText1Size);
      Cookies.set('footerText2Size', footerText2Size);
      Cookies.set('backgroundColor', backgroundColor);
      Cookies.set('titleColor', titleColor);
      Cookies.set('qrBgColor', qrBgColor);
      Cookies.set('qrColor', qrColor);
      Cookies.set('qrSize', qrSize);
      Cookies.set('titlePaddingTop', titlePaddingTop);
      Cookies.set('titlePaddingBottom', titlePaddingBottom);
      Cookies.set('textUnderTitlePaddingTop', textUnderTitlePaddingTop);
      Cookies.set('textUnderTitlePaddingBottom', textUnderTitlePaddingBottom);
      Cookies.set('footerText1PaddingTop', footerText1PaddingTop);
      Cookies.set('footerText1PaddingBottom', footerText1PaddingBottom);
      Cookies.set('footerText2PaddingTop', footerText2PaddingTop);
      Cookies.set('footerText2PaddingBottom', footerText2PaddingBottom);
      Cookies.set('orientation', orientation);
      Cookies.set('fontFamily', fontFamily);
      Cookies.set('textUnderTitleWeight', textUnderTitleWeight);
      Cookies.set('titleFontWeight', titleFontWeight);
      Cookies.set('footerText1Weight', footerText1Weight);
      Cookies.set('footerText2Weight', footerText2Weight);
      Cookies.set('aspectRatio', aspectRatio);
    };
    saveCookies();
    // Добавим зависимости, чтобы обновлять куки при изменении соответствующих состояний
  }, [
    title,
    textUnderTitle,
    //qrValue,
    footerText1,
    footerText2,
    titleSize,
    textUnderTitleSize,
    footerText1Size,
    footerText2Size,
    backgroundColor,
    titleColor,
    qrBgColor,
    qrColor,
    qrSize,
    titlePaddingTop,
    titlePaddingBottom,
    textUnderTitlePaddingTop,
    textUnderTitlePaddingBottom,
    footerText1PaddingTop,
    footerText1PaddingBottom,
    footerText2PaddingTop,
    footerText2PaddingBottom,
    orientation,
    fontFamily,
    titleFontWeight,
    textUnderTitleWeight,
    footerText1Weight,
    footerText2Weight,
    aspectRatio,
  ]);

  return (
    <div>
      <div className="w-20 mb-4 mt-5">
        <select
          value={qrValue}
          onChange={handleChange}
          className="border font-normal border-slate-800 p-1 mr-4 ml-1 text-lg">
          {/* Проверяем, есть ли элементы в tables перед их отображением */}
          <option value="notable">Без привязки к столику/месту</option>
          {tables && tables.length > 0 ? (
            tables.map((table) => (
              <option key={table.id} value={table.link}>
                {table.name}
              </option>
            ))
          ) : (
            <option disabled>Нет доступных столов/мест</option>
          )}
        </select>
      </div>
      <div className="w-20 mb-4">
        <select
          value={linkOption}
          onChange={handleLinkOptionChange}
          className="border font-normal border-slate-800 p-1 mr-4 ml-1 text-base">
          <option value="direct">Основная ссылка (рекомендуется)</option>
          <option value="feedback">Отзыв</option>
          <option value="payment">Оплата</option>
          <option value="tips">Чаевые</option>
          <option value="delivery">Доставка</option>
        </select>
        {/*  <p>{`${process.env.REACT_APP_LINK}/${branchLink}?t=${qrValue}`}</p> */}
      </div>


      <p className='pl-1'>пропорции макета</p>
      <select
        onChange={handleAspectRationChange}
        value={aspectRatio}
        className="border font-normal border-slate-800 p-1 mr-4 ml-1 text-base">
        <option value="ar4-5">4:5 вертик. (1.25)</option>
        <option value="ar2-3">2:3 вертик. (1.5)</option>
        <option value="ar3-5">3:5 вертик. (1.66)</option>
        <option value="ar9-16">9:16 вертик. (1.77)</option>
        <option value="ar1-2">1:2 вертик. (2)</option>
        <option value="square">1:1 квадрат</option>
        <option value="ar5-4">5:4 гориз. (1.25)</option>
        <option value="ar3-2">3:2 гориз. (1.5)</option>
        <option value="ar5-3">5:3 гориз. (1.66)</option>
        <option value="ar16-9">16:9 гориз. (1.7)</option>
        <option value="ar2-1">2:1 гориз. (2)</option>
      </select>


      <div className="flex flex-row mt-4">
        <div className="flex flex-row items-center ">
          <div>
            <p>цвет фона</p>
          </div>
          <div>
            <button
              className="border-slate-700 border w-12 h-5 ml-4"
              style={{ backgroundColor: backgroundColor }}
              onClick={toggleBackgroundColorPicker}></button>
          </div>
          <div className="">
            {showBackgroundColorPicker && (
              <PopupColorPicker
                color={backgroundColor}
                onChangeComplete={handleBackgroundColorChange}
                name="backgroundColor"
                isVisible={showBackgroundColorPicker}
                onClose={() => setShowBackgroundColorPicker(false)}
              />
            )}
          </div>
        </div>

        <div className="flex flex-row items-center ml-6 ">
          <div>
            <p>цвет текста</p>
          </div>
          <div>
            <button
              className="border-slate-700 border w-12 h-5 ml-4"
              style={{ backgroundColor: titleColor }}
              onClick={toggleTitleColorPicker}></button>
          </div>
          <div className="">
            {showTitleColorPicker && (
              <PopupColorPicker
                color={titleColor}
                onChangeComplete={handleTitleColorChange}
                name="titleColor"
                isVisible={showTitleColorPicker}
                onClose={() => setShowTitleColorPicker(false)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row items-end mt-6 mb-6">
        <div className='mr-4'>
          <p>Шрифт</p>
        </div>
        <div>
          <select
            value={fontFamily}
            onChange={handleChangeFont}
            style={{ fontFamily }}
            className="text-lg  p-2 border border-slate-800">
            {fonts.map((font) => (
              <option key={font} value={font} style={{ fontFamily: font }} className="text-lg">
                {font}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="bg-gray-200 pt-2 pl-2">
        <textarea
          className="input-1"
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Заголовок"
          style={{ width: '300px' }}
        />

        <div className="flex flex-row">
          <div className="flex flex-row">
            <div>размер текста:</div>
            <div>
              <input
                type="number"
                value={titleSize}
                onChange={handleTitleSizeChange}
                className="input-font-size w-10 "
              />
            </div>
          </div>
          <div className="ml-4">
            <label>
              жирный
              <input
                type="checkbox"
                checked={titleFontWeight === '700'}
                onChange={handleTitleFontWeight}
              />
            </label>
          </div>
          <div className="flex flex-row ml-4">
            <div>отступы:</div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↑</span>
              <input
                type="number"
                value={titlePaddingTop}
                onChange={handleTitlePaddingTop}
                className="input-font-size w-10 "
              />
            </div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↓</span>
              <input
                type="number"
                value={titlePaddingBottom}
                onChange={handleTitlePaddingBottom}
                className="input-font-size w-10 "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-200 pt-2 pl-2 mt-4">
        <textarea
          className="input-1"
          type="text"
          value={textUnderTitle}
          onChange={handleTextUnderTitleChange}
          placeholder="Подзаголовок"
          style={{ width: '300px', padding: '5px' }}
        />

        <div className="flex flex-row">
          <div className="flex flex-row">
            <div>размер текста:</div>
            <div>
              <input
                type="number"
                value={textUnderTitleSize}
                onChange={handleUnderTitleSizeChange}
                className="input-font-size w-10"
              />
            </div>
          </div>

          <div className="ml-4">
            <label>
              жирный
              <input
                type="checkbox"
                checked={textUnderTitleWeight === '700'}
                onChange={handleTextUnderTitleWeight}
              />
            </label>
          </div>

          <div className="flex flex-row ml-4">
            <div>отступы:</div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↑</span>
              <input
                type="number"
                value={textUnderTitlePaddingTop}
                onChange={handleTextUnderTitlePaddingTop}
                className="input-font-size w-10"
              />
            </div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↓</span>
              <input
                type="number"
                value={textUnderTitlePaddingBottom}
                onChange={handleTextUnderTitlePaddingBottom}
                className="input-font-size w-10 "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 pt-2 pl-2 mt-4">
        <div className="font-semibold">QR код</div>
        <div className="flex flex-row">
          <div className="flex flex-row items-center ">
            <div>
              <p>цвет фона</p>
            </div>
            <div>
              <button
                className="border-slate-700 border w-12 h-5 ml-4"
                style={{ backgroundColor: qrBgColor }}
                onClick={toggleQrBgColorPicker}></button>
            </div>
            <div className="">
              {showQrBgColor && (
                <PopupColorPicker
                  color={qrBgColor}
                  onChangeComplete={handleQrBgColorChange}
                  name="qrBgColor"
                  isVisible={showQrBgColor}
                  onClose={() => setShowQrBgColor(false)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row items-center ml-4">
            <div>
              <p>цвет элементов</p>
            </div>
            <div>
              <button
                className="border-slate-700 border w-12 h-5 ml-4"
                style={{ backgroundColor: qrColor }}
                onClick={toggleQrColorPicker}></button>
            </div>
            <div className="">
              {showQrColor && (
                <PopupColorPicker
                  color={qrColor}
                  onChangeComplete={handleQrColorChange}
                  name="qrColor"
                  isVisible={showQrColor}
                  onClose={() => setShowQrColor(false)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row ml-4">
            <div>размер qr кода:</div>
            <div>
              <input
                type="number"
                value={qrSize}
                onChange={handleQrSize}
                className="input-size w-48 "
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-200 pt-2 pl-1 mt-1">
          <input
            className="input-1"
            type="text"
            value={qrLink}
            onChange={(e) => setQrValue(e.target.value)}
            placeholder="Введите URL для QR кода"
            style={{ marginBottom: '10px', width: '600px', padding: '5px' }}
          />
        </div>
      </div>
      <div className="mt-4"></div>
      <div className="bg-gray-200 pt-2 pl-2 mt-4">
        <textarea
          className="input-1"
          type="text"
          value={footerText1}
          onChange={(e) => setFooterText1(e.target.value)}
          placeholder="Текст под qr кодом"
          style={{ width: '300px', padding: '5px' }}
        />

        <div className="flex flex-row">
          <div className="flex flex-row">
            <div>размер текста:</div>
            <div>
              <input
                type="number"
                value={footerText1Size}
                onChange={handleFooterText1Size}
                className="input-font-size w-10 "
              />
            </div>
          </div>
          <div className="ml-4">
            <label>
              жирный
              <input
                type="checkbox"
                checked={footerText1Weight === '700'}
                onChange={handleFooterText1Weight}
              />
            </label>
          </div>
          <div className="flex flex-row ml-4">
            <div>отступы:</div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↑</span>
              <input
                type="number"
                value={footerText1PaddingTop}
                onChange={handleFooterText1PaddingTop}
                className="input-font-size w-10 "
              />
            </div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↓</span>
              <input
                type="number"
                value={footerText1PaddingBottom}
                onChange={handleFooterText1PaddingBottom}
                className="input-font-size w-10 "
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-200 pt-2 pl-2 mt-4">
        <textarea
          className="input-1"
          type="text"
          value={footerText2}
          onChange={(e) => setFooterText2(e.target.value)}
          placeholder="Текст под qr кодом"
          style={{ marginBottom: '10px', width: '300px', padding: '5px' }}
        />
        <div className="flex flex-row">
          <div className="flex flex-row">
            <div>размер текста:</div>
            <div>
              <input
                type="number"
                value={footerText2Size}
                onChange={handleFooterText2Size}
                className="input-font-size w-10 "
              />
            </div>
          </div>
          <div className="ml-4">
            <label>
              жирный
              <input
                type="checkbox"
                checked={footerText2Weight === '700'}
                onChange={handleFooterText2Weight}
              />
            </label>
          </div>
          <div className="flex flex-row ml-4">
            <div>отступы:</div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↑</span>
              <input
                type="number"
                value={footerText2PaddingTop}
                onChange={handleFooterText2PaddingTop}
                className="input-font-size w-10 "
              />
            </div>
            <div>
              <span className="ml-1 mr-1 font-semibold">↓</span>
              <input
                type="number"
                value={footerText2PaddingBottom}
                onChange={handleFooterText2PaddingBottom}
                className="input-font-size w-10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-5"></div>

      <div className='mb-5'>
        <button
          className="button-s"
          onClick={handleDownloadPdf}>
          Скачать PDF
        </button>
      </div>

      <div>
        {orientation === 'portrait' ? (
          <div className="border border-dashed border-slate-700 inline-block">
            <div
              className="flex flex-col  items-center"
              id="content-to-capture"
              style={{ background: backgroundColor, width: `${sideW}px`, height: `${sideH}px` }}>
              <div>
                <h1
                  style={{
                    fontSize: titleSize + 'px',
                    paddingTop: titlePaddingTop + 'px',
                    paddingBottom: titlePaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: titleFontWeight,
                    color: titleColor,
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}>
                  {title}
                </h1>
              </div>
              <div>
                <p
                  style={{
                    fontSize: textUnderTitleSize + 'px',
                    paddingTop: textUnderTitlePaddingTop + 'px',
                    paddingBottom: textUnderTitlePaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: textUnderTitleWeight,
                    color: titleColor,
                    paddingRight: '20px',
                    paddingLeft: '20px',
                  }}>
                  {textUnderTitle}
                </p>
              </div>
              <div style={styleQrCode}>
                {qrCodeImage && (
                  <img src={qrCodeImage} style={{ width: qrSize + 'px', height: qrSize + 'px' }} />
                )}
              </div>
              <div>
                <p className="text-sm" style={{ color: titleColor }}>
                  Сервис RestoLink.ru
                </p>
              </div>
              <div>
                <h2
                  style={{
                    fontSize: footerText1Size + 'px',
                    paddingTop: footerText1PaddingTop + 'px',
                    paddingBottom: footerText1PaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: footerText1Weight,
                    color: titleColor,
                    paddingRight: '20px',
                    paddingLeft: '20px',
                  }}>
                  {footerText1}
                </h2>
              </div>
              <div>
                <p
                  style={{
                    fontSize: footerText2Size + 'px',
                    paddingTop: footerText2PaddingTop + 'px',
                    paddingBottom: footerText2PaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: footerText2Weight,
                    color: titleColor,
                    paddingRight: '20px',
                    paddingLeft: '20px',
                  }}>
                  {footerText2}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="border border-dashed border-slate-700 inline-block">
            <div
              className="flex flex-row"
              id="content-to-capture"
              style={{
                background: backgroundColor,
                width: `${sideW}px`, // Ширина контейнера
                minHeight: `${sideH}px`, // Минимальная высота контейнера
              }}>
              <div
                style={{
                  width: qrSize + 'px',
                  flexShrink: 0, // Запрещаем сжиматься
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <div style={styleQrCode}>
                  {qrCodeImage && (
                    <img
                      src={qrCodeImage}
                      className="ml-12"
                      alt="qr-code"
                      style={{ width: qrSize + 'px', height: qrSize + 'px' }}
                    />
                  )}
                </div>
                <div>
                  <p className="text-sm" style={{ color: titleColor }}>
                    Сервис RestoLink.ru
                  </p>
                </div>
              </div>
              <div className="flex flex-col flex-grow ml-12 pr-5" style={{ overflow: 'visible' }}>
                <div
                  style={{
                    fontSize: titleSize + 'px',
                    paddingTop: titlePaddingTop + 'px',
                    paddingBottom: titlePaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: titleFontWeight,
                    color: titleColor,
                    overflow: 'auto', // Изменено для поддержки прокрутки, если текст выходит за пределы блока
                  }}>
                  {title}
                </div>
                <div
                  style={{
                    fontSize: textUnderTitleSize + 'px',
                    paddingTop: textUnderTitlePaddingTop + 'px',
                    paddingBottom: textUnderTitlePaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: textUnderTitleWeight,
                    color: titleColor,
                    overflow: 'auto',
                  }}>
                  {textUnderTitle}
                </div>
                <div
                  style={{
                    fontSize: footerText1Size + 'px',
                    paddingTop: footerText1PaddingTop + 'px',
                    paddingBottom: footerText1PaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: footerText1Weight,
                    color: titleColor,
                    overflow: 'auto',
                  }}>
                  {footerText1}
                </div>
                <div
                  style={{
                    fontSize: footerText2Size + 'px',
                    paddingTop: footerText2PaddingTop + 'px',
                    paddingBottom: footerText2PaddingBottom + 'px',
                    fontFamily: fontFamily,
                    fontWeight: footerText2Weight,
                    color: titleColor,
                    overflow: 'auto',
                  }}>
                  {footerText2}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    
    </div>
  );
};

export default ExportToPDF;
