import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



export const fetchTipsByUserId = createAsyncThunk(
    'tips/fetchByUserId',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/tips/${userId}`);
            return response.data; // предполагается, что это массив
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// создаем организацию (аналог Компании в CloudeTips)
export const createCompany = createAsyncThunk(
    'tips/createCompany',
    async ({ name, inn, userId }, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/create-company`,
                { name, inn, userId },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : { message: "Невозможно выполнить запрос" });
        }
    }
);


// запрос токена CloudTips
export const fetchToken = createAsyncThunk(
    'tips/fetchToken',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/get-token`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const checkOrCreateBranchTips = createAsyncThunk(
    'tips/checkOrCreateBranchTips',
    async (branchId, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/branch/checkOrCreateTips`, {
                branchId
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchCloudTipsId = createAsyncThunk(
    'tips/fetchCloudTipsId',
    async (branchId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/fetch-cloudtips-id/${branchId}`);
            return response.data;  // предполагается, что сервер возвращает { cloudtipsId: '...' }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


// добавление сотрудника получателя чаевых
export const addEmployee = createAsyncThunk(
    'tips/addEmployee',
    async ({ employeeName, employeePhone, cloudtipsId, branchId }, { rejectWithValue }) => {
        try {
            // Отправка запроса на сервер для добавления сотрудника
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/add-employee`,
                { employeeName, employeePhone, cloudtipsId, branchId }
            );
            return response.data; // Возвращаем данные с сервера (если есть)
        } catch (error) {
            return rejectWithValue(error.response.data); // Возвращаем ошибку, если запрос не удался
        }
    }
);

export const uploadRecipientPhoto = createAsyncThunk(
    'tips/uploadRecipientPhoto',
    async ({ id, photoFile }) => {
        const formData = new FormData();
        formData.append('photo', photoFile);
        const response = await axios.post(`${process.env.REACT_APP_URL}/api/tips/recipients/${id}/photo`, formData);
        return response.data;
    }
);

export const deleteRecipientPhoto = createAsyncThunk(
    'tips/deleteRecipientPhoto',
    async ({ cloudtips_user_id, id }) => {
        const response = await axios.post(`${process.env.REACT_APP_URL}/api/tips/deleterecipientsphoto`, {
            cloudtips_user_id, id
        });
        return response.data;
    }
);



// добавляем филиал в систему чаевых

export const addBranchToTips = createAsyncThunk(
    'tips/addBranchToTips',
    async ({ branchName, branchId }, { rejectWithValue }) => {
        console.log(' branchId: ', branchId);
        try {
            // Выполнение запроса к серверу для добавления филиала
            const response = await axios.post(`${process.env.REACT_APP_URL}/create-place`, { branchName, branchId });
            return response.data;
        } catch (error) {
            // Обработка ошибок и возврат значения с помощью rejectWithValue
            return rejectWithValue(error.response.data);
        }
    }
);
// добавить существующего сотрудника в существующий филиал 
export const addExistRecipientToBranch = createAsyncThunk(
    'tips/addExistRecipientToBranch',
    async ({ cloudtips_user_id, id, branchIdCT }, { rejectWithValue }) => {
        try {
            // Выполнение запроса к серверу для добавления филиала
            const response = await axios.post(`${process.env.REACT_APP_URL}/add/exist/recipient/branch`, { cloudtips_user_id, id, branchIdCT });
            return response.data;
        } catch (error) {
            // Обработка ошибок и возврат значения с помощью rejectWithValue
            return rejectWithValue(error.response.data);
        }
    }
);
// отвязать существующего сотрудника от филиала
export const detachRecipientFromBranch = createAsyncThunk(
    'tips/detachRecipientFromBranch',
    async ({ cloudtips_user_id, id, branchIdCT }, { rejectWithValue }) => {
        try {
            // Выполнение запроса к серверу для отвязки получателя от филиала
            const response = await axios.delete(`${process.env.REACT_APP_URL}/detach/exist/recipient/branch`, {
                data: { cloudtips_user_id, id, branchIdCT }
            });

            return response.data;
        } catch (error) {
            // Обработка ошибок и возврат значения с помощью rejectWithValue
            return rejectWithValue(error.response.data);
        }
    }
);
// отправка смс сотруднику
export const sendSms = createAsyncThunk(
    'tips/detachRecipientFromBranch',
    async ({ cloudtips_user_id, id, branchIdCT }, { rejectWithValue }) => {
        try {
            // Выполнение запроса к серверу для отвязки получателя от филиала
            const response = await axios.delete(`${process.env.REACT_APP_URL}/detach/exist/recipient/branch`, {
                data: { cloudtips_user_id, id, branchIdCT }
            });

            return response.data;
        } catch (error) {
            // Обработка ошибок и возврат значения с помощью rejectWithValue
            return rejectWithValue(error.response.data);
        }
    }
);


const initialState = {
    tipsOrganization: [],
    loading: false,
    error: null,
    hasTipsOrganization: false, // Новое состояние для проверки наличия организаций
    token: null,  // Добавляем токен в начальное состояние
    cloudtipsId: null,
    tipsBranches: [],
    tipsRecipients: [],
    phoneNumberExists: false,

};

const tipsSlice = createSlice({
    name: 'tips',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addExistRecipientToBranch.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const branchIndex = state.tipsBranches.findIndex((b) => b.cloudtips_id === action.payload.branchIdCT);
                if (branchIndex !== -1) {
                    const updatedBranch = {
                        ...state.tipsBranches[branchIndex],
                        recipients: [...state.tipsBranches[branchIndex].recipients, action.payload.id],
                    };
                    state.tipsBranches[branchIndex] = updatedBranch;
                }
            })
            .addCase(detachRecipientFromBranch.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const branchIndex = state.tipsBranches.findIndex((b) => b.cloudtips_id === action.payload.branchIdCT);
                if (branchIndex !== -1) {
                    const updatedBranch = {
                        ...state.tipsBranches[branchIndex],
                        recipients: state.tipsBranches[branchIndex].recipients.filter((recipientId) => recipientId !== action.payload.id),
                    };
                    state.tipsBranches[branchIndex] = updatedBranch;
                }
            })
            .addCase(addBranchToTips.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(addBranchToTips.fulfilled, (state, action) => {
                state.status = 'succeeded';
                action.payload.branch.recipients = []

                state.tipsBranches.push(action.payload.branch)
            })
            .addCase(addBranchToTips.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteRecipientPhoto.fulfilled, (state, action) => {
                state.loading = false;
                const { id, photoUrl } = action.payload;
                const recipient = state.tipsRecipients.find((r) => r.id === id);
                if (recipient) {
                    recipient.photo_url = null;
                }
            })
            .addCase(uploadRecipientPhoto.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadRecipientPhoto.fulfilled, (state, action) => {
                state.loading = false;
                const { id, photoUrl } = action.payload;
                const recipient = state.tipsRecipients.find((r) => r.id === id);
                if (recipient) {
                    recipient.photo_url = photoUrl;
                }
            })
            .addCase(uploadRecipientPhoto.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(addEmployee.fulfilled, (state, action) => {
                state.loading = false;
                const { newReiciverFromCloudTips, recipientId, relationshipValues } = action.payload;

                // Добавление нового сотрудника в список tipsRecipients
                if (newReiciverFromCloudTips && newReiciverFromCloudTips.created && newReiciverFromCloudTips.created.length > 0) {
                    const newRecipient = newReiciverFromCloudTips.created[0];
                    const formattedRecipient = {
                        id: recipientId, // Внутренний идентификатор
                        fullname: newRecipient.name,
                        payment_link: null,
                        phone_number: newRecipient.phoneNumber,
                        photo_url: null,
                        user_id: newRecipient.userId,
                        layout_id: newRecipient.layoutId
                    };
                    state.tipsRecipients.push(formattedRecipient); // Добавляем нового сотрудника в список сотрудников
                }

                // Обновление списка получателей в каждом филиале
                if (recipientId) {
                    state.tipsBranches.forEach(branch => {
                        branch.recipients.push(recipientId);
                    });
                }
            }).addCase(addEmployee.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;

                // Проверяем вхождение подстроки в сообщении об ошибке
                if (action.payload && action.payload.message && action.payload.message.includes('уже в скоупе')) {
                    state.phoneNumberExists = true; // Устанавливаем флаг ошибки дублирования номера телефона
                } else {
                    state.phoneNumberExists = false; // Сбрасываем флаг ошибки, если это другая ошибка
                }
            })
            .addCase(addEmployee.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.phoneNumberExists = false; // Сбрасываем флаг ошибки перед началом запроса
            })
            .addCase(fetchCloudTipsId.pending, (state) => {
                state.loading = true;
                state.cloudtipsId = null;
                state.error = null;
            })
            .addCase(fetchCloudTipsId.fulfilled, (state, action) => {
                state.loading = false;
                state.cloudtipsId = action.payload.cloudtipsId;
            })
            .addCase(fetchCloudTipsId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Обработчики для запроса токена
            .addCase(fetchToken.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchToken.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.access_token;
            })
            .addCase(fetchToken.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchTipsByUserId.pending, (state) => {
                console.log('fetchTipsByUserId PENDING action.payload');
                state.loading = true;
                state.error = null;
                state.hasTipsOrganization = false;
            })
            .addCase(fetchTipsByUserId.fulfilled, (state, action) => {
                console.log('fetchTipsByUserId FULFILLED action.payload', action.payload);

                state.loading = false;
                state.tipsOrganization = action.payload.orgData;
                state.hasTipsOrganization = action.payload.orgData.length > 0;
                state.tipsBranches = action.payload.branchesWithRecipients;
                state.tipsRecipients = action.payload.recipients;
            })
            .addCase(fetchTipsByUserId.rejected, (state, action) => {
                console.log('fetchTipsByUserId REJECTED');
                state.loading = false;
                state.error = action.payload;
                state.hasTipsOrganization = false;
            })
            .addCase(createCompany.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.tipsOrganization.push(action.payload.organization);
                state.hasTipsOrganization = true;
            })
            .addCase(createCompany.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Ошибка при создании организации';
                state.validationErrors = action.payload.validationErrors || [];
            }).addCase(checkOrCreateBranchTips.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(checkOrCreateBranchTips.fulfilled, (state, action) => {
                state.loading = false;
                // Сохраняем cloudtipsId в стейт
                state.cloudtipsId = action.payload.cloudtipsId;
            })
            .addCase(checkOrCreateBranchTips.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.cloudtipsId = null; // Очищаем cloudtipsId в случае ошибки
            });
        /*  .addCase(createCompany.pending, (state) => {
             state.loading = true;
         })
         .addCase(createCompany.fulfilled, (state, action) => {
             state.loading = false;
             // При необходимости добавьте логику для обработки успешного создания организации
             console.log('Созданная компания:', action.payload);
             alert('Компания успешно создана!');
         })
         .addCase(createCompany.rejected, (state, action) => {
             state.loading = false;
             console.error('Ошибка при создании компании:', action.payload);
             alert('Ошибка при создании компании!');
         }) */
    }
});

export default tipsSlice.reducer;
