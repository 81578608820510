import axios from 'axios';
import { setCategories } from '../store/slices/categoriesSlice';

const getCategories = async (branchId, dispatch) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/get_categories`, { 
      params: { 
        branchId: branchId 
      } 
    });
    const sortedCategories = [...response.data].sort((a, b) => a.position - b.position);
    dispatch(setCategories(sortedCategories));
  } catch (error) {
    console.error(error);
  }
};

export default getCategories;
