// Obsolete
//src\store\slices\newBranchSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { selectUserId } from './userSlice';
import NewBranch from '../../components/NewBranch';

export const addNewBranch = createAsyncThunk(
    'branch/addNewBranch',
    async (branchData, { getState, rejectWithValue }) => {
        // // console.log('~ addNewBranch отработал');
        const userId = selectUserId(getState()); // Извлеките userId с помощью селектора
        // // console.log('~ userId = ', userId);
        const newBranch = branchData;
        try {
            const formData = new FormData();
                  
            formData.append('userId', userId);
            formData.append('name', newBranch.name);
            formData.append('city', newBranch.city);
            formData.append('address', newBranch.address);
            formData.append('link', newBranch.link);
            formData.append('description', newBranch.description);
            formData.append('file', newBranch.fileData);


            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewBranch`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // вот тут надо вытащить ответ сервера из запистать ответ в переменную. Там хранится id нового филиала 
            // console.log('~ response.data addNewBranch  = ', response.data);
            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const newBranchSlice = createSlice({
    name: 'newBranch',
    initialState: {
        name: '',
        city: '',
        address: '',
        link: '',
        description: '',
        //logo: null,
        status: 'idle',
        fileData: null, // Здесь можно хранить строку с данными файла, если нужно
        fileName: '',
        fileType: '',
        newBranchId: null, // id нового созданного филиала 

    },
    reducers: {
        // Здесь вы можете добавить редьюсеры для изменения полей
        updateName: (state, action) => {
            state.name = action.payload;
        },
        updateCity: (state, action) => {
            state.city = action.payload;
        },
        updateAddress: (state, action) => {
            state.address = action.payload;
        },
        updateLink: (state, action) => {
            state.link = action.payload;
        },
        updateDescription: (state, action) => {
            state.description = action.payload;
        },
        updateStatus: (state, action) => {
            state.status = action.payload;
        },
        setFileData: (state, action) => {
            state.fileData = action.payload.data;
            state.fileName = action.payload.name;
            state.fileType = action.payload.type;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addNewBranch.fulfilled, (state, action) => {
          // action.payload должен содержать объект с ID филиала
          state.newBranchId = action.payload.id;
          state.id = action.payload.id;
        });
      },
});


export const selectNewBranchId = (state) => state.newBranch.newBranchId;

export const {
    updateName, updateCity, updateAddress, updateLink, updateDescription, updateStatus, setFileData,
} = newBranchSlice.actions;
// Экспортируйте только редьюсер
export default newBranchSlice.reducer;
