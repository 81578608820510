import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Создайте асинхронное действие для получения записей с сервера
export const fetchReviewPlatformsByBranch = createAsyncThunk(
  'reviewPlatforms/fetchByBranch',
  async (branch_id) => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/getReviewPlatforms/${branch_id}`, {
      params: {
        branch_id
      }
    });
    return response.data;
  }
);

export const deleteReviewPlatform = createAsyncThunk(
  'reviewPlatforms/delete',
  async (id) => {
    // // console.log('~ вызван deleteReviewPlatform');
    const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteReviewPlatform/${id}`);
    return id;
  }
);

export const updateReviewPlatform = createAsyncThunk(
  'reviewPlatforms/update',
  async (updatedPlatform) => {
    // // console.log('~ Сработал асинк updateReviewPlatform');
    // // console.log('~ updatedPlatform = ', updatedPlatform);
    const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateReviewPlatform/${updatedPlatform.id}`, updatedPlatform);
    return response.data;
  }
);

export const addNewPlatform = createAsyncThunk(
  'reviewPlatforms/addNewPlatform',
  async (platformData, thunkAPI) => {
    // console.log('~ вызван в слайсе addNewPlatform');
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/createReviewPlatform`, platformData);
    return response.data;
  }
);



const reviewPlatformsSlice = createSlice({
  name: 'reviewPlatforms',
  initialState: {
    platforms: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchReviewPlatformsByBranch.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReviewPlatformsByBranch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.platforms = action.payload;
      })
      .addCase(fetchReviewPlatformsByBranch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }).addCase(deleteReviewPlatform.fulfilled, (state, action) => {
        state.platforms = state.platforms.filter(platform => platform.id !== action.payload);
      })
      .addCase(updateReviewPlatform.fulfilled, (state, action) => {
        const updatedPlatformIndex = state.platforms.findIndex(platform => platform.id === action.payload.id);
        if (updatedPlatformIndex !== -1) {
          state.platforms[updatedPlatformIndex] = action.payload;
        }
      }).addCase(addNewPlatform.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addNewPlatform.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Добавляем новую платформу в список платформ
        state.platforms.push(action.payload);
      })
      .addCase(addNewPlatform.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    ;
  }
});



export default reviewPlatformsSlice.reducer;
