import React from 'react';
import Sidebar from '../components/Sidebar';
import OptionsFeedback from '../components/OptionsFeedback';
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import TelegramOptions from '../components/TelegramOptions';
import AddLeastOneBranch from '../components/AddLeastOneBranch';
import SocialLinks from '../components/SocialLinks';
import TopCardBranch from '../components/TopCardBranch';
import CustomFormsFeedback from '../components/CustomFormsFeedback';
import FeedbackReports from '../components/FeedbackReports';

const FeedbackPage = () => {
  const [active, setActive] = React.useState(0);
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;
  const chatIdFeedback = activeBranch?.chat_id_feedback;
  //usecustomformfeedback
  const handleClick = (index) => {
    setActive(index);
  };

  // для телеграм компонента
  const actionPage = 'feedback';

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex flex-col w-full">
        <TopCardBranch />
        <div className="px-16 py-6 shadow-md  ">
          <button
            className={`px-4 py-2 ${
              active === 0 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(0)}>
            Основные настройки
          </button>
          <button
            className={`px-4 py-2 ${
              active === 2 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(2)}>
            Отчеты
          </button>
          <button
            className={`px-4 py-2 ${
              active === 1 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(1)}>
            Опросники
          </button>
        </div>
        <div className="px-4">
          {active === 0 && (
            <div>
              <div>
                <SocialLinks branchId={activeBranchId} />
              </div>
              <div>
                <OptionsFeedback />
              </div>
              <div>
                <TelegramOptions
                  key={Math.random()}
                  chatId={chatIdFeedback}
                  actibeBranchId={activeBranchId}
                  actionPage={actionPage}
                />
              </div>
            </div>
          )}
          {active === 1 && (
            <div>
              <CustomFormsFeedback />
            </div>
          )}
          {active === 2 && (
            <div>
              <FeedbackReports />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
