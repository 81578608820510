import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { motion } from 'framer-motion';

const Gorygirodelivery = () => {
  const [dishes, setDishes] = useState(null);
  //const [operatorName, setOperatorName] = useState('(имя)');
  const [selectedButton, setSelectedButton] = useState(null);
  const [isDeliveryConfirmed, setIsDeliveryConfirmed] = useState(false);
  const [deliveryConfirmation, setDeliveryConfirmation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Все');
  const [searchQuery, setSearchQuery] = useState('');
  const [cart, setCart] = useState([]);

  const [addGrilledPotatoes, setAddGrilledPotatoes] = useState(null);
  const [addSauce, setAddSauce] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);
  const [promo, setPromo] = useState(null);
  //const [selectedGrilledPotatoes, setSelectedGrilledPotatoes] = useState('');
  //const [selectedSauce, setSelectedSauce] = useState('');
  //const [isPotatoAddedThroughPrompt, setIsPotatoAddedThroughPrompt] = useState(false);
  const [orderComment, setOrderComment] = useState('');
  const [animatingButton, setAnimatingButton] = useState(null);
  const [comments, setComments] = useState({});

  const [selectedModifiers, setSelectedModifiers] = useState({});

  // тестовые данные
  //const sauceId = 267;
  //const potatoes = 225;
  //const sauceIds = [267, 388, 389, 390]; // тут д.б все соусы из меню
  //const branch = 'rest1';
  //const modifiedDishId = 217; // ID модифицируемого блюда */

  const [isAnimating, setIsAnimating] = useState(false);

  // боевые данные
  const sauceId = 717;
  const potatoes = 714;
  const sauceIds = [717, 718, 719, 720, 721, 722]; // тут д.б все соусы из меню
  const branch = 'gorygirogaruna';
  const modifiedDishId = 708; // ID модифицируемого блюда

  const modifiers = [
    { name: 'Острый', price: 30 },
    { name: 'Сырный', price: 40 },
    { name: 'Дзадзыки', price: 30 },
    { name: 'Соус для гиро доп', price: 30 },
    { name: 'Вишневый соус', price: 50 },
    { name: 'Без Соуса', price: 0 },
  ];

  const [customerDetails, setCustomerDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    street: '',
    house: '',
    entrance: '',
    floor: '',
    apartment: '',
    intercomCode: '',
    comment: '',
  });

  const clearSearch = () => {
    setSearchQuery('');
  };

  const hasSauceInCart = () => {
    return sauceIds.some((sauceId) => cart.some((item) => item.dishId === sauceId));
  };

  const handleModifierChange = (name, price) => {
    setSelectedModifiers((prevModifiers) => {
      const updatedModifiers = { ...prevModifiers };
      if (updatedModifiers[name] !== undefined) {
        delete updatedModifiers[name];
      } else {
        updatedModifiers[name] = price;
      }
      return updatedModifiers;
    });
  };

  const handleOrderCommentChange = (e) => {
    setOrderComment(e.target.value);
  };

  const handleGrilledPotatoesChange = (value) => {
    setAddGrilledPotatoes(value);

    const selectedDish = dishes.find((dish) => dish.id === potatoes);
    if (!selectedDish) return;

    if (value === 'Да') {
      setCart((prevCart) => [...prevCart, { dishId: selectedDish.id, modifiers: {} }]);
    } else if (value === 'Нет') {
      setCart((prevCart) => {
        const newCart = prevCart.filter((item) => item.dishId !== selectedDish.id);
        return newCart;
      });
    }
  };

  const handleSauceChange = (value) => {
    setAddSauce(value);
    if (value === 'Да') {
      scrollToFirstSauce();
    }
  };

  const handlePaymentTypeChange = (value) => {
    setPaymentType(value);
  };

  const handlePromoChange = (value) => {
    setPromo(value);
  };

  const handleOrderSummaryChange = (e) => {
    setOrderSummary(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Обновление куки при изменении имени оператора
  const handleNameChange = (e) => {
    //const newName = e.target.value;
    //setOperatorName(newName);
    //Cookies.set('operatorName', newName, { expires: 365 }); // куки будут действовать 365 дней
  };
  const handleAddToCart = (dishId, modifiers = {}, comment = '') => {
    const newItem = { id: `${dishId}-${Date.now()}`, dishId, modifiers, comment };
    setCart((prevCart) => [...prevCart, newItem]);
    // Очистка выбранных модификаторов после добавления в корзину
    setSelectedModifiers({});
    // Очистка комментария
    setComments((prevComments) => ({
      ...prevComments,
      [dishId]: '',
    }));
  };

  /*   const handleClick = () => {
    handleAddToCart(dish.id, selectedModifiers);
    setIsAnimating(true);
  }; */

  // Функция для удаления из корзины
  const handleRemoveFromCart = (itemId) => {
    setCart((prevCart) => {
      const indexToRemove = prevCart.findIndex((item) => item.id === itemId);
      if (indexToRemove !== -1) {
        const itemToRemove = prevCart[indexToRemove];
        const newCart = [...prevCart];
        newCart.splice(indexToRemove, 1);

        // Сброс состояния кнопок, если удалены картошка фри или соус
        if (itemToRemove.dishId === potatoes) {
          setAddGrilledPotatoes(null);
        }
        if (sauceIds.includes(itemToRemove.dishId)) {
          setAddSauce(null);
        }

        return newCart;
      }
      return prevCart;
    });
  };

  // Функция для удаления из меню
  const handleRemoveFromMenu = (dishId) => {
    setCart((prevCart) => {
      const indexToRemove = prevCart.map((item) => item.dishId).lastIndexOf(dishId);
      if (indexToRemove !== -1) {
        const newCart = [...prevCart];
        newCart.splice(indexToRemove, 1);
        console.log('Updated Cart after removing from menu:', newCart);
        return newCart;
      }
      return prevCart;
    });
  };

  const getDishCount = (dishId) => {
    return cart.filter((item) => item.dishId === dishId).length;
  };

  // Обработчики нажатий на кнопки
  const handleDeliveryClick = () => {
    setSelectedButton('delivery');
    setIsDeliveryConfirmed(false); // Сброс состояния подтверждения доставки
    setDeliveryConfirmation(null); // Сброс состояния кнопок подтверждения доставки
  };

  const handlePickupClick = () => {
    setSelectedButton('pickup');
    setIsDeliveryConfirmed(false); // сбросить состояние доставки
    setIsDeliveryConfirmed(true);
  };
  const handleOtherClick = () => {
    setSelectedButton('other');
    setIsDeliveryConfirmed(false); // сбросить состояние доставки
    setIsDeliveryConfirmed(false);
  };

  const handleYesClick = () => {
    setIsDeliveryConfirmed(true);
    setDeliveryConfirmation('yes');
  };

  const handleNoClick = () => {
    setIsDeliveryConfirmed(false);
    setDeliveryConfirmation('no');
  };

  // Обработчик изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    const activeBranchId = branch;
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/menu/${activeBranchId}`);
        setDishes(response.data);

        // dispatch(setDishes(response.data));
        //dispatch(fetchDishTags());
      } catch (error) {
        console.error('An error occurred while fetching the dishes:', error);
      }
    };
    fetchData();
  }, []);

  const showDishes = () => {
    console.log('dishes: ', dishes);
  };

  const getCategories = () => {
    if (!dishes) return [];
    const categories = dishes.map((dish) => dish.category_name);
    return ['Все', ...new Set(categories)];
  };

  const renderCategoryButtons = () => {
    const categories = getCategories();
    return categories.map((category) => (
      <button
        key={category}
        className={`px-4 py-2 mx-1 rounded my-1  ${
          selectedCategory === category ? 'bg-stone-400 text-white' : 'bg-stone-100'
        }`}
        onClick={() => setSelectedCategory(category)}>
        {category}
      </button>
    ));
  };

  const scrollToFirstSauce = () => {
    const firstSauceId = sauceIds[0];
    const element = document.getElementById(`dish-${firstSauceId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const clearCart = () => {
    setCart([]);
    setAddGrilledPotatoes(null);
    setAddSauce(null);
    console.log('Cart has been cleared:', []);
  };

  const renderDishes = () => {
    if (!dishes) return null;

    const getComment = (dishId) => {
      return comments[dishId] || '';
    };

    const getDishCount = (dishId) => {
      return cart.filter((item) => item.dishId === dishId).length;
    };

    return dishes
      .filter((dish) => selectedCategory === 'Все' || dish.category_name === selectedCategory)
      .filter((dish) => dish.menuoption === 1 || dish.menuoption === 3)
      .filter((dish) => dish.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .map((dish) => (
        <div
          key={dish.id}
          id={`dish-${dish.id}`}
          className="bg-white p-4 my-2 rounded shadow flex flex-col justify-between">
          <div className="w-full flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold">{dish.title}</p>
              <p className="text-gray-600">{dish.description}</p>
              {dish.output && <p className="text-gray-500">{dish.output}</p>}
              <p className="text-lg font-semibold mt-2">{parseFloat(dish.price).toFixed(2)} ₽</p>
            </div>
            <div className="flex items-center">
              <span className="mr-4 text-lg font-semibold">{getDishCount(dish.id)}</span>{' '}
              {/* Количество заказанных блюд */}
              <motion.button
                key={dish.id}
                className="px-2 py-1 rounded font-extrabold text-4xl text-pink-950"
                onClick={() => {
                  handleAddToCart(dish.id, selectedModifiers, getComment(dish.id));
                  setComments((prevComments) => ({
                    ...prevComments,
                    [dish.id]: '', // очистить комментарий после добавления
                  }));
                  setAnimatingButton(dish.id); // Установить текущую кнопку для анимации
                  setIsAnimating(true); // Запустить анимацию
                }}
                animate={isAnimating && animatingButton === dish.id ? { scale: [1, 1.2, 1] } : {}}
                transition={{ duration: 0.3 }}
                onAnimationComplete={() => {
                  setIsAnimating(false); // Остановить анимацию
                  setAnimatingButton(null); // Сбросить кнопку для анимации
                }}>
                <IoIosAddCircle />
              </motion.button>
            </div>
          </div>
          {dish.id === modifiedDishId && (
            <div className="w-full mt-2">
              {modifiers.map((modifier) => (
                <div key={modifier.name} className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedModifiers[modifier.name] !== undefined}
                    onChange={(e) =>
                      handleModifierChange(modifier.name, e.target.checked ? modifier.price : 0)
                    }
                  />
                  <p className="text-gray-700 ml-2">
                    {modifier.name}: {modifier.price} ₽
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ));
  };

  // Проверка наличия картошки фри в корзине
  const hasPotatoesInCart = () => {
    return cart.some((item) => item.dishId === potatoes);
  };

  const groupCartItems = () => {
    const groupedItems = {};

    cart.forEach((item) => {
      const key = `${item.dishId}-${JSON.stringify(item.modifiers)}`;
      if (!groupedItems[key]) {
        groupedItems[key] = {
          ...item,
          quantity: 0,
        };
      }
      groupedItems[key].quantity += 1;
    });

    return Object.values(groupedItems);
  };

  const updateCommentInCart = (itemId, newComment) => {
    setCart((prevCart) =>
      prevCart.map((item) => (item.id === itemId ? { ...item, comment: newComment } : item)),
    );
  };

  const renderOrder = () => {
    if (cart.length === 0) {
      return <p className="text-center mt-4">Корзина пуста</p>;
    }

    const groupedItems = groupCartItems();

    const orderItems = groupedItems.map((item, index) => {
      const dish = dishes.find((d) => d.id === item.dishId);
      const price = parseFloat(dish.price) || 0;
      const modifiersPrice = Object.values(item.modifiers).reduce(
        (sum, modPrice) => sum + modPrice,
        0,
      );
      const totalPrice = (price + modifiersPrice) * item.quantity;

      return (
        <div
          key={item.id}
          className="flex justify-between items-center p-4 rounded border-b-2 border-dashed border-slate-400 w-full">
          <div>
            <p className="text-lg font-semibold">{dish.title}</p>
            <p className="text-gray-600">
              {Object.keys(item.modifiers).map((key, index, arr) => (
                <span key={key}>
                  {key}: {item.modifiers[key]} ₽{index < arr.length - 1 && ' | '}
                </span>
              ))}
            </p>
            <textarea
              className="border border-1 bg-stone-300 mt-2"
              value={item.comment || ''}
              placeholder="коммент..."
              onChange={(e) => {
                const newComment = e.target.value;
                setCart((prevCart) =>
                  prevCart.map((cartItem) =>
                    cartItem.id === item.id ? { ...cartItem, comment: newComment } : cartItem,
                  ),
                );
              }}
            />
          </div>
          <div className="flex items-center">
            <button
              className="px-2 py-1 rounded border w-7"
              onClick={() => handleRemoveFromCart(item.id)}>
              -
            </button>
            <span className="mx-2">{item.quantity}</span>
            <button
              className="px-2 py-1 rounded border w-7"
              onClick={() => handleAddToCart(item.dishId, item.modifiers)}>
              +
            </button>
            <p className="text-lg font-semibold ml-4">{totalPrice.toFixed(2)} ₽</p>
          </div>
        </div>
      );
    });

    const totalAmount = groupedItems
      .reduce((total, item) => {
        const dish = dishes.find((d) => d.id === item.dishId);
        const price = parseFloat(dish.price) || 0;
        const modifiersPrice = Object.values(item.modifiers).reduce(
          (sum, modPrice) => sum + modPrice,
          0,
        );
        return total + (price + modifiersPrice) * item.quantity;
      }, 0)
      .toFixed(2);

    return (
      <div className="w-full flex flex-col items-center mt-10">
        <h2 className="text-2xl font-semibold mb-4">Ваш заказ</h2>
        {orderItems}
        <div className="flex justify-between items-center p-4 my-2 rounded w-full">
          <p className="text-lg font-semibold">Итого</p>
          <p className="text-lg font-semibold">{totalAmount} ₽</p>
        </div>
        <button
          className="text-black px-2 py-2 rounded mt-4 border border-stone-300"
          onClick={clearCart}>
          Очистить корзину
        </button>
      </div>
    );
  };

  const renderAllOrder = () => {
    if (cart.length === 0) {
      return <p className="text-center mt-4">Корзина пуста</p>;
    }

    const groupedItems = groupCartItems();

    const orderItems = groupedItems.map((item, index) => {
      const dish = dishes.find((d) => d.id === item.dishId);
      const price = parseFloat(dish.price) || 0;
      const modifiersPrice = Object.values(item.modifiers).reduce(
        (sum, modPrice) => sum + modPrice,
        0,
      );
      const totalPrice = (price + modifiersPrice) * item.quantity;

      return (
        <tr key={item.id} className="border-b-2 border-dashed border-slate-400">
          <td className="px-4 py-2 text-lg font-semibold">{dish.title}</td>
          <td className="px-4 py-2 text-gray-600">
            {Object.keys(item.modifiers).map((key, index, arr) => (
              <span key={key}>
                {key}: {item.modifiers[key]} ₽{index < arr.length - 1 && ' | '}
              </span>
            ))}
            {item.comment && <div className="text-gray-500 mt-2">{item.comment}</div>}
          </td>
          <td className="px-4 py-2 text-center">{item.quantity}</td>
          <td className="px-4 py-2 text-lg font-semibold">{totalPrice.toFixed(2)} ₽</td>
        </tr>
      );
    });

    const totalAmount = groupedItems
      .reduce((total, item) => {
        const dish = dishes.find((d) => d.id === item.dishId);
        const price = parseFloat(dish.price) || 0;
        const modifiersPrice = Object.values(item.modifiers).reduce(
          (sum, modPrice) => sum + modPrice,
          0,
        );
        return total + (price + modifiersPrice) * item.quantity;
      }, 0)
      .toFixed(2);

    return (
      <div className="w-full flex flex-col items-center mt-10">
        <h2 className="text-2xl font-semibold mb-4">Ваш заказ</h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr className="w-full bg-gray-100 text-left border-b-2 border-gray-300">
              <th className="px-4 py-2">Наименование</th>
              <th className="px-4 py-2">Комментарий</th>
              <th className="px-4 py-2 text-center">Количество</th>
              <th className="px-4 py-2">Сумма</th>
            </tr>
          </thead>
          <tbody>{orderItems}</tbody>
          <tfoot>
            <tr className="border-t-2 border-gray-300">
              <td colSpan="3" className="px-4 py-2 text-right text-lg font-semibold">
                Итого
              </td>
              <td className="px-4 py-2 text-lg font-semibold">{totalAmount} ₽</td>
            </tr>
            <tr className="border-t-2 border-gray-300">
              <td colSpan="4" className="px-4 py-2 text-lg font-semibold">
                {orderComment !== '' && 'Комментарий к заказу:'}

                <div className="text-gray-500 mt-2">{orderComment}</div>
                {selectedButton === 'delivery' && (
                  <>
                    <p className="mt-6">Адрес</p>
                    <div className="mt-1">
                      {customerDetails.street && (
                        <>
                          <div>
                            <span className="font-normal">Улица:&nbsp;</span>
                            {customerDetails.street}
                          </div>
                        </>
                      )}
                      {customerDetails.house && (
                        <>
                          <div>
                            <span className="font-normal">Дом:&nbsp;</span>
                            {customerDetails.house}
                          </div>
                        </>
                      )}
                      {customerDetails.entrance && (
                        <>
                          <div>
                            <span className="font-normal">Подъезд:&nbsp;</span>
                            {customerDetails.entrance}
                          </div>
                        </>
                      )}
                      {customerDetails.floor && (
                        <>
                          <div>
                            <span className="font-normal">Этаж:&nbsp;</span>
                            {customerDetails.floor}
                          </div>
                        </>
                      )}
                      {customerDetails.apartment && (
                        <>
                          <div>
                            <span className="font-normal">Квартира:&nbsp;</span>
                            {customerDetails.apartment}
                          </div>
                        </>
                      )}
                      {customerDetails.intercomCode && (
                        <>
                          <div>
                            <span className="font-normal">Код домофона:&nbsp;</span>
                            {customerDetails.intercomCode}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}

                {selectedButton === 'pickup' && 
                (
                  <>
                  <p className='mt-6'>Адрес самовывоза: </p>
                  {customerDetails.pickupLocation}
                  
                  </>
                )
                }

              
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  function sendTestMsg() {
    console.log('dish: ', cart);

    const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
    const CHAT_ID = '-1002182124952';

    if (!customerDetails.pickupLocation && selectedButton === 'pickup') {
      alert('Выберите адрес самовывоза!');
      return;
    }

    if (cart.length === 0) {
      alert('Корзина пуста! Пожалуйста, добавьте товары в корзину перед отправкой заказа.');
      return;
    }
    if (!paymentType) {
      alert('Выберите тип оплаты!');
      return;
    }

    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    let text = '';

    if (selectedButton === 'delivery') {
      text += '🚚 Заказ на доставку: \n';
    }
    if (selectedButton === 'pickup') {
      text += '🚶‍♀️ Заказ на самовывоз: \n';
      if (customerDetails.pickupLocation) {
        text += `Адрес самовывоза: ${customerDetails.pickupLocation}\n`;
      }
    }

    text += `оплата: ${paymentType} \n`;

    if (customerDetails.firstName) {
      text += `Имя: ${customerDetails.firstName} \n`;
    }
    if (customerDetails.lastName) {
      text += `Фамилия: ${customerDetails.lastName}\n`;
    }
    if (customerDetails.phoneNumber) {
      text += `Тел: ${customerDetails.phoneNumber}\n`;
    }

    if (selectedButton === 'delivery') {
      if (customerDetails.street) {
        text += `Улица: ${customerDetails.street}\n`;
      }
      if (customerDetails.house) {
        text += `Дом: ${customerDetails.house}\n`;
      }
      if (customerDetails.entrance) {
        text += `Подъезд: ${customerDetails.entrance}\n`;
      }
      if (customerDetails.floor) {
        text += `Этаж: ${customerDetails.floor}\n`;
      }
      if (customerDetails.apartment) {
        text += `кв: ${customerDetails.apartment}\n`;
      }
      if (customerDetails.intercomCode) {
        text += `код домоф: ${customerDetails.intercomCode}\n`;
      }
    }
    
    if (customerDetails.comment) {
      text += `коммент к адресу: ${customerDetails.comment}\n`;
    }
    
    if (promo) {
      text += `картошка в подарок при заказе от 1000 р: ${promo}\n`;
    }

    const groupCartItems = () => {
      const groupedItems = {};

      cart.forEach((item) => {
        const key = `${item.dishId}-${JSON.stringify(item.modifiers)}`;
        if (!groupedItems[key]) {
          groupedItems[key] = {
            ...item,
            quantity: 0,
          };
        }
        groupedItems[key].quantity += 1;
      });

      return Object.values(groupedItems);
    };

    const generateOrderMessage = (cart, dishes) => {
      let message = '📜заказ:\n\n';
      let totalAmount = 0;

      const groupedItems = groupCartItems();

      groupedItems.forEach((item) => {
        const dish = dishes.find((d) => d.id === item.dishId);
        if (dish) {
          const quantity = item.quantity;
          const modifiersPrice = Object.values(item.modifiers).reduce(
            (sum, modPrice) => sum + Number(modPrice),
            0,
          );
          const dishPrice = Number(dish.price);
          const totalPrice = (dishPrice + modifiersPrice) * quantity;
          totalAmount += totalPrice;

          let dishMessage = `${dish.title}`;
          if (Object.keys(item.modifiers).length > 0) {
            dishMessage += ` (${Object.keys(item.modifiers)
              .map((key) => `${key}: ${item.modifiers[key]} ₽`)
              .join(' | ')})`;
          }
          dishMessage += `: ${quantity} x ${(dishPrice + modifiersPrice).toFixed(
            2,
          )} ₽ = ${totalPrice.toFixed(2)} ₽`;

          // Добавление комментария, если он есть
          if (item.comment) {
            dishMessage += `\n(Комментарий: ${item.comment})`;
          }

          dishMessage += '\n\n';

          message += dishMessage;
        }
      });

      if (orderComment) {
        message += '\nКоментарий к заказу: \n' + orderComment + '\n';
      }

      message += `\nИтого: ${totalAmount.toFixed(2)} ₽`;
      return message;
    };

    const orderMessage = generateOrderMessage(cart, dishes);
    console.log(orderMessage);

    text += `\n${orderMessage}`;

    return axios
      .post(endpoint, {
        chat_id: CHAT_ID,
        text: text,
      })
      .then((response) => {
        handleOrderSent(); // вызываем функцию после успешной отправки
      })
      .catch((error) => {
        console.error('~ Error sending message to Telegram:', error);
      });
  }

  function handleOrderSent() {
    alert('Заказ успешно отправлен!');
    setCart([]);
    setAddGrilledPotatoes(null);
    setAddSauce(null);
    setPaymentType(null);
    setOrderSummary(null);
    setPromo(null);
    //setSelectedGrilledPotatoes('');
    //setSelectedSauce('');
    //setIsPotatoAddedThroughPrompt(false);
    setSelectedModifiers({});
    setOrderComment('');

    setCustomerDetails({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      street: '',
      house: '',
      entrance: '',
      floor: '',
      apartment: '',
      intercomCode: '',
      comment: '',
    });
  }

  const getMe = () => {
    console.log('cart: ', cart);
  };

  return (
    <div className="bg-stone-200 min-h-screen" onClick={getMe}>
      {/*  <button>get me</button> */}

      <p className="text-center pt-10 font-medium text-2xl">Заказ Гиро</p>
      <p className="text-center mt-10 font-normal text-xl">
        1. Добрый день, Горы Гиро Махачкала, меня зовут (Имя), Хотите оформить заказ на доставку или
        самовывоз?
      </p>
      <div className="flex justify-center items-center mt-10">
        <button
          className={`border border-1 rounded px-10 py-2 border-slate-900 ${
            selectedButton === 'delivery' ? 'bg-stone-500 text-white' : ''
          }`}
          onClick={handleDeliveryClick}>
          🚚 Доставка
        </button>
        <button
          className={`border border-1 rounded px-10 py-2 border-slate-900 ml-6 ${
            selectedButton === 'pickup' ? 'bg-stone-500 text-white' : ''
          }`}
          onClick={handlePickupClick}>
          🚶‍♀️ Самовывоз
        </button>
        <button
          className={`border border-1 rounded px-10 py-2 border-slate-900 ml-6 ${
            selectedButton === 'other' ? 'bg-stone-500 text-white' : ''
          }`}
          onClick={handleOtherClick}>
          ❔ Прочее
        </button>
      </div>
      {selectedButton === 'delivery' && (
        <div className="mt-10 p-4 border border-1 border-slate-300">
          <p className="text-center">Доставка</p>
          <p className="text-center mt-4 font-normal text-xl">
            2. Отлично! Доставка осуществляется по тарифу такси и будет рассчитана по факту
            доставки. <br />
            Доставка до двери стоит дополнительно 50 руб. <br /> Готовы оформить доставку через
            такси?
          </p>
          <div className="flex justify-center items-center mt-10">
            <button
              className={`border rounded border-1 px-10 py-2 border-slate-900 ml-6 ${
                deliveryConfirmation === 'yes' ? 'bg-green-500 text-white' : 'text-green-600'
              }`}
              onClick={handleYesClick}>
              Да
            </button>
            <button
              className={`border rounded border-1 px-10 py-2 border-slate-900 ml-6 ${
                deliveryConfirmation === 'no' ? 'bg-red-500 text-white' : 'text-red-700'
              }`}
              onClick={handleNoClick}>
              Нет
            </button>
          </div>

          {deliveryConfirmation === 'no' && (
            <div className="text-center mt-10 font-normal text-xl ">
              <p> В таком случае я могу вам предложить оформить самовывоз, </p>
              <p>
                к сожалению доставка силами службы такси единственный способ доставки, который мы
                предоставляем.
              </p>
            </div>
          )}
          {isDeliveryConfirmed && (
            <div className="flex flex-col justify-center items-center mt-10 p-4 border border-1 border-slate-300 md:w-1/2 lg:w-1/2 xl:w-6/12 mx-auto">
              <p className="text-center">Заполните информацию для доставки</p>

              <div className="w-full">
                <div>
                  <label className="block mt-4">
                    Имя
                    <input
                      type="text"
                      name="firstName"
                      value={customerDetails.firstName}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                  <label className="block mt-4">
                    Фамилия
                    <input
                      type="text"
                      name="lastName"
                      value={customerDetails.lastName}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>

                  <label className="block mt-4">
                    Телефон
                    <input
                      type="text"
                      name="phoneNumber"
                      value={customerDetails.phoneNumber}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>

                  <label className="block mt-4">
                    Улица
                    <input
                      type="text"
                      name="street"
                      value={customerDetails.street}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>

                  <label className="block mt-4">
                    Дом
                    <input
                      type="text"
                      name="house"
                      value={customerDetails.house}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                  <label className="block mt-4">
                    Подъезд
                    <input
                      type="text"
                      name="entrance"
                      value={customerDetails.entrance}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                  <label className="block mt-4">
                    Этаж
                    <input
                      type="text"
                      name="floor"
                      value={customerDetails.floor}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                  <label className="block mt-4">
                    Квартира
                    <input
                      type="text"
                      name="apartment"
                      value={customerDetails.apartment}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                  <label className="block mt-4">
                    Код домофона
                    <input
                      type="text"
                      name="intercomCode"
                      value={customerDetails.intercomCode}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>

                  <label className="block mt-4">
                    Комментарий к адресу
                    <input
                      type="text"
                      name="comment"
                      value={customerDetails.comment}
                      onChange={handleInputChange}
                      className="border border-1 border-slate-300 w-full mt-2"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {selectedButton === 'pickup' && (
        <div className="flex flex-col justify-center items-center  mt-10 p-4 border border-1 border-slate-300">
          <p className="text-center">Самовывоз</p>
          <p className="text-center mt-4 font-normal text-xl">
            2. Выберите адрес для самовывоза и заполните информацию о заказчике.
          </p>
          <div className="mt-10 p-4 border border-1 border-slate-300 w-10/12 md:w-1/2 lg:w-1/2 xl:w-6/12">
            <p className="text-center">Заполните информацию для самовывоза</p>
            <div className="mt-4 ">
              <label>
                Адрес для самовывоза
                <select
                  name="pickupLocation"
                  value={customerDetails.pickupLocation}
                  onChange={handleInputChange}
                  className="border border-1 border-slate-300 w-full mt-2">
                  <option value="">Выберите адрес</option>
                  <option value="Гагарина 66">Гагарина 66</option>
                  <option value="Ермошкина 11">Ермошкина 11</option>
                  <option value="Гаруна Курбанова 24">Гаруна Курбанова 24</option>
                </select>
              </label>
              <label>
                Имя
                <input
                  type="text"
                  name="firstName"
                  value={customerDetails.firstName}
                  onChange={handleInputChange}
                  className="border border-1 border-slate-300 w-full mt-2"
                />
              </label>
              <label>
                Фамилия
                <input
                  type="text"
                  name="lastName"
                  value={customerDetails.lastName}
                  onChange={handleInputChange}
                  className="border border-1 border-slate-300 w-full mt-2"
                />
              </label>
              <label>
                Телефон
                <input
                  type="text"
                  name="phoneNumber"
                  value={customerDetails.phoneNumber}
                  onChange={handleInputChange}
                  className="border border-1 border-slate-300 w-full mt-2"
                />
              </label>
              <label className="block mt-4">
                Комментарий к адресу
                <input
                  type="text"
                  name="comment"
                  value={customerDetails.comment}
                  onChange={handleInputChange}
                  className="border border-1 border-slate-300 w-full mt-2"
                />
              </label>
            </div>
          </div>
        </div>
      )}

      {selectedButton === 'other' && (
        <>
         <div className="flex text-xl flex-col justify-center items-center  mt-10 p-4 border border-1 border-slate-300">
         Одну минуту, я соединю вас с оператором сервисной службы.
         </div>
        </>
      )

      }

      {isDeliveryConfirmed && (
        <>
          {/* Кнопки категорий */}
          <div className="mt-10 flex flex-wrap justify-center w-full">
            {renderCategoryButtons()}
          </div>
        </>
      )}

      {isDeliveryConfirmed && (
        <>
          {/* Поле для поиска */}
          <div className="flex justify-center items-center w-full mt-6">
            <div className="relative w-10/12 md:w-1/2 lg:w-1/2 xl:w-6/12">
              <input
                type="text"
                placeholder="Поиск по блюдам..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="border border-1 border-slate-300 p-2 w-full"
              />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-0 top-0 mt-2 mr-2 text-gray-600 hover:text-gray-800">
                  ✖
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {isDeliveryConfirmed && (
        <>
          {/* Здесь рендерим блюда */}
          <div className="flex justify-center items-center w-full">
            <div className="w-10/12 md:w-1/2 lg:w-1/2 xl:w-6/12">{renderDishes()}</div>
          </div>
        </>
      )}
      {isDeliveryConfirmed && (
        <>
          {/* Рендер заказа */}
          <div className="mt-10 w-full flex justify-center">
            <div className="w-10/12 md:w-1/2 lg:w-1/2 xl:w-6/12">{renderOrder()}</div>
          </div>
        </>
      )}

      {isDeliveryConfirmed && (
        <>
          {/* картошка, соус и т.д */}
          <div className="mt-10 w-full flex flex-col items-center">
            <div className="w-10/12 md:w-1/2 lg:w-1/2 xl:w-6/12">
              {!hasPotatoesInCart() && (
                <div className="my-4">
                  <p className="font-semibold">6. Добавить картошку фри в заказ?</p>
                  <div className="flex items-center">
                    <button
                      className={`px-4 py-2 mx-2 rounded ${
                        addGrilledPotatoes === 'Да' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                      }`}
                      onClick={() => handleGrilledPotatoesChange('Да')}>
                      Да
                    </button>
                    <button
                      className={`px-4 py-2 mx-2 rounded ${
                        addGrilledPotatoes === 'Нет' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                      }`}
                      onClick={() => handleGrilledPotatoesChange('Нет')}>
                      Нет
                    </button>
                  </div>
                </div>
              )}

              {!hasSauceInCart() && (
                <div className="my-4">
                  <p className="font-semibold">7. Добавить соус в заказ?</p>
                  <div className="flex items-center">
                    <button
                      className={`px-4 py-2 mx-2 rounded ${
                        addSauce === 'Да' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                      }`}
                      onClick={() => {
                        setAddSauce('Да');
                        scrollToFirstSauce();
                      }}>
                      Да
                    </button>
                    <button
                      className={`px-4 py-2 mx-2 rounded ${
                        addSauce === 'Нет' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                      }`}
                      onClick={() => setAddSauce('Нет')}>
                      Нет
                    </button>
                  </div>
                </div>
              )}

              <div className="my-4">
                <p className="font-semibold">8. Тип оплаты</p>
                <div className="flex items-center">
                  <button
                    className={`px-4 py-2 mx-2 rounded ${
                      paymentType === 'Наличные' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                    }`}
                    onClick={() => handlePaymentTypeChange('Наличные')}>
                    Наличные
                  </button>
                  <button
                    className={`px-4 py-2 mx-2 rounded ${
                      paymentType === 'QR-код' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                    }`}
                    onClick={() => handlePaymentTypeChange('QR-код')}>
                    QR-код
                  </button>
                </div>
              </div>
              <div className="my-4">
                <p className="font-semibold">Комментарий к заказу</p>
                <textarea
                  value={orderComment}
                  onChange={handleOrderCommentChange}
                  className="border border-1 border-slate-300 w-full mt-2 p-2"
                  rows="4"
                />
              </div>
              <div className="my-4">
                <p className="font-semibold">
                  Заказав на сайте действует акция - картошка в подарок при заказе от 1000 р.
                </p>
               {/*  <div className="flex items-center">
                  <button
                    className={`px-4 py-2 mx-2 rounded ${
                      promo === 'Да' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                    }`}
                    onClick={() => handlePromoChange('Да')}>
                    Да
                  </button>
                  <button
                    className={`px-4 py-2 mx-2 rounded ${
                      promo === 'Нет' ? 'bg-stone-500 text-white' : 'bg-stone-100'
                    }`}
                    onClick={() => handlePromoChange('Нет')}>
                    Нет
                  </button>
                </div> */}
              </div>
              <hr className="my-10 border-t-4 border-blue-500" />
              <p className="text-orange-700 font-semibold text-xl mt-16">
                9. ПОВТОРИ ВЕСЬ ЗАКАЗ, СУММУ, АДРЕС ДОСТАВКИ.
              </p>

              <div className="">{renderAllOrder()}</div>

              <p className="mt-6"> Спасибо что заказали еду в Горы Гиро</p>
        
            </div>
          </div>
        </>
      )}

      {isDeliveryConfirmed && (
        <div className="flex items-center justify-center">
          <button
            className=" text-black bg-stone-100 px-2 py-2 rounded mt-4  border-2 border-stone-700"
            onClick={sendTestMsg}>
            Отправить заказ
          </button>
        </div>
      )}
      <div className="h-20"></div>
    </div>
  );
};

export default Gorygirodelivery;
