import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'


export const fetchBranchOptions = createAsyncThunk(
    'branchOptions/fetchBranchOptions',
    async (branchId) => {
        // console.log('~ вызван fetchBranchOptions');
        const response = await axios.get(`${process.env.REACT_APP_URL}/api/branchoptions/${branchId}`);
        return response.data;
    }
);

export const updatePositiveFeedback = createAsyncThunk(
    'branchOptions/updatePositiveFeedback',
    async ({branchId, positiveText}) => {
        // //console.log('~ updatePositiveFeedback = ', updatePositiveFeedback);
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updatePositiveText/${branchId}`, {
        positive_feedback_text: positiveText
      });
      return response.data;
    }
  );


export const updateNegativeFeedback = createAsyncThunk(
    'branchOptions/updateNegativeFeedback',
    async ({branchId, negativeText}) => {
        // // console.log('~ updateNegativeFeedback negativeText = ', negativeText);
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateNegativeText/${branchId}`, {
        negative_feedback_text: negativeText
      });
      return response.data;
    }
  );
  



export const branchOptions = createSlice({
    name: 'branchOptions',
    initialState: [],
    reducers: {
        clearBranchOptions: (state) => {
            return [];
        }
    },
    extraReducers: {
        [fetchBranchOptions.fulfilled]: (state, action) => {
            return action.payload;
        },
        
    }
})

export const selectBranchOptions = (state) => state.branchOptions;
export const { clearBranchOptions } = branchOptions.actions;


export default branchOptions.reducer;