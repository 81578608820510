import React from 'react';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

const AddLeastOneBranch = () => {
  return (
    <div className="flex flex-col pl-6 pt-6">
      <div className='text-2xl font-semibold'>Добавьте хотя бы один филиал</div>
      <div>
        <Link to="/newbranch">
          <button className="flex bg-slate-300 hover:bg-slate-400 text-slate-800 font-sm py-2 px-4 my-10 rounded shadow-md">
            Создать новый филиал
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AddLeastOneBranch;
