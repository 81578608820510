import React from 'react';
import { useSelector } from 'react-redux';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { selectBranch } from '../store/slices/branchSlice';
import { Link } from 'react-router-dom';

const Branch = () => {
  const branches = useSelector(selectBranch);
  return (
    <div className="flex flex-row gap-2 flex-wrap ">
      <Link to="/newbranch">
        <div className="w-64 py-4 px-20 bg-white shadow-lg rounded-lg my-6 flex flex-col hover:bg-slate-100 hover:cursor-pointer">
          <div className="flex justify-center	text-slate-300 hover:text-slate-400">
            <BsPlusSquareDotted className="text-6xl" />
          </div>
          <div>
            <h5 className="text-gray-800 text-lg font-semibold py-2 text-center">
              Добавить новый филиал
            </h5>
          </div>
        </div>
      </Link>

      {branches.map((branch, index) => (
        <div
          className="w-64 py-4 px-8 bg-white shadow-lg rounded-lg my-5 flex-row hover:bg-slate-100 border "
          key={index}>
          <div className="flex justify-center md:justify-center ">
            {branch.logo_url !== null && (
              <img
                className="w-20 h-20 object-cover rounded-full border border-slate-400"
                src={`${process.env.REACT_APP_URL}/${branch.logo_url}`}
                alt="logo"
              />
            )}
          </div>
          <div>
            <h2 className="text-gray-800 text-xl font-semibold py-2">{branch.name}</h2>
            <p className="mt-2 text-gray-600">
              {branch.city}
              <br /> {branch.address}
            </p>
          </div>
          {branch.note && (
            <div className="mt-4">
              <blockquote className="pl-2 italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote">
                <p className="text-gray-800 text-sm font-light py-2">{branch.note}</p>
              </blockquote>
            </div>
          )}

          <div className="flex justify-end mt-4">
            <Link to={`/editbranch/${branch.id}`} className="text-base font-medium text-indigo-500">
              Редактировать
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Branch;
