import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteBranchFromServerAsync,
  deleteLogo,
  selectActiveBranch,
  selectBranch,
  selectBranchById,
  updateBranch,
  updateBranchName,
  updateBranchOnServer,
  uploadLogo,
  updateAddress,
  updateDescription,
  updateCity,
  updatePhone,
  updatePhoneDelivery,
  updateNote,
} from '../store/slices/branchSlice';
import { useSelector, useDispatch } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import TablesEdit from './TablesEdit';
import SocialLinks from './SocialLinks';
import { RiDeleteBinLine } from 'react-icons/ri';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { selectUserId } from '../store/slices/userSlice';
import EditLink from './EditLink';

//TODO edit branch берет данные из activeBranch, что не совсем логично. Мы ведь вошли через Филиалы. ПО идее надо DropDown скрывать

//Когда добавляем столик, ссылка идеть всегда ambar, а надо текущий редактируемый branch

const EditBranch = () => {
  const location = useLocation();
  const newBranch = location.state?.newBranch;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const branches = useSelector(selectBranch);
  const activeBranch = useSelector(selectActiveBranch);
  const userId = useSelector(selectUserId);

  const { branchId } = useParams();

  // извлекаем из редакс нужный нам филиал по его id
  const branch = useSelector((state) => selectBranchById(state, parseInt(branchId)));

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (newBranch) {
      setFormData({
        name: newBranch.name,
        address: newBranch.address,
        description: newBranch.description,
        city: newBranch.city,
        logo: `${process.env.REACT_APP_URL}/` + newBranch.logo_url,
        //old_logo: newBranch.logo_url,
      });
    } else if (branch) {
      setFormData({
        name: branch.name,
        address: branch.address,
        description: branch.description,
        city: branch.city,
        logo: `${process.env.REACT_APP_URL}/` + branch.logo_url,
        //old_logo: branch.logo_url,
      });
    }
  }, [newBranch, branch]);

  // Используем локальное состояние для хранения значения инпута
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneDelivery, setPhoneDelivery] = useState('');
  const [note, setNote] = useState('');

  // // //console.log('~ formData.logo = ', formData);

  const [previewImage, setPreviewImage] = useState(null);

  const [logoUrl, setLogoUrl] = useState(`${process.env.REACT_APP_URL}/` + branch.logo_url);
  // новое лого
  const [logoFile, setLogoFile] = useState(null);

  // Когда компонент загружается или branch меняется, обновляем значение инпута
  useEffect(() => {
    if (branch) {
      setName(branch.name);
      setAddress(branch.address);
      setDescription(branch.description);
      setCity(branch.city);
      setPhone(branch.phone);
      setPhoneDelivery(branch.phone_delivery);
      setNote(branch.note);
    }
  }, [branch]);

  const changeNameOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (name !== branch.name) {
      dispatch(updateBranchName({ name: name, branchId: branchId }));
    }
  };
  const changeAddressOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (address !== branch.address) {
      dispatch(updateAddress({ address: address, branchId: branchId }));
    }
  };
  const changeDescriptionOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (description !== branch.description) {
      dispatch(updateDescription({ description: description, branchId: branchId }));
    }
  };
  const changeNoteOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (note !== branch.note) {
      dispatch(updateNote({ note: note, branchId: branchId }));
    }
  };
  const changeCityOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (city !== branch.city) {
      dispatch(updateCity({ city: city, branchId: branchId }));
    }
  };
  const changePhoneOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (phone !== branch.phone) {
      // // // console.log('~ телефон поменялся епта');
      dispatch(updatePhone({ phone: phone, branchId: branchId }));
    }
  };
  const changePhoneDeliveryOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (phoneDelivery !== branch.setPhoneDelivery) {
      // // // console.log('~ телефон доставки поменялся епта');
      dispatch(updatePhoneDelivery({ phone_delivery: phoneDelivery, branchId: branchId }));
    }
  };

  // Обработчик изменений инпута
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handlePhoneDeliveryChange = (e) => {
    setPhoneDelivery(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    // // // console.log('~ handleSubmit сработал');
    e.preventDefault();
    // Создание объекта FormData
    const data = new FormData();
    // Добавление всех значений из formData в data
    Object.keys(formData).forEach((key) => {
      if (key !== 'logo') {
        data.append(key, formData[key]);
      }
    });
    // Добавление файла логотипа, если он существует. Просто файл, без url и т.д.
    if (logoFile) {
      data.append('logo', logoFile);
      // data.append('old_logo', )
    }

    // Здесь data содержит все поля formData и новый файл логотипа (если он был выбран)
    // // // console.log('~ activeBranch = ', activeBranch);
    dispatch(updateBranchOnServer({ id: branchId, data, activeBranch }));
  };

  const onFileChange = async (event) => {
    // путь к старому лого
    // // // console.log('~ onFileChange вызван');
    const newFile = event.target.files[0];
    // // //console.log('~ ', newFile);
    try {
      const image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          newFile,
          300, // размеры
          300,
          'PNG',
          100,
          0,
          (uri) => {
            // Convert blobUrl to File object
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], newFile.name, {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                });
                resolve(file);
              })
              .catch((err) => console.error(err));
          },
          'base64',
        );
      });
      setLogoFile(image); // ставим полученный файл в useEffect
      setLogoUrl(URL.createObjectURL(image)); // генерируем url для превьюшки
      //setPreviewImage(URL.createObjectURL(image)); // После инициализации image
      // устанавливаем новый файл
      setFormData({ ...formData, logo: image });
      // // // console.log('~ image ====== ', image);

      dispatch(uploadLogo({ image, branchId, userId }));
    } catch (err) {
      // console.log('~ ', err);
    }
  };

  function deleteBranchFromServer() {
    // // // console.log('~ Удаляем филиал = ', branchId);
    const confirmDelete = window.confirm(
      'Вы уверены, что хотите удалить этот филиал? Все данные связанные с ним пропадут безвозвратно',
    );
    if (confirmDelete) {
      //если подтвердили удаление
      // // // console.log(' ~ Ok, удаляем');
      // // // console.log('~ activeBranch id из компонента');
      dispatch(deleteBranchFromServerAsync({ branchId, branches, dispatch, activeBranch }));
      navigate(`/branches`);
    }
  }

  function deleteImage() {
    // // // console.log('~ Вызвана deleteImage');
    setLogoUrl('');
    // // // console.log('~ branch id >>> ', branchId);
    dispatch(deleteLogo(branchId));
  }

  return (
    <>
      <div className="">
        <div className="text-xl">
          <div className="text-field text-field_floating-2">
            <input
              type="text"
              name="name"
              value={name}
              onChange={handleNameChange}
              onBlur={changeNameOnServer}
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="name">
              Название филиала
            </label>
          </div>
        </div>

        <div className="pt-4">
          <div className="text-field text-field_floating-2">
            <input
              type="text"
              name="city"
              value={city}
              onChange={handleCityChange}
              onBlur={changeCityOnServer}
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="city">
              Город
            </label>
          </div>
        </div>

        <div className="pt-4">
          <div className="text-field text-field_floating-2">
            <input
              type="text"
              name="address"
              value={address}
              onChange={handleAddressChange}
              onBlur={changeAddressOnServer}
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="address">
              Адрес
            </label>
          </div>
        </div>

        <div className="pt-4">
          <div className="text-field text-field_floating-2">
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              onBlur={changePhoneOnServer}
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="phone">
              Телефон
            </label>
          </div>
        </div>

        <div className="pt-4">
          <div className="text-field text-field_floating-2">
            <input
              type="text"
              name="phoneDelivery"
              value={phoneDelivery}
              onChange={handlePhoneDeliveryChange}
              onBlur={changePhoneDeliveryOnServer}
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="phoneDelivery">
              Телефон доставки
            </label>
          </div>
        </div>

        <EditLink link={branch.link} branchId={branch.id} />

        <div className="pt-4">
          <div>Описание:</div>
          <div>
            <textarea
              name="description"
              rows={5}
              value={description}
              onChange={handleDescriptionChange}
              onBlur={changeDescriptionOnServer}
              className="textarea-1 w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
          </div>
        </div>
        <div className="pt-4">
          <div>Заметка:</div>
          <div>
            <textarea
              placeholder="Заметку видите только вы"
              name="note"
              rows={5}
              value={note}
              onChange={handleNoteChange}
              onBlur={changeNoteOnServer}
              className="textarea-1 w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
          </div>
        </div>

        <br />
        <br />
        <label>
          Логотип: <br />
          <input type="file" name="logo" onChange={onFileChange} className="mt-4" />
        </label>
        {logoUrl !== '' && logoUrl !== 'http://t:3005/null' && (
          <div className="flex flex-row">
            <img className="h-40 mt-2" src={logoUrl} alt="logo" />
            <button
              className="mt-2 mr-2 text-white rounded-full w-6 h-6 flex items-center justify-center"
              onClick={deleteImage}>
              <RiDeleteBinLine className="text-slate-700 mt-2" />
            </button>
          </div>
        )}

        <div className="flex flex-col"></div>
        <div className='mt-5'>
          <Link to="/branches">
            <button className="button-cancel">
              Выход
            </button>
          </Link>
        </div>
      </div>
      <div>
        <TablesEdit branch={branch} />
        <SocialLinks branchId={branchId} />
      </div>
      <div className='mt-3 pb-10'>
        <button
          className=" button-danger "
          type="submit"
          onClick={deleteBranchFromServer}>
          {' '}
          Удалить филиал
        </button>
      </div>
    </>
  );
};

export default EditBranch;
