import React, { useState } from 'react';
import axios from 'axios';

const ApiTestPage2 = () => {
  const [places, setPlaces] = useState([]);

  const fetchPlaces = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/get-places2`, {
        headers: {
          Authorization: `Bearer 6xve384au82zy0r1`, // Отправка токена серверу
        },
      })
      .then((response) => {
        console.log('Places:', response.data);
        setPlaces(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении списка заведений:', error);
      });
  };

  return (
    <div>
      Api Test Page 2
      <button className="button-s" onClick={fetchPlaces}>
        Получить список заведений
      </button>
    </div>
  );
};

export default ApiTestPage2;
