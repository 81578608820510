import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  deleteTable,
  selectActiveBranch,
  selectBranch,
  selectBranchById,
  updateTableName,
} from '../store/slices/branchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTableToServer } from '../store/slices/branchSlice';

const TablesEdit = (props) => {
  const dispatch = useDispatch();
  // id редактируемого филиала
  const { branchId } = useParams();
  // выбираем столики редактируемого филиала для рендеринга в компоненте
  const editedBranch = useSelector((state) => selectBranchById(state, branchId));
  // раньше брались из пропсов родительского компонента
  //const [tablesProps, setTablesProps] = useState(props.branch.tables);
  const [tablesProps, setTablesProps] = useState(editedBranch.tables);
  const branhes = useSelector(selectBranch);

  useEffect(() => {
    setTablesProps(editedBranch.tables);
  }, [editedBranch.tables]);

  const [isChanged, setIsChanged] = useState(false);

  const [showNewInputs, setShowNewInputs] = useState(false);

  const [tableName, setTableName] = useState('');
  const [tableLink, setTableLink] = useState('');

  //const branchLink = useSelector(selectActiveBranch).link;

  function toggleNewTableInputs() {
    setShowNewInputs(!showNewInputs);
  }

  function addNewTableCancel() {
    setShowNewInputs(false); // Скрывает инпуты после Отмены
  }

  function addNewTable() {
    if (tableName === '') {
      alert('Дайте название столику');
      return;
    }
    setTableName('');
    setTableLink('');
    dispatch(addNewTableToServer({ tableName, branchId }));
    setShowNewInputs(false); // Скрывает инпуты после сохранения
  }

  const handleTableChange = (e, tableId, field) => {
    // Находим стол, который нужно обновить
    let changeDetected = false;
    const updatedTables = tablesProps.map((table) => {
      if (table.id === tableId) {
        changeDetected = true;
        return { ...table, [field]: e.target.value }; // Обновляем нужное поле
      }
      return table;
    });
    if (changeDetected) {
      setIsChanged(true);
    }
    // Устанавливаем новое состояние
    setTablesProps(updatedTables);
  };

  const saveTableChanges = (newName, tableId) => {
    if (!isChanged) {
      return; // Если изменений не было, просто выходим из функции
    }

    if (newName === '') {
      alert('Дайте название столику');
      return;
    }

    dispatch(updateTableName({ branchId, tableId, newName }));
  };

  const handleDeleteTable = (tableId) => {
    const confirmDelete = window.confirm('Удалить этот столик?');
    if (confirmDelete) {
      dispatch(deleteTable({ tableId, branchId }));
    } else {
    }
  };

  return (
    <div className="bg-slate-100 mt-6 pb-4">
      <h5 className="pt-4 pl-4 text-xl">Редактирование столиков</h5>
      <div className="mt-6 ml-6">
        {/* Отображение столов */}
        {tablesProps &&
          tablesProps.length > 0 &&
          tablesProps[0] !== null &&
          tablesProps.map((table) => (
            <div key={table.id} className="mt-3">
              <div className="">
                <div className="">
                  <div>
                    <p className="font-thin">Название столика</p>
                  </div>
                  <div className="mr-4">
                    {' '}
                    <input
                      placeholder="Название столика"
                      className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      type="text"
                      name="name"
                      value={table.name}
                      onChange={(e) => handleTableChange(e, table.id, 'name')}
                      onBlur={() => saveTableChanges(table.name, table.id)}
                    />
                  </div>
                </div>

                <div className="flex justify-end w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12 ">
                  <RiDeleteBinLine
                    className="cursor-pointer mx-2 text-slate-600 mr-4"
                    onClick={() => handleDeleteTable(table.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        {/*         <button className="border mt-3 p-1" onClick={saveTableChanges}>
          Сохранить изменения 777
        </button> */}

        <div>
          {showNewInputs ? (
            <div className="mt-6 mr-6 ">
              <input
                type="text"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
                className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12 mr-4 py-1 px-2 pr-11 block border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-700 "
                placeholder="Название столика (места)"
              />
              <div className='flex flex-row gap-4 mt-4'>
                <button className="button-cancel" onClick={addNewTableCancel}>
                  Отмена
                </button>
                <button className="button-s" onClick={addNewTable}>
                  Сохранить
                </button>
              </div>
            </div>
          ) : (
            <button
              className=" button-s"
              onClick={toggleNewTableInputs}>
              Добавить новый столик
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TablesEdit;
