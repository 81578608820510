import React from 'react';
import Sidebar from '../components/Sidebar';
import EditBranch from '../components/EditBranch';

const EditBranchPage = () => {
  return (
    <div className="flex w-full">
      <div>
        <Sidebar />
      </div>
      <div className="pl-4	w-full">
      <p className='text-2xl pb-6'>Редактирование филиала</p>
        <EditBranch />
      </div>

    </div>
  );
};

export default EditBranchPage;
