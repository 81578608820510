import React, { useState, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { HiOutlineColorSwatch } from "react-icons/hi";

function PopupColorPicker({ color, onChangeComplete, name, isVisible, onClose }) {
    const pickerRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
          onClose && onClose();
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [onClose]);
  
    if (!isVisible) return null;
  
    const style = {
      position: 'fixed', // Используется фиксированное позиционирование
      top: '50%', // Расположение по вертикали по центру
      left: '50%', // Расположение по горизонтали по центру
      transform: 'translate(-50%, -50%)', // Сдвигаем элемент на 50% его размера влево и вверх для центрирования
      zIndex: 1000, // Убедитесь, что пикер находится над другими элементами
    };
  
    return (
      <div ref={pickerRef} style={style}>
        <SketchPicker color={color} onChange={(color) => onChangeComplete(color.hex, name)} />
      </div>
    );
  }
  
  

export default PopupColorPicker;
