// src\store\slices\branchSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
import { selectUserId } from './userSlice';
import { addNewBranch } from './newBranchSlice';
import { useSelect } from '@material-tailwind/react';
import { useDispatch } from 'react-redux';

//const savedActiveBranch = localStorage.getItem('activeBranch');

const initialState = {
  data: [],
  activeBranch: [],
  newBranchId: null,
  stage: 0,
};

export const removeImageCss = createAsyncThunk(
  'branch/removeImageCss',
  async ({ image_id, url, branchId }, thunkAPI) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/removeImageCss/`, {
        data: { image_id, branchId, url },
      });
      return response.data; // возвращаем ответ сервера, если он вам нужен
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // возвращаем ошибку, если что-то пошло не так
    }
  }
);





export const uploadImageCss = createAsyncThunk(
  'images/uploadImageCss',
  async ({ file, branchId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      // Добавление branchId в formData
      formData.append('branchId', branchId);

      const response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadImageCss`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data; // Предполагается, что сервер возвращает данные, связанные с загруженным изображением
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateDesignMenu = createAsyncThunk(
  'branch/updateDesignMenu',
  async ({ designSettings, branchId }, { rejectWithValue }) => {
    // console.log("🚀 ~ designSettings:", designSettings);
    // console.log('🎈 ~ Вызван updateDesignMenu');

    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateDesignMenu`, {
        branchId,
        settings: designSettings // Предполагаем, что на сервере эти данные обрабатываются в поле `settings`
      });
      return response.data;
    } catch (error) {
      // Убедитесь, что error.response существует перед обращением к .data
      return rejectWithValue(error.response ? error.response.data : "An unknown error occurred");
    }
  }
);

export const updateCss = createAsyncThunk(
  'branch/updateCss',
  async ({ css, branchId }, { rejectWithValue }) => {
    console.log('🎈 ~ Вызван updateCss');
    console.log(' ~ 🚜 css: ', css);
    
    /*      console.log("🚀 ~ css:", css);
         console.log("🚀 ~ branchId:", branchId); */

    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updatecustomcss`, {
        branchId,
        css,
      });
      return response.data;
    } catch (error) {
      // Убедитесь, что error.response существует перед обращением к .data
      return rejectWithValue(error.response ? error.response.data : "An unknown error occurred");
    }
  }
);
export const updateCustomFormJson = createAsyncThunk(
  'branch/updateCustomFormJson',
  async ({ customFormJson, branchId }, { rejectWithValue }) => {
    console.log('🎈 ~ Вызван updateCustomFormJson');

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/updateCustomFormJson`,
        {
          branchId,
          customFormJson: JSON.parse(customFormJson), // Парсим строку обратно в объект для отправки
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : "An unknown error occurred");
    }
  }
);


export const updateBranchSubscriptions = createAsyncThunk(
  'branch/updateSubscriptions',
  async ({ updates, dailyAllPayment, userId, monthAllPayment, subscriptionEndDate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/branches/update-subscriptions`, {
        updates,
        dailyAllPayment,
        userId,
        monthAllPayment,
        subscriptionEndDate,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUseMenuAsync = createAsyncThunk(
  'branch/updateUseMenu',
  async ({ newUseMenuState, branchId }, { rejectWithValue }) => {
    try {
      // console.log('~ Вызван updateUseMenuAsync');
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseMenu/${branchId}`, {
        usemenu: newUseMenuState
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBranchFromServerAsync = createAsyncThunk(
  'branch/deleteBranch',
  async ({ branchId, branches, dispatch, activeBranch }, { rejectWithValue }) => {
    try {
      // console.log('~ 📍 Вызван deleteBranchFromServerAsync');
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteBranch/${branchId}`)

      // console.log('~ activeBranch ===== ', activeBranch);

      branchId = parseInt(branchId);
      const activeBranchId = activeBranch?.id || activeBranch?.newBranchId;

      // console.log('~ 📍activeBranch id = ', activeBranchId);
      // console.log('~ 📍delete branchId = ', branchId);

      if (activeBranchId === branchId) {

        // console.log('~ Зашел в условие !!!!!!');
        // Удаляемый филиал активный
        // Тут вы можете выбрать первый попавшийся филиал в data и установить его как активный
        if (activeBranch) {
          // console.log('~ Сработало 2е условие');



          let firstBranch = branches[0]; // Выбираем первый попавшийся филиал

          // если удаляемый, активный и первый филиал один и то же
          if (firstBranch.id === branchId) {
            // console.log('~ 🔴 удаляемый, активный и первый филиал - один и тот же');
            firstBranch = branches[1]
          }

          // console.log('~ firstBranch = ', firstBranch);
          try {
            // Тут можно использовать функции dispatch и setActiveBranch для управления состоянием.
            // console.log('~ перед setActiveBranch');
            dispatch(setActiveBranch(firstBranch)); // Используйте ваш редьюсер для установки активного филиала
            // console.log('~ после setActiveBranch');
          } catch (error) {
            // console.log('~ error = ', error);
          }

        }
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateBranchOnServer = createAsyncThunk(
  'branch/updateBranchOnServer',
  async ({ id, data, activeBranch }, { getState, rejectWithValue }) => {
    const userId = selectUserId(getState());
    //data.append('userId', userId);
    // ~  console.log('~ updateBranchOnServer - запущен');
    const objectFromFormData = Object.fromEntries(data.entries());
    // console.log('~ data.entries >>', objectFromFormData);

    const id_int = parseInt(id);
    const activeBranchId = activeBranch?.id || activeBranch?.newBranchId;
    // console.log('~ activeBranchId = ', activeBranchId);
    // console.log('~ id_int ===> ', id_int);


    const logo = objectFromFormData.logo;
    // console.log('~ logo = ', logo);

    if (activeBranchId === id_int) {
      // console.log('~ Редактируемый и активный филиал совпадают');
      //activeBranch.name = 'wefwefwefwefwefwefwef';
    }

    try {
      const formData = new FormData();
      formData.append('userId', userId);
      formData.append('name', objectFromFormData.name);
      formData.append('city', objectFromFormData.city);
      formData.append('address', objectFromFormData.address);
      formData.append('description', objectFromFormData.description);
      formData.append('logo', objectFromFormData.logo);
      formData.append('old_logo', objectFromFormData.old_logo);

      const response = await axios.put(`${process.env.REACT_APP_URL}/api/branchEdit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log('~ response.data = ', response.data);
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const uploadLogo = createAsyncThunk(
  'branches/uploadLogo',
  async ({ image, branchId, userId }, { dispatch }) => {
    const formData = new FormData();
    // console.log('~ ranchId = ', branchId);
    formData.append('branchId', branchId);
    formData.append('userId', userId);
    formData.append('image', image);
    // console.log('~ image == ', image);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadLogo`, formData, config);
    return response.data;
    ; // предполагается, что сервер возвращает logoUrl
  }
);


export const deleteLogo = createAsyncThunk(
  'branch/deleteLogoStatus',
  async (branchId, thunkAPI) => {
    // console.log('~ вызван deleteLogo');
    // console.log('~ branchId = ', branchId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/removeLogo`, { branchId });

      if (response.status === 200) {
        // console.log('~ response.data = ', response.data);
        return response.data;
      } else {
        throw new Error('Failed to delete the logo.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const updateBranchName = createAsyncThunk(
  'branch/updateNameStatus',
  async ({ name, branchId }, thunkAPI) => {
    try {
      // console.log('~ вызван updateBranchName');
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateBranchName`, {
        name: name,
        branchId: branchId
      });

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateLink = createAsyncThunk(
  'branch/updateLinkStatus',
  async ({ url, branchId }, thunkAPI) => {
    // console.log('~ вызван updateLink >>');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateLink`, {
        url: url,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const updateAddress = createAsyncThunk(
  'branch/updateAddressStatus',
  async ({ address, branchId }, thunkAPI) => {

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateAddress`, {
        address: address,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateDescription = createAsyncThunk(
  'branch/updateDescriptionStatus',
  async ({ description, branchId }, thunkAPI) => {
    // console.log('~ вызван updateDescription');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateDescription`, {
        description: description,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateNote = createAsyncThunk(
  'branch/updateNoteStatus',
  async ({ note, branchId }, thunkAPI) => {
    // console.log('~ вызван updateDescription');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateNote`, {
        note: note,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update note');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const updateCity = createAsyncThunk(
  'branch/updateCityStatus',
  async ({ city, branchId }, thunkAPI) => {
    // console.log('~ вызван updateCity');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateCity`, {
        city: city,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch City.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updatePhone = createAsyncThunk(
  'branch/updatePhoneStatus',
  async ({ phone, branchId }, thunkAPI) => {
    // console.log('~ вызван updatePhone');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updatePhone`, {
        phone: phone,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch Phone.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updatePhoneDelivery = createAsyncThunk(
  'branch/updatePhoneDeliveryStatus',
  async ({ phone_delivery, branchId }, thunkAPI) => {
    // console.log('~ вызван updatePhone');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updatePhoneDelivery`, {
        phone_delivery: phone_delivery,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch Phone Delivery.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateTextAboveMenu = createAsyncThunk(
  'branch/updateTextAboveMenu',
  async ({ newText, branchId }, thunkAPI) => {
    // console.log('~ 🥼 вызван updatePhone');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateTextAboveMenu`, {
        newText: newText,
        branchId: branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch Text Above Menu Delivery.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const updateDeliveryTems = createAsyncThunk(
  'branch/updateDeliveryTermsStatus',
  async ({ terms, branchId }, thunkAPI) => {
    // console.log('~ вызван updatePhone');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateDeliveryTerms`, {
        terms,
        branchId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update delvery terms.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addNewTableToServer = createAsyncThunk(
  'tables/addNewTable',
  async ({ tableName, branchId }, thunkAPI) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addTable`, {
        name: tableName,
        branchId: branchId,
      });
      // console.log('~ response.data = ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// обновление сосотяния activeBranch
const updateActiveBranchState = (state) => {
  const activeBranchId = state.activeBranch.id;
  const updatedBranch = state.data.find(branch => branch.id === activeBranchId);
  if (updatedBranch) {
    state.activeBranch = updatedBranch;
  }
};

export const deleteTable = createAsyncThunk(
  'tables/deleteTable',
  async ({ branchId, tableId, newName }, thunkAPI) => {

    try {
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteTable/`, {
        data: { tableId: tableId, branchId: branchId },
      });
      return response.data; // возвращаем ответ сервера, если он вам нужен
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message); // возвращаем ошибку, если что-то пошло не так
    }
  }
);

export const updateTableName = createAsyncThunk(
  'tables/updateName',
  async ({ branchId, tableId, newName }, thunkAPI) => {
  console.log('вызвался');
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateTableName`, {
        tableId,
        newName,
        branchId
      });

      return response.data; // предполагая, что сервер возвращает обновленные данные стола
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addSocialLink = createAsyncThunk(
  'socialLinks/add',
  async ({ name, url, branchId }, thunkAPI) => {
    try {
      // console.log('~ вызвался');
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addSocialLink`, {
        name, url, branchId
      });

      return response.data;

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteSocialLink = createAsyncThunk(
  'socialLinks/delete',
  async ({ socialLinkId, branchId }, thunkAPI) => {
    // console.log('~ slice socialLinkId = ', socialLinkId);
    // console.log('~ slice branchId = ', branchId);
    try {
      // console.log('~ вызвался');
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteSocialLink`, {
        data: {
          socialLinkId,
          branchId,
        }
      });
      // console.log('~ >>>!!! response.data = ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateSocialLinkName = createAsyncThunk(
  'branch/updateSocialLinkName',
  async ({ newName, id, branchId }, thunkAPI) => {
    // console.log('~ вызван updateSocialLinkName');
    // console.log('~ newName = ', newName);
    // console.log('~ id = ', id);
    // console.log('~ branchId = ', branchId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateSocialLinkName`, {
        newName,
        branchId,
        id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch Link Name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateSocialLinkUrl = createAsyncThunk(
  'branch/updateSocialLinkUrl',
  async ({ newUrl, id, branchId }, thunkAPI) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateSocialLinkUrl`, {
        newUrl,
        branchId,
        id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch Link Url.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const addNewBranchOnServer = createAsyncThunk(
  'branch/addNewBranchServer',
  async ({ name, address, city, description, url, userId }, thunkAPI) => {
    // console.log('~  name = ', name);
    // console.log('~  city = ', city);
    // console.log('~  address = ', address);
    // console.log('~  url = ', url);
    // console.log('~  description = ', description);
    // console.log('~  userId = ', userId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewBranchOnServer`, {
        name,
        city,
        address,
        url,
        description,
        userId
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to add New Branch.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const branchSlice = createSlice({
  name: 'branch',
  initialState: initialState,
  reducers: {
    setActiveBranchForLocalStorage: (state, action) => {
      state.activeBranch = action.payload;
      localStorage.setItem('activeBranch', JSON.stringify(action.payload));
    },
    updateBranches: (state, action) => {
      state.data = action.payload;
    },
    setActiveBranch: (state, action) => {
      // console.log('~ 📞 setActiveBranch сработал');
      // * console.log(' ~ action.payload: ', action.payload); *
      state.activeBranch = action.payload;
    },
    clearActiveBranch: (state) => {
      // console.log('~ clearActiveBranch сработал в слайсе');
      state.activeBranch = [];
      localStorage.removeItem('activeBranch');
    },
    setNewBranchId: (state, action) => {
      state.newBranchId = action.payload;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    updateBranch: (state, action) => {
      const branchToUpdate = state.data.find(branch => branch.id === action.payload.id);
      if (branchToUpdate) {
        Object.assign(branchToUpdate, action.payload);
      }
    },
    updateUseOrderLocal: (state, action) => {
      state.activeBranch.useOrderLocal = action.payload;
    },
    updateUseMenu: (state, action) => {
      // console.log('~ Сработал reducer updateUseMenu');
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.usemenu = action.payload;
      }
    },
    updateUseFeedback: (state, action) => {
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.usefeedback = action.payload;
      }
    },
    updateUsePayment: (state, action) => {
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.usepayment = action.payload;
      }
    },
    updateUseTips: (state, action) => {
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.usetips = action.payload;
      }
    },
    updateUseDelivery: (state, action) => {
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.usedelivery = action.payload;
      }
    },
    updateUseCallWaiter: (state, action) => {
      if (state.activeBranch) { // Проверка на null или undefined
        state.activeBranch.callwaiter = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewBranchOnServer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log('~ fulfilled');
        // console.log('~ action.payload = ', action.payload);
        const newBranchWithTables = { ...action.payload, tables: [] };
        state.data.push(newBranchWithTables);
        state.activeBranch = action.payload;
      })
      .addCase(addNewBranchOnServer.rejected, (state, action) => {
        // console.log('~ rejected');
        state.status = 'failed';
        // console.log('~ rejected action.payload = ', action.payload);

        state.error = action.payload.error;
      })
      .addCase(updateDeliveryTems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDeliveryTems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].deliveryterms = action.payload.terms;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateDeliveryTems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateSocialLinkName.fulfilled, (state, action) => {
        const branchId = Number(action.payload.branchId);
        const socialLinkId = action.payload.id;
        const newName = action.payload.newName;

        // Найти индекс нужного филиала в массиве data
        const branchIndex = state.data.findIndex(branch => branch.id === branchId);
        if (branchIndex !== -1) {
          // Найти индекс нужной ссылки в массиве reviewplatforms филиала
          const linkIndex = state.data[branchIndex].reviewplatforms.findIndex(link => link.id === socialLinkId);
          if (linkIndex !== -1) {
            // Обновить имя у этой ссылки в массиве reviewplatforms
            state.data[branchIndex].reviewplatforms[linkIndex].name = newName;
          }
        }
      })
      .addCase(updateSocialLinkUrl.fulfilled, (state, action) => {
        const branchId = Number(action.payload.branchId);
        const socialLinkId = action.payload.id;
        const newName = action.payload.newUrl;

        // Найти индекс нужного филиала в массиве data
        const branchIndex = state.data.findIndex(branch => branch.id === branchId);
        if (branchIndex !== -1) {
          // Найти индекс нужной ссылки в массиве reviewplatforms филиала
          const linkIndex = state.data[branchIndex].reviewplatforms.findIndex(link => link.id === socialLinkId);
          if (linkIndex !== -1) {
            // Обновить имя у этой ссылки в массиве reviewplatforms
            state.data[branchIndex].reviewplatforms[linkIndex].url = newName;
          }
        }
      })
      .addCase(deleteSocialLink.fulfilled, (state, action) => {
        const branchId = Number(action.payload.branchId);
        const socialLinkId = action.payload.socialLinkId;

        // Найти индекс нужного филиала в массиве data
        const branchIndex = state.data.findIndex(branch => branch.id === branchId);
        if (branchIndex !== -1) {
          // Найти индекс нужной ссылки в массиве reviewplatforms филиала
          const linkIndex = state.data[branchIndex].reviewplatforms.findIndex(link => link.id === socialLinkId);
          if (linkIndex !== -1) {
            // Удалить эту ссылку из массива reviewplatforms
            state.data[branchIndex].reviewplatforms.splice(linkIndex, 1);
          }
        }
      })
      .addCase(updateCss.fulfilled, (state, action) => {
        const branchId = Number(action.payload.branchId);
        console.log('~ 🐆 action.payload = ', action.payload);
        //const css = action.payload.css;
       /*  console.log('~ branchId:', branchId);
        console.log('~ css:', css); */

        // Найти индекс нужного филиала в массиве data
        const branchIndex = state.data.findIndex(branch => branch.id === branchId);
        if (branchIndex !== -1) {
          console.log('нашел индекс = ', branchIndex);
          state.data[branchIndex].customcss = action.payload.css
          updateActiveBranchState(state);

        }
      })
      .addCase(addSocialLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addSocialLink.fulfilled, (state, action) => {
        state.status = 'succeeded';

        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          // проверяем, есть ли уже массив reviewplatforms в этом филиале
          if (!state.data[index].reviewplatforms) {
            state.data[index].reviewplatforms = [];
          }

          // добавляем данные в массив reviewplatforms филиала
          state.data[index].reviewplatforms.push({
            id: action.payload.id,
            url: action.payload.url,
            name: action.payload.name
          });
        }



      })
      .addCase(addSocialLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateTableName.fulfilled, (state, action) => {
        const { id, branch_id, name } = action.payload;
        // Находим филиал
        const branchToUpdate = state.data.find(branch => branch.id === branch_id);
        if (branchToUpdate) {
          // Находим стол внутри филиала
          const tableToUpdate = branchToUpdate.tables.find(table => table.id === id);

          if (tableToUpdate) {
            tableToUpdate.name = name;
          }
        }
      })

      .addCase(updateTableName.rejected, (state, action) => {
        // Обработка ошибки, если она произошла
        // например, добавить ошибку в state.error
        state.error = action.payload;
      })
      .addCase(deleteTable.pending, (state) => {
        // Обработка состояния pending (если нужно)
      })
      .addCase(deleteTable.fulfilled, (state, action) => {
        const { tableId, branchId } = action.payload;
        const numericBranchId = Number(branchId);
        // Находим соответствующий branch в data по branchId
        const branchToUpdate = state.data.find(branch => branch.id === numericBranchId);

        if (branchToUpdate) {
          // Фильтруем столики, чтобы удалить нужный
          branchToUpdate.tables = branchToUpdate.tables.filter(table => table.id !== tableId);
          updateActiveBranchState(state);
        }
      })
      .addCase(deleteTable.rejected, (state, action) => {
        // Обработка ошибок
        // action.payload будет содержать сообщение об ошибке
      })
      .addCase(addNewTableToServer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addNewTableToServer.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        // console.log('~ action.payload = ', action.payload);
        // console.log('~ index = ', index);
        if (index !== -1) {
          state.data[index].tables.push(action.payload)
          updateActiveBranchState(state);
        }
      })
      .addCase(addNewTableToServer.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateOrderThanks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderThanks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].orderthanks = action.payload.orderThanksText;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateOrderThanks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateLink.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].link = action.payload.url;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updatePhoneDelivery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePhoneDelivery.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].phone_delivery = action.payload.phone_delivery;
          updateActiveBranchState(state);
        }
      })
      .addCase(updatePhoneDelivery.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updatePhone.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePhone.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].phone = action.payload.phone;
          updateActiveBranchState(state);

        }
      })
      .addCase(updatePhone.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateCity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log('~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        // console.log('~ index = ', index);

        if (index !== -1) {
          state.data[index].city = action.payload.city;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateCity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateDescription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDescription.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log('~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        // console.log('~ index = ', index);

        if (index !== -1) {
          state.data[index].description = action.payload.description;
          updateActiveBranchState(state);
        }

      })
      .addCase(updateDescription.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateNote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));

        if (index !== -1) {
          state.data[index].note = action.payload.note;
          updateActiveBranchState(state);
        }

      })
      .addCase(updateNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateTextAboveMenu.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log('~ 🎨 = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].textabovemenu = action.payload.newText;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateAddress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // console.log('~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        // console.log('~ index = ', index);
        updateActiveBranchState(state);


        if (index !== -1) {
          state.data[index].address = action.payload.address;
        }

      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateBranchName.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBranchName.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex(branch => branch.id === parseInt(action.payload.branchId, 10));
        if (index !== -1) {
          state.data[index].name = action.payload.name;
          updateActiveBranchState(state);
        }

      })
      .addCase(updateBranchName.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(deleteLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteLogo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const existingBranch = state.data.find(branch => String(branch.id) === String(action.payload.branchId));
        if (existingBranch) {
          // Обновляем logo_url для этой ветки
          existingBranch.logo_url = null;
          updateActiveBranchState(state);
        }
        //state.logo_url = null; // Обновляем logoUrl в состоянии
      })
      .addCase(deleteLogo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(uploadLogo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadLogo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const existingBranch = state.data.find(branch => String(branch.id) === String(action.payload.branchId));

        if (existingBranch) {
          // Обновляем logo_url для этой ветки
          existingBranch.logo_url = action.payload.logoUrl;
          updateActiveBranchState(state);
        }
      })
      .addCase(uploadLogo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewBranch.fulfilled, (state, action) => {
        // Добавляем новый филиал в data (если data - это массив филиалов)
        state.data = [...state.data, action.payload];
        // Если нужно, вы можете сохранить ID филиала в отдельное поле
        state.newBranchId = action.payload.id;
      })
      .addCase(updateBranchOnServer.fulfilled, (state, action) => {
        const updatedBranch = action.payload.data;
        const branchToUpdate = state.data.find(branch => branch.id === updatedBranch.id);
        if (branchToUpdate) {
          Object.assign(branchToUpdate, updatedBranch);
        }
      })
      .addCase(updateUseOrderLocalOnServer.fulfilled, (state, action) => {
        // console.log('🎋 ~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        if (index !== -1) {
          state.data[index].useOrderLocal = action.payload.useOrderLocal;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseOrderCommentOnServer.fulfilled, (state, action) => {
        // console.log('🎋 ~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        if (index !== -1) {
          state.data[index].useordercomment = action.payload.useordercomment;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateChatIdOrderOnServer.fulfilled, (state, action) => {
        // console.log('🎋 ~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // console.log('~ index = ', index);
        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].chat_id_order = action.payload.chat_id_order;
          updateActiveBranchState(state);
        }

      }).addCase(updateChatIdFeedbackOnServer.fulfilled, (state, action) => {
        const updatedBranch = action.payload.data;
        state.activeBranch = updatedBranch;  // Обновляем activeBranch новыми данными
      }).addCase(updateChatIdDeliveryOnServer.fulfilled, (state, action) => {
        const updatedBranch = action.payload.data;
        state.activeBranch = updatedBranch;  // Обновляем activeBranch новыми данными
      }).addCase(fetchBranches.fulfilled, (state, action) => {
        state.branches = action.payload;
      }).addCase(updateFeedbackOption.fulfilled, (state, action) => {
        state.feedback_options = action.payload.feedback_options;
        const branchIndexStr = state.data.findIndex(branch => branch.id === action.payload.branchId);
        const branchIndex = parseInt(branchIndexStr)
        // console.log('~ extra Reduser branchIndex = ', branchIndex);  получаю -1
        // console.log('~ action.payload V');
        // console.log('~ ', action.payload);
        // console.log('~ state.data V');
        // console.log('~ ', state.data);

        if (branchIndex !== -1) {
          state.data[branchIndex].feedback_options = action.payload.feedback_option;

        }
      })
      .addCase(updateUseMenuAsync.fulfilled, (state, action) => {
        // console.log('🎋 ~ action.payload = ', action.payload);
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // console.log('~ index = ', index);
        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usemenu = action.payload.usemenu;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseFeedbackAsync.fulfilled, (state, action) => {
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usefeedback = action.payload.usefeedback;
          updateActiveBranchState(state);

        }
      })
      .addCase(updateUsePaymentAsync.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);

        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usepayment = action.payload.usepayment;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseCustomForm.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);

        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usecustomformfeedback = action.payload.usecustomformfeedback;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseTipsAsync.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);

        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usetips = action.payload.usetips;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseGoToFeedback.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // console.log("🚀 ~ .addCase ~ updateUseGoToFeedback > action.payload:", action.payload)


        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          if (!state.data[index].settings) {
            state.data[index].settings = {};
          }
          state.data[index].settings.goToFeedback = action.payload.settings.goToFeedback;
          updateActiveBranchState(state);
        }
      })
      .addCase(updateDesignMenu.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // console.log("🚀 ~ .addCase ~ updateDesignMenu > action.payload:", action.payload)


        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          if (!state.data[index].settings) {
            state.data[index].settings = {};
          }
          state.data[index].settings.colorMainBackground = action.payload.settings.colorMainBackground;
          state.data[index].settings.colorDishBackground = action.payload.settings.colorDishBackground;
          state.data[index].settings.colorDishCard = action.payload.settings.colorDishCard;
          state.data[index].settings.colorCategoryMenu = action.payload.settings.colorCategoryMenu;
          state.data[index].settings.colorCategoryDescriptionMenu = action.payload.settings.colorCategoryDescriptionMenu;
          state.data[index].settings.colorCategoryHorizontal = action.payload.settings.colorCategoryHorizontal;
          state.data[index].settings.colorOrderButtonPlus = action.payload.settings.colorOrderButtonPlus;
          state.data[index].settings.colorTopMainMenu = action.payload.settings.colorTopMainMenu;
          state.data[index].settings.colorSearch = action.payload.settings.colorSearch;
          state.data[index].settings.colorSearchBorder = action.payload.settings.colorSearchBorder;
          state.data[index].settings.colorFontDishTitle = action.payload.settings.colorFontDishTitle;
          state.data[index].settings.colorFontDishDescription = action.payload.settings.colorFontDishDescription;
          state.data[index].settings.colorFontDishPrice = action.payload.settings.colorFontDishPrice;
          state.data[index].settings.colorFontDishOutput = action.payload.settings.colorFontDishOutput;
          state.data[index].settings.colorDishCardBorder = action.payload.settings.colorDishCardBorder;
          state.data[index].settings.colorFontDishOrder = action.payload.settings.colorFontDishOrder;
          state.data[index].settings.colorFontDishOrderCount = action.payload.settings.colorFontDishOrderCount;
          state.data[index].settings.colorFontCategory = action.payload.settings.colorFontCategory;
          state.data[index].settings.colorFontCategoryDescription = action.payload.settings.colorFontCategoryDescription;
          state.data[index].settings.colorFontMainMenu = action.payload.settings.colorFontMainMenu;
          state.data[index].settings.colorFontIconsMainMenu = action.payload.settings.colorFontIconsMainMenu;
          state.data[index].settings.colorFontIconsMainMenuActive = action.payload.settings.colorFontIconsMainMenuActive;
          state.data[index].settings.colorFontMainMenuActive = action.payload.settings.colorFontMainMenuActive;
          state.data[index].settings.colorBorderMainMenu = action.payload.settings.colorBorderMainMenu;
          state.data[index].settings.colorFontCategoryHorizontal = action.payload.settings.colorFontCategoryHorizontal;
          state.data[index].settings.colorBorderCategoryHorizontal = action.payload.settings.colorBorderCategoryHorizontal;
          state.data[index].settings.colorFontBranchName = action.payload.settings.colorFontBranchName;
          state.data[index].settings.colorFontBranchAddress = action.payload.settings.colorFontBranchAddress;
          state.data[index].settings.colorFontTextAboveMenu = action.payload.settings.colorFontTextAboveMenu;
          state.data[index].settings.colorWaiterCallIconButton = action.payload.settings.colorWaiterCallIconButton;
          state.data[index].settings.colorFontSearchPlaceholder = action.payload.settings.colorFontSearchPlaceholder;
          state.data[index].settings.colorFontSearchInput = action.payload.settings.colorFontSearchInput;
          state.data[index].settings.primaryFont = action.payload.settings.primaryFont;
          state.data[index].settings.secondaryFont = action.payload.settings.secondaryFont;
          state.data[index].settings.fontForTitle = action.payload.settings.fontForTitle;
          state.data[index].settings.fontForAdress = action.payload.settings.fontForAdress;
          state.data[index].settings.colorSearchBorder = action.payload.settings.colorSearchBorder;
          state.data[index].settings.colorFontGroupStories = action.payload.settings.colorFontGroupStories;
          state.data[index].settings.colorDivTitleAndTextStory = action.payload.settings.colorDivTitleAndTextStory;
          /*  state.data[index].settings.fontForSearchText = action.payload.settings.fontForSearchText;*/
          state.data[index].settings.fontForDishCardDescription = action.payload.settings.fontForDishCardDescription;
          state.data[index].settings.colorOrderDiv = action.payload.settings.colorOrderDiv;
          state.data[index].settings.colorOrderDivButton = action.payload.settings.colorOrderDivButton;
          state.data[index].settings.colorOrderDivButtonBorder = action.payload.settings.colorOrderDivButtonBorder;
          state.data[index].settings.colorOrderDivFontColor = action.payload.settings.colorOrderDivFontColor;
          state.data[index].settings.colorOrderDivFontColorButton = action.payload.settings.colorOrderDivFontColorButton;
          state.data[index].settings.colorDivFeedback = action.payload.settings.colorDivFeedback; //цвет плашки отзыва
          state.data[index].settings.colorDivBorderFeedback = action.payload.settings.colorDivBorderFeedback; //цвет плашки обводки отзыва 
          state.data[index].settings.colorStarsFeedback = action.payload.settings.colorStarsFeedback; //цвет звездочек отзыва
          state.data[index].settings.colorEditsFeedback = action.payload.settings.colorEditsFeedback; //цвет эдитов в отзыве
          state.data[index].settings.colorEditsBorderFeedback = action.payload.settings.colorEditsBorderFeedback; //цвет бордеров в эдите отзывов
          state.data[index].settings.colorButtonFeedback = action.payload.settings.colorButtonFeedback; //цвет кнопки в отзывах
          state.data[index].settings.colorButtonTextFeedback = action.payload.settings.colorButtonTextFeedback; //цвет текста на кнопке в отзывах
          state.data[index].settings.colorButtonBorderFeedback = action.payload.settings.colorButtonBorderFeedback; //цвет обводки на кнопке в отзывах



          state.data[index].settings.fontForTitleSize = action.payload.settings.fontForTitleSize;
          state.data[index].settings.fontForTitleWeight = action.payload.settings.fontForTitleWeight;
          state.data[index].settings.fontForTitleStyle = action.payload.settings.fontForTitleStyle;

          state.data[index].settings.fontForAdressSize = action.payload.settings.fontForAdressSize;
          state.data[index].settings.fontForAdressWeight = action.payload.settings.fontForAdressWeight;
          state.data[index].settings.fontForAdressStyle = action.payload.settings.fontForAdressStyle;


          // текст над меню
          state.data[index].settings.fontForTextAboveMenu = action.payload.settings.fontForTextAboveMenu;
          state.data[index].settings.fontForTextAboveMenuSize = action.payload.settings.fontForTextAboveMenuSize;
          state.data[index].settings.fontForTextAboveMenuWeight = action.payload.settings.fontForTextAboveMenuWeight;
          state.data[index].settings.fontForTextAboveMenuStyle = action.payload.settings.fontForTextAboveMenuStyle;


          // шрифт горизонтальных категории
          state.data[index].settings.fontForHorizontalCategory = action.payload.settings.fontForHorizontalCategory;
          state.data[index].settings.fontForHorizontalCategorySize = action.payload.settings.fontForHorizontalCategorySize;
          state.data[index].settings.fontForHorizontalCategoryWeight = action.payload.settings.fontForHorizontalCategoryWeight;
          state.data[index].settings.fontForHorizontalCategoryStyle = action.payload.settings.fontForHorizontalCategoryStyle;

          // Шрифт главного меню
          state.data[index].settings.fontForMainMenu = action.payload.settings.fontForMainMenu;
          state.data[index].settings.fontForMainMenuSize = action.payload.settings.fontForMainMenuSize;
          state.data[index].settings.fontForMainMenuWeight = action.payload.settings.fontForMainMenuWeight;
          state.data[index].settings.fontForMainMenuStyle = action.payload.settings.fontForMainMenuStyle;

          // шрифт Категории меню
          state.data[index].settings.fontForCategory = action.payload.settings.fontForCategory;
          state.data[index].settings.fontForCategorySize = action.payload.settings.fontForCategorySize;
          state.data[index].settings.fontForCategoryWeight = action.payload.settings.fontForCategoryWeight;
          state.data[index].settings.fontForCategoryStyle = action.payload.settings.fontForCategoryStyle;

          // шрифт описания категории меню
          state.data[index].settings.fontForCategoryDescription = action.payload.settings.fontForCategoryDescription;
          state.data[index].settings.fontForCategoryDescriptionSize = action.payload.settings.fontForCategoryDescriptionSize;
          state.data[index].settings.fontForCategoryDescriptionWeight = action.payload.settings.fontForCategoryDescriptionWeight;
          state.data[index].settings.fontForCategoryDescriptionStyle = action.payload.settings.fontForCategoryDescriptionStyle;

          //Шрифт названия блюда в карточке
          state.data[index].settings.fontForDishCardTitle = action.payload.settings.fontForDishCardTitle;
          state.data[index].settings.fontForDishCardTitleSize = action.payload.settings.fontForDishCardTitleSize;
          state.data[index].settings.fontForDishCardTitleWeight = action.payload.settings.fontForDishCardTitleWeight;
          state.data[index].settings.fontForDishCardTitleStyle = action.payload.settings.fontForDishCardTitleStyle;

          //Шрифт описания блюда в карточке
          state.data[index].settings.fontForDishCardDescription = action.payload.settings.fontForDishCardDescription;
          state.data[index].settings.fontForDishCardDescriptionSize = action.payload.settings.fontForDishCardDescriptionSize;
          state.data[index].settings.fontForDishCardDescriptionWeight = action.payload.settings.fontForDishCardDescriptionWeight;
          state.data[index].settings.fontForDishCardDescriptionStyle = action.payload.settings.fontForDishCardDescriptionStyle;

          //Шрифт выхода блюда в карточке
          state.data[index].settings.fontForDishCardOutput = action.payload.settings.fontForDishCardOutput;
          state.data[index].settings.fontForDishCardOutputSize = action.payload.settings.fontForDishCardOutputSize;
          state.data[index].settings.fontForDishCardOutputWeight = action.payload.settings.fontForDishCardOutputWeight;
          state.data[index].settings.fontForDishCardOutputStyle = action.payload.settings.fontForDishCardOutputStyle;

          //Шрифт цены блюда в карточке
          state.data[index].settings.fontForDishCardPrice = action.payload.settings.fontForDishCardPrice;
          state.data[index].settings.fontForDishCardPriceSize = action.payload.settings.fontForDishCardPriceSize;
          state.data[index].settings.fontForDishCardPriceWeight = action.payload.settings.fontForDishCardPriceWeight;
          state.data[index].settings.fontForDishCardPriceStyle = action.payload.settings.fontForDishCardPriceStyle;

          //Шрифт заказа
          state.data[index].settings.fontForOrderDiv = action.payload.settings.fontForOrderDiv;
          state.data[index].settings.fontForOrderDivSize = action.payload.settings.fontForOrderDivSize;
          state.data[index].settings.fontForOrderDivWeight = action.payload.settings.fontForOrderDivWeight;
          state.data[index].settings.fontForOrderDivStyle = action.payload.settings.fontForOrderDivStyle;

          // Шрифт в отзывах
          state.data[index].settings.colorFontFeedback = action.payload.settings.colorFontFeedback; //цвет шрифта для отзывов
          state.data[index].settings.fontForFeedback = action.payload.settings.fontForFeedback; //шрифт для отзывов
          state.data[index].settings.fontForFeedbackSize = action.payload.settings.fontForFeedbackSize; //шрифт для отзывов
          state.data[index].settings.fontForFeedbackWeight = action.payload.settings.fontForFeedbackWeight; //шрифт для отзывов
          state.data[index].settings.fontForFeedbackStyle = action.payload.settings.fontForFeedbackStyle; //шрифт для отзывов

          state.data[index].settings.colorStoriesGroupBorder = action.payload.settings.colorStoriesGroupBorder; //цвет плашки отзыва

          // истории 
          state.data[index].settings.opacityDivTitleAndTextStory = action.payload.settings.opacityDivTitleAndTextStory;
          // группы историй
          state.data[index].settings.fontForTitleGroupStoies = action.payload.settings.fontForTitleGroupStoies;
          state.data[index].settings.fontForTitleGroupStoriesSize = action.payload.settings.fontForTitleGroupStoriesSize;
          state.data[index].settings.fontForTitleGroupStoriesWeight = action.payload.settings.fontForTitleGroupStoriesWeight;
          state.data[index].settings.fontForTitleGroupStoriesStyle = action.payload.settings.fontForTitleGroupStoriesStyle;

          state.data[index].settings.groupStoriesWidth = action.payload.settings.groupStoriesWidth;
          state.data[index].settings.groupStoriesHeight = action.payload.settings.groupStoriesHeight;
          state.data[index].settings.groupStoriesBorderRadius = action.payload.settings.groupStoriesBorderRadius;
          state.data[index].settings.groupStoriesBorderRadiusSize = action.payload.settings.groupStoriesBorderRadiusSize;

          // заголовок истории
          state.data[index].settings.colorFontTitleStory = action.payload.settings.colorFontTitleStory; // цвет заголовка истории
          state.data[index].settings.fontForTitleStorySize = action.payload.settings.fontForTitleStorySize;
          state.data[index].settings.fontForTitleStory = action.payload.settings.fontForTitleStory;
          state.data[index].settings.fontForTitleStoryWeight = action.payload.settings.fontForTitleStoryWeight;
          state.data[index].settings.fontForTitleStoryStyle = action.payload.settings.fontForTitleStoryStyle;
          // текст истории 
          state.data[index].settings.fontForTextStory = action.payload.settings.fontForTextStory;
          state.data[index].settings.colorFontTextStory = action.payload.settings.colorFontTextStory;
          state.data[index].settings.fontForTextSize = action.payload.settings.fontForTextSize;
          state.data[index].settings.fontForTextWeight = action.payload.settings.fontForTextWeight;
          state.data[index].settings.fontForTextStyle = action.payload.settings.fontForTextStyle;

          //главное меню
          state.data[index].settings.mainMenuBorderRadius = action.payload.settings.mainMenuBorderRadius;
          state.data[index].settings.mainMenuBorderSize = action.payload.settings.mainMenuBorderSize;

          //поиск
          //state.data[index].settings.searchBorderRadius = action.payload.settings.searchBorderRadius;
          state.data[index].settings.searchBorderSize = action.payload.settings.searchBorderSize;

          //горизонтальное меню
          state.data[index].settings.horizCategoryBorderRadius = action.payload.settings.horizCategoryBorderRadius;
          state.data[index].settings.horizCategoryBorderSize = action.payload.settings.horizCategoryBorderSize;

          //карточка блюда
          state.data[index].settings.dishCardBorderRadius = action.payload.settings.dishCardBorderRadius;
          state.data[index].settings.dishCardBorderSize = action.payload.settings.dishCardBorderSize;
          state.data[index].settings.colorDishCardButtonBorder = action.payload.settings.colorDishCardButtonBorder;
          state.data[index].settings.dishCardButtonBorderRadius = action.payload.settings.dishCardButtonBorderRadius;
          state.data[index].settings.dishCardButtonBorderSize = action.payload.settings.dishCardButtonBorderSize;

          // радиус закгругления всех кнопок
          state.data[index].settings.allButtonsBorderRadius = action.payload.settings.allButtonsBorderRadius;
          state.data[index].settings.allInputsBorderRadius = action.payload.settings.allInputsBorderRadius;

          // текст доставки

          state.data[index].settings.fontDeliveryMenu = action.payload.settings.fontDeliveryMenu;
          state.data[index].settings.colorfontDeliveryMenu = action.payload.settings.colorfontDeliveryMenu;
          state.data[index].settings.fontDeliveryMenuStyle = action.payload.settings.fontDeliveryMenuStyle;
          state.data[index].settings.fontDeliveryMenuSize = action.payload.settings.fontDeliveryMenuSize;
          state.data[index].settings.fontDeliveryMenuWeight = action.payload.settings.fontDeliveryMenuWeight;

          state.data[index].settings.colorFontInputFeedback = action.payload.settings.colorFontInputFeedback;


          state.data[index].settings.colorBackgroundHorizontalMenu = action.payload.settings.colorBackgroundHorizontalMenu;
          
          state.data[index].settings.colorActiveButtonHorizontalMenu = action.payload.settings.colorActiveButtonHorizontalMenu;
          state.data[index].settings.colorActiveButtonTextHorizontalMenu = action.payload.settings.colorActiveButtonTextHorizontalMenu;


          // инструкция, как добавить новое свойство obsidian > Как добавить стиль в restolink

          ///////////////////////////////////////////
          updateActiveBranchState(state);
        }
      })
      .addCase(updateUseDeliveryAsync.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);
        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].usedelivery = action.payload.usedelivery;
          updateActiveBranchState(state);

        }
      })
      .addCase(uploadImageCss.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        console.log('~ 🎆 action.payload: ', action.payload);
        const index = state.data.findIndex(branch => branch.id === action.payload.data.branch_id);
        console.log('~ index: ', index);
        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].images.push(action.payload.data);
          updateActiveBranchState(state);

        }
      })
      .addCase(removeImageCss.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        console.log('~ 🎆 action.payload: ', action.payload);
        const indexBranch = state.data.findIndex(branch => branch.id === action.payload.branchId);
        console.log('~ indexBranch: ', indexBranch);
        // Обновить только поле 'usemenu' для этого филиала
        if (indexBranch !== -1) {

          const indexImage = state.data[indexBranch].images.findIndex(image => image.id === action.payload.image_id);
          console.log('~ 🎇 indexImage: ', indexImage);
          state.data[indexBranch].images.splice(indexImage, 1);
          updateActiveBranchState(state);

        }
      })
      .addCase(updateUseCallWaiter.fulfilled, (state, action) => {
        // Найти индекс филиала с заданным ID
        const index = state.data.findIndex(branch => branch.id === action.payload.id);

        // Обновить только поле 'usemenu' для этого филиала
        if (index !== -1) {
          state.data[index].callwaiter = action.payload.callwaiter;
          updateActiveBranchState(state);

        }
      }).addCase(deleteBranchFromServerAsync.fulfilled, (state, action) => {
        // Удаление филиала из data по его ID
        console.log(' ***************************** ', action.payload)
        state.data = state.data.filter(branch => branch.id !== parseInt(action.payload.id, 10));
      })
      .addCase(updateBranchSubscriptions.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(updateBranchSubscriptions.fulfilled, (state, action) => {
        state.updateLoading = false;
        // console.log('~ 😍 action payload = ', action.payload);
        // Если action.payload содержит обновленные данные филиалов,
        // их нужно соответствующим образом интегрировать в state.data

        if (Array.isArray(action.payload)) { // Проверяем, что payload это массив
          state.data = state.data.map(branch => {
            const updatedBranch = action.payload.find(b => b.id === branch.id);
            return updatedBranch ? { ...branch, ...updatedBranch } : branch;
          });
        }
      })
      .addCase(updateBranchSubscriptions.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.payload || action.error.message; // предполагаем, что payload содержит ошибку
      });
  }


});


export const updateFeedbackOption = createAsyncThunk(
  'branch/updateFeedbackOption',
  async ({ branchId, feedbackOption }) => {
    // console.log('~ BS вызван updateFeedbackOption branchId, feedbackOption = ', branchId, feedbackOption);
    const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateFeedbackOptions/${branchId}/`, {
      feedback_option: feedbackOption
    });
    // console.log('~ response.data = ', response.data);
    return response.data;
  }
);



export const updateUseFeedbackAsync = createAsyncThunk(
  'branch/updateUsefeedback',
  async ({ newUseFeedbackState, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseFeedback/${branchId}`, {
        usefeedback: newUseFeedbackState
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUsePaymentAsync = createAsyncThunk(
  'branch/updateUsePayment',
  async ({ newUsePaymentState, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUsePayment/${branchId}`, {
        usepayment: newUsePaymentState
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateUseCustomForm = createAsyncThunk(
  'branch/updateUseCustomForm',
  async ({ newUseCustomForm, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseCustomForm/${branchId}`, {
        usecustomformfeedback: newUseCustomForm
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateUseCallWaiter = createAsyncThunk(
  'branch/updateUseCallWaiter',
  async ({ newUseCallWaiter, branchId }, { rejectWithValue }) => {
    // console.log('~ newUseCallWaiter = ', newUseCallWaiter);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseCallWaiter/${branchId}`, {
        callwaiter: newUseCallWaiter
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUseTipsAsync = createAsyncThunk(
  'branch/updateUseTips',
  async ({ newUseTipsState, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseTips/${branchId}`, {
        usetips: newUseTipsState
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUseGoToFeedback = createAsyncThunk(
  'branch/updateUseGoToFeedback',
  async ({ newUseToggleGoToFeedback, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseGoToFeedback/${branchId}`, {
        goToFeedback: newUseToggleGoToFeedback
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUseDeliveryAsync = createAsyncThunk(
  'branch/updateUseDelivery',
  async ({ newUseDeliveryState, branchId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseDelivery/${branchId}`, {
        usedelivery: newUseDeliveryState
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrderThanks = createAsyncThunk(
  'branchOptions/updateOrderThanks',
  async ({ branchId, orderThanksText }) => {
    // console.log('~ Вызван updateOrderThanks');
    const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateOrderThanks/${branchId}`, {
      orderThanksText: orderThanksText
    });
    // console.log('~ response.data order thanks = ', response.data);
    return response.data;
  }
);


export const fetchBranches = createAsyncThunk(
  'branches/fetch',
  async (token, thunkAPI) => {
    // console.log('~ вызыван из слайса fetchBranches');
    // console.log('~ токен из слайса = ', token);
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log('~ response.data fetchBranches', response.data);
    return response.data;
  }
);

export const updateUseOrderLocalOnServer = createAsyncThunk(
  'branch/updateUseOrderLocal',
  async ({ useOrderLocal, branchId }, { dispatch, rejectWithValue }) => {
    // console.log('~ slice useOrderLocalValue = ', useOrderLocal);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseOrderLocal/${branchId}`, {
        useOrderLocal
      });
      // console.log('~ Response from server:', response.data);   Вывести ответ сервера

      const updatedActiveBranch = response.data; // Предположим, сервер возвращает обновлённый объект
      dispatch(setActiveBranch(updatedActiveBranch)); // обновляем activeBranch

      return updatedActiveBranch;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUseOrderCommentOnServer = createAsyncThunk(
  'branch/updateUseOrderCommentOnServer',
  async ({ useordercomment, branchId }, { dispatch, rejectWithValue }) => {
    // console.log('~ slice useOrderLocalValue = ', useordercomment);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateUseOrderCommentOnServer/${branchId}`, {
        useordercomment
      });
      // console.log('~ Response from server:', response.data);   Вывести ответ сервера

      const updatedActiveBranch = response.data; // Предположим, сервер возвращает обновлённый объект
      dispatch(setActiveBranch(updatedActiveBranch)); // обновляем activeBranch

      return updatedActiveBranch;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateChatIdOrderOnServer = createAsyncThunk(
  'branch/updateChatIdOrderOnServer',
  async ({ chat_id_order, branchId }, { dispatch, rejectWithValue }) => {
    // console.log('~ slice updateChatId = ', chat_id_order);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateChatIdOrder/${branchId}`, {
        chat_id_order
      });
      // console.log('~ Response from server:', response.data);   Вывести ответ сервера

      const updatedActiveBranch = response.data; // Предположим, сервер возвращает обновлённый объект
      dispatch(setActiveBranch(updatedActiveBranch)); // обновляем activeBranch

      return updatedActiveBranch;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateChatIdDeliveryOnServer = createAsyncThunk(
  'branch/updateChatIdDeliveryOnServer',
  async ({ chat_id_delivery, branchId }, { dispatch, rejectWithValue }) => {
    // console.log('~ slice updateChatId = ', chat_id_delivery);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateChatIdDelivery/${branchId}`, {
        chat_id_delivery
      });
      // console.log('~ Response from server:', response.data);   Вывести ответ сервера

      const updatedActiveBranch = response.data; // Предположим, сервер возвращает обновлённый объект
      dispatch(setActiveBranch(updatedActiveBranch)); // обновляем activeBranch

      return updatedActiveBranch;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateChatIdFeedbackOnServer = createAsyncThunk(
  'branch/updateChatIdFeedbackOnServer',
  async ({ chat_id_feedback, branchId }, { dispatch, rejectWithValue }) => {
    // console.log('~ slice updateChatIdFeedback = ', chat_id_feedback);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/updateChatIdFeedback/${branchId}`, {
        chat_id_feedback
      });
      // console.log('~ Response from server:', response.data);   Вывести ответ сервера

      const updatedActiveBranch = response.data; // Предположим, сервер возвращает обновлённый объект
      dispatch(setActiveBranch(updatedActiveBranch)); // обновляем activeBranch

      return updatedActiveBranch;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// поиск филиала по id 
export const selectBranchById = (state, branchId) => {
  const id = Number(branchId); // Преобразование branchId в число
  return state.branch.data?.find(branch => branch.id === id);

};
export const { clearActiveBranch, updateUseMenu, updateUseFeedback, updateUsePayment, updateUseTips, updateUseDelivery, setActiveBranchForLocalStorage, updateBranches, setActiveBranch, setNewBranchId, setStage, updateBranch } = branchSlice.actions;
export const selectBranch = (state) => state.branch.data;
export const selectActiveBranch = (state) => state.branch.activeBranch;
export const selectNewBranchId = (state) => state.branch.newBranchId;
export const selectStage = (state) => state.branch.stage;
export const { updateDataBranch } = branchSlice.actions;
export const selectDesignSettings = (state) => state.branch.activeBranch.settings;
export const selectCustomCss = (state) => state.branch.activeBranch.customcss;







export default branchSlice.reducer;
