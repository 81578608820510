import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ExternalPaymentPage = () => {
  const { uuid } = useParams();
  const [userData, setUserData] = useState({ id: '', email: '' });
  const [amount, setAmount] = useState(false);

  const notifyAmount = () =>
    toast.error('Сумма платежа должна быть не меньше 50 руб. ', {
      duration: 4000,
      position: 'top-center',
    });

  const payment = () => {
    // // console.log('~ 🛩amount === ', amount);
    if (parseFloat(amount) < 50) {
      notifyAmount();
      return;
    }
    // // console.log('~ Пошел процесс оплаты');
    // Здесь добавляем данные для оплаты, например, сумму и идентификатор пользователя
    const paymentData = {
      amount: amount, // Пример суммы
      userId: userData.id, // Пример идентификатора пользователя
    };
    axios
      .post(`${process.env.REACT_APP_URL}/api/pay`, paymentData)
      .then((response) => {
        // // //console.log('~ 🛴 Ответ сервера:', response.data);
        // // //console.log('~ 🛹 url = ', response.data.confirmation.confirmation_url);
        //navigate(response.data.confirmation.confirmation_url); //переадресация на оплату
        // Проверка, что URL начинается с http:// или https://
        if (/^https?:\/\//.test(response.data.confirmation.confirmation_url)) {
          window.location.href = response.data.confirmation.confirmation_url;
        } else {
          // Обработка случая, когда URL не начинается с http:// или https://
          console.error(
            'Получен неправильный URL для переадресации:',
            response.data.confirmation.confirmation_url,
          );
        }
        // Обработка успешной оплаты
      })
      .catch((error) => {
        console.error('Ошибка при оплате:', error);
        // Обработка ошибки оплаты
      });
  };

  useEffect(() => {
    // console.log('~ uuid = ', uuid);
    axios
      .get(`${process.env.REACT_APP_URL}/api/getUserByUuid/${uuid}`)
      .then((response) => {
        setUserData(response.data); // Предполагается, что ответ содержит объект с id и email
      })
      .catch((error) => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  }, [uuid]); // Зависимость от uuid гарантирует, что запрос выполнится при изменении uuid

  return (
    <div className="flex ">
      <div className="flex flex-col w-full items-center justify-center">
        <div className="text-center pt-10 text-xl font-semibold">Оплата сервиса RestoLink.ru</div>
        <div className="text-center pt-2">Пользователь: {userData.email}</div>

        <div>
          <div className="pt-5 font-normal">Сумма пополнения</div>
          <div>
            <input
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              name=""
              id=""
              className="p-2 w-full mt-1   py-1 px-2  block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="text-xs font-normal">Минимальный платеж 50 руб.</div>
          <button
            onClick={payment}
            className="mt-4 bg-blue-600 hover:bg-blue-500 font-sm py-2 px-4 rounded shadow-md text-white">
            Выбрать способ оплаты
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalPaymentPage;
