import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import { Link } from 'react-router-dom';
import AddLeastOneBranch from '../components/AddLeastOneBranch';
import QrCode from 'qrcode-reader';
import axios from 'axios';

const PaymentPage = () => {
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;
  const [qrLink, setQrLink] = useState('');
  //const [payment, setPayment] = useState(null);

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/payments`, {
        branch_id: activeBranchId,
        link_sbp: qrLink,
      });

      if (response.status === 200) {
        console.log('Payment updated successfully');
      } else {
        console.error('Error updating payment:', response.status);
      }
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  useEffect(() => {
    const fetchPayment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/payments?branch_id=${activeBranchId}`,
        );

        const payment = response.data;

        if (payment) {
          console.log('Payment found:', payment);
          setQrLink(payment.link_sbp);
        } else {
          console.log('Payment not found');
          setQrLink('');
        }
      } catch (error) {
        console.error('Error fetching payment:', error);
      }
    };

    if (activeBranchId) {
      console.log('~ activeBranchId: ', activeBranchId);
      fetchPayment();
    }
  }, [activeBranchId]);

  const handleQrCodeUpload = (event) => {
    console.log('22222');
    return new Promise((resolve, reject) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const qr = new QrCode();
        qr.callback = (error, result) => {
          if (error) {
            console.error('QR code reading error:', error);
            reject(error);
            return;
          }
          resolve(result.result);
        };
        qr.decode(e.target.result);
      };

      reader.onerror = (error) => {
        console.error('File reading error:', error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleQrCodeSelect = async (event) => {
    console.log('11111');
    try {
      const qrCodeData = await handleQrCodeUpload(event);
      console.log('QR code data:', qrCodeData);
      setQrLink(qrCodeData);
    } catch (error) {
      console.error('QR code reading error:', error);
    }
  };
  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="pl-3">
        <div className="mt-10">
          <input type="file" accept="image/*" onChange={handleQrCodeSelect} />
        </div>
        {qrLink && (
          <div className="mt-4">
            <div>
              <label htmlFor="qrLink">Ссылка из QR-кода:</label>
            </div>
            <div>
              <input
                type="text"
                id="qrLink"
                value={qrLink}
                readOnly
                className="border border-gray-300 rounded px-2 py-1 w-full"
              />
            </div>
            <div className="flex flex-row mt-4">
              <div className="mt-6">Кнопка для оплаты:</div>
              <div>
                <button
                  className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-medium py-2 px-4 rounded flex items-center ml-3"
                  onClick={() => window.open(qrLink, '_blank')}>
                  <div className="flex flex-row">
                    <div>
                      <img src="/sbp.png" alt="СБП" className=" w-7 mr-2" />
                    </div>
                    <div className="flex flex-col justify-center align-top	">Оплатить по СБП </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="mt-10" onClick={handleSaveChanges}>
              <button className="button-s">Сохранить изменения оплаты</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
