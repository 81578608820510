import React from 'react';
import Sidebar from '../components/Sidebar';
import NewBranch from '../components/NewBranch';
import AddLogo from '../components/AddLogo'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStage, selectStage } from '../store/slices/branchSlice';


const NewBranchPage = () => {
  
 

  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex flex-col px-8 w-screen">
        <div><p className='font-semibold text-xl pt-6'>Добавление нового филиала</p></div>
        {/*     <h3>Новый филиал</h3> */}
        {/* <NewBranch/> */}
        {/* <NewBranch></NewBranch> */}
        {/* {stage === 0 && (
          <div>
            <NewBranch />
            <button onClick={()=>{setStage(1)}}>Далее</button>
          </div>
        )}
        {stage === 1 && (
          <div>
            <AddLogo />
          </div>
        )} */}

        <NewBranch/>
      </div>
    </div>
  );
};

export default NewBranchPage;
