import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
//import { updateChatIdOrderInRedux } from '../store/slices/yourSliceName';
import {
  updateChatIdOnServer,
  selectBranchById,
  updateBranch,
  updateBranchOnServer,
  selectActiveBranch,
  updateChatIdOrderOnServer,
  updateChatIdDeliveryOnServer,
  updateChatIdFeedbackOnServer,
} from '../store/slices/branchSlice';

const TelegramOptions = ({ chatId, actionPage }) => {
  const dispatch = useDispatch();

  const activeBranch = useSelector(selectActiveBranch);
  //const avChatIdOrder = activeBranch.chat_id_order;
  const activeBranchId = activeBranch.id;

  const [inputValue, setInputValue] = useState(chatId);
  const [inputValueTest, setInputValueTest] = useState(chatId);

  //setInputValue(avChatIdOrder);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputTestChange = (e) => {
    setInputValueTest(e.target.value);
  };

  const handleChatIdSave = (event) => {
    // // // // // // // // // console.log('~ Нажал на Save');
    const newValue = event.target.checked;
    // // // // // // // // // console.log('~ inputValue = ', inputValue);
    if (actionPage === 'order') {
      dispatch(updateChatIdOrderOnServer({ chat_id_order: inputValue, branchId: activeBranchId }));
    }
    if (actionPage === 'delivery') {
      dispatch(
        updateChatIdDeliveryOnServer({ chat_id_delivery: inputValue, branchId: activeBranchId }),
      );
    }
    if (actionPage === 'feedback') {
      dispatch(
        updateChatIdFeedbackOnServer({ chat_id_feedback: inputValue, branchId: activeBranchId }),
      );
    }
  };

  const TELEGRAM_TOKEN = '6639968001:AAH1BGTahC-6I_Ivdy3jyij5_85rBa50f5Q';
  const CHAT_ID = inputValue;

  function sendTestMsg() {
    const endpoint = `https://api.telegram.org/bot${TELEGRAM_TOKEN}/sendMessage`;

    return axios
      .post(endpoint, {
        chat_id: CHAT_ID,
        text: inputValueTest,
      })
      .then((response) => {
        // console.log('~ Message sent to Telegram:', response.data);
      })
      .catch((error) => {
        console.error('~ Error sending message to Telegram:', error);
      });
  }

  return (
    <div className="flex flex-col pt-6 pb-6">
      <div className="font-semibold text-xl">Настройка Телеграм</div>
      <div className="pt-2 text-sm">
        <p>Настройте телеграм группу для приема заказов</p>
        <p className="font-extralight">
          1) Создайте приватную группу в телеграм. <br />
          2) Добавьте в вашу группу бота заказов @sqOrderBot. <br />
          3) Добавьте в вашу группу бота @RawDataBot. Он предоставит вам chat-id вашей группы в
          формате "-9938992883". <br />
          4) Удалите из группы бота @RawDataBot. <br />
          5) Скопируйте полученный chat_id в поле ниже. <br />
          6) Добавьте в группу официантов, чтобы они могли получать заказы от посетителей. <br />
        </p>
      </div>

      <p className="text-base mt-4">скопируйте chat_id в это поле</p>
      <div class="text-field text-field_floating-2 mt-3">
        <input
          name="chat_id"
          type="text"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          placeholder="скопируйте chat_id в это поле"
          onChange={handleInputChange}
          value={inputValue}
        />
        <label class="text-field__label" for="chat_id">
          chat_id
        </label>
      </div>
      <div className="mt-2">
        <button onClick={handleChatIdSave} className="button-s w-36">
          Сохранить
        </button>
      </div>

      <p className="text-base mt-4">Отправить тестовое сообщение в группу</p>

      <div class="text-field text-field_floating-2 mt-3">
        <input
          name="message"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          type="text"
          placeholder="Отправить тестовое сообщение в группу"
          onChange={handleInputTestChange}
        />
        <label class="text-field__label" for="message">
          сообщение
        </label>
      </div>

      <div className="mt-2">
        <button className="w-52 button-s" onClick={sendTestMsg}>
          Отправить сообщение
        </button>
      </div>
    </div>
  );
};

export default TelegramOptions;
