import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Пример асинхронной операции для получения меню от сервера
// Асинхронная функция для отправки ссылки на сервер и получения обработанных данных
export const fetchImportedMenu = createAsyncThunk(
    'menuImported/fetchImportedMenu',
    async (menuUrl, { rejectWithValue }) => {
      // console.log('~ 🎈 fetchImportedMenu');
      try {
        const serverUrl = `${process.env.REACT_APP_URL}/api/importMenu`; // Путь к вашему API
        const response = await axios.get(serverUrl, { params: { url: menuUrl } });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const initialState = {
  branches: [],
  loading: false,
  error: null,
};

export const menuImportedSlice = createSlice({
  name: 'menuImported',
  initialState,
  reducers: {
    // Здесь можно добавить редюсеры для управления состоянием
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImportedMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchImportedMenu.fulfilled, (state, action) => {
        state.loading = false;
        // Добавление загруженных меню в соответствующий филиал
        const branchIndex = state.branches.findIndex(branch => branch.id === action.meta.arg);
        if (branchIndex !== -1) {
          state.branches[branchIndex].menus = action.payload;
        }
      })
      .addCase(fetchImportedMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Экспорт редюсеров и асинхронных операций
export const {} = menuImportedSlice.actions;
export default menuImportedSlice.reducer;
