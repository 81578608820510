import React from 'react';
import { Link } from 'react-router-dom';

// карточка филиала чаевых (не страница)
const BranchCardTips = ({ name, city, address, logo, branchId, recipientsCount }) => {
  const logoURL = logo ? `${process.env.REACT_APP_URL}/${logo}` : null;
  return (
    <div className="bg-white shadow-lg rounded-lg p-4 border border-slate-200">
      <div className="flex justify-between items-center">
        {logoURL && <img src={logoURL} alt="Логотип" className="h-16 w-16 rounded-full" />}
        <div className="flex flex-col justify-center items-start ml-3">
          <h2 className="text-lg font-semibold">{name}</h2>
          <div className="flex flex-row">
            <h5 className="text-xs font-thin pr-3">{city} {` `} {address}</h5>
          </div>
          <p className="mt-2">Сотрудников: {recipientsCount}</p>
          <div className="mt-2">
          <div className="flex justify-end mt-4">
            <Link to={`/editbranchtips/${branchId}`} className="text-base font-medium text-indigo-500">
              Добавить
            </Link>
          </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchCardTips;
