import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import TopCardBranch from '../components/TopCardBranch';
import AddLeastOneBranch from '../components/AddLeastOneBranch';
import LinkMain from '../components/LinkMain';
import EditablePDF from '../components/EditablePDF';
import ExportToPDF from '../components/ExportToPDF'

const LinksPage = () => {
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;

  const [active, setActive] = React.useState(0);
  const handleClick = (index) => {
    setActive(index);
  };

  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex flex-col w-full ">
        <TopCardBranch />

        <div className="px-16 py-6 shadow-md  ">
          <button
            className={`px-4 py-2 ${
              active === 0 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(0)}>
            Ссылки
          </button>
          <button
            className={`px-4 py-2 ${
              active === 1 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(1)}>
            Макеты для печати
          </button>
        </div>
        <div className="px-4">
          {active === 0 && (
            <div>
             <LinkMain></LinkMain>
            </div>
          )}
          {active === 1 && (
            <div>
              <ExportToPDF></ExportToPDF>
             {/*  <EditablePDF></EditablePDF> */}
            </div>
          )}
         
        </div>
      </div>
    </div>
  );
};

export default LinksPage;
