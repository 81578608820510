// globalSettingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

 export const fetchGlobalSettings = createAsyncThunk(
    'globalSettings/fetchGlobalSettings',
    async (countryCode, thunkAPI) => {
        // console.log('~ 🎉fetchGlobalSettings');
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/global-settings/${countryCode}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
); 

const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
         builder
            .addCase(fetchGlobalSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGlobalSettings.fulfilled, (state, action) => {
                // // console.log('~ 🎆 action.payload = ', action.payload);
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchGlobalSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }); 
    },
});

export const { setGlobalSettings } = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;
