import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk для загрузки историй по ID филиала
export const fetchStoriesByBranchId = createAsyncThunk(
    'stories/fetchByBranchId',
    async (branchId, { rejectWithValue }) => {
        // console.log("🎈 ~ fetchStoriesByBranchId:")
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/stories/${branchId}`);
            // console.log('~ response.data = ', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteGroupImage = createAsyncThunk(
    'stories/deleteGroupImage',
    async ({ groupId, profileImage }, { rejectWithValue }) => {
        // console.log('~ groupId = ', groupId);
        // console.log('~ profileImage = ', profileImage);
        try {
            // Предполагаем, что у вас есть серверный маршрут для удаления картинки группы историй по ID
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/stories/image/${groupId}`, {
                data: { profileImage }
            })
            // Возвращаем ID группы для обновления состояния после успешного удаления
            return groupId;
        } catch (error) {
            // Возвращаем ошибку, если запрос не удался
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteStoryImage = createAsyncThunk(
    'stories/deleteStoryImage',
    async ({ groupId, id, url }, { rejectWithValue }) => {

        // console.log("🎈 ~ deleteStoryImage");
        // console.log(" ~ groupId: ", groupId);
        // console.log(" ~ id: ", id);
        // console.log(" ~ url: ", url);
        try {
            // Важно: используем axios.delete с конфигурацией, чтобы передать тело запроса,
            // потому что axios.delete не принимает тело запроса в качестве второго параметра по умолчанию.
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/story/deleteimage/${id}`, {
                data: { groupId, url }
            });
            // console.log('🎨 ~ deleteStoryImage response.data = ', response.data);
            // Предполагается, что сервер возвращает обновленные данные группы историй после удаления изображения.
            return response.data;
        } catch (error) {
            console.error('🎨 ~ deleteStoryImage error = ', error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk для обновления заголовка группы историй
export const updateGroupTitle = createAsyncThunk(
    'stories/updateGroupTitle',
    async ({ id, title }, { rejectWithValue }) => {
        // console.log("🎈 ~ updateGroupTitle:")
        // console.log(" ~ id:", id)

        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/api/stories/updateTitle/${id}`, {
                title
            });
            // console.log('🐆 ~ response.data = ', response.data);
            return response.data; // Предполагается, что API возвращает обновленную группу историй
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateStory = createAsyncThunk(
    'stories/updateStory',
    async ({ id, heading, subheading }, { rejectWithValue }) => {
        // console.log('~ 🎈 updateStory');
        try {
            const response = await axios.put(`${process.env.REACT_APP_URL}/api/stories/update/${id}`, {
                heading,
                subheading,
            });
            // console.log('🎨 ~ updateStory response.data = ', response.data);
            return response.data; // Предполагается, что сервер возвращает обновленные данные истории
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadStoryImage = createAsyncThunk(
    'stories/uploadStoryImage',
    async ({ groupId, imageFile, id }, { rejectWithValue }) => {
        // console.log("🎈 ~ uploadStoryImage")
        // console.log(" ~ groupId: ", groupId)

        // console.log(" ~ id: ", id)



        // Создаем экземпляр FormData
        const formData = new FormData();
        // Добавляем данные в formData
        formData.append('image', imageFile); // 'image' - это ключ, по которому на сервере будет доступен файл
        formData.append('groupId', groupId); // Также можно добавить дополнительные данные, например UUID истории
        formData.append('id', id); // Также можно добавить дополнительные данные, например UUID истории

        try {
            // Отправляем запрос на сервер с formData
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/stories/uploadStoryImage/${groupId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Указываем тип содержимого как форму с файлом
                },
            });

            // Возвращаем данные от сервера
            return response.data;
        } catch (error) {
            // Возвращаем ошибку, если запрос не удался
            return rejectWithValue(error.response.data);
        }
    }
);



// Создаем async thunk
export const uploadGroupImage = createAsyncThunk(
    'stories/uploadGroupImage',
    async ({ groupId, imageFile }, { rejectWithValue }) => {
        // console.log('~ 🎈 uploadGroupImage');
        // console.log('~ groupId = ', groupId);
        // console.log('~ imageFile = ', imageFile);
        try {
            const formData = new FormData();
            formData.append('image', imageFile);

            const response = await axios.post(`${process.env.REACT_APP_URL}/api/stories/uploadGroupImage/${groupId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data; // Предполагается, что сервер возвращает данные с URL загруженного изображения
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addNewGroupStories = createAsyncThunk(
    'stories/addNewGroupStories',
    async ({ activeBranchId, newGroupStoriesName }, { rejectWithValue }) => {
        // console.log('~ activeBranchId: ', activeBranchId);
        // console.log('~ newGroupStoriesName: ', newGroupStoriesName);

        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewGroupStories`, {
                branchId: activeBranchId,
                title: newGroupStoriesName,
                // Здесь можете добавить дополнительные данные, если это необходимо
            });
            return response.data; // Предполагается, что сервер возвращает данные созданной группы историй
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteGroupStory = createAsyncThunk(
    'stories/deleteGroupStory',
    async ({ groupId, profileImage }, { rejectWithValue }) => {
        // console.log('~ profileImage: ', profileImage);

        // console.log('🎈 ~ deleteGroupStory');
        // console.log('~ groupId = ', groupId);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteGroupStories/${groupId}`, {
                data: { profileImage }
            })
            return groupId;
        } catch (error) {

            return rejectWithValue(error.response.data);
        }
    }
);

export const addNewStory = createAsyncThunk(
    'stories/addNewStory',
    async ({ groupId, newStoryHeading }, { rejectWithValue }) => {
        // console.log('🎈 ~ addNewStory');
        // console.log('~ groupId: ', groupId);
        // console.log('~ newStoryHeading: ', newStoryHeading);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewStory`, {
                groupId,
                newStoryHeading,
                // Здесь можете добавить дополнительные данные, если это необходимо
            });
            return response.data; // Предполагается, что сервер возвращает данные созданной группы историй
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateGroupStoriesOption = createAsyncThunk(
    'stories/updateGroupStoriesOption',
    async ({ groupId, displayLocation }, { rejectWithValue }) => {
        // console.log('🎈 ~ updateGroupStoriesOption');
        // console.log('~ groupId: ', groupId);
        // console.log('~ newGroupOption: ', displayLocation);
        try {
            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/updateGroupStoriesOption/${groupId}`, {
                displayLocation
            });

            return response.data;
        } catch (error) {

            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteStory = createAsyncThunk(
    'stories/deleteStory',
    async ({ content_id, url, groupId }, { rejectWithValue }) => {
        // console.log('🎈 ~ deleteStory');
        // console.log('~ content_id: ', content_id);
        // console.log('~ url: ', url);
        // console.log('~ groupId: ', groupId);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteStory/${content_id}`, {
                data: { url, groupId }
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const moveUpGroup = createAsyncThunk(
    'stories/moveUpGroup',
    async ({ groupId, display_order, branchId }, { rejectWithValue }) => {
        // console.log('🎈 ~ moveUpGroup');
        try {
            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/moveUpGroup/${groupId}`, {
                display_order,
                branchId
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const moveDownGroup = createAsyncThunk(
    'stories/moveDownGroup',
    async ({ groupId, display_order, branchId }, { rejectWithValue }) => {
        // console.log('🎈 ~ moveDownGroup');
        try {
            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/moveDownGroup/${groupId}`, {
                display_order,
                branchId
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const moveUpStory = createAsyncThunk(
    'stories/moveUpStory',
    async ({ groupId, content_id, display_order }, { getState, dispatch, rejectWithValue }) => {
        // console.log('🎈 ~ moveUpStory');


        try {
            // Отправляем измененный список историй на сервер
            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/moveUpStory/${groupId}`, {
                id: content_id,
                display_order
            });
            return response.data;
            // После успешного ответа от сервера обновляем состояние
            //dispatch(updateStories({ groupId, stories: response.data.stories }));
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const moveDownStory = createAsyncThunk(
    'stories/moveDownStory',
    async ({ groupId, content_id, display_order }, { getState, dispatch, rejectWithValue }) => {
        // console.log('🎈 ~ moveDownStory');


        try {
            // Отправляем измененный список историй на сервер
            const response = await axios.patch(`${process.env.REACT_APP_URL}/api/moveDownStory/${groupId}`, {
                id: content_id,
                display_order
            });
            return response.data;
            // После успешного ответа от сервера обновляем состояние
            //dispatch(updateStories({ groupId, stories: response.data.stories }));
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////

//  storiesSlice.js
export const storiesSlice = createSlice({
    name: 'stories',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {


    },
    extraReducers: (builder) => {
        builder
            .addCase(moveUpStory.fulfilled, (state, action) => {
                // console.log('🚦 ~ moveUpStory.fulfilled action.payload = ', action.payload);
                const indexGroup = state.items.findIndex(group => group.id === Number(action.payload.updatedStories.groupId));
                // console.log('~ indexGroup: ', indexGroup);

                if (indexGroup !== -1) {
                    const indexStoryMovedUp = state.items[indexGroup].contents.findIndex(story => story.content_id === action.payload.updatedStories.movedUp.id);

                    // console.log('~ indexStoryMovedUp: ', indexStoryMovedUp);
                    if (indexStoryMovedUp !== -1) {
                        // Обновляем title и text для найденной истории в найденной группе
                        state.items[indexGroup].contents[indexStoryMovedUp].display_order = action.payload.updatedStories.movedUp.display_order;
                    }
                    const indexStoryMovedDown = state.items[indexGroup].contents.findIndex(story => story.content_id === action.payload.updatedStories.movedDown.id);
                    if (indexStoryMovedDown !== -1) {
                        // Обновляем title и text для найденной истории в найденной группе
                        state.items[indexGroup].contents[indexStoryMovedDown].display_order = action.payload.updatedStories.movedDown.display_order;
                    }
                }

            })
            .addCase(moveDownStory.fulfilled, (state, action) => {
                // console.log('🚦 ~ moveDownStory.fulfilled action.payload = ', action.payload);
                // находим индекс группы
                const indexGroup = state.items.findIndex(group => group.id === Number(action.payload.updatedStories.groupId));
                // console.log('~ indexGroup: ', indexGroup);

                if (indexGroup !== -1) {
                    const indexStoryMovedUp = state.items[indexGroup].contents.findIndex(story => story.content_id === action.payload.updatedStories.movedUp.id);

                    // console.log('~ indexStoryMovedUp: ', indexStoryMovedUp);
                    if (indexStoryMovedUp !== -1) {
                        // Обновляем title и text для найденной истории в найденной группе
                        state.items[indexGroup].contents[indexStoryMovedUp].display_order = action.payload.updatedStories.movedUp.display_order;
                    }
                    const indexStoryMovedDown = state.items[indexGroup].contents.findIndex(story => story.content_id === action.payload.updatedStories.movedDown.id);
                    if (indexStoryMovedDown !== -1) {
                        // Обновляем title и text для найденной истории в найденной группе
                        state.items[indexGroup].contents[indexStoryMovedDown].display_order = action.payload.updatedStories.movedDown.display_order;
                    }
                }

            })


            .addCase(moveDownGroup.fulfilled, (state, action) => {
                // console.log('🎇 ~ moveDownGroup.fulfilled action.payload = ', action.payload);
                const indexMovedUpGroup = state.items.findIndex(group => group.id === action.payload.movedUpGroup.id);
                if (indexMovedUpGroup !== -1) {
                    // Обновляем содержимое группы историй новыми историями
                    state.items[indexMovedUpGroup].display_order = action.payload.movedUpGroup.display_order;
                } else {
                    console.error('Группа историй не найдена');
                }
                const indexMovedDownGroup = state.items.findIndex(group => group.id === action.payload.movedDownGroup.id);
                if (indexMovedDownGroup !== -1) {
                    // Обновляем содержимое группы историй новыми историями
                    state.items[indexMovedDownGroup].display_order = action.payload.movedDownGroup.display_order;
                } else {
                    console.error('Группа историй не найдена');
                }

            })
            .addCase(moveUpGroup.fulfilled, (state, action) => {
                // console.log('🎇 ~ moveUpGroup.fulfilled action.payload = ', action.payload);
                const indexMovedUpGroup = state.items.findIndex(group => group.id === action.payload.movedUpGroup.id);
                if (indexMovedUpGroup !== -1) {
                    // Обновляем содержимое группы историй новыми историями
                    state.items[indexMovedUpGroup].display_order = action.payload.movedUpGroup.display_order;
                } else {
                    console.error('Группа историй не найдена');
                }
                const indexMovedDownGroup = state.items.findIndex(group => group.id === action.payload.movedDownGroup.id);
                if (indexMovedDownGroup !== -1) {
                    // Обновляем содержимое группы историй новыми историями
                    state.items[indexMovedDownGroup].display_order = action.payload.movedDownGroup.display_order;
                } else {
                    console.error('Группа историй не найдена');
                }


                //state.items = action.payload
            })
            .addCase(updateGroupStoriesOption.fulfilled, (state, action) => {
                // console.log('🎇 ~ updateGroupStoriesOption.fulfilled action.payload = ', action.payload);
                const groupId = Number(action.payload.groupId);
                const displayLocation = action.payload.displayLocation;
                const groupIndex = state.items.findIndex(group => group.id === groupId);
                if (groupIndex !== -1) {
                    state.items[groupIndex].display_location = displayLocation;
                }
            })
            .addCase(deleteStory.fulfilled, (state, action) => {
                // console.log('🎇 ~ deleteStory.fulfilled action.payload = ', action.payload);

                //const groupId = Number(action.payload.groupId);
                // Находим индекс элемента группы с соответствующим id
                const groupIndex = state.items.findIndex(group => group.id === action.payload.groupId);
                // console.log('~ groupIndex: ', groupIndex);
                if (groupIndex !== -1) {
                    // Находим индекс истории внутри найденной группы по uuid
                    const storyIndex = state.items[groupIndex].contents.findIndex(story => story.content_id === Number(action.payload.content_id));
                    // console.log('~ storyIndex: ', storyIndex);
                    if (storyIndex !== -1) {
                        // console.log('~ gr: ', state.items[groupIndex].contents[storyIndex].title);
                        // Если история найдена, удаляем её из массива
                        state.items[groupIndex].contents.splice(storyIndex, 1);
                    }
                }
            })
            .addCase(addNewStory.fulfilled, (state, action) => {
                // console.log('🎇 ~ addNewStory.fulfilled action.payload = ', action.payload);

                // Находим индекс обновленного элемента в массиве
                const groupIndex = state.items.findIndex(group => group.id === action.payload.story_id);
                // console.log('~ groupIndex: ', groupIndex);
                let newStory = action.payload;
                newStory.content_id = action.payload.id;

                if (groupIndex !== -1) {
                    // Обновляем элемент по найденному индексу новыми данными
                    state.items[groupIndex].contents.push(newStory);

                } else {
                    // Если элемента с таким ID нет, добавляем его как новый элемент
                    // console.log('~ Группа для вставки истории не найдена!!!');
                }
            })
            .addCase(deleteGroupStory.fulfilled, (state, action) => {
                // console.log('🎇 ~ deleteGroupStory.fulfilled action.payload = ', action.payload);
                const index = state.items.findIndex(group => group.id === action.payload);
                if (index !== -1) {
                    // Удаляем группу из массива
                    state.items.splice(index, 1);
                }
            })
            .addCase(addNewGroupStories.fulfilled, (state, action) => {
                // console.log('🎇 ~ addNewGroupStories.fulfilled action.payload = ', action.payload);
                state.loading = false;
                // Создаем новый объект группы историй с пустым массивом contents
                const newGroupWithContents = { ...action.payload, contents: [] };
                // Добавляем этот новый объект в массив items
                state.items.push(newGroupWithContents);

            })
            .addCase(uploadGroupImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadGroupImage.fulfilled, (state, action) => {
                // console.log('🎇 ~ uploadGroupImage.fulfilled action.payload = ', action.payload);
                state.loading = false;
                // Здесь логика для обновления URL изображения группы историй в вашем состоянии
                const id = Number(action.payload.groupId);
                // console.log('~ id == ', id);
                const index = state.items.findIndex(group => group.id === id);
                if (index !== -1) {
                    state.items[index].profileImg = action.payload.imageUrl;
                }
            })
            .addCase(uploadGroupImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteStoryImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteStoryImage.fulfilled, (state, action) => {
                state.loading = false;
                // Поиск и обновление истории в массиве items
                const groupIdN = Number(action.payload.groupId);
                const idN = Number(action.payload.id)
                const indexGroup = state.items.findIndex(group => group.id === groupIdN);
                // console.log('~ indexGroup: ', indexGroup);

                if (indexGroup !== -1) {
                    const indexStory = state.items[indexGroup].contents.findIndex(story => story.content_id === idN);
                    // Обновляем историю в найденном индексе новыми данными
                    // console.log('~ indexStory: ', indexStory);
                    if (indexStory !== -1) {
                        state.items[indexGroup].contents[indexStory].url_image_story = '';
                    }
                }


            })
            .addCase(deleteStoryImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(deleteGroupImage.fulfilled, (state, action) => {
                // Находим индекс группы историй, картинка которой была удалена
                // console.log('🎇 ~ addCase deleteGroupImage.fulfilled action.payload: ', action.payload);

                const index = state.items.findIndex(item => item.id === action.payload);
                // console.log('~ index :', index);
                if (index !== -1) {
                    // Удаляем картинку из найденной группы
                    state.items[index].profileImg = '';
                }
            }).addCase(fetchStoriesByBranchId.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchStoriesByBranchId.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })
            .addCase(fetchStoriesByBranchId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Не удалось загрузить истории';
            }).addCase(updateGroupTitle.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateGroupTitle.fulfilled, (state, action) => {
                // console.log('~ updateGroupTitle fulfilled ');

                // console.log('~ action.payload: ', action.payload);

                state.loading = false;

                const storyId = Number(action.payload.id);
                // Находим индекс истории в массиве items, где id совпадает
                const index = state.items.findIndex(item => item.story_id === storyId);
                // console.log('~ index: ', index);

                if (index !== -1) {
                    // Обновляем title в найденной истории
                    state.items[index].story_title = action.payload.title;
                }

            })
            .addCase(updateGroupTitle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Не удалось обновить заголовок группы историй';
            })
            .addCase(updateStory.fulfilled, (state, action) => {
                state.loading = false;
                // Находим индекс обновленной истории в массиве items по ID
                // console.log('~ 🎇 updateStory.fulfilled action.payload: ', action.payload);
                // находим индекс группы
                const indexGroup = state.items.findIndex(group => group.id === action.payload.updatedStory.story_id);
                if (indexGroup !== -1) {
                    const indexStory = state.items[indexGroup].contents.findIndex(story => story.content_id === action.payload.updatedStory.id);
                    if (indexStory !== -1) {
                        // Обновляем title и text для найденной истории в найденной группе
                        state.items[indexGroup].contents[indexStory].title = action.payload.updatedStory.title;
                        state.items[indexGroup].contents[indexStory].text = action.payload.updatedStory.text;
                    } else {
                        // Обработка ситуации, когда история не найдена
                    }
                } else {
                    // Обработка ситуации, когда группа не найдена
                }


            })
            .addCase(updateStory.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Не удалось обновить историю';
            }).addCase(uploadStoryImage.fulfilled, (state, action) => {
                state.loading = false;
                // Находим индекс обновленной истории в массиве items по ID
                // console.log('🎇 ~ action.payload', action.payload);

                const groupIdN = Number(action.payload.groupId);
                const idN = Number(action.payload.id)

                // console.log('~ groupIdN: ', groupIdN);
                // console.log('~ idN: ', idN);

                const indexGroup = state.items.findIndex(group => group.id === groupIdN);

                // console.log('~ indexGroup: ', indexGroup);

                if (indexGroup !== -1) {
                    const indexStory = state.items[indexGroup].contents.findIndex(story => story.content_id === idN);
                    // Обновляем историю в найденном индексе новыми данными
                    // console.log('~ indexStory: ', indexStory);
                    if (indexStory !== -1) {
                        state.items[indexGroup].contents[indexStory].url_image_story = action.payload.imageUrl;
                    }
                }
            });
    },
});
export const selectAllStories = (state) => state.stories.items;
export const { moveStoryUpReducer, updateStories, moveStoryUp } = storiesSlice.actions;


// Экспорт редьюсера
export default storiesSlice.reducer;
