import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { selectActiveBranch, selectBranch } from '../store/slices/branchSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveBranchForLocalStorage } from '../store/slices/branchSlice';
import AddLeastOneBranch from '../components/AddLeastOneBranch';
import { selectUserId } from '../store/slices/userSlice';
import { fetchTipsByUserId, fetchToken } from '../store/slices/tipsSlice';
import AddNewCompany from '../components/tips/AddNewCompany';
import BranchCardTips from '../components/tips/BranchCardTips';

const TipsPage = () => {
  const dispatch = useDispatch();
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;
  const userId = useSelector(selectUserId);
  const [showAddNewCompany, setShowAddNewCompany] = useState(false); // Состояние для контроля видимости компонента добавления организации
  const branches = useSelector(selectBranch);

  const tips = useSelector((state) => state.tips.tips);
  //console.log(' ~ userId: ', userId);
  const { tipsOrganization, loading, error, hasTipsOrganization } = useSelector(
    (state) => state.tips,
  );

  useEffect(() => {
    if (userId) {
      dispatch(fetchTipsByUserId(userId));
    }
  }, [userId, dispatch]);

  const handleAddCompanyClick = () => {
    setShowAddNewCompany(true);
    /* dispatch(fetchToken()).then(() => {
    }); */
  };

  useEffect(() => {
    if (activeBranch) {
      dispatch(setActiveBranchForLocalStorage(activeBranch));
    }
  }, [dispatch, activeBranch]);

  const recipientsCountMap = useSelector((state) => {
    const countMap = {};
    state.tips.tipsBranches.forEach((tipsBranch) => {
      countMap[tipsBranch.branch_id] = tipsBranch.recipients.length;
    });
    return countMap;
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

  return (
    <div className="flex ">
      <Sidebar />
      <div className="mt-6 ml-4">
        {hasTipsOrganization ? (
          <div>
            <ul>
              {tipsOrganization.map((org) => (
                <li key={org.id}>
                  <div className="flex flex-row gap-4">
                    <div>
                      <p className="text-slate-500">организация:</p>
                    </div>
                    <div>{org.name}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div>
                      <p className="text-slate-500">ИНН:</p>
                    </div>
                    <div>{org.inn}</div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div>
                      <p className="text-slate-500">id:</p>
                    </div>
                    <div>{org.uuidcloudtips}</div>
                  </div>
                </li>
              ))}
            </ul>
            <hr class="hr-line"></hr>
            <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
              {branches.map((branch, index) => {
                const recipientsCount = recipientsCountMap[branch.id] || 0;

                return (
                  <div key={branch.id} className="mt-3 mr-3">
                    <BranchCardTips
                      name={branch.name}
                      city={branch.city}
                      address={branch.address}
                      logo={branch.logo_url}
                      branchId={branch.id}
                      recipientsCount={recipientsCount}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div>
            <p className="text-lg font-semibold">
              Чтобы начать работу с чаевыми, нужно создать организацию
            </p>
            <div className="mt-6">
              <button className="button-s" onClick={handleAddCompanyClick}>
                Добавить организацию
              </button>
            </div>
            {showAddNewCompany && <AddNewCompany />}
          </div>
        )}
      </div>
    </div>
  );
};

export default TipsPage;
