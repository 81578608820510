import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import ToggleSwitch from '../components/ToggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveBranch,
  updateUseCallWaiter,
  updateUseDeliveryAsync,
  updateUseFeedbackAsync,
  updateUseGoToFeedback,
  updateUseMenuAsync,
  updateUsePaymentAsync,
  updateUseTipsAsync,
} from '../store/slices/branchSlice';
import TopCardBranch from '../components/TopCardBranch';
import { updateOrderThanks } from '../store/slices/branchSlice';
import AddLeastOneBranch from '../components/AddLeastOneBranch';

const OptionsPage = () => {
  const dispatch = useDispatch();
  const activeBranch = useSelector(selectActiveBranch);

  const activeBranchId = activeBranch?.id;

  const [initialOrderThanks, setInitialOrderThanks] = useState(
    activeBranch ? activeBranch.orderthanks : null,
  );
  const [orderThanks, setOrderThanks] = useState(activeBranch ? activeBranch.orderthanks : null);

  // const [initialOrderThanks, setInitialOrderThanks] = useState(activeBranch.orderthanks);
  // const [orderThanks, setOrderThanks] = useState(activeBranch?.orderthanks);

  const [isUseMenu, setIsUseMenu] = useState(false);
  const [isUseFeedback, setIsUseFeedback] = useState(false);
  const [isUsePayment, setIsUsePayment] = useState(false);
  const [isUseTips, setIsUseTips] = useState(false);
  const [isUseDelivery, setIsUseDelivery] = useState(false);
  const [isUseCallWaiter, setIsUseCallWaiter] = useState(0);
  const [isUseGoToFeedback, SetIsUseGoToFeedback] = useState(false)

  useEffect(() => {
    if (!activeBranch) return;
    if (activeBranch) {
      setIsUseMenu(activeBranch.usemenu);
      setIsUseFeedback(activeBranch.usefeedback);
      setIsUsePayment(activeBranch.usepayment);
      setIsUseTips(activeBranch.usetips);
      setIsUseDelivery(activeBranch.usedelivery);
      setIsUseCallWaiter(activeBranch.callwaiter);
      SetIsUseGoToFeedback(activeBranch.settings?.goToFeedback || false);


      if (activeBranch.orderthanks === null) {
        setInitialOrderThanks('');
        setOrderThanks('');
      } else {
        setInitialOrderThanks(activeBranch.orderthanks);
        setOrderThanks(activeBranch.orderthanks);
      }
    }
  }, [activeBranch]);

  const handleToggleMenu = () => {
    console.log('~ Сработал handleToggleMenu');
    const newUseMenuState = !isUseMenu;
    setIsUseMenu(newUseMenuState);
    //dispatch(updateUseMenu(newUseMenuState));
    dispatch(updateUseMenuAsync({ newUseMenuState, branchId: activeBranch.id }));
  };
  const handleToggleFeedback = () => {
    const newUseFeedbackState = !isUseFeedback;
    setIsUseFeedback(newUseFeedbackState);
    //dispatch(updateUseFeedback(newUseFeedbackState));
    dispatch(updateUseFeedbackAsync({ newUseFeedbackState, branchId: activeBranch.id }));
  };

  const handleTogglePayment = () => {
    const newUsePaymentState = !isUsePayment;

    setIsUsePayment(newUsePaymentState);
    //dispatch(updateUsePayment(newUsePaymentState));
    dispatch(updateUsePaymentAsync({ newUsePaymentState, branchId: activeBranch.id }));
  };

  const handleToggleCallWaiter = () => {
    let newUseCallWaiter = !isUseCallWaiter;
    if (isUseCallWaiter === 0) {
      newUseCallWaiter = 1;
    } else {
      newUseCallWaiter = 0;
    }
    setIsUseCallWaiter(newUseCallWaiter);
    //dispatch(updateUsePayment(newUsePaymentState));
    dispatch(updateUseCallWaiter({ newUseCallWaiter, branchId: activeBranch.id }));
  };

  const handleToggleTips = () => {
    const newUseTipsState = !isUseTips;
    setIsUseTips(newUseTipsState);
    //dispatch(updateUseTips(newUseTipsState));
    dispatch(updateUseTipsAsync({ newUseTipsState, branchId: activeBranch.id }));
  };

  const handleToggleGoToFeedback = () => {
    const newUseToggleGoToFeedback = !isUseGoToFeedback;
    SetIsUseGoToFeedback(newUseToggleGoToFeedback);
    //dispatch(updateUseTips(newUseTipsState));
    dispatch(updateUseGoToFeedback({ newUseToggleGoToFeedback, branchId: activeBranch.id }));
  };

  const handleToggleDelivery = () => {
    const newUseDeliveryState = !isUseDelivery;
    setIsUseDelivery(newUseDeliveryState);
    //dispatch(updateUseDelivery(newUseDeliveryState));
    dispatch(updateUseDeliveryAsync({ newUseDeliveryState, branchId: activeBranch.id }));
  };

  const handleOrderThanksServerChange = (e) => {
    if (orderThanks !== initialOrderThanks) {
      dispatch(
        updateOrderThanks({
          branchId: activeBranch.id,
          orderThanksText: orderThanks,
        }),
      );
      // Обновляем начальное значение, так как текст был сохранен
      setInitialOrderThanks(orderThanks);
    }
  };

  const handleOrderThanksTextChange = (event) => {
    const newText = event.target.value;
    setOrderThanks(newText);
  };

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

  return (
    <div className="flex">
      <Sidebar />

      <div className="flex flex-col w-full">
        <TopCardBranch></TopCardBranch>
        <div className="pl-6">
          <div className="pb-6 pt-6 text-center font-semibold text-lg">Настройки</div>

          <div className='flex flex-row'>
            <div>Прямое перенаправление с главной страницы на страницу отзывов:</div>
            <div className='ml-2'>
              {' '}
              <ToggleSwitch
                isChecked={isUseGoToFeedback}
                onToggle={handleToggleGoToFeedback}
                label={isUseCallWaiter ? 'Да' : 'Нет'}
              />
            </div>
          </div>

          <div className="w-full">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="text-left">Настройка</th>
                  <th className="text-left">Статус</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td>Использовать меню:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUseMenu}
                      onToggle={handleToggleMenu}
                      label={isUseMenu ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Использовать отзывы:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUseFeedback}
                      onToggle={handleToggleFeedback}
                      label={isUseFeedback ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Использовать оплату:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUsePayment}
                      onToggle={handleTogglePayment}
                      label={isUsePayment ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Использовать чаевые:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUseTips}
                      onToggle={handleToggleTips}
                      label={isUseTips ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Использовать доставку:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUseDelivery}
                      onToggle={handleToggleDelivery}
                      label={isUseDelivery ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <hr />
                <br />
                <hr />
                <tr>
                  <td>Кнопка вызова официанта и счета:</td>
                  <td>
                    <ToggleSwitch
                      isChecked={isUseCallWaiter}
                      onToggle={handleToggleCallWaiter}
                      label={isUseCallWaiter ? 'Да' : 'Нет'}
                    />
                  </td>
                </tr>
                <div></div>
              </tbody>
            </table>
          </div>

          <hr />

          <div className="pt-6 pb-2">
            <p>Текст, появляющийся после заказа на доставку</p>
          </div>
          <textarea
            value={orderThanks}
            placeholder="Спасибо за заказ!"
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12 textarea-1"
            rows="4"
            onChange={handleOrderThanksTextChange}
            onBlur={handleOrderThanksServerChange}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionsPage;
