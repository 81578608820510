import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeImageCss,
  selectActiveBranch,
  selectCustomCss,
  updateCss,
  uploadImageCss,
} from '../store/slices/branchSlice';

const CustomCss = () => {
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;
  const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newImage = {
        id: images.length + 1,
        file,
        preview: URL.createObjectURL(file),
      };
      setImages([...images, newImage]);
    }
    if (file) {
      // Вызов асинхронного экшна для загрузки изображения
      dispatch(uploadImageCss({ file, branchId: activeBranchId }));
    }
  };

  const handleAddImage = (imageUrl) => {
    setImages([...images, { id: images.length + 1, url: imageUrl }]);
  };

  const dispatch = useDispatch();
  const handleRemoveImage = (imageId) => {
    console.log('~ 🧧 imageId: ', imageId);
    // Ищем изображение по уникальному идентификатору из базы данных, а не по порядковому номеру
    const imageToRemove = images.find((image) => image.id === imageId);
    if (!imageToRemove) {
      console.log('Изображение для удаления не найдено');
      return;
    }

    // Обновляем состояние, исключая удаляемое изображение
    const newImages = images.filter((image) => image.id !== imageId);
    setImages(newImages);

    // Если у изображения есть свойство file, освобождаем URL
    if (imageToRemove.file) {
      URL.revokeObjectURL(imageToRemove.preview);
    }

    // Отправляем на сервер идентификатор изображения, URL для удаления файла и ID филиала
    dispatch(
      removeImageCss({
        image_id: imageId, // Теперь это уникальный идентификатор из базы данных
        url: imageToRemove.preview, // Это временный URL; возможно, вам нужно будет изменить логику для передачи правильного пути файла
        branchId: activeBranchId,
      }),
    );
  };

  // Получаем CSS для активного филиала из Redux store
  const css = useSelector(selectCustomCss);

  // Инициализируем локальное состояние на основе значения из Redux
  const [localCss, setLocalCss] = useState('');

  // Обновляем локальное состояние при изменении значения в Redux
  /*   useEffect(() => {
    //setLocalCss(css);
  }, [css]); 
 */
  const handleChange = (e) => {
    console.log('handleChange: ', e.target.value); // Для проверки
    setLocalCss(e.target.value);
  };

  const handleSave = () => {
    console.log('🍖🍖🍖 handleSave: ', localCss); // Для проверки
    dispatch(updateCss({ css: localCss, branchId: activeBranchId }));
  };

  useEffect(() => {
    if (activeBranch?.customcss !== undefined) {
      setLocalCss(activeBranch.customcss || '');
    }
    if (activeBranch && activeBranch.images) {
      // Преобразуйте структуру данных, если это необходимо, чтобы соответствовать ожидаемой структуре в компоненте
      const loadedImages = activeBranch.images.map((img, index) => ({
        id: img.id, // Предполагается, что у вас нет уникального ID, поэтому используется индекс
        preview: img.image_url, // Предполагается, что img.image_url - это строка URL изображения
      }));
      setImages(loadedImages);
    }
  }, [activeBranch]);

  return (
    <div>
      <div>
        <p className="text-xl font-semibold mt-6">Кастомные CSS стили</p>
        <p className="mt-2">
          Пожалуйста, используйте эти настройки, только если разбираетесь в css стилях
        </p>
        <textarea
          value={localCss}
          onChange={handleChange}
          className="w-full mt-5 py-2 px-2 text-gray-700 bg-slate-100 border border-1 border-slate-500 rounded-lg shadow focus:outline-none focus:shadow-outline"
          cols="30"
          rows="20"></textarea>
        <div className='mt-5'>
          <button onClick={handleSave} className="button-s">
            Сохранить
          </button>
        </div>
      </div>
      <div className="mt-6">
        <input type="file" onChange={handleFileChange} accept="image/*" />
      </div>
      <div>
        {images.map((image) => (
          <div key={image.id} style={{ margin: '10px 0' }}>
            <img
              src={`${process.env.REACT_APP_URL}/${image.preview}`}
              alt={`Preview ${image.id}`}
              style={{ width: '100px', height: 'auto' }}
            />
            <p>
              Ссылка на изображение:{' '}
              {`${process.env.REACT_APP_URL}/${image.preview.replace(/\\/g, '/')}`}{' '}
            </p>
            <button onClick={() => handleRemoveImage(image.id)}>Удалить</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCss;
