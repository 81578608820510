// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAHpzqHzPTy3flgsylzaI5S1xYND370M30",
  authDomain: "signalqr.firebaseapp.com",
  projectId: "signalqr",
  storageBucket: "signalqr.appspot.com",
  messagingSenderId: "191032848806",
  appId: "1:191032848806:web:faa43d691e131122f31f67"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);