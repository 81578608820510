import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { selectBranch } from '../store/slices/branchSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ReviewsTable from './ReviewsTable';
import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
import { selectUserId } from '../store/slices/userSlice';

const FeedbackReports = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const branches = useSelector(selectBranch);
  const [selectedBranchId, setSelectedBranchId] = useState('all');
  const [reviews, setReviews] = useState([]);
  const userId = useSelector(selectUserId);

  // Функция для форматирования даты
  const formatDate = (dateString) => {
    const date = parseISO(dateString); // Преобразуем строку ISO в объект даты
    return format(date, 'dd.MM.yyyy HH:mm'); // Форматируем дату
  };

  // Вычисляем общее количество отзывов
  const totalReviews = reviews.length;

  // Вычисляем среднюю оценку
  const averageRating = reviews.reduce((acc, review) => acc + review.rating, 0) / totalReviews || 0;

  const handleBranchChange = (e) => {
    setSelectedBranchId(e.target.value);
  };

  const handleGetReviews = async () => {
    // Форматируем даты в нужный формат, например, YYYY-MM-DD
    /*  const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];
 */

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      userId: userId,
    };
    if (selectedBranchId !== 'all') {
      params.branchId = selectedBranchId;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/reviews`, { params });
      setReviews(response.data);
      console.log(response.data);
      // Здесь логика для обработки данных
    } catch (error) {
      console.error('Ошибка при запросе отзывов:', error);
    }
  };

  const handleDelete = async (reviewId) => {
    const updatedReviews = reviews.filter((review) => review.id !== reviewId);

    try {
      await axios.delete(`${process.env.REACT_APP_URL}/api/reviews/${reviewId}`);
      setReviews(updatedReviews);
      // Можете добавить сообщение об успешном удалении или обновлении UI
    } catch (error) {
      console.error('Ошибка при удалении отзыва:', error);
      // Обработка ошибок, например, отображение сообщения об ошибке
    }
  };
  const handleHide = async (reviewId) => {
    const updatedReviews = reviews.filter((review) => review.id !== reviewId);
    setReviews(updatedReviews);
  };

  const exportToExcel = (reviews, fileName) => {
    // Собираем все уникальные ключи из custom_review всех отзывов
    const allKeys = [
      ...new Set(reviews.flatMap((review) => Object.keys(review.custom_review || {}))),
    ];

    // Преобразовываем каждый отзыв в объект с динамическими полями
    const dataForExcel = reviews.map((review) => {
      const customData = review.custom_review || {};
      const customFields = allKeys.reduce((acc, key) => {
        acc[key] = customData[key] || ''; // Добавляем значение или пустую строку, если ключ отсутствует
        return acc;
      }, {});

      return {
        ID: review.id,
        Дата: formatDate(review.created_at), // Форматируем дату
        Рейтинг: review.rating,
        Комментарий: review.comment,
        ...customFields, // Добавляем динамические поля
      };
    });

    // Создание листа и книги
    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Reviews');

    // Сохранение файла
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <div>
      <div className="flex flex-row pt-6 items-end">
        <div className="">
          <div>
            <div>Филиал</div>
            <select
              className="form-select border-2 rounded-md"
              value={selectedBranchId}
              onChange={handleBranchChange}>
              <option value="all">Все филиалы</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name} {branch.city} {branch.address}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <span className="pr-1 pl-6">с</span>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            className="border-2 rounded-md"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div>
          <span className="px-1">по</span>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            className="border-2 rounded-md"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
      </div>

      <div className="flex flex-row items-end mt-3">
        <button
          onClick={handleGetReviews}
          className="button-s">
          Показать
        </button>

        <div className="pl-4">Всего отзывов: {totalReviews}</div>
        <div className="pl-4">Средняя оценка: {averageRating.toFixed(1)}</div>
      </div>
      <button className="text-xs text-blue-600 " onClick={() => exportToExcel(reviews, 'Reviews')}>
        Экспорт в Excel
      </button>

      <div>
        <ReviewsTable reviews={reviews} onDelete={handleDelete} onHide={handleHide} />
      </div>
    </div>
  );
};

export default FeedbackReports;
