// src\store\slices\tableSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    addTable: (state, action) => {
      const table = action.payload;
      state.byId[table.id] = table;
      state.allIds.push(table.id);
    },
    updateTable: (state, action) => {
      const table = action.payload;
      state.byId[table.id] = table;
    },
    deleteTable: (state, action) => {
      const id = action.payload;
      delete state.byId[id];
      state.allIds = state.allIds.filter(tableId => tableId !== id);
    },
    setTables: (state, action) => {
      const tables = action.payload;
      state.byId = tables.reduce((byId, table) => {
        byId[table.id] = table;
        return byId;
      }, {});
      state.allIds = tables.map(table => table.id);
    },
    
  },
});

export const { addTable, updateTable, deleteTable, setTables } = tableSlice.actions;

export const selectTableById = (state, id) => state.table.byId[id];
export const selectAllTables = state => state.table.allIds.map(id => state.table.byId[id]);

export default tableSlice.reducer;
