import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateName,
  updateDescription,
  updatePrice,
  updateIngredients,
  updateCookingTime,
  updateCalories,
  updateOutput,
  setFileData,
} from '../store/slices/newDishSlice';
import Resizer from 'react-image-file-resizer';
import FoodPhotoBank from './FoodPhotoBank';

const AddNewDish = () => {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.newDish.name);
  const description = useSelector((state) => state.newDish.description);
  const price = useSelector((state) => state.newDish.price);
  const ingredients = useSelector((state) => state.newDish.ingredients);
  const cooking_time = useSelector((state) => state.newDish.cooking_time);
  const calories = useSelector((state) => state.newDish.calories);
  const output = useSelector((state) => state.newDish.output);

  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [showPhotoBank, setShowPhotoBank] = useState(false);

  const togglePhotoBank = () => setShowPhotoBank(!showPhotoBank);

  const selectImage = async (url) => {
    setShowPhotoBank(false); // Закрыть FoodPhotoBank после выбора картинки
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], 'downloaded.png', { type: 'image/png' });

      const resizedImage = await new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          300, // width
          300, // height
          'PNG',
          100, // quality
          0, // rotation
          (uri) => {
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const resizedFile = new File([blob], file.name, {
                  type: 'image/png',
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              })
              .catch((err) => reject(err));
          },
          'base64', // output type
        );
      });

      setFile(resizedImage);
      setPreviewImage(URL.createObjectURL(resizedImage));
      dispatch(
        setFileData({
          data: resizedImage,
          name: resizedImage.name,
          type: resizedImage.type,
        }),
      );
    } catch (err) {
      console.error('Error resizing image:', err);
    }
  };

  //сжатие файла

  useEffect(() => {
    dispatch(updateName(''));
    dispatch(updateCalories(''));
    dispatch(updateCookingTime(''));
    dispatch(updateDescription(''));
    dispatch(updateIngredients(''));
    dispatch(updateOutput(''));
    dispatch(updatePrice(''));
    dispatch(setFileData([]));
  }, []);

  useEffect(() => {
    // // console.log('~ Сработал useEffect > AddNewDish');
  }, []);

  const onFileChange = async (event) => {
    // // // // console.log('~ 🧇 onFileChange вызван');
    const originalFile = event.target.files[0];
    console.log('~ originalFile = ', originalFile);
    try {
      const image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          originalFile,
          300, // Установите размеры, которые вы хотите здесь.
          300,
          'PNG',
          100,
          0,
          (uri) => {
            // Convert blobUrl to File object
            // // // // console.log('~ сработал Resizer');
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], originalFile.name, {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                });
                resolve(file);
              })
              .catch((err) => console.error(err));
          },
          'base64',
        );
      });
      setFile(image);
      setPreviewImage(URL.createObjectURL(image)); // После инициализации image
      dispatch(
        setFileData({
          data: image, // здесь можно поместить данные файла, если нужно
          name: image.name, // здесь используем image, а не file
          type: image.type,
        }),
      );
    } catch (err) {
      // console.log('~ ', err);
    }
  };

  return (
    <div className="mx-2 mt-6">
      <div class="text-field text-field_floating-2">
        <input
          value={name}
          name="name"
          onChange={(e) => dispatch(updateName(e.target.value))}
          placeholder="Название блюда (обязательно)"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="name">
          Название блюда (обязательно)
        </label>
      </div>

      <div class="text-field text-field_floating-2 mt-3">
        <input
          value={price}
          onChange={(e) => dispatch(updatePrice(e.target.value))}
          type="number"
          step="10"
          placeholder="Цена (обязательно)"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="price">
          Цена (обязательно)
        </label>
      </div>
      <div class="text-field text-field_floating-2 mt-3">
        <input
          name="output"
          value={output}
          onChange={(e) => dispatch(updateOutput(e.target.value))}
          type="text"
          step="10"
          placeholder="Выход блюда"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="output">
          Выход блюда
        </label>
      </div>
      <textarea
        value={description}
        onChange={(e) => dispatch(updateDescription(e.target.value))}
        placeholder="Описание, ингредиенты"
        rows={6}
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 textarea-1"
      />

      <p className="mt-2">Фото блюда</p>
      <div>{previewImage && <img className="my-1" src={previewImage} alt="Preview" />}</div>

      <div>
        <input
          className="my-2 bg-slate-300 hover:bg-slate-400 text-slate-800 font-sm py-2 px-4  rounded shadow-md"
          onChange={onFileChange}
          type="file"
        />
      </div>

      <div>
        <button className="button-s" onClick={togglePhotoBank}>
          Фотобанк
        </button>
        {showPhotoBank && (
          <FoodPhotoBank closePhotoBank={togglePhotoBank} selectImage={selectImage} />
        )}
      </div>
    </div>
  );
};

export default AddNewDish;
