import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'
// Асинхронная функция для обновления категории
  export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async ({ id, fieldName, newValue }, { rejectWithValue }) => {
    // // console.log('~ вызван updateCategory из слайса');
    // // console.log('~ slice id = ', id);
    // // console.log('~ slice filedName = ', fieldName);
    // // console.log('~ slice newValue = ', newValue);
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/api/categories/${id}`, { fieldName, newValue });
      // // console.log("~ Response from server: ", response);
      return { id, fieldName, newValue };
    } catch (error) {
      // // console.log('~ error updateCategory');
      return rejectWithValue(error.response.data);
    }
  }
); 

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: [],
  reducers: {
    setCategories: (state, action) => {
      return action.payload;
    },
    setPositions: (state, action) => {
      action.payload.forEach(({ id, newValue }) => {
        const category = state.find((category) => category.id === id);
        if (category) {
          category.position = newValue;
        }
      });
    },
    addCategory: (state, action) => {
      // Добавляем новую категорию в state
      state.push(action.payload);
    },
  },
  // Добавляем новые редьюсеры для асинхронного обновления
  extraReducers: (builder) => {
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      // //console.log('~ addCase сработал');
      if (action.payload) {
        const { id, fieldName, newValue } = action.payload;
        const category = state.find((category) => category.id === id);
        if (category) {
          category[fieldName] = newValue;
        }
      }
    });
  },
});

export const { setCategories, updatePositions, setPositions, addCategory   } = categoriesSlice.actions;
export const selectCategories = (state) => state.categories;
export default categoriesSlice.reducer;
