import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveBranch,
  updateDeliveryTems,
  updatePhoneDelivery,
} from '../store/slices/branchSlice';

const DeliveryOptions = () => {
  const dispatch = useDispatch();

  const activeBranch = useSelector(selectActiveBranch);
  const [phone, setPhone] = useState('');
  const [terms, setTerms] = useState('');

  useEffect(() => {
    if (activeBranch && activeBranch.phone_delivery) {
      setPhone(activeBranch.phone_delivery);
      setTerms(activeBranch.deliveryterms);
    }
  }, [activeBranch]);

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleTermsChange = (e) => {
    setTerms(e.target.value);
  };

  const handleSavePhone = (e) => {
    if (activeBranch.phone_delivery === phone) {
      return;
    } else {
      dispatch(updatePhoneDelivery({ phone_delivery: phone, branchId: activeBranch.id }));
    }
  };
  const handleSaveTerms = (e) => {
    if (activeBranch.deliveryterms === terms) {
      return;
    } else {
      dispatch(updateDeliveryTems({ terms, branchId: activeBranch.id }));

      // dispatch
    }
  };

  return (
    <>

      <div className="text-field text-field_floating-2">
        <input
          type="text"
          placeholder="Телефон доставки"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          value={phone}
          onChange={handlePhoneChange}
          onBlur={handleSavePhone}
        />
        <label class="text-field__label" for="category-name">
          Телефон доставки
        </label>
      </div>

      <div className="pt-6">
        <div>Условия доставки. Отображается в меню доставки.</div>
        <div>
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Условия доставки"
            value={terms}
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  textarea-1"
            onChange={handleTermsChange}
            onBlur={handleSaveTerms}></textarea>
        </div>
      </div>
    </>
  );
};

export default DeliveryOptions;
