import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import { TiClipboard } from 'react-icons/ti';
import AddLeastOneBranch from './AddLeastOneBranch';
import Sidebar from '../components/Sidebar';
import Links from '../components/Links';



const LinkMain = () => {

    const activeBranch = useSelector(selectActiveBranch);
    const activeBranchId = activeBranch?.id;
    const [tables, setTables] = useState([]);

    
  useEffect(() => {
    // console.log('~ сработал useEffect LinksPage');
    setTables(activeBranch.tables);
    // console.log('~ tables = ', tables);
  }, [activeBranch]);

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

    const copyToClipboard = (text) => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            // console.log('~ Текст скопирован в буфер обмена');
          })
          .catch((err) => {
            console.error('~ Ошибка при копировании текста: ', err);
          });
      };
    

  return (
    <div>
      <div className="font-thin">
        Используйте эти сервисы для генерации QR кодов для ваших ссылок.
        <p>
          <a href="https://qrcode.tec-it.com/ru/Url" className="text-blue-500">
            qrcode.tec-it.com,&nbsp;
          </a>
          <a href="https://www.qrcode-monkey.com/ru/" className="text-blue-500">
            qrcode-monkey.com,&nbsp;
          </a>
          <a href="https://www.qrcode-tiger.com/" className="text-blue-500">
            qrcode-tiger.com,&nbsp;
          </a>
          <a href="https://qr.io/" className="text-blue-500">
            qr.io
          </a>
        </p>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-bold">Мои ссылки</h2>
      </div>
      <div className="pt-2">
        <div>Ссылка на доставку:</div>
        <div className="flex flex-row items-center">
          <div>
            <a href={`https://app.restolink.ru/${activeBranch.link}/delivery`}>
              https://app.restolink.ru/{activeBranch.link}/delivery
            </a>
          </div>
          <div className="text-gray-600">
            <TiClipboard
              className="font-bold ml-2"
              onClick={() =>
                copyToClipboard(`https://app.restolink.ru/${activeBranch.link}/delivery`)
              }
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
      {tables &&
          tables.map((table) => {
            if (!table) return null; // Эта строка вернёт null, если table равно null или undefined
            return (
              <div key={table.id}>
                <Links
                  id={table.id}
                  name={table.name}
                  link={table.link}
                  branchLink={activeBranch.link}
                />
              </div>
            );
          })}
    </div>
  );
};

export default LinkMain;
