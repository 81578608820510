import React from 'react';
import Sidebar from '../components/Sidebar';
import { selectActiveBranch } from '../store/slices/branchSlice';
import { useSelector } from 'react-redux';
import TopCardBranch from '../components/TopCardBranch';
import TelegramOptions from '../components/TelegramOptions';
import AddLeastOneBranch from '../components/AddLeastOneBranch';
import DeliveryOptions from '../components/DeliveryOptions';

const DeliveryPage = () => {
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;

  const chatIdFeedback = activeBranch?.chat_id_delivery;

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }

  // для телеграм компонента
  const actionPage = 'delivery';

  return (
    <div className="flex ">
      <Sidebar />

      <div className="flex flex-col w-full">
                <TopCardBranch />
        <div className="flex flex-col ml-4 mt-6">
          <div className='text-xl font-semibold'>Настройки доставки</div>
          <div className='pt-6'>
            <DeliveryOptions />
          </div>
          <div>
            <TelegramOptions
              key={Math.random()}
              chatId={chatIdFeedback}
              actibeBranchId={activeBranchId}
              actionPage={actionPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
