import React from 'react';
import Sidebar from '../components/Sidebar';
import Menu from '../components/Menu';
import MenuCategory from '../components/MenuCategory';
import Promotions from '../components/Promotions';
import { useSelector, useDispatch } from 'react-redux'; // <-- Импорт useSelector
import { selectActiveBranch, selectBranch } from '../store/slices/branchSlice';
import { useEffect, useState } from 'react';
import { setCategories } from '../store/slices/categoriesSlice';
import getCategories from '../utils/categoriesAPI';
import { FaBeer } from 'react-icons/fa';
import OptionsMenu from '../components/OptionsMenu';
import TopCardBranch from '../components/TopCardBranch';
import AddLeastOneBranch from '../components/AddLeastOneBranch';

import axios from 'axios';
import Import from '../components/Import';
import DesignMenu from '../components/DesignMenu';
import CustomCss from '../components/CustomCss';

const MenuPage = () => {
/*   const branchData = useSelector(selectBranch);
 */
  const [active, setActive] = React.useState(0);
  const dispatch = useDispatch();
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch?.id;

  useEffect(() => {
    if (activeBranch?.id) {
      getCategories(activeBranch.id, dispatch);
    }
  }, []);

  useEffect(() => {
    if (activeBranch?.id) {
      getCategories(activeBranch.id, dispatch);
    }
  }, [activeBranch]);

  const handleClick = (index) => {
    setActive(index);
  };

  if (!activeBranchId) {
    return (
      <div className="flex">
        <Sidebar />
        <AddLeastOneBranch />
      </div>
    );
  }


  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex flex-col w-full">
        <TopCardBranch />
        
        
        <div className="px-16 py-6 shadow-md  ">
          <button
            className={`px-4 py-2 ${
              active === 0 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(0)}>
            Меню
          </button>
          <button
            className={`px-4 py-2 ${
              active === 1 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(1)}>
            Настройки меню
          </button>
          <button
            className={`px-4 py-2 ${
              active === 2 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(2)}>
            Истории, Акции
          </button>
          <button
            className={`px-4 py-2 ${
              active === 3 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(3)}>
            Дизайн меню
          </button>
          <button
            className={`px-4 py-2 ${
              active === 4 ? 'bg-slate-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => handleClick(4)}>
            Custom Css
          </button>
        </div>
        <div className="px-4">
          {active === 0 && (
            <div>
              <Menu />
            </div>
          )}
          {active === 1 && (
            <div>
              <OptionsMenu />
            </div>
          )}
          {active === 2 && (
            <div>
              <Promotions />
            </div>
          )}
          {active === 3 && (
            <div>
              <DesignMenu />
            </div>
          )}
          {active === 4 && (
            <div>
              <CustomCss />
            </div>
          )}
        </div>
      
      </div>
    </div>
  );
};

export default MenuPage;
