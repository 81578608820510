import React, { useEffect, useMemo, useState } from 'react';
import { selectActiveBranch } from '../store/slices/branchSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewGroupStories,
  fetchStoriesByBranchId,
  selectAllStories,
} from '../store/slices/storiesSlice';
import StoriesGroup from './StoriesGroup';

const Promotions = () => {
  const dispatch = useDispatch();
  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;
  const allStories = useSelector(selectAllStories);
  const [isFormVisible, setFormVisible] = useState(false);
  const [newGroupStoriesName, SetNewGroupStoriesName] = useState('');

  const handleAddButtonClick = () => {
    setFormVisible(true);
  };

  const handleSaveButtonClick = () => {
    setFormVisible(false);
    dispatch(addNewGroupStories({ activeBranchId, newGroupStoriesName }));
  };

  const handleCancelButtonClick = () => {
    setFormVisible(false);
    SetNewGroupStoriesName('');
  };

  useEffect(() => {
    if (activeBranchId) {
      dispatch(fetchStoriesByBranchId(activeBranchId));
    }
  }, [activeBranchId, dispatch]);

  // useMemo будет пересчитывать отсортированный массив только когда allStories изменится
  const sortedStories = useMemo(() => {
    return [...allStories].sort((a, b) => a.display_order - b.display_order);
  }, [allStories]);

  return (
    <>
      <div className="mt-4">
        <button onClick={handleAddButtonClick} type="submit" className="button-s">
          Добавить новую группу историй
        </button>
      </div>

      {isFormVisible && (
        <div className="border-4 my-5 flex flex-col px-2">
          <div>
            <div class="text-field text-field_floating-2">
              <input
                name="story-name"
                onChange={(e) => SetNewGroupStoriesName(e.target.value)}
                placeholder="Название группы истории"
                className="my-4 w-full md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
              <label class="text-field__label" for="story-name">
                Название группы истории
              </label>
            </div>
          </div>
          <div className="flex flex-row gap-4 mb-4">
            <button className="button-cancel" onClick={handleCancelButtonClick}>
              Отмена
            </button>
            <button className="button-s" onClick={handleSaveButtonClick}>
              Сохранить
            </button>
          </div>
        </div>
      )}

      {sortedStories.map((storiesGroup, index) => (
        <StoriesGroup
          key={storiesGroup.id} // лучше использовать уникальный id, а не индекс массива
          title={storiesGroup.title}
          stories={storiesGroup.contents}
          id={storiesGroup.id}
          profileImage={storiesGroup.profileImg}
          display_location={storiesGroup.display_location}
          display_order={storiesGroup.display_order}
          branchId={activeBranchId}
          isFirst={index === 0} // True, если элемент первый в массиве
          isLast={index === sortedStories.length - 1} // True, если элемент последний в массиве
        />
      ))}
    </>
    //запросить
  );
};

export default Promotions;
