import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSocialLink,
  deleteSocialLink,
  selectActiveBranch,
  selectBranchById,
  updateSocialLinkName,
  updateSocialLinkUrl,
} from '../store/slices/branchSlice';

//TODO Отмена добавления нового (для комфорта юзера)

const SocialLinks = ({ branchId }) => {
  const dispatch = useDispatch();

  //const { branchId } = useParams();
  // //console.log('~ 👍branchId = ', branchId);
  let editedBranch = useSelector((state) => selectBranchById(state, branchId));

  //const branchFromEditBranch = useSelector((state) => selectBranchById(state, branchId));

  //const branchFromActiveBranch = useSelector(selectActiveBranch);

  /* if (branchId) {
    // если вызвано из editBranch, то id будет в параметрах. Иначе Активный branch
    editedBranch = branchFromEditBranch;
  } else {
    editedBranch = branchFromActiveBranch;
  } */

  // //console.log('~ branchFromEditBranch =  ', branchFromEditBranch);
  // //console.log('~ branchFromActiveBranch =  ', branchFromActiveBranch);

  const [socialLinks, setSocialLinks] = useState(editedBranch.reviewplatforms);
  const socialLinksInitial = editedBranch.reviewplatforms;

  const [showNewInputs, setShowNewInputs] = useState(false);

  const [linkName, setLinkName] = useState('');
  const [linkUrl, setLinkUrl] = useState('');

  useEffect(() => {
    setSocialLinks(editedBranch.reviewplatforms);
  }, [editedBranch.reviewplatforms]);

  const handleSocialLinksChange = (e, socialLinkId, field) => {
    // Находим стол, который нужно обновить
    const updatedTables = socialLinks.map((socialLink) => {
      if (socialLink.id === socialLinkId) {
        return { ...socialLink, [field]: e.target.value }; // Обновляем нужное поле
      }
      return socialLink;
    });
    // Устанавливаем новое состояние
    setSocialLinks(updatedTables);
    // //console.log('~ handleSocialLinksChange >>> ', socialLinks);
  };

  const saveNameChanges = (newName, id) => {
    // // // // console.log('~ 🔵 newName = ', newName);
    // // // // console.log('~ 🔵 id = ', id);

    // Находим имя ссылки по её id в массиве socialLinks
    const existingLink = socialLinksInitial.find((link) => link.id === id);
    const existingName = existingLink ? existingLink.name : null;

    // console.log('~ 111 socialLinks = ', socialLinks);

    // console.log('~ nameInitial = ', existingName);

    // Если имя найдено и оно отличается от нового
    if (existingName && existingName !== newName) {
      // Делаем что-то (например, диспатчим экшн на переименование)
      // console.log('~ Name has changed! Dispatching an action...');
      dispatch(updateSocialLinkName({ newName, id, branchId }));
    } else {
      // console.log('~ Измненений в имени не было.');
      return;
    }
  };

  const saveUrlChanges = (newUrl, id) => {
    // // // console.log('~ 🔵 newUrl = ', newUrl);
    // // // console.log('~ 🔵 id = ', id);

    // Находим имя ссылки по её id в массиве socialLinks
    const existingLink = socialLinksInitial.find((link) => link.id === id);
    const existingUrl = existingLink ? existingLink.url : null;

    // // // console.log('~ existingUrl = ', existingUrl);

    // // // console.log('~ 111 socialLinks = ', socialLinks);

    // // // console.log('~ urlInitial = ', existingUrl);

    // Если имя найдено и оно отличается от нового
    if (existingUrl && existingUrl !== newUrl) {
      // Делаем что-то (например, диспатчим экшн на переименование)
      // console.log('~ Name has changed! Dispatching an action...');
      // dispatch(renameLinkAction(id, newName));
      dispatch(updateSocialLinkUrl({ newUrl, id, branchId }));
    } else {
      // console.log('~ Name remains the same. No action needed.');
      return;
    }
  };

  /*   useEffect(() => {
    fetchSocialLinks();
  }, [branchId]); */

  function toggleNewSocialLinks() {
    setShowNewInputs(!showNewInputs);
  }

  function addNewSocialLinkCancel() {
    setShowNewInputs(false); // Скрывает инпуты после Отмена
  }

  function addNewSocialLink() {
    if (linkName === '' || linkUrl === '') {
      alert('Заполните все поля');
      return;
    }

    const newTable = {
      name: linkName, // Вы можете изменить это в соответствии со своей структурой данных
      url: linkUrl,
      branchId: branchId,
    };

    // console.log('~ newTable.name = ', newTable.name);
    // console.log('~ newTable.link = ', newTable.link);
    // console.log('~ newTable.branchId = ', newTable.branchId);

    dispatch(addSocialLink({ name: linkName, url: linkUrl, branchId }));
    setLinkName('');
    setLinkUrl('');
    setShowNewInputs(false); // Скрывает инпуты после сохранения
  }



  const handleSocialLinkDelete = (socialLinkId) => {
    // console.log('~ socialLinkId = ', socialLinkId);
    const confirmDelete = window.confirm('Удалить эту ссылку?');
    if (confirmDelete) {
      // console.log('~ удаляемая ссылка socialLinkId =', socialLinkId);
      dispatch(deleteSocialLink({ socialLinkId, branchId }));
    } else {
      // console.log('~ Удаление ссылки отменено');
    }
  };

  return (
    <div className="bg-slate-100 mt-6 pb-6">
      <h5 className="pt-4 pl-4 text-xl">Ссылки на карты и соц. сети</h5>
      <div className="mt-6 ml-6">
        {socialLinks &&
          socialLinks.length > 0 &&
          socialLinks[0] !== null &&
          socialLinks.map((socialLink) => (
            <div key={socialLink.id} className="flex flex-col mt-3">
              <div>
                <div>
                  <div>
                  <p className='font-thin text-xs'>Название соц. сети или карт</p>
                    <div class="flex rounded-md">

                      <input
                        type="text"
                        placeholder="Название столика (места). Видно только в телеграм боте"
                        class="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        value={socialLink.name}
                        onChange={(e) => handleSocialLinksChange(e, socialLink.id, 'name')}
                        onBlur={() => saveNameChanges(socialLink.name, socialLink.id)}
                      />
                    </div>
                  </div>
                  <div>
                    <p className='font-thin text-xs'>Ссылка</p>
                    <div class="flex rounded-md ">
                      <input
                        type="text"
                        placeholder="Ссылка"
                        class="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        name="url"
                        value={socialLink.url}
                        onChange={(e) => handleSocialLinksChange(e, socialLink.id, 'url')}
                        onBlur={() => saveUrlChanges(socialLink.url, socialLink.id)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12 ">
                  {' '}
                  <RiDeleteBinLine
                    className="cursor-pointer mx-2 text-slate-600 mr-4"
                    onClick={() => handleSocialLinkDelete(socialLink.id)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="ml-6">
        {showNewInputs ? (
          <div className="mt-6 pt-4 flex flex-col">
            <div>
              <p className="mb-2 font-semibold">Новая ссылка</p>
            </div>
            <div className="mr-6">
              
              <input
                type="text"
                value={linkName}
                onChange={(e) => setLinkName(e.target.value)}
                className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Название карты или соц. сети (Яндекс, Гугл, 2Гис и т.д.)"
              />
            </div>
            <div className="mt-3 mr-6">
              
              <input
                type="text"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border border-slate-300 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Ссылка"
              />
            </div>
            <hr />
            <div className="flex flex-row gap-4 mt-3">
              <div className="">
                <button
                  className=" button-cancel"
                  onClick={addNewSocialLinkCancel}>
                  Отмена
                </button>
              </div>
              <div>
                <button
                  className="button-s"
                  onClick={addNewSocialLink}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        ) : (
          <button
            className="button-s"
            onClick={toggleNewSocialLinks}>
            Добавить ссылку
          </button>
        )}
      </div>
    </div>
  );
};

export default SocialLinks;
