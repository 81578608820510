import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';


const ProtectedRoute = () => {
  const { isAuth, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Или компонент загрузки
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};


export default ProtectedRoute;