import React from 'react';
import Sidebar from '../components/Sidebar';
import BranchEditTips from '../components/tips/BranchEditTips';
import { useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectBranchById } from '../store/slices/branchSlice';

const EditBranchPage = () => {
  //  const location = useLocation();
  const { branchId } = useParams();
  console.log(' ~ branchId: ', branchId);
  const branch = useSelector((state) => selectBranchById(state, parseInt(branchId)));

  return (
    <div className="flex w-full">
      <div>
        <Sidebar />
      </div>
      <div className="w-full">
        <div className=" py-2 px-4 font-medium shadow-md ">
          <p>
            {branch.name} {', '} {branch.city}
            {', '}
            {branch.address}
          </p>
        </div>
        <div className='pl-4'>
          <p className="text-2xl  pt-6">Настройка чаевых</p>
          
          <BranchEditTips branchId={branchId} />
        </div>
      </div>
    </div>
  );
};

export default EditBranchPage;
