import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBranchToTips,
  addEmployee,
  checkOrCreateBranchTips,
  fetchCloudTipsId,
  sendSms,
} from '../../store/slices/tipsSlice';
import TipsRecipientCard from './TipsRecipientCard';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import InputMask from 'react-input-mask';
import { selectBranchById } from '../../store/slices/branchSlice';

const BranchEditTips = ({ branchId }) => {
  const dispatch = useDispatch();
  const [employeeName, setEmployeeName] = useState('');
  const [employeePhone, setEmployeePhone] = useState('');
  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const branch = useSelector((state) => selectBranchById(state, parseInt(branchId)));

  const phoneNumberExists = useSelector((state) => state.tips.phoneNumberExists);

  // Получаем данные из Redux состояния
  const tipsBranches = useSelector((state) => state.tips.tipsBranches);
  const tipsRecipients = useSelector((state) => state.tips.tipsRecipients);

  // Находим филиал с помощью branchId
  const selectedBranch = tipsBranches.find((branch) => branch.branch_id === parseInt(branchId));
  // Функция для нахождения получателей чаевых, работающих в выбранном филиале
  const getRecipientsInBranch = () => {
    return tipsRecipients.filter((recipient) => selectedBranch.recipients.includes(recipient.id));
  };

  // проверяет, можно ли добавить сотрудника
  const handleCheckEmployee = () => {
    if (!showEmployeeForm) {
      dispatch(checkOrCreateBranchTips(branchId));
      setShowEmployeeForm(true); // Показать форму только после нажатия кнопки
    }
  };

  const handleAddEmployee = () => {
    if (employeeName === '') {
      alert('Имя сотрудника обязательно!');
      return;
    }
    if (employeePhone === '') {
      alert('Телефон сотрудника обязателен!');
      return;
    }
    const formattedPhone = formatPhoneNumber(employeePhone);
    dispatch(addEmployee({ employeeName, employeePhone: formattedPhone, cloudtipsId, branchId }));
    setShowEmployeeForm(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'RU');
      if (parsedNumber) {
        return parsedNumber.format('E.164');
      }
    } catch (error) {
      console.error('Invalid phone number:', error);
    }
    return phoneNumber;
  };

  // добавляем филиал в систему чаевых
  const handleConnectBranch = () => {
    dispatch(addBranchToTips({ branchName: branch.name, branchId }));
  };

  const handleSendSms =()=>{
    dispatch(sendSms())
  }

  const cloudtipsId = useSelector((state) => state.tips.cloudtipsId);

  useEffect(() => {
    dispatch(fetchCloudTipsId(branchId));
  }, [dispatch, branchId]); // Зависимости useEffect

  // Если филиал не найден, возвращаем создание филиала
  if (!selectedBranch) {
    return (
      <div>
        <div className="flex flex-row">
          <p>
            {' '}
            Подключить филиал &nbsp;
            <span className="font-semibold">
              {' '}
              {branch.name}
              {', '} {branch.city} {', '}
              {branch.address}
            </span>{' '}
            &nbsp; к системе получения чаевых?
          </p>
        </div>
        <div className="mt-3">
          <button className="button-s" onClick={handleConnectBranch}>
            Да, подключить
          </button>
        </div>
      </div>
    );
  }

  // Получаем список получателей чаевых, работающих в выбранном филиале
  const recipientsInBranch = getRecipientsInBranch();

  return (
    <div>
      <div className="text-sm font-extralight">ID филиала: {cloudtipsId}</div>
      <div>
        После добавления сотрудника в филиал отправьте ему инструкцию по регистрации в системе:{' '}
        <a href="https://restolink.ru/help/recipient-attach">
          {' '}
          https://restolink.ru/help/recipient-attach
        </a>
      </div>

      {/*  <div>{branchId}</div> */}
      <div className="mt-6">
        <button className="button-s" onClick={handleCheckEmployee}>
          Добавить нового сотрудника
        </button>
        {phoneNumberExists && (
          <div className='mt-6'>
            {' '}
            <p>Такой пользователь уже существует в системе чаевых CloudTips</p>{' '}
            <p>Для его привязки необходимо отправить сотруднику на его номер телефона код: </p>{' '}
            <p>{employeePhone}</p>
            <div className='mt-2'>
            <button className='button-s' onClick={handleSendSms}>Отправить код</button>
            </div>
          </div>
        )}

        {showEmployeeForm && cloudtipsId && (
          <div>
            <div className="text-field text-field_floating-2 mt-6">
              <input
                className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
                type="text"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
                placeholder="Имя сотрудника"
                id="name"
              />
              <label class="text-field__label" for="name">
                Имя сотрудника
              </label>
            </div>

            <div className="text-field text-field_floating-2 mt-6">
              <InputMask
                className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
                mask="+7 (999) 999-99-99"
                maskChar="_"
                id="phone"
                value={employeePhone}
                onChange={(e) => setEmployeePhone(e.target.value)}
                placeholder="Телефонный номер сотрудника"
              />
              <label class="text-field__label" for="phone">
                Телефонный номер
              </label>
            </div>
            <div className="mt-3">
              <button className="button-s" onClick={() => handleAddEmployee()}>
                Сохранить
              </button>
            </div>
          </div>
        )}
      </div>
      <div>
        <h2 className="text-lg font-medium mt-5 pb-3">Сотрудники привязанные к этому филиалу</h2>
        {recipientsInBranch.length > 0 ? (
          recipientsInBranch.map((recipient) => (
            <div key={recipient.id}>
              <TipsRecipientCard
                fullname={recipient.fullname}
                id={recipient.id}
                cloudtips_user_id={recipient.user_id}
                status={'added'}
                photo_url={recipient.photo_url}
                phone_number={recipient.phone_number}
                branchIdCT={cloudtipsId}
              />
            </div>
          ))
        ) : (
          <p className="pl-5 font-light ">Нет сотрудников привязанных к этому филиалу</p>
        )}
      </div>

      <div>
        <h2 className="text-lg font-medium mt-8 pb-4">Все доступные сотрудники</h2>
        {tipsRecipients.filter((recipient) => !selectedBranch.recipients.includes(recipient.id))
          .length > 0 ? (
          tipsRecipients
            .filter((recipient) => !selectedBranch.recipients.includes(recipient.id))
            .map((recipient) => (
              <div key={recipient.id} className="">
                <div>
                  <TipsRecipientCard
                    fullname={recipient.fullname}
                    id={recipient.id}
                    photo_url={recipient.photo_url}
                    cloudtips_user_id={recipient.user_id}
                    status={'notadded'}
                    phone_number={recipient.phone_number}
                    branchIdCT={cloudtipsId}
                  />
                </div>
              </div>
            ))
        ) : (
          <p className="pl-5 font-light">Нет доступных сотрудников</p>
        )}
      </div>
    </div>
  );
};

export default BranchEditTips;
